import { configReducer } from './modules/config/config';
import { digestReducer } from './modules/digest/digest';
import { domainReducer } from './modules/domain/domain';
import { housingInfoReducer } from './modules/housing_info';
import { buildingTypeReducer } from './modules/search/building_types';
import { priceReducer } from './modules/search/price';
import { roomsReducer } from './modules/search/rooms';
import { squareReducer } from './modules/search/square';
import { suburbanOfferFilterReducer } from './modules/search/suburban_offer_filter';
import { typeReducer } from './modules/search/type';
import { userGeoReducer } from './modules/search/user_geo';
import { yearReducer } from './modules/search/year';
import { serviceReducer } from './modules/services';
import { topThreeReducer } from './modules/top_three';
import { vacanciesReducer } from './modules/vacancies';
import { userReducer } from './user';

export const oldReducers = {
  buildingType: buildingTypeReducer,
  config: configReducer,
  digest: digestReducer,
  domain: domainReducer,
  housingInfo: housingInfoReducer,
  price: priceReducer,
  rooms: roomsReducer,
  service: serviceReducer,
  square: squareReducer,
  suburbanOfferFilter: suburbanOfferFilterReducer,
  topThree: topThreeReducer,
  type: typeReducer,
  user: userReducer,
  userGeo: userGeoReducer,
  vacancies: vacanciesReducer,
  year: yearReducer,
};
