import * as React from 'react';
import { connect } from 'react-redux';

import { IUrls } from '../../types/urls';
import { IDeveloper, ISpecialist, ISpecialists } from '../../types/specialist';
import { IRegionsData } from '../../types/regions_data';
import { IApplicationState } from '../../types/redux';
import { declineNumeric } from '../../utils/format_helper';
import { trackSpecialist } from '../../utils/helpers/metrics_helper';
import { SpecialistBlock } from '../../components/specialist_block/specialist_block';

interface IProps {
  isFetching: boolean;
  section: string;
  popularPresented: boolean;
  realtors: ISpecialist[];
  agencies: ISpecialist[];
  developers: IDeveloper[];
  regionsData: IRegionsData;
  urls: IUrls;
}

interface ISpecProps {
  section: string;
  popularPresented: boolean;
}

class Specialists extends React.Component<IProps> {
  private static getSummaryRealtors = (s: ISpecialist) =>
    `${s.offersCount} ${declineNumeric(s.offersCount, ['предложение', 'предложения', 'предложений'])}`;

  private static getSummaryAgencies = (s: ISpecialist) => Specialists.getSummaryRealtors(s);

  private static getSummaryDevelopers = (s: ISpecialist) =>
    `${s.offersCount} ${declineNumeric(s.offersCount, ['квартира', 'квартиры', 'квартир'])}` +
    ` в ${s.newobjectsCount} ЖК `;

  private getLivingTitle = () =>
    this.props.section === 'new-buildings'
      ? this.props.regionsData.subdomainRegionAllowed
        ? `Застройщики ${this.props.regionsData.subdomainRegionInflectedTitleLoct}`
        : 'Застройщики в Москве'
      : 'Застройщики';

  public render() {
    const realtors: ISpecialists = {
      key: 'realtor',
      title: 'Риелторы',
      specialists: this.props.realtors,
      url: '/realtors/',
      urlTitle: 'Все риелторы',
      isRoundPhoto: true,
      getSummary: Specialists.getSummaryRealtors,
    };

    const agencies: ISpecialists = {
      key: 'agents',
      title: 'Агентства',
      specialists: this.props.agencies,
      url: '/agentstva/',
      urlTitle: 'Все агентства',
      getSummary: Specialists.getSummaryAgencies,
    };

    const developersArray: ISpecialist[] | null =
      this.props.developers &&
      this.props.developers.map((item: IDeveloper) => ({
        id: item.name,
        url: item.detailFullUrl,
        imageUrl: item.logoFullUrl,
        title: item.name,
        offersCount: item.offersCount,
        newobjectsCount: item.newobjectsCount,
      }));

    const developers: ISpecialists = {
      key: 'developers',
      title: this.getLivingTitle(),
      specialists: developersArray,
      url: this.props.urls.developers,
      urlTitle: 'Все застройщики',
      getSummary: Specialists.getSummaryDevelopers,
    };

    const specialists = [realtors, agencies, developers].filter(arr => arr.specialists && arr.specialists.length > 2);

    if (specialists.length > 0) {
      return (
        <SpecialistBlock
          popularPresented={this.props.popularPresented}
          specialists={specialists}
          // tslint:disable-next-line:no-unnecessary-callback-wrapper
          onItemClick={(eventLabel: string) => trackSpecialist(eventLabel)}
          isFetching={this.props.isFetching}
        />
      );
    } else {
      return <div />;
    }
  }
}

const SpecialistsWrapper = connect((state: IApplicationState, props: ISpecProps) => ({
  isFetching: state.mainpage.isFetching,
  realtors: state.mainpage.realtors,
  agencies: state.mainpage.agencies,
  developers: state.mainpage.developers,
  regionsData: state.mainpage.regionsData,
  urls: state.mainpage.urls,
  popularPresented: props.popularPresented,
  section: props.section,
}))(Specialists);

export { SpecialistsWrapper as Specialists };
