/**
 * Возвращает номер дня недели с учётом того, что неделя начинается с понедельника.
 * Диапазон значений остался тот же, от 0 до 6, но 0 теперь понедельник, а 6 — воскресенье.
 */
export const getFirstWeekDayOfMonth = (month: number, year: number): number => {
  const firstWeekDayOfMonth = new Date(year, month, 1).getDay();

  if (firstWeekDayOfMonth === 0) {
    return 6;
  }

  return firstWeekDayOfMonth - 1;
};
