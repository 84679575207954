import * as React from 'react';

import { ITopGroup } from '../../types/top_three';
import { Link } from '../link/link';
import { SalesBlock } from '../../containers/popular_list/sales_block';
import { RentsBlock } from '../../containers/popular_list/rents_block';

interface IProps {
  onPromoClick(eventLabel: string): void;
  showLinkToAll: boolean;
  salesId?: string;
  salesTitle?: string;
  salesGroups?: ITopGroup[];
  salesAllLink?: string;
  rentsId?: string;
  rentsTitle?: string;
  rentsGroups?: ITopGroup[];
  rentsAllLink?: string;
  services?: React.ReactNode;
}

class TopThree extends React.Component<IProps> {
  public render() {
    const s = require('./top_three.css');

    return (
      <div className={s['c-popular-container']}>
        <h2 className={s['c-popular-title']}>
          Популярные объявления
          <Link
            href="/promo/adv/#top3"
            className={`${s['c-popular-title-additional-link']}`}
            onClick={() => this.props.onPromoClick('from_main_page_how_to_get')}
          >
            Как сюда попасть?
          </Link>
        </h2>
        {this.props.salesId && (
          <SalesBlock
            id={this.props.salesId}
            title={this.props.salesTitle || ''}
            groups={this.props.salesGroups || []}
          />
        )}
        {this.props.salesId && (
          <Link className={`${s['c-link-default']} ${s['c-all-propositions']}`} href={this.props.salesAllLink || '#'}>
            {this.props.showLinkToAll && 'Все предложения'}
          </Link>
        )}
        {this.props.rentsId && (
          <RentsBlock
            id={this.props.rentsId}
            title={this.props.rentsTitle || ''}
            groups={this.props.rentsGroups || []}
          />
        )}
        {this.props.rentsId && (
          <Link className={`${s['c-link-default']} ${s['c-all-propositions']}`} href={this.props.rentsAllLink || '#'}>
            {this.props.showLinkToAll && 'Все предложения'}
          </Link>
        )}
        {this.props.services && this.props.services}
      </div>
    );
  }
}

export { TopThree };
