import { IAdFoxBannerOnLoadData } from '@cian/adfox-component';
import * as React from 'react';

import { AdfoxNew, IAdfoxNewProps } from '../adfox/adfoxNew';
import { ADFOX_MEDIA_BANNER_LABEL, ADFOX_NEW_MEDIA_CONSTANTS } from '../adfox/constants';

import { HeroContainer } from '../../containers/HeroContainer';
import { Marquee } from './Marquee';
import { mergeStyles } from '@cian/utils';
import { FiltersBackground } from '../FiltersBackground';

const s = require('./filters_block.css');

interface IProps {
  section: string;
  backgroundImageUrl?: string;
  defaultBackgroundImageUrl?: string;
  backgroundShown: boolean;
  onLoadAdfox?(context: IAdFoxBannerOnLoadData): void;
  onStubAdfox?(): void;
  mediaInfoText?: string;
  mediaNote?: string;
  isNewFilters: boolean;
  children?: React.ReactNode;
}

function AdfoxPropsAreEqual(oldProps: IAdfoxNewProps, newProps: IAdfoxNewProps) {
  return oldProps.section === newProps.section;
}

const MemoAdfox = React.memo(AdfoxNew, AdfoxPropsAreEqual);

export const FiltersBlock: React.FC<IProps> = props => {
  const { isNewFilters } = props;
  const isDailyRent = props.section === 'daily-rent';

  React.useEffect(() => {
    /* на посуточной не нужна реклама */
    if (isDailyRent) {
      if (props.onStubAdfox) {
        props.onStubAdfox();
      }
    }
  }, []);

  return (
    <div
      data-testid="FiltersBlock"
      {...mergeStyles(s['c-filters'], isNewFilters && s['c-filters--new'], isDailyRent && s['c-filters-dailyrent'])}
    >
      <div className={s['c-filters-content']}>
        <div className={s['c-filters-inner']}>
          {isNewFilters && <HeroContainer />}
          {!isNewFilters && props.children}
          <Marquee mediaNote={props.mediaNote} hasNewPlacement={isNewFilters} />
        </div>
        {/* на посуточной не нужна реклама */}
        {!isDailyRent && (
          <MemoAdfox
            constants={ADFOX_NEW_MEDIA_CONSTANTS}
            section={props.section}
            onLoad={props.onLoadAdfox}
            onStub={props.onStubAdfox}
            label={ADFOX_MEDIA_BANNER_LABEL}
            withPostView
          />
        )}
      </div>
      {props.backgroundShown && (
        <FiltersBackground
          section={props.section}
          imageUrl={props.backgroundImageUrl}
          defaultImageUrl={props.defaultBackgroundImageUrl}
        />
      )}
    </div>
  );
};
