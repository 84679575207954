import { connect } from 'react-redux';

import { purgePriceDataAction, setMaxPrice, setMinPrice, setPriceTypeAction } from '../../actions/filters';
import { FilterCoworkingPrice } from '../../components/FilterCoworkingPrice';
import { IApplicationState } from '../../types/redux';

import { priceGteSelector } from './internal/selectors/priceGteSelector';
import { priceLteSelector } from './internal/selectors/priceLteSelector';
import {
  FilterCoworkingPriceContainerDispatchProps,
  FilterCoworkingPriceContainerOwnProps,
  FilterCoworkingPriceContainerStateProps,
} from './types';

export const FilterCoworkingPriceContainer = connect<
  FilterCoworkingPriceContainerStateProps,
  FilterCoworkingPriceContainerDispatchProps,
  FilterCoworkingPriceContainerOwnProps,
  IApplicationState
>(
  state => ({
    gte: priceGteSelector(state),
    lte: priceLteSelector(state),
  }),
  {
    onDestroy: purgePriceDataAction,
    onMaxPriceChange: setMaxPrice,
    onMinPriceChange: setMinPrice,
    onPriceTypeChange: setPriceTypeAction,
  },
)(FilterCoworkingPrice);
