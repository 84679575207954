import * as R from 'ramda';
import { connect } from 'react-redux';

import { TThunkDispatch, IApplicationState } from '../../types/redux';
import { selectRoomType } from '../../actions/filters';
import { FilterRoomType, IFilterRoomTypeProps } from '../../components/FilterRoomType';
import { ERoomType } from '../../../packages/api-models/common/json_query';

type TFilterRoomTypeStateProps = Pick<IFilterRoomTypeProps, 'value'>;
type TFilterRoomTypeDispatchProps = Pick<IFilterRoomTypeProps, 'onSelect'>;

export function mapStateToProps(state: IApplicationState): TFilterRoomTypeStateProps {
  const { jsonQuery } = state.filters;

  return {
    value: R.pathOr([], ['room', 'value'], jsonQuery),
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TFilterRoomTypeDispatchProps {
  return {
    onSelect: (roomType: ERoomType) => dispatch(selectRoomType(roomType)),
  };
}

export const FilterRoomTypeContainer = connect(mapStateToProps, mapDispatchToProps)(FilterRoomType);
