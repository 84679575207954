import { ca } from '@cian/analytics';

export function trackEntityTeaserShow(offerId: number, position: number) {
  ca('teaser', {
    product: {
      id: offerId,
      name: 'show_card',
      extra: {
        offerType: 'offer',
      },
      position,
      screen: 'Home',
      block: 'Home_popular',
    },
  });
}
