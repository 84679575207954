const defaultQueryParams = {
  cian_source: 'cian',
  cian_medium: 'trap',
  cian_campaign: 'mainpage',
  cian_content: 'header_btn',
};

export const createMortgageMainUrl = (
  propertyAmount: number | undefined,
  initialPayment: number | undefined,
): string => {
  const urlParams = new URLSearchParams(defaultQueryParams);

  if (propertyAmount) {
    urlParams.append('property_cost', propertyAmount.toString());
  }

  if (initialPayment) {
    urlParams.append('initial_payment', initialPayment.toString());
  }

  return `/ipoteka-main/?${urlParams.toString()}`;
};
