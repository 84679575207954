import * as React from 'react';
import { SpecialistList } from '../specialist_list/specialist_list';
import { ISpecialists } from '../../types/specialist';

const s = require('./specialist_block.css');

interface IProps {
  popularPresented: boolean;
  specialists: ISpecialists[];
  isFetching: boolean;
  onItemClick(eventLabel: string): void;
}

export const SpecialistBlock = (props: IProps) =>
  !props.isFetching ? (
    <div
      className={`${s['c-specialists']}
                        ${props.popularPresented ? '' : s['c-specialists-horizontal']}`}
    >
      <h2 className={s['c-specialists-title']}>Специалисты</h2>
      {props.specialists.map(specialists => (
        <SpecialistList
          popularPresented={props.popularPresented}
          onItemClick={() => props.onItemClick(specialists.key)}
          specialists={specialists}
          key={specialists.title}
        />
      ))}
    </div>
  ) : (
    <div></div>
  );
