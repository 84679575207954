export enum EGeocodeStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface IGeocodeState {
  status: EGeocodeStatus;
}
