import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackSubsidisedMortgageBannerShow, trackSubsidisedMortgageBannerClick } from './tracking';
import { SubsidisedMortgageBanner } from '../../components/SubsidisedMortgageBanner';
import { selectRegionId } from '../../selectors/filters/selectRegionId';
import { useApplicationContext } from '../../utils/applicationContext';
import { getRangeValue } from '../../utils/jsonQuery';
import { selectJsonQuery } from '../../selectors/filters/selectJsonQuery';

type THeaderBannerInfo = {
  title: string;
  subtitle: string;
  backgroundColor: string;
  backgroundImage: string;
  defaultUrl: string;
  focusRegionIds: number[];
};

export const SubsidisedMortgageBannerContainer: React.FC = () => {
  const { config } = useApplicationContext();
  const regionId = useSelector(selectRegionId);
  const jsonQuery = useSelector(selectJsonQuery);
  const isEnabled = config.get<boolean>('frontend-mainpage.subsidizedMortgage.Enabled');
  const bannerInfo = config.get<THeaderBannerInfo>('subsidizedMortgage.desktopMainpageBannerInfo');

  const handleShow = React.useCallback(() => {
    trackSubsidisedMortgageBannerShow();
  }, []);

  const handleClick = React.useCallback(() => {
    trackSubsidisedMortgageBannerClick();
  }, []);

  const url = React.useMemo(() => {
    /* istanbul ignore next */
    if (!bannerInfo) {
      return '';
    }

    const url = new URL(bannerInfo.defaultUrl);
    const price = getRangeValue('price')(jsonQuery);

    /* istanbul ignore next */
    if (price?.lte) {
      url.searchParams.set('maxprice', String(price.lte));
    }

    /* istanbul ignore next */
    if (regionId) {
      url.searchParams.set('region', String(regionId));
    }

    return url.toString();
  }, [bannerInfo, regionId, jsonQuery]);

  /* istanbul ignore next */
  if (!isEnabled || !bannerInfo || !regionId || !bannerInfo.focusRegionIds.includes(regionId)) {
    return null;
  }

  const { title, subtitle, backgroundColor, backgroundImage } = bannerInfo;

  return (
    <SubsidisedMortgageBanner
      title={title}
      subtitle={subtitle}
      url={url}
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}
      onClick={handleClick}
      onShow={handleShow}
    />
  );
};
