import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IReadyBusinessGroupItem } from '../../../../repositories/announcements/entities/AnnouncementReferences/ReadyBusinessGroupItem';
import { businessAppointmentsDataSelector } from '../../../../selectors/businessAppointments/businessAppointmentsDataSelector';
import { filterReadyBusinessTypesSelector } from '../../../../selectors/filters/filterReadyBusinessTypesSelector';
import { TBusinessAppointmentsState } from '../../../../types/businessAppointments';
import { IApplicationState } from '../../../../types/redux';
import { getIsBusinessCategory } from '../../../../utils/getIsBusinessCategory';

export function useBusinessAppointmentsDataSelector(): Array<IReadyBusinessGroupItem> {
  const businessAppointments = useSelector<IApplicationState, TBusinessAppointmentsState['data']>(
    businessAppointmentsDataSelector,
  );

  const readyBusinessTypes = useSelector(filterReadyBusinessTypesSelector);

  return useMemo(() => {
    const groups = Array.of<IReadyBusinessGroupItem>();

    if (!businessAppointments) {
      return groups;
    }

    const { isRentalBusiness, isReadyBusiness } = getIsBusinessCategory(readyBusinessTypes);

    if (isRentalBusiness) {
      groups.push({
        groupType: 'rentalBusiness',
        items: businessAppointments.rentalBusiness.items,
        name: 'Арендный бизнес',
      });
    }

    if (isReadyBusiness) {
      groups.push(...businessAppointments.readyBusiness.groups);
    }

    return groups;
  }, [businessAppointments, readyBusinessTypes]);
}
