// tslint:disable:object-literal-sort-keys
import * as React from 'react';
import map from 'lodash/map';

import { ISearchConfig } from '../../types/search';
import { CommercialPriceFilter, CommercialRentPriceFilter, PriceFilter } from '../filters/price_filter';
import { UserGeoFilter } from '../filters/user_geo_filter';
import { SuburbanOfferFilter } from '../filters/suburban_offer_filter';
import { RoomsFilter, RoomsRentFilter } from '../filters/rooms_filter';
import { FlatTypeFilter } from '../filters/flat_type_filter';
import { FlatSquareFilter, GroundSquareFilter } from '../filters/square_filter';
import { YearFilter } from '../filters/year_filter';

const RoomFiltersSet = [PriceFilter, UserGeoFilter];
const SuburbanFiltersSet = [SuburbanOfferFilter, PriceFilter, UserGeoFilter];
const FlatFiltersSet = [RoomsFilter, PriceFilter, UserGeoFilter];
const FlatRentFiltersSet = [RoomsRentFilter, PriceFilter, UserGeoFilter];
const FlatSaleFiltersSet = [FlatTypeFilter, RoomsFilter, PriceFilter, UserGeoFilter];
const OfficeFiltersSet = [CommercialPriceFilter, FlatSquareFilter, UserGeoFilter];
const OfficeRentFiltersSet = [CommercialRentPriceFilter, FlatSquareFilter, UserGeoFilter];
const SectorFiltersSet = [PriceFilter, GroundSquareFilter, UserGeoFilter];
const SectorSuburbanFiltersSet = [SuburbanOfferFilter, PriceFilter, GroundSquareFilter, UserGeoFilter];
const NewBuildingsFilterSet = [RoomsFilter, YearFilter, PriceFilter, UserGeoFilter];

export const SearchConfig: ISearchConfig = {
  buy: {
    flat: FlatFiltersSet,
    secondary_flat: FlatFiltersSet,
    new_flat: FlatFiltersSet,

    room: RoomFiltersSet,
    part: RoomFiltersSet,
    house: SuburbanFiltersSet,
    housepart: SuburbanFiltersSet,
    townhouse: SuburbanFiltersSet,
    ready: RoomFiltersSet,
    garage: RoomFiltersSet,

    office: OfficeFiltersSet,
    trade_area: OfficeFiltersSet,
    warehouse: OfficeFiltersSet,
    psn: OfficeFiltersSet,
    fastfood: OfficeFiltersSet,
    enterprise: OfficeFiltersSet,
    auto: OfficeFiltersSet,
    building: OfficeFiltersSet,
    domestic: OfficeFiltersSet,

    sector: SectorSuburbanFiltersSet,
  },
  rent_long: {
    flat: FlatRentFiltersSet,

    room: RoomFiltersSet,
    bed: RoomFiltersSet,
    house: RoomFiltersSet,
    housepart: RoomFiltersSet,
    townhouse: RoomFiltersSet,
    garage: RoomFiltersSet,
    ready: RoomFiltersSet,

    office: OfficeRentFiltersSet,
    trade_area: OfficeRentFiltersSet,
    warehouse: OfficeRentFiltersSet,
    psn: OfficeRentFiltersSet,
    fastfood: OfficeRentFiltersSet,
    enterprise: OfficeRentFiltersSet,
    auto: OfficeRentFiltersSet,
    building: OfficeRentFiltersSet,
    domestic: OfficeRentFiltersSet,

    comsector: SectorFiltersSet,
  },
  rent_short: {
    flat: FlatRentFiltersSet,

    room: RoomFiltersSet,
    bed: RoomFiltersSet,
  },
};

export const AbstractFlatSearchConfig: ISearchConfig = {
  ...SearchConfig,
  buy: {
    ...SearchConfig.buy,
    flat: FlatSaleFiltersSet,
    secondary_flat: FlatSaleFiltersSet,
    new_flat: FlatSaleFiltersSet,
  },
};

const filtersMap = (filtersSet: React.ComponentType<{}>[]) =>
  map(filtersSet, (element: React.ComponentClass<{}>, i: number) => React.createElement(element, { key: i }));

export const renderFilters = (
  searchType: string,
  buildingTypes: string[],
  isNew: boolean,
  searchConfig: ISearchConfig,
) => {
  if (isNew) {
    return filtersMap(NewBuildingsFilterSet);
  } else {
    const buildingType = buildingTypes.includes('sector') ? 'sector' : buildingTypes[0];

    return filtersMap(searchConfig[searchType][buildingType]);
  }
};
