import {
  REGION_SUGGEST_GET_FAILED,
  REGION_SUGGEST_GET_SUCCESS,
  SET_MORTGAGE_BUILDING_TYPE_TYPE,
  SET_MORTGAGE_DOWN_PAYMENT_TYPE,
  SET_MORTGAGE_REALTY_PRICE_TYPE,
  SET_MORTGAGE_REGION_TYPE,
  SET_MORTGAGE_TERMS_TYPE,
  SET_REGION_USER_INPUT_TYPE,
} from '../../actions/mortgage';
import { IMortgageState } from '../../types/mortgage';
import { EMortgageBuildingType } from '../../types/mortgage/mortgageType';
import { TReduxActions } from '../../types/redux';

export const defaultMortgageState: Readonly<IMortgageState> = {
  filterBuildingType: EMortgageBuildingType.OldBuilding,
  filterRegionUserInput: '',
  regionSuggests: [],
  analyticsData: {
    rates: {
      newBuilding: undefined,
      used: undefined,
      cottage: undefined,
      land: undefined,
    },
    banks: [],
  },
};

export function mortgageFilters(
  mortgageState: IMortgageState = defaultMortgageState,
  action: TReduxActions,
): IMortgageState {
  switch (action.type) {
    case SET_REGION_USER_INPUT_TYPE:
      return {
        ...mortgageState,
        filterRegionUserInput: action.userInput,
      };
    case REGION_SUGGEST_GET_SUCCESS:
      return {
        ...mortgageState,
        regionSuggests: action.regionSuggests,
        regionSuggestsError: undefined,
      };
    case REGION_SUGGEST_GET_FAILED:
      return {
        ...mortgageState,
        regionSuggests: [],
        regionSuggestsError: action.regionSuggestError,
      };
    case SET_MORTGAGE_REGION_TYPE:
      return {
        ...mortgageState,
        filterRegionId: action.regionId,
        filterRegionTitle: action.regionTitle,
      };
    case SET_MORTGAGE_BUILDING_TYPE_TYPE:
      return {
        ...mortgageState,
        filterBuildingType: action.buildingType,
      };
    case SET_MORTGAGE_TERMS_TYPE:
      return {
        ...mortgageState,
        filterTerms: action.term,
      };
    case SET_MORTGAGE_REALTY_PRICE_TYPE:
      return {
        ...mortgageState,
        filterRealtyPrice: action.price,
      };
    case SET_MORTGAGE_DOWN_PAYMENT_TYPE:
      return {
        ...mortgageState,
        filterDownPayment: action.downPayment,
      };
    default:
      return mortgageState;
  }
}
