import { Action } from 'redux';

import { TThunkAction } from '../../../types/redux';
import { EGeocodeStatus } from '../../../types/filters';
import { ECatalogObjectType, IGeocodeForSearchDetails } from '../../../types/geocode';
import { FILTERS_GEOCODE_TYPE } from '../../../constants/action_types';
import { geocodeCached } from '../../../api/geocodeCached';
import { geocodeForSearch } from '../../../api/geocodeForSearch';
import { getMeta } from '../getMeta';
import { selectGeo } from './selectGeo';
import { setRegion } from '../setRegion';
import { TGeoValue } from '../../../../packages/api-models/common/json_query';

export type TGeocodeType = 'filters/geocode/status_changed';

export interface IGeocodeFailed extends Action<TGeocodeType> {
  type: TGeocodeType;
  status: EGeocodeStatus.Failed;
  error: string;
}

export interface IGeocode extends Action<TGeocodeType> {
  type: TGeocodeType;
  status: EGeocodeStatus.Loading | EGeocodeStatus.Succeed;
}

export type TGeocode = IGeocode | IGeocodeFailed;

export interface IGeocodeParameters {
  query: string;
}

let lastRequestId: Symbol;
// tslint:disable-next-line: no-any
export function geocode({ query }: IGeocodeParameters): TThunkAction<Promise<TGeoValue>> {
  return async (dispatch, getState, context) => {
    const requestId = (lastRequestId = Symbol());

    dispatch<TGeocode>({
      type: FILTERS_GEOCODE_TYPE,
      status: EGeocodeStatus.Loading,
    });

    const {
      config: { subdomain },
    } = getState();
    const { makeRequest, httpApi } = context;

    try {
      const geocodeCachedResult = await geocodeCached({ httpApi, subdomain, query });
      const geocodeItem = geocodeCachedResult.items[0];
      const geocodeForSearchResult = await geocodeForSearch(makeRequest, {
        address: geocodeItem.text,
        kind: geocodeItem.kind,
        coordinates: [geocodeItem.coordinates[1], geocodeItem.coordinates[0]],
      });

      if (geocodeForSearchResult.isParsed || !!geocodeItem.boundedBy) {
        let geoValue: TGeoValue;

        if (geocodeForSearchResult.isParsed) {
          if (geocodeForSearchResult.geoLocationCatalogLink) {
            const { objectType, id } = geocodeForSearchResult.geoLocationCatalogLink;

            switch (objectType) {
              case ECatalogObjectType.Newobject:
                geoValue = {
                  type: 'newobject',
                  id,
                };

                break;
              default:
                throw new Error(`Unexpected geo location catalog objectType '${objectType}'`);
            }

            if (geocodeForSearchResult.regionId) {
              dispatch(setRegion([geocodeForSearchResult.regionId]));
            }

            dispatch(selectGeo(geoValue));
          } else {
            const geoObjectDetails = geocodeForSearchResult.details[geocodeForSearchResult.details.length - 1];

            if (geocodeForSearchResult.regionId) {
              dispatch(setRegion([geocodeForSearchResult.regionId]));
            }

            geoValue = geoObjectDetailsToGeoValue(geoObjectDetails);
            dispatch(selectGeo(geoValue));
          }
        } else if (geocodeItem.boundedBy) {
          const bounds = geocodeItem.boundedBy.map(b => b.map(String) as [string, string]);

          const polygon: [string, string][] = [
            [bounds[0][0], bounds[0][1]],
            [bounds[0][0], bounds[1][1]],
            [bounds[1][0], bounds[1][1]],
            [bounds[1][0], bounds[0][1]],
            [bounds[0][0], bounds[0][1]],
          ];

          if (geocodeForSearchResult.regionId) {
            dispatch(setRegion([geocodeForSearchResult.regionId]));
          }

          geoValue = {
            type: 'polygon',
            name: geocodeItem.name,
            coordinates: polygon,
          };
          dispatch(selectGeo(geoValue));
        } else {
          throw new Error(
            `Unexpected geocode result ${JSON.stringify(geocodeItem)} ${JSON.stringify(geocodeForSearchResult)}`,
          );
        }

        dispatch<TGeocode>({
          type: FILTERS_GEOCODE_TYPE,
          status: EGeocodeStatus.Succeed,
        });

        dispatch(getMeta());

        return geoValue;
      } else {
        throw new Error(
          `Unexpected geocode result ${JSON.stringify(geocodeItem)} ${JSON.stringify(geocodeForSearchResult)}`,
        );
      }
    } catch (error) {
      // context.logger.error(error);
      console.error(error);

      if (requestId === lastRequestId) {
        dispatch<TGeocode>({
          type: FILTERS_GEOCODE_TYPE,
          status: EGeocodeStatus.Failed,
          error: 'Произошла непредвиденная ошибка',
        });
      }

      throw error;
    }
  };
}

const geoTypesMap = {
  Location: 'location',
  Street: 'street',
  Road: 'highway',
  District: 'district',
  Underground: 'underground',
  House: 'house',
};

function geoObjectDetailsToGeoValue(geoObjectDetails: IGeocodeForSearchDetails): TGeoValue {
  const { geoType, id } = geoObjectDetails;

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: (geoTypesMap as any)[geoType],
    id,
  } as TGeoValue;
}
