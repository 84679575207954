import * as React from 'react';
import filter from 'lodash/filter';
import { PromoSubmenu } from './promo_submenu';
import { ISeoBlockItem } from '../../types/seo_urls';

interface IProps {
  promoUrls: ISeoBlockItem[];
}

interface IState {
  openedID: string;
}

export class PromoMenu extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    const links = this.getPromoUrls();
    if (links.length !== 1) {
      this.state = { openedID: '' };
    } else {
      this.state = { openedID: links[0].title };
    }
  }

  public openTab(tabID: string) {
    this.setState({ openedID: this.state.openedID === tabID ? '' : tabID });
  }

  public render() {
    const links = this.getPromoUrls();

    return (
      <div className="cg-col-5 cg-skip-1">
        <div className="c-popular-links">
          {links.map((promoUrl: ISeoBlockItem, i: number) => (
            <PromoSubmenu
              key={`promo_submenu_${i}`}
              opened={this.state.openedID === promoUrl.title}
              onOpened={() => {
                this.openTab(promoUrl.title);
              }}
              {...promoUrl}
            />
          ))}
        </div>
      </div>
    );
  }

  private getPromoUrls() {
    return filter(this.props.promoUrls, (item: ISeoBlockItem) => item.links.length > 0);
  }
}
