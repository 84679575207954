import { CustomTooltip, Outside } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';
import * as React from 'react';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import intersection from 'lodash/intersection';
import { suburbanTypes } from '../../../reducers/modules/search/building_types';
import { filterOptions, onInsideClick } from './helpers';
import { filterChange, filterClick } from './tracking';
import {
  IBuildingTypeParsed,
  IBuildingTypesConfig,
  IBuildingTypesConfigOptions,
  IBuildingTypesRow,
} from '../../../types/filters/building_types';
import { ISearchConfig } from '../../../types/search';

const s = require('../search_filters.css');

interface IProps {
  value: string[];
  type: string;
  buildingTypes: IBuildingTypesConfig;
  searchConfig: ISearchConfig;
  isShortMode?: boolean;
  customButtonText?: string;
  onChange(value: string, deselect?: boolean): void;
  options?: IBuildingTypesConfig;
  canShowPopupAutoChangeToSuburban?: boolean;
  hidePopupAutoChangeToSuburban?(): void;
}

interface IState {
  opened: boolean;
}

export class BuildingTypeFilterInput extends React.Component<IProps, IState> {
  private typeNames: IBuildingTypesConfigOptions;
  private withCloseOutsideRef = React.createRef<HTMLButtonElement>();

  public componentDidUpdate() {
    const filteredBuildingTypes = this.getFilterOptions();
    const selectedKeys = flatten(map(filteredBuildingTypes, (value: IBuildingTypesRow) => Object.keys(value.options)));

    if (intersection(selectedKeys, this.props.value).length === 0) {
      this.props.onChange(selectedKeys[0]);
    }
  }

  public constructor(props: IProps) {
    super(props);
    this.typeNames = this.getTypeNames();
    this.state = { opened: false };
  }

  public render() {
    const { isShortMode, canShowPopupAutoChangeToSuburban, hidePopupAutoChangeToSuburban } = this.props;
    const text = this.props.value
      .filter(Boolean)
      .map(val => this.typeNames[val] && this.typeNames[val].values[1])
      .join(', ');

    return (
      <div
        className={`${s['c-filters-field']} ${s['c-filters-field-property']}`}
        onClick={onInsideClick(() => filterClick(isShortMode ? 'Category' : 'OfferType'))}
      >
        <Outside onOutside={() => this.close()}>
          <div className={s['c-several-filters-dropdown-container']}>
            <button
              ref={this.withCloseOutsideRef}
              title={this.props.customButtonText || undefined}
              className={`${s['c-filters-field-content']}
                    ${s['c-filters-field-content-arrow']}
                    ${this.state.opened ? s['c-filters-dropdown-opened'] : ''}`}
              onClick={() => this.togglePopup()}
            >
              {this.props.customButtonText || text}
            </button>
            {this.renderPopup()}
          </div>
        </Outside>
        {canShowPopupAutoChangeToSuburban && hidePopupAutoChangeToSuburban && (
          <Outside onOutside={hidePopupAutoChangeToSuburban}>
            <CustomTooltip
              content="Тип недвижимости изменен, потому что вы выбрали коттеджный поселок"
              anchorRef={this.withCloseOutsideRef}
              open
              placement="bottom-start"
              theme="black"
            />
          </Outside>
        )}
      </div>
    );
  }

  private getFilterOptions(): IBuildingTypesConfig {
    const { type, buildingTypes, searchConfig, options: options } = this.props;
    if (options) {
      return options;
    }

    return filterOptions(type, buildingTypes, searchConfig);
  }

  private togglePopup() {
    this.setState({ opened: !this.state.opened });
  }

  private close() {
    this.setState({ opened: false });
  }

  private optionChecked(index: string): boolean {
    return this.props.value.includes(index);
  }

  private renderPopup() {
    const { isShortMode } = this.props;

    const filteredBuildingTypes = this.getFilterOptions();

    if (this.state.opened) {
      return (
        <div className={s['c-filters-dropdown']}>
          <div
            {...mergeStyles(
              s['c-filters-dropdown-content'],
              s['c-filters-property-content'],
              isShortMode && s['c-filters-property-content--short'],
            )}
          >
            {' '}
            {map(filteredBuildingTypes, (option: IBuildingTypesRow, index: string) => (
              <div key={`option${index}`} className={s['c-filters-property-row']}>
                <span className={s['c-filters-property-title']}>{option.name}</span>
                <ul {...mergeStyles(s['c-filters-property-list'], isShortMode && s['c-filters-property-list--short'])}>
                  {' '}
                  {map(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    sortBy(toPairs(option.options), (suboption: any) => (suboption as any)[1]['order']),
                    (opt: IBuildingTypeParsed) => (
                      <li className={s['c-filters-property-list-item']} key={`subopt${opt[0]}`}>
                        <label role="button" className={s['c-filters-property-lbl']}>
                          <input
                            role="radio"
                            type="checkbox"
                            aria-checked={this.optionChecked(opt[0])}
                            name="property-type"
                            className={s['c-filters-property-radio']}
                            onChange={() => {
                              filterChange(isShortMode ? 'Category' : 'OfferType', opt[0]);
                              this.props.onChange(opt[0], this.props.value.includes(opt[0]));
                              if (!suburbanTypes.includes(opt[0])) {
                                this.close();
                              }
                            }}
                            checked={this.optionChecked(opt[0])}
                          />
                          <span className={s['c-filters-property-radio-lbl']}>{opt[1].values[0]}</span>
                        </label>
                      </li>
                    ),
                  )}{' '}
                </ul>
              </div>
            ))}{' '}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  private getTypeNames(): IBuildingTypesConfigOptions {
    const { buildingTypes } = this.props;

    return Object.assign(
      {},
      ...map(buildingTypes, (option: IBuildingTypesRow) => option.options),
    ) as IBuildingTypesConfigOptions;
  }
}
