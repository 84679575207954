import { EPageKind } from '../types/page';

export function getEventCategoryFromPageKind(pageKind: EPageKind): string {
  switch (pageKind) {
    case EPageKind.Sale:
      return 'main_sale';
    case EPageKind.Rent:
      return 'main_rent';
    case EPageKind.DailyRent:
      return 'main_daily';
    case EPageKind.Commercial:
      return 'main_commercial';
    case EPageKind.ResidentialComplexes:
      return 'main_newobject';
    case EPageKind.Default:
    default:
      return 'main_page';
  }
}
