import { ca } from '@cian/analytics';

export function trackSubsidisedMortgageBannerClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'Click',
    label: 'MortgageDiscountSecondary',
  });
}

export function trackSubsidisedMortgageBannerShow() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'Show',
    label: 'MortgageDiscountSecondary',
  });
}
