import { IRangeDate } from '../../types/calendar';

type TRangeDate = IRangeDate | null;

export const getRangeDate = (date: Date | null): TRangeDate => {
  if (!date) {
    return null;
  }

  return {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
  };
};
