// tslint:disable:object-literal-sort-keys
import { ca } from '@cian/analytics';
import { parseCookies } from '@cian/utils';
import uniq from 'lodash/uniq';

import { getOfferType } from '../components/search_filters/filters/utils';
import { IJsonQuery } from '../reducers/modules/search/search';
import { TUser } from '../types/user';

interface IMapSectionToPage {
  [key: string]: string;
}

/* istanbul ignore next */
export const pagesWithYandexGeo = ['index', 'sale', 'rent'];

const mapSectionToPage: IMapSectionToPage = {
  index: 'main_page',
  sale: 'main_sale',
  rent: 'main_rent',
  'new-buildings': 'main_newobject',
  commercial: 'main_commercial',
  'daily-rent': 'main_daily',
};

/* istanbul ignore next */
export function clickGeoSuggest(category: string) {
  ca('event', {
    name: 'oldevent',
    category: mapSectionToPage[category],
    action: 'click_GEO_suggest',
  });
}

/* istanbul ignore next */
export function changeGeoSuggest(category: string, id: number) {
  ca('event', {
    name: 'oldevent',
    category: mapSectionToPage[category],
    action: 'change_GEO_suggest',
    label: id,
  });
}

export function trackSearchEvent(
  action: string,
  category: string,
  query: string,
  searchConditions: IJsonQuery,
  user: TUser,
  abGroup: number,
) {
  const cookies = parseCookies(document.cookie);

  ca('event', {
    action,
    category: mapSectionToPage[category],
    label: query,
    modelVersion: 1,
    name: 'oldevent',
    page: {
      pageType: 'Home',
      siteType: 'desktop',
    },
    partner: {
      CriteoEmail: user.isAuthenticated ? user.criteoEmail : '',
      CriteoNewCustomer: !cookies['_CIAN_GK'] ? 1 : 0,
      CriteoProducts: [],
    },
    searchConditions,
    schemaVersion: 1,
    user: {
      abGroup,
      balance: user.isAuthenticated ? user.balance : '',
      cianUserId: user.isAuthenticated ? user.id : '',
      email: user.isAuthenticated ? user.email : '',
      type: user.isAuthenticated ? user.userType : 'not_authorized',
      userId: user.isAuthenticated ? user.userId : '',
    },
  });
}

/* istanbul ignore next */
export function clickSearchOnMapButtonOnFilter(category: string, query: string) {
  ca('event', {
    name: 'oldevent',
    category: mapSectionToPage[category],
    action: 'button_search_on_map',
    label: query,
  });
}

/* istanbul ignore next */
export function clickUserGeoStrictFilter(category: string) {
  ca('event', {
    name: 'oldevent',
    category: mapSectionToPage[category],
    action: 'click_GEO_suggest',
  });
}

/* istanbul ignore next */
export function changeValueUserGeoStrictFilter(category: string, geoType: string) {
  ca('event', {
    name: 'oldevent',
    category: mapSectionToPage[category],
    action: 'change_GEO_suggest',
    label: geoType,
  });
}

/* istanbul ignore next */
export function trackYandexGeoSuggestClick() {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'Click',
    label: '',
  });
}

/* istanbul ignore next */
export function trackYandexGeoSuggestChange(geoType: string) {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'Change',
    label: `${geoType}/yandex`,
  });
}

/* istanbul ignore next */
export function trackYandexGeoSuggestNotFound(requestText: string) {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'NotFound',
    label: requestText,
  });
}

/* istanbul ignore next */
export function isYandexGeo(category: string): boolean {
  return pagesWithYandexGeo.includes(category);
}

/* istanbul ignore next */
export function trackClickGeoSuggest() {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'Click',
    label: '',
  });
}

/* istanbul ignore next */
export function trackSelectGeoSuggestItem(group: string, type: string) {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'Change',
    label: `${group}/${type}`,
  });
}

/* istanbul ignore next */
export function trackGeoSuggestNotFound(query: string) {
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'NotFound',
    label: query,
  });
}

/* istanbul ignore next */
export function trackAutoOfferChangeToSuburban(id: number, buildingTypes: string[]) {
  const labelOfferType = uniq(buildingTypes.map(getOfferType)).join(',');
  ca('event', {
    name: 'oldevent',
    category: 'GeoSuggest',
    action: 'AutoOfferChange',
    label: `${id}/${labelOfferType}/suburban`,
  });
}

interface ITrackRegionExpansionParams {
  prevRegion: number;
  currentRegion: number;
}

export function trackRegionExpansion(params: ITrackRegionExpansionParams) {
  const { prevRegion, currentRegion } = params;

  ca('eventSite', {
    name: 'oldevent',
    category: 'extend_region',
    action: 'extended',
    page: {
      pageType: 'Home',
      siteType: 'desktop',
      extra: {
        extend_region: {
          previous_region: prevRegion,
          current_region: currentRegion,
        },
      },
    },
  });
}
