import { OFFER_TYPES_RENT_COWORKING } from '../../../../constants/filters/offerTypes';
import { FDealType, FOfferType } from '../../../../utils/category';

import { TCoworkingOfferTypes } from '../../types';

export const coworkingOfferTypes: TCoworkingOfferTypes = [
  {
    dealType: FDealType.RentLongterm,
    coworkingOfferType: FOfferType.Coworking,
    list: OFFER_TYPES_RENT_COWORKING,
  },
];
