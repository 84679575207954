import * as React from 'react';
import cx from 'classnames';
import * as styles from './style.css';
import { ArticleHeading5 } from '@cian/ui-kit';
import { INewbuildingSeoBlockData } from '../../types/newbuildingSeoBlock';

interface INewbuildingSeoBlockProps {
  seoBlock: INewbuildingSeoBlockData;
  onClick: () => void;
}

export const LinksBlock = ({ seoBlock, onClick }: INewbuildingSeoBlockProps) => {
  return (
    <div className={cx(styles['grid'], styles['grid_fit'])} data-testid="NewbuildingSeoLinksBlock">
      <div className={styles['row']}>
        <div className={styles['heading']}>
          <ArticleHeading5 as="h5">{seoBlock.title}</ArticleHeading5>
        </div>
        <ul className={styles['list']}>
          {seoBlock.urls.slice(0, 8).map((link, linkIndex) => (
            <li key={`${seoBlock.title}-${linkIndex}`}>
              <a className={styles['link']} href={link.url} title={link.name}>
                {link.name}
              </a>
            </li>
          ))}
          {seoBlock.urls.length > 8 && (
            <li>
              <button onClick={onClick} className={styles['button']}>
                Все
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};
