export enum ESuggestionsStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface IAddressSuggest {
  displayName: string;
  type: 'geo';
  value: string;
  hl: IYmapsCoord[];
}

export interface ILocationSuggest {
  id: number;
  name: string;
  fullName: string;
  matches: [number, number][];
  type: 'city' | 'district' | 'railway' | 'road';
  regionId?: number;
}

export interface ILine {
  lineColor: string;
}

export interface IUndergroundSuggest {
  id: number;
  name: string;
  fullName: string;
  matches: [number, number][];
  lines: ILine[];
  type: 'underground';
  underConstruction?: boolean;
  releaseYear?: number;
  regionId?: number;
}

export interface IBuildingSuggest {
  id: number;
  name: string;
  fullName: string;
  matches: [number, number][];
  link: string;
  address: string;
  type: 'businessCenter' | 'shoppingCenter' | 'village' | 'newbuilding';
  regionId?: number;
}

export interface ISuggestions {
  addresses?: IAddressSuggest[];
  cities?: ILocationSuggest[];
  districts?: ILocationSuggest[];
  undergrounds?: IUndergroundSuggest[];
  newbuildings?: IBuildingSuggest[];
  businessCenters?: IBuildingSuggest[];
  shoppingCenters?: IBuildingSuggest[];
  railways?: ILocationSuggest[];
  villages?: IBuildingSuggest[];
  roads?: ILocationSuggest[];
  regionalDistricts?: ILocationSuggest[];
  builders?: ILocationSuggest[];
}

export interface ISuggestionsState {
  status: ESuggestionsStatus;
  suggestions: ISuggestions;
  sortOrder: string[];
}
