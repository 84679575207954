import * as React from 'react';
import { ArticleHeading4, UIText2 } from '@cian/ui-kit';
import { IconChevronRight12 } from '@cian/ui-kit/icons';
import cn from 'classnames';

import { ESalesType, ISalesLink } from '../../../../types/salesLink';
import { SALES_TYPE_ICONS } from './constants';
import { getArrowColor, getDescriptionColor, getTitleColor } from './utils';

import * as styles from './SalesLink.css';

interface ISalesLinkProps {
  link: ISalesLink;
  onClick?(link: ISalesLink, event: React.MouseEvent<HTMLAnchorElement>): void;
}

const SALES_TYPE_CLASSES = new Map<ESalesType, string>([
  [ESalesType.BlackFriday, styles['black-friday']],
  [ESalesType.SalesLeader, styles['sales-leader']],
  [ESalesType.SalesStart, styles['sales-start']],
  [ESalesType.UpcomingSale, styles['sales-soon']],
]);

export function SalesLink({ link, onClick }: ISalesLinkProps) {
  const { salesType } = link;

  const iconSource = SALES_TYPE_ICONS.get(salesType);
  const salesTypeClassName = SALES_TYPE_CLASSES.get(salesType);
  const titleColor = getTitleColor(salesType);
  const descriptionColor = getDescriptionColor(salesType);
  const arrowColor = getArrowColor(salesType);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (onClick) {
        onClick(link, event);
      }
    },
    [onClick, link],
  );

  return (
    <a className={cn(styles['link'], salesTypeClassName)} href={link.url} onClick={handleClick} data-testid="SalesLink">
      <div className={styles['container']}>
        {iconSource && <img className={styles['icon']} src={iconSource} alt="" />}
        <div className={styles['content']}>
          <div className={styles['title']}>
            <ArticleHeading4 as="h4" color={titleColor}>
              {link.title}
            </ArticleHeading4>
          </div>
          <UIText2 color={descriptionColor}>{link.text}</UIText2>
        </div>
        <div className={styles['arrow']}>
          <IconChevronRight12 color={arrowColor} />
        </div>
      </div>
    </a>
  );
}
