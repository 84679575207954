import { TReduxActions } from '../../../../shared/types/redux';
import { ISquare, ISquareAction } from '../../../../shared/types/filters/square';

export const SQUARE_UPDATE = 'search/SQUARE_UPDATE';

const initialState: ISquare = {
  squareFrom: undefined,
  squareTo: undefined,
  metric: 'square_meters',

  isDirty: false,
};

export const squareReducer = (state = initialState, action: TReduxActions) => {
  switch (action.type) {
    case SQUARE_UPDATE:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
};

export const setSquareFrom = (squareFrom: number | undefined): ISquareAction => {
  return {
    type: SQUARE_UPDATE,
    payload: {
      squareFrom,
    },
  };
};

export const setSquareTo = (squareTo: number | undefined): ISquareAction => {
  return {
    type: SQUARE_UPDATE,
    payload: {
      squareTo,
    },
  };
};

export const setSquareMetric = (metric: string): ISquareAction => {
  return {
    type: SQUARE_UPDATE,
    payload: {
      metric,
    },
  };
};

export const setDirty = (isDirty: boolean): ISquareAction => {
  return {
    type: SQUARE_UPDATE,
    payload: {
      isDirty,
    },
  };
};
