import { connect } from 'react-redux';
import { setMortgageRealtyPrice } from '../../actions/mortgage';
import { FilterAmountInput, IFilterAmountInputProps } from '../../components/FilterAmountInput';
import { selectMortgageFilterRealtyPrice } from '../../selectors/mortgage/selectMortgageFilterRealtyPrice';
import { IApplicationState, TThunkDispatch } from '../../types/redux';

type TFilterMortgageRealtyPriceStateProps = Pick<
  IFilterAmountInputProps,
  'integerLimit' | 'placeholder' | 'suffix' | 'value'
>;
type TFilterMortgageRealtyPriceDispatchProps = Pick<IFilterAmountInputProps, 'onValueChange'>;

export function mapStateToProps(state: IApplicationState): TFilterMortgageRealtyPriceStateProps {
  return {
    integerLimit: 12,
    placeholder: 'Стоимость недвижимости',
    suffix: `\u20BD`,
    value: selectMortgageFilterRealtyPrice(state),
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TFilterMortgageRealtyPriceDispatchProps {
  return {
    onValueChange: (value: number) => dispatch(setMortgageRealtyPrice(value)),
  };
}

export const FilterMortgageRealtyPriceContainer = connect(mapStateToProps, mapDispatchToProps)(FilterAmountInput);
