import { createMakeRequest as _createMakeRequest, IMakeRequest } from '../shared/utils/network_helper';
import { getLogger } from '@cian/logger/browser';
import { getHttpApi } from '@cian/http-api/browser';

export function createMakeRequest(baseHost: string): IMakeRequest {
  return _createMakeRequest(
    getHttpApi(),
    baseHost || window.location.host,
    window.location.protocol === 'http:' ? 'http' : 'https',
    getLogger(),
  );
}
