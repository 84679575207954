import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EJsonQueryPriceType } from '../../../../../packages/api-models/common/json_query';

import { setPriceTypeAction } from '../../../../actions/filters';
import { FCoworkingOfferType } from '../../../../utils/category';

export function useCoworkingOfferTypeChangeEffect(coworkingOfferType: FCoworkingOfferType): void {
  const dispatch = useDispatch<ReturnType<typeof setPriceTypeAction>>();

  useEffect(() => {
    dispatch(
      setPriceTypeAction(
        (FCoworkingOfferType.FlexibleOffice & coworkingOfferType) === 0 ? EJsonQueryPriceType.Workplace : undefined,
      ),
    );
  }, [coworkingOfferType, dispatch]);
}
