import { findRightElement } from './findRightElement';

export function getRightScrollOffset(container: HTMLElement, scrollLeft: number) {
  const rightBorderPosition = scrollLeft + container.offsetWidth;
  const rightChild = findRightElement(container.children, rightBorderPosition, container.offsetLeft);
  const maxLeftOffset = container.scrollWidth - container.offsetWidth;

  if (!rightChild) {
    return maxLeftOffset;
  }

  return rightChild.offsetLeft + rightChild.offsetWidth - container.offsetLeft - container.offsetWidth;
}
