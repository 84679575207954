import { Action } from 'redux';
import { clone, omit } from 'ramda';

import { TThunkAction } from '../../types/redux';
import { EMetaStatus } from '../../types/filters';
import { FILTERS_GET_META_TYPE } from '../../constants/action_types';
import { getMeta as getMetaApi } from '../../api/getMeta';
import { getAvailableBusinessAppointmentsItems } from '../../utils/getAvailableBusinessAppointmentsItems';
import { setTerms } from '../../utils/jsonQuery';
import { dealTypeFromJsonQuery, FDealType, FOfferType, offerTypeFromJsonQuery } from '../../utils/category';
import { EOfficeType, IJsonQuery } from '../../../packages/api-models/common/json_query';

export type TGetMetaType = 'filters/get_meta/status_changed';

export interface IGetMetaFailed extends Action<TGetMetaType> {
  type: TGetMetaType;
  status: EMetaStatus.Failed;
  error: string;
}

export interface IGetMetaSucceed extends Action<TGetMetaType> {
  type: TGetMetaType;
  status: EMetaStatus.Succeed;
  url: string;
  mapUrl: string;
  count: number;
}

export interface IGetMeta extends Action<TGetMetaType> {
  type: TGetMetaType;
  status: EMetaStatus.Loading;
}

export type TGetMeta = IGetMeta | IGetMetaFailed | IGetMetaSucceed;

let lastRequestId: Symbol;
export function getMeta(keepEvery?: boolean): TThunkAction<Promise<void>> {
  return (dispatch, getState, context) => {
    const requestId = (lastRequestId = Symbol());

    dispatch<TGetMeta>({
      type: FILTERS_GET_META_TYPE,
      status: EMetaStatus.Loading,
    });

    const state = getState();

    const {
      filters: { jsonQuery },
      config: { subdomain },
      businessAppointments,
    } = state;

    /**
     * @todo Данный хак нужен для того, что бы при отправке на бек значение коворкингов считалось,
     * @todo как значение офисов, на беке сказали делать долго очень.
     * @todo Как только на беке смогут принимать значение для коворкингов следует выпилить,
     * @todo а то на это больно смотреть
     * @start
     */
    const newJsonJsonQuery = ((): IJsonQuery => {
      let _jsonQuery = clone(jsonQuery);

      if (jsonQuery.office_type?.value.includes(EOfficeType.Coworking)) {
        if (_jsonQuery.office_type) {
          _jsonQuery.office_type.value = Array.of<EOfficeType>(EOfficeType.Office);
        }

        return _jsonQuery;
      }

      /**
       * @description businessAppointments не является собственной категорией, это виртуальная категория,
       * которая является доступной только в случае, если это одна из категорий бизнеса,
       * из-за этого нужно переопределять поле перед отправкой
       */
      if (_jsonQuery.ready_business_types?.value.length && businessAppointments.data) {
        const availableBusinessAppointmentsItems = getAvailableBusinessAppointmentsItems(
          businessAppointments.data,
          _jsonQuery.ready_business_types.value,
          businessAppointments.filterBusinessAppointmentsIds,
        );

        if (availableBusinessAppointmentsItems.availableIds.length) {
          _jsonQuery = setTerms('specialty_types')(_jsonQuery)(availableBusinessAppointmentsItems.availableIds);
        }

        return _jsonQuery;
      }

      const dealType = dealTypeFromJsonQuery(jsonQuery);
      const offerType = offerTypeFromJsonQuery(jsonQuery);

      // Исключаем фильтр по количеству комнат для регионов в эксперименте посутки
      if ((offerType & FOfferType.Flat) !== 0 && dealType === FDealType.RentDaily) {
        return omit(['room'], _jsonQuery);
      }

      return jsonQuery;
    })();
    /**
     * @end
     */

    return getMetaApi({
      context,
      subdomain,
      /** @todo Заменить костыль переменной newJsonJsonQuery на нормальное значение jsonQuery */
      parameters: { ...newJsonJsonQuery },
    })
      .then(searchMetaResponse => {
        if (!keepEvery && requestId !== lastRequestId) {
          return;
        }

        if (searchMetaResponse.statusCode === 200) {
          const { fullUrl, fullUrlWithoutSeo, url, mapUrl, count } = searchMetaResponse.response.data;

          dispatch<TGetMeta>({
            type: FILTERS_GET_META_TYPE,
            status: EMetaStatus.Succeed,
            url: fullUrl || fullUrlWithoutSeo || url,
            mapUrl,
            count,
          });
        } else {
          dispatch<TGetMeta>({
            type: FILTERS_GET_META_TYPE,
            status: EMetaStatus.Failed,
            error: 'Произошла непредвиденная ошибка',
          });
        }
      })
      .catch(error => {
        // context.logger.error(error);
        console.error(error);

        if (!keepEvery && requestId !== lastRequestId) {
          return;
        }

        dispatch<TGetMeta>({
          type: FILTERS_GET_META_TYPE,
          status: EMetaStatus.Failed,
          error: 'Произошла непредвиденная ошибка',
        });
      });
  };
}
