import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { selectCoworkingOfferType } from '../../../../actions/filters';
import { FCoworkingOfferType } from '../../../../utils/category';

import { THandleChangeOfferType } from '../../types';

export function useHandleChangeOfferType(coworkingOfferType: FCoworkingOfferType) {
  const dispatch = useDispatch();

  return useCallback<THandleChangeOfferType>(
    e => {
      const value: FCoworkingOfferType = Number(e.currentTarget.value);

      if (value !== coworkingOfferType) {
        dispatch(selectCoworkingOfferType(value));
      }
    },
    [coworkingOfferType, dispatch],
  );
}
