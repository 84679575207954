import { Spinner } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './LoadableFallback.css';

export const LoadableFallback: React.FC = () => {
  return (
    <div className={styles['container']}>
      <Spinner color="icon-secondary-default" size={20} />
    </div>
  );
};
