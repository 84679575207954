import * as React from 'react';
import { formatPrice } from '../../utils/format_helper';

const s = require('./promo_menu.css');

interface IProps {
  url: string;
  id: number;
  objectsCount: string;
  title: string;
}

export class PromoMenuLink extends React.Component<IProps> {
  public render() {
    return (
      <a className={s['c-popular-links-item']} href={this.props.url}>
        <span className={s['c-popular-links-item-text']}>{this.props.title}</span>
        &nbsp;
        <span className={s['c-popular-links-item-count']}>{formatPrice(+this.props.objectsCount, 4, '\u00a0')}</span>
      </a>
    );
  }
}
