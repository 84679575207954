import * as React from 'react';
import { connect } from 'react-redux';

import { NewBuildingsServiceBlock } from '../../components/service_block/new_buildings_service_block';
import { trackService } from '../../utils/helpers/metrics_helper';
import { IPromo } from '../../types/promo';
import { IApplicationState } from '../../types/redux';

interface IProps {
  promo: IPromo[];
}

class NewBuildingsServicesComponent extends React.Component<IProps> {
  public render() {
    if (this.props.promo.length > 0) {
      return (
        <NewBuildingsServiceBlock
          // tslint:disable-next-line:no-unnecessary-callback-wrapper
          onAdsClick={(eventLabel: string) => trackService(eventLabel)}
        />
      );
    } else {
      return <div />;
    }
  }
}

export const NewBuildingsServices = connect<IProps, {}, {}>((state: IApplicationState) => ({
  promo: state.mainpage.promoBlocks,
}))(NewBuildingsServicesComponent);
