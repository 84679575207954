import cx from 'classnames';
import * as React from 'react';
import { Image, UIHeading2, UIText1 } from '@cian/ui-kit';
import { TBannerTheme } from '../utils/bannerTypeToTheme';

import * as styles from './Banner.css';

export interface IBanner {
  title: string;
  subtitle: string;
  footer: React.ReactElement;
  labels: React.ReactElement | null;
  imageUrl?: string | null;
  theme: TBannerTheme;
}

export const Banner = ({ title, subtitle, labels, imageUrl, footer, theme }: IBanner) => {
  return (
    <div className={cx(styles['banner'], styles[theme])}>
      <div className={styles['content']}>
        {imageUrl && (
          <div className={styles['illustration']}>
            <Image width="56px" height="56px" src={imageUrl} />
          </div>
        )}
        <div className={styles['description']}>
          <div className={styles['labels']}>{labels}</div>
          <div className={styles['title']}>
            <UIHeading2>{title}</UIHeading2>
          </div>
          <UIText1>{subtitle}</UIText1>
        </div>
      </div>
      <div className={styles['footer']}>{footer}</div>
    </div>
  );
};
