import { connect } from 'react-redux';
import { FilterSingleChoose, IFilterSingleChooseProps } from '../../components/FilterSingleChoose';
import { EMortgageBuildingType } from '../../types/mortgage/mortgageType';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { setMortgageBuildingType } from '../../actions/mortgage';

type TFilterMortgageBuildingTypeStateProps = Pick<
  IFilterSingleChooseProps<EMortgageBuildingType>,
  'items' | 'selectedItemKey' | 'label'
>;
type TFilterMortgageBuildingTypeDispatchProps = Pick<IFilterSingleChooseProps<EMortgageBuildingType>, 'onSelectItem'>;

export function mapStateToProps(state: IApplicationState): TFilterMortgageBuildingTypeStateProps {
  return {
    items: new Map([
      [EMortgageBuildingType.NewBuilding, 'Квартира в новостройке'],
      [EMortgageBuildingType.OldBuilding, 'Квартира во вторичке'],
      [EMortgageBuildingType.Suburban, 'Дом или таунхаус'],
      [EMortgageBuildingType.HousePart, 'Комната или доля'],
    ]),
    selectedItemKey: state.mortgage.filterBuildingType,
    label: 'Тип недвижимости',
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TFilterMortgageBuildingTypeDispatchProps {
  return {
    onSelectItem: (buildingType: EMortgageBuildingType) => dispatch(setMortgageBuildingType(buildingType)),
  };
}

export const FilterMortgageBuildingTypeContainer = connect(mapStateToProps, mapDispatchToProps)(FilterSingleChoose);
