import { connect } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { Filters } from '../../components/Filters';
import { selectSpecialTabActive } from '../../hooks/selectors/specialTabs';
import { dealTypeFromJsonQuery, offerTypeFromJsonQuery } from '../../utils/category';
import { IFiltersProps } from '../../components/Filters/types';

export function mapStateToProps(state: IApplicationState): IFiltersProps {
  const jsonQuery = state.filters.jsonQuery;

  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const specialTabActive = selectSpecialTabActive(state);

  return {
    dealType,
    offerType,
    specialTabActive,
  };
}

export const FiltersContainer = connect(mapStateToProps)(Filters);
