import { ca } from '@cian/analytics';

export const trackNewbuildingFlightBannerClick = () => {
  ca('eventSite', {
    action: 'click',
    category: 'BlackFriday', // Переиспользуем категорию ЧП.
    label: 'nb_main',
    name: 'oldevent',
  });
};
