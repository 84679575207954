import { FOfferType, FDealType } from './category';
import { capitalize } from './string';

const OfferTypeNames: { [key: number]: string } = {
  [FOfferType.Flat]: 'квартиру в новостройке и вторичке',
  [FOfferType.FlatNew]: 'квартиру в новостройке',
  [FOfferType.FlatOld]: 'квартиру во вторичке',
  [FOfferType.FlatShared]: 'долю',
  [FOfferType.Room]: 'комнату',
  [FOfferType.House]: 'дом, дачу',
  [FOfferType.HousePart]: 'часть дома',
  [FOfferType.Townhouse]: 'таунхаус',
  [FOfferType.Land]: 'участок',
  [FOfferType.Bed]: 'койко-место',
  [FOfferType.Garage]: 'гараж',
  [FOfferType.Office]: 'офис',
  [FOfferType.TradeArea]: 'торг. площадь',
  [FOfferType.Warehouse]: 'склад',
  [FOfferType.FreeAppointmentObject]: 'ПСН',
  [FOfferType.PublicCatering]: 'общепит',
  [FOfferType.Manufacture]: 'производ.',
  [FOfferType.AutoService]: 'автосервис',
  [FOfferType.Building]: 'здание',
  [FOfferType.DomesticServices]: 'быт. услуги',
  [FOfferType.CommercialLand]: 'комм. землю',
  [FOfferType.Coworking]: 'коворкинг',
  [FOfferType.RentalBusiness]: 'арендный бизнес',
  [FOfferType.ReadyBusiness]: 'готовый бизнес',
};

export function getOfferTypeLabel(dealType: FDealType, offerType: FOfferType): string | null {
  if (offerType === FOfferType.Residential) {
    return 'Жилую';
  }

  if (offerType === FOfferType.Commercial) {
    return 'Коммерческую';
  }

  // TODO: Отпилить костыль. Придумать нормальное решение.
  if ((dealType & FDealType.Rent) !== 0 && (offerType & FOfferType.Flat) !== 0) {
    return 'Квартиру';
  }

  if (offerType in OfferTypeNames) {
    return capitalize(OfferTypeNames[offerType]);
  }

  const offerTypeName = Object.keys(OfferTypeNames)
    .map<string | null>(type => ((Number(type) & offerType) !== 0 ? OfferTypeNames[Number(type)] : null))
    .filter(Boolean)
    .join(', ');

  return (offerTypeName && capitalize(offerTypeName)) || null;
}
