import * as React from 'react';
import { MortgageLandingContainer } from '../../containers/MortgageLandingContainer';
import { TopNewbuildingBlockContainer } from '../../containers/TopNewbuildingBlock';
import { SeoContainer } from '../../containers/seo_container';
import { Specialists } from '../../containers/specialists/specialists';
import { VacanciesContainer } from '../../containers/vacancies/vacancies';
import { VerticalPromo } from '../../containers/vertical_promo/vertical_promo';
import { CommercialPromo } from '../../containers/commercial_promo/commercial_promo';
import { NewBuildingsServices } from '../../containers/new_buildings_service/new_buildings_services';
import { MagazineContainer } from '../../containers/magazine/magazine';
import { Filters } from '../../containers/search_block/filters';
import { SaleServices } from '../../containers/sale_services/sale_services';
import { Promo } from '../../containers/promo/promo';
import { PopularList } from '../../containers/popular_list/popular_list';
import { JKPopularList } from '../../containers/popular_jk/popular_jk';
import { NewBuildingCaption } from '../../containers/new_building_caption/new_building_caption';
import { SalesLinksContainer } from '../../containers/SalesLinksContainer';
import { GeoSwitcherWrapperContainer } from '../../containers/GeoSwitcherContainer';
import { RecommendationsContainer } from '../../containers/RecommendationsContainer';
import { useApplicationContext } from '../../utils/applicationContext';
import { useSelector } from 'react-redux';
import { selectIsFetching } from '../../selectors/mainpage/selectIsFetching';
import { selectSpecialTabActive } from '../../hooks/selectors/specialTabs';
import { ESpecialTabs } from '../../constants/specialTabs';
import { selectPageSection } from '../../selectors/pageSection';
import { selectTop3 } from '../../selectors/mainpage/selectTop3';
import { selectPopularJks } from '../../selectors/mainpage/selectPopularJks';
import { FiltersContainer } from '../../components/filters_container/filters_container';
import { TwoColumnContainer } from '../../components/two_column_container/two_column_container';
import { UsefulLinksContainer } from '../UsefulLinksContainer';
import { ChatPopupContainer } from '../ChatPopupContainer';
import { NewbuildingRecommendationsContainer } from '../NewbuildingRecommendations/NewbuildingRecommendationscontainer';

export const HomeContainer: React.FC = () => {
  const isFetching = useSelector(selectIsFetching);
  const isMortgageTabActive = useSelector(selectSpecialTabActive) === ESpecialTabs.Mortgage;
  const section = useSelector(selectPageSection);
  const top3 = useSelector(selectTop3);
  const popularJks = useSelector(selectPopularJks);

  const { config } = useApplicationContext();
  const isRedesignUsefulLinksEnabled = config.get<boolean>('frontend-mainpage.redesignUsefulLinks.Enabled') || false;
  const popularPresented = top3.length > 0 || popularJks.length > 0;

  const servicesBlock = React.useMemo(() => {
    switch (section) {
      case 'daily-rent':
      case 'commercial':
      case 'sale':
      case 'rent':
        return <SaleServices />;
      case 'new-buildings':
        return <NewBuildingsServices />;
      case 'index':
      default:
        return null;
    }
  }, [section]);

  const isUsefulLinksVisible =
    isRedesignUsefulLinksEnabled && ['index', 'sale', 'rent', 'daily-rent'].includes(section);

  const promoBlock = React.useMemo(() => {
    if (isUsefulLinksVisible) {
      return null;
    }

    switch (section) {
      case 'index':
        return <Promo />;
      case 'daily-rent':
      case 'sale':
      case 'rent':
      case 'new-buildings':
        return <VerticalPromo />;
      case 'commercial':
        return <CommercialPromo />;
      default:
        return null;
    }
  }, [section, isUsefulLinksVisible]);

  const popularBlock = React.useMemo(() => {
    if (section === 'new-buildings') {
      return <JKPopularList services={servicesBlock} />;
    } else {
      return <PopularList showLinkToAll={section !== 'index'} services={servicesBlock} />;
    }
  }, [section, servicesBlock]);

  const isIndexSection = section === 'index';

  if (isFetching) {
    return null;
  }

  if (isMortgageTabActive) {
    return (
      <div>
        <GeoSwitcherWrapperContainer />
        <FiltersContainer>
          <NewBuildingCaption section={section} />
          <Filters section={section} />
        </FiltersContainer>
        <MortgageLandingContainer />
        <ChatPopupContainer />
      </div>
    );
  } else {
    return (
      <div>
        <GeoSwitcherWrapperContainer />
        <FiltersContainer>
          <NewBuildingCaption section={section} />
          <Filters section={section} />
        </FiltersContainer>

        {isIndexSection && <TopNewbuildingBlockContainer />}

        <RecommendationsContainer />
        <NewbuildingRecommendationsContainer />

        {isUsefulLinksVisible && <UsefulLinksContainer />}

        <SalesLinksContainer section={section} />

        {promoBlock}

        <TwoColumnContainer wideRow={section === 'commercial'} popularPresented={popularPresented}>
          {popularBlock}
          <Specialists section={section} popularPresented={popularPresented} />
        </TwoColumnContainer>

        {isIndexSection && (
          <>
            <MagazineContainer />
            <VacanciesContainer />
          </>
        )}

        <SeoContainer />
        <ChatPopupContainer />
      </div>
    );
  }
};
