import { Action } from 'redux';

import { TThunkAction } from '../../../types/redux';
import { ESuggestionsStatus, ISuggestions } from '../../../types/filters';
import { FILTERS_GET_GEO_SUGGESTIONS_TYPE } from '../../../constants/action_types';
import { getGeoSuggestionItems } from '../../../api/getGeoSuggestions';
// eslint-disable-next-line import/no-restricted-paths
import { loadYMapsApi } from '../../../../browser/utils/ymaps';
import { EGeoSuggestStatus, IGeoSuggestResult } from '../../../services/geoSuggest';
import { IStructuredSuggestions } from '../../../../packages/api-models/suggest/v2/suggest';

export type TGetGeoSuggestionsType = 'filters/geo/get_suggestions/status_changed';

export interface IGetGeoSuggestionsFailed extends Action<TGetGeoSuggestionsType> {
  type: TGetGeoSuggestionsType;
  status: ESuggestionsStatus.Failed;
  error: string;
}

export interface IGetGeoSuggestionsSucceed extends Action<TGetGeoSuggestionsType> {
  type: TGetGeoSuggestionsType;
  status: ESuggestionsStatus.Succeed;
  suggestions: ISuggestions;
  sortOrder: string[];
}

export interface IGetGeoSuggestions extends Action<TGetGeoSuggestionsType> {
  type: TGetGeoSuggestionsType;
  status: ESuggestionsStatus.Loading;
}

export type TGetGeoSuggestions = IGetGeoSuggestions | IGetGeoSuggestionsFailed | IGetGeoSuggestionsSucceed;

export interface IYandexSuggestOptions {
  boundedBy: [IYmapsCoord, IYmapsCoord];
  results: number;
}

export interface IGetGeoSuggestionsParameters {
  query: string;
  options?: IYandexSuggestOptions;
}

export type TSuggestResult = [IGeoSuggestResult | null, YMaps.ISuggestResult[] | null];

let lastRequestId: Symbol;
export function suggest({ query, options }: IGetGeoSuggestionsParameters): TThunkAction<Promise<TSuggestResult>> {
  return (dispatch, getState, context) => {
    const requestId = (lastRequestId = Symbol());

    dispatch<TGetGeoSuggestions>({
      type: FILTERS_GET_GEO_SUGGESTIONS_TYPE,
      status: ESuggestionsStatus.Loading,
    });

    const {
      filters: { jsonQuery },
      mainpage: {
        regionsData: { subdomainCityId: regionId },
      },
    } = getState();

    const geoRequest = getGeoSuggestionItems(context, { jsonQuery, regionId, query });
    const queryWithoutMotherland = query.trim().replace(/([, ]*Россия$|^Россия[, ]*)/gi, '');
    const yandexRequest = loadYMapsApi({ require: ['suggest'] })
      .then(ymaps => ymaps.suggest(`Россия, ${queryWithoutMotherland}`, options))
      .then(suggestions => (suggestions.length > 0 && suggestions) || null)
      .catch(() => {
        console.error('Failed to get yandex suggestions');

        return null;
      });

    return Promise.all([geoRequest, yandexRequest])
      .then(([geoSuggestionsResponse, yandexSuggestions]) => {
        if (requestId !== lastRequestId) {
          return [geoSuggestionsResponse, yandexSuggestions] as TSuggestResult;
        }

        if (
          !yandexSuggestions &&
          (!geoSuggestionsResponse || geoSuggestionsResponse.status !== EGeoSuggestStatus.Success)
        ) {
          throw new Error('Failed to get any suggestions');
        }

        let suggestions: ISuggestions = {};
        let sortOrder: string[] = [];
        if (yandexSuggestions) {
          suggestions = { ...suggestions, addresses: yandexSuggestions.map(removeMotherland) };
          sortOrder = ['addresses'];
        }

        if (geoSuggestionsResponse && geoSuggestionsResponse.status === EGeoSuggestStatus.Success) {
          const { suggestions: geoSuggestions, sortOrder: nextSortOrder } = geoSuggestionsResponse.data;

          Object.keys(geoSuggestions).forEach((key: keyof IStructuredSuggestions) => {
            const nextSuggestions = geoSuggestions[key];
            if (nextSuggestions?.items && nextSuggestions.items.length > 0) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              suggestions[key] = nextSuggestions.items as any;
            }
          });

          sortOrder = nextSortOrder;
        }

        dispatch<TGetGeoSuggestions>({
          type: FILTERS_GET_GEO_SUGGESTIONS_TYPE,
          status: ESuggestionsStatus.Succeed,
          suggestions,
          sortOrder,
        });

        return [geoSuggestionsResponse, yandexSuggestions] as TSuggestResult;
      })
      .catch(error => {
        // context.logger.error(error);
        console.error(error);

        if (requestId === lastRequestId) {
          dispatch<TGetGeoSuggestions>({
            type: FILTERS_GET_GEO_SUGGESTIONS_TYPE,
            status: ESuggestionsStatus.Failed,
            error: 'Произошла непредвиденная ошибка',
          });
        }

        throw error;
      });
  };
}

function removeMotherland<T>(item: YMaps.ISuggestResult<T>): YMaps.ISuggestResult<T> {
  return {
    ...item,
    displayName: item.displayName.replace(/, Россия$/, ''),
  };
}
