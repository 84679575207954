import {
  ECurrentRegionActionTypes,
  EGetGeoSuggestActionTypes,
  EGetPopularCitiesActionTypes,
  EGetRegionActionTypes,
  TGeoSwitcherTypes,
} from '../../actions/geoSwitcher';
import { EGeoSwitcherStatus, IGeoSwitcherState } from '../../types/geoSwitcher';

export const defaultState: IGeoSwitcherState = {
  region: null,
  popularCities: [],
  suggests: [],
  status: EGeoSwitcherStatus.Initial,
};

export const geoSwitcherReducer = (state: IGeoSwitcherState = defaultState, action: TGeoSwitcherTypes) => {
  switch (action.type) {
    case EGetPopularCitiesActionTypes.Loading:
    case EGetGeoSuggestActionTypes.Loading:
    case EGetRegionActionTypes.Loading:
      return { ...state, status: EGeoSwitcherStatus.Loading };
    case EGetPopularCitiesActionTypes.Succeed:
      return { ...state, popularCities: action.payload, status: EGeoSwitcherStatus.Succeed };
    case EGetPopularCitiesActionTypes.Failed:
    case EGetGeoSuggestActionTypes.Failed:
    case EGetRegionActionTypes.Failed:
      return { ...state, status: EGeoSwitcherStatus.Failed };
    case EGetGeoSuggestActionTypes.Succeed:
      return { ...state, suggests: action.payload, status: EGeoSwitcherStatus.Succeed };
    case EGetRegionActionTypes.Succeed:
      return { ...state, region: action.payload, status: EGeoSwitcherStatus.Succeed };
    case ECurrentRegionActionTypes.Set:
      return { ...state, region: action.payload };
    default:
      return state;
  }
};
