import { TReduxActions } from '../../../types/redux';
import { FILTERS_SELECT_GEO_TYPE } from '../../../constants/action_types';
import { JsonQuery } from '../../../utils/jsonQuery';
import { defaultState } from './defaultState';
import { IJsonQuery } from '../../../../packages/api-models/common/json_query';

export function selectGeoReducer(jsonQuery: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  switch (action.type) {
    case FILTERS_SELECT_GEO_TYPE:
      return new JsonQuery(jsonQuery)
        .clearGeo()
        .setVillage(undefined)
        .setBSCenters([])
        .selectGeo(action.geoValue)
        .getSerializable();
  }

  return jsonQuery;
}
