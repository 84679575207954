import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import {
  fetchGetPopularCitiesForGeopopup,
  TGetPopularCitiesForGeopopupResponse,
} from '../../../repositories/geo-suggest/v1/get-popular-cities-for-geopopup';
import { TThunkAction } from '../../../types/redux';
import { getPopularCitiesFailed } from './getPopularCitiesFailed';
import { getPopularCitiesLoading } from './getPopularCitiesLoading';
import { getPopularCitiesSucceed } from './getPopularCitiesSucceed';
import { preparePopularCities } from './utils';

export const getPopularCities = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getPopularCitiesLoading());
    try {
      const response: TGetPopularCitiesForGeopopupResponse = await fetchGetPopularCitiesForGeopopup({
        httpApi,
        parameters: {},
      });

      if (response.statusCode !== 200) {
        throw new HttpBadStatusCodeError(
          'Не удалось загрузить список городов',
          response.statusCode,
          'actions.getPopularCities',
        );
      }

      const popularCities = preparePopularCities(response.response.cities);

      dispatch(getPopularCitiesSucceed(popularCities));
    } catch (ex) {
      logger.error(ex, {
        domain: 'actions.getPopularCities',
      });

      dispatch(getPopularCitiesFailed());
    }
  };
};
