import * as React from 'react';
import { hydrateRoot } from 'react-dom/client';

import { App } from '../../shared/containers/app/app';
import { IApplicationContext } from '../../shared/types/application';
import { IReduxStore } from '../../shared/types/redux';
import { MicrofrontendContext } from '@cian/mf-react-legacy';
import { IBrowserMicrofrontend } from '@cian/mf-registry-legacy/browser';

interface IDependencies {
  store: IReduxStore;
  context: IApplicationContext;
  microfrontend: IBrowserMicrofrontend;
}

export function renderApplication(dependencies: IDependencies) {
  const { store, context, microfrontend } = dependencies;

  const projectName = context.config.getStrict<string>('projectName');
  const rootElement = document.getElementById(projectName as string);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  hydrateRoot(
    rootElement,
    <MicrofrontendContext.Provider value={microfrontend}>
      <App reduxStore={store} context={context} />
    </MicrofrontendContext.Provider>,
  );
}
