import { TSectionType } from '../../types/sectionType';
import { ITrackingPageExtra, TTrackingPlacement, TTrackingPlacementType } from '../../types/tracking';
import { FDealType } from '../category';

const DEAL_TYPE_MAP: Partial<Record<FDealType, TTrackingPlacementType>> = {
  [FDealType.Sale]: 'sale',
  [FDealType.RentLongterm]: 'rent_long',
  [FDealType.RentDaily]: 'rent_daily',
};

interface IParams {
  dealType?: FDealType;
  queryString?: string;
  withEntrSource?: boolean;
  section?: TSectionType;
}

const PLACEMENT: Partial<Record<TSectionType, TTrackingPlacement>> = {
  'daily-rent': 'dailyrent',
  sale: 'sale',
  rent: 'sale',
  index: 'mainpage',
};

export const getPageExtra = (extra?: Partial<IParams>): ITrackingPageExtra => {
  const placementType = extra?.dealType ? DEAL_TYPE_MAP[extra.dealType] : undefined;
  const placement = extra?.section ? PLACEMENT[extra.section] : undefined;
  const entrSource = extra?.withEntrSource ? 'lending_main_page' : undefined;

  return {
    lending_main_page: true,
    placement: placement || 'mainpage',
    placement_type: placementType,
    querystring: extra?.queryString,
    entr_source: entrSource,
  };
};
