import { ca } from '@cian/analytics';
import { TSectionType } from '../../types/sectionType';

export const trackUsefulLinksShow = (section: TSectionType) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'Show_sopr',
    label: 'UsefulLinks',
    page: {
      extra: {
        tabName: section,
      },
      pageType: 'Home',
      siteType: 'desktop',
    },
  });
};

export const trackUsefulLinkClick = (section: TSectionType, categoryName: string) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'Click',
    label: 'UsefulLinks',
    page: {
      extra: {
        tabName: section,
        categoryName,
      },
      pageType: 'Home',
      siteType: 'desktop',
    },
  });
};
