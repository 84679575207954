import * as React from 'react';
import { formatPrice } from '../../../utils/format_helper';

interface IProps {
  url: string;
  objectCount: number;
  h1: string;
  title: string;
}

const s = require('../vertical_promo_blocks.css');

export class SummaryPromoLink extends React.Component<IProps> {
  public render() {
    return (
      <a className={s['c-new-promo-block-links-item']} href={this.props.url}>
        <span className={s['c-new-promo-link-text']}>{this.props.title}</span>
        <span className={s['c-new-promo-link-count']}>{formatPrice(this.props.objectCount, 4)}</span>
      </a>
    );
  }
}
