import * as React from 'react';
import {
  connect,
  TAdFoxBannerProps as TAdFoxBannerComponentProps,
  IAdFoxBannerOnLoadData,
} from '@cian/adfox-component';
import { OwnTemplates } from './OwnTemplates';
import { withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';

const AdFoxBannerComponent = connect(OwnTemplates);

type TAdFoxBannerProps = TAdFoxBannerComponentProps & {
  disableYan?: boolean;
  withPostView?: boolean;
  label?: string;
  section?: string;
};

export const NewAdfoxBanner: React.FC<TAdFoxBannerProps> = props => {
  const [isHidden, setIsHidden] = React.useState(false);
  const { disableYan, withPostView, label, section, onStub, onLoad, onError, ...sharedProps } = props;

  const handleLoad = React.useCallback(
    (data: IAdFoxBannerOnLoadData) => {
      const { bundleName } = data;

      if (disableYan && bundleName === 'banner.direct') {
        setIsHidden(true);
      }

      if (onLoad) {
        onLoad(data);
      }
    },
    [disableYan, onLoad],
  );

  const bannerWithPostView = React.useMemo(() => {
    const AdfoxWithPostView = withAdfoxPostViewAnalytics(
      AdFoxBannerComponent,
      {
        ...sharedProps,
        onLoad: handleLoad,
        onStub,
        onError,
      },
      {
        banner: {
          place: `home_${section}`,
          name: label,
        },
      },
    );

    return <AdfoxWithPostView />;
  }, [handleLoad, onStub, onError, label, section]);

  if (disableYan && isHidden) {
    return null;
  }

  return withPostView ? (
    bannerWithPostView
  ) : (
    <AdFoxBannerComponent {...sharedProps} onLoad={handleLoad} onStub={onStub} onError={onError} />
  );
};
