import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getSuitableBuildingType } from '../../components/search_filters/filters/helpers';
import { TypeFilter as TypeFilterInput } from '../../components/search_filters/filters/type_filter';
import { updateBuildingType } from '../../reducers/modules/search/building_types';
import { setDirty, setSearchType } from '../../reducers/modules/search/type';
import { clearCommercialGeo } from '../../reducers/modules/search/user_geo';
import { GenericFilter } from './generic_filter';
import { ISearchType, SearchType } from '../../types/filters/type';
import { IBuildingTypesConfig } from '../../types/filters/building_types';
import { ISearchConfig } from '../../types/search';
import { IApplicationState } from '../../types/redux';

interface IStoreProps {
  type: ISearchType;
  buildingType: string[];
}

interface IDispatchProps {
  onClearCommercialGeo(): void;
  setDirty(isDirty: boolean): void;
  setSearchType(searchType: string): void;
  updateBuildingType(buildingType: string[]): void;
}

interface IOwnProps {
  buildingTypes: IBuildingTypesConfig;
  searchConfig: ISearchConfig;
}

interface IProps extends IStoreProps, IDispatchProps, IOwnProps {}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IState {}

class TypeFilterClass extends GenericFilter<IProps, IState> {
  protected setDirty(isDirty: boolean) {
    this.props.setDirty(isDirty);
  }

  public render() {
    return <TypeFilterInput value={this.props.type.searchType} onChange={this.hendlerOnChange} />;
  }

  private hendlerOnChange = (type: string) => {
    const { setSearchType, onClearCommercialGeo, updateBuildingType, buildingType, buildingTypes, searchConfig } =
      this.props;

    setSearchType(type);

    if (type === 'rent_short') {
      onClearCommercialGeo();
    }

    const suitableBuildingType = getSuitableBuildingType(type, buildingType, buildingTypes, searchConfig);

    updateBuildingType(suitableBuildingType);
  };
}

export function mapStateToProps(state: IApplicationState): IStoreProps {
  return {
    type: state.type,
    buildingType: state.buildingType.buildingType,
  };
}

export function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return {
    onClearCommercialGeo: () => dispatch(clearCommercialGeo()),
    updateBuildingType: (buildingType: string[]) => dispatch(updateBuildingType(buildingType)),
    setDirty: (isDirty: boolean) => dispatch(setDirty(isDirty)),
    setSearchType: (searchType: SearchType) => dispatch(setSearchType(searchType)),
  };
}

const TypeFilter = connect<IStoreProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TypeFilterClass);

export { TypeFilter, TypeFilterClass };
