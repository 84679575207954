export interface IRegion {
  displayName: string;
  id: number;
  mainTownId?: number | null;
  baseHost?: string | null;
}

export enum EGeoSwitcherStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface IGeoSwitcherState {
  region: IRegion | null;
  suggests: IRegion[];
  popularCities: IRegion[];
  status: EGeoSwitcherStatus;
}
