import * as React from 'react';
import { formatMapURL } from '../../utils/map_helper';
import { ServiceAds } from '../service_ads/service_ads';

import * as styles from './sale_item_block.css';

interface IProps {
  coordinates?: {
    lat: number;
    lng: number;
  };
  section: string;
  onAdsClick(eventLabel: string): void;
}

export const MapSearchBlock = (props: IProps) => (
  <div className={`${styles['c-bottom-selfads-col']} cg-col-12`}>
    <ServiceAds
      class={styles['c-selfads-item-map']}
      onClick={() => props.onAdsClick('search_on_map')}
      title="Ищите на карте"
      description="Ищите объявления рядом с работой, парком или родственниками"
      buttonText="Найти на карте"
      buttonUrl={formatMapURL(props.coordinates, props.section)}
    />
  </div>
);
