import { IApplicationState } from '../../types/redux/store';

export const selectUserId = ({ user }: IApplicationState): number | null =>
  (user.isAuthenticated && user.userId) || null;

export const selectIsUserProfi = (state: IApplicationState) =>
  (state.user.isAuthenticated && state.user.isProfi) || false;

// TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
export const selectIsUserAgent = (state: IApplicationState) =>
  (state.user.isAuthenticated && state.user.isAgent) || false;

// TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
export const selectUserTariffs = (state: IApplicationState) =>
  (state.user.isAuthenticated && state.user.tariff) || null;
