import * as React from 'react';

import { DefaultPopularItem } from '../popular_item/default_popular_item';
import { ITopJK, ITopOffer } from '../../../shared/types/top_three';

const s = require('./popular_content.css');

export interface IPopularContentProps {
  onItemClick(order: number): void;
  offers: ITopOffer[] | ITopJK[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popularItem: React.FC<any>;
}

export const PopularContent = (props: IPopularContentProps) => {
  return (
    <div className="с-tabs-content">
      <div className="c-tabs-tab-content">
        <div className={`${s['c-popular-block-wrap']} cg-row`}>
          {props.offers.map((offer: ITopOffer | ITopJK, i: number) => (
            <props.popularItem
              key={`offer-${i}`}
              position={i + 1}
              {...offer}
              promoText={i === 0 ? (offer as ITopJK).promoText : null}
              onClick={() => props.onItemClick(i)}
            />
          ))}
          {(!props.offers || props.offers.length < 3) && <DefaultPopularItem />}
        </div>
      </div>
    </div>
  );
};
