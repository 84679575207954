import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { EMortgageBuildingType } from '../../types/mortgage/mortgageType';

export type TSetMortgageBuildingTypeType = 'mortgage/set_mortgage_building_type';

export const SET_MORTGAGE_BUILDING_TYPE_TYPE: TSetMortgageBuildingTypeType = 'mortgage/set_mortgage_building_type';

export interface ISetMortgageBuildingType extends Action<TSetMortgageBuildingTypeType> {
  type: TSetMortgageBuildingTypeType;
  buildingType: EMortgageBuildingType;
}

export type TSetMortgageBuildingType = ISetMortgageBuildingType;

export function setMortgageBuildingType(buildingType: EMortgageBuildingType): TThunkAction {
  return dispatch => {
    dispatch<TSetMortgageBuildingType>({
      type: SET_MORTGAGE_BUILDING_TYPE_TYPE,
      buildingType,
    });
  };
}
