import { CheckboxGroup } from '@cian/nested-checkbox-groups';
import { capitalize } from '../../../../utils/string';

export function getLabel(
  checkboxValues: Array<number>,
  checkboxGroups: Array<CheckboxGroup>,
  defaultLabel: string,
): string {
  const checkboxValuesCollection = new Set<number>(checkboxValues);
  let label: string;

  if (!checkboxValuesCollection.size) {
    label = defaultLabel;
  } else {
    const labelList = Array.of<string>();
    let isEveryValuesIncluded = true;

    for (const checkboxGroup of checkboxGroups) {
      for (const item of checkboxGroup.items) {
        if (checkboxValuesCollection.has(item.value)) {
          labelList.push(item.label.toLowerCase());
        } else {
          isEveryValuesIncluded = false;
        }
      }
    }

    if (!labelList.length) {
      label = defaultLabel;
    } else if (isEveryValuesIncluded) {
      label = 'Все категории';
    } else {
      label = capitalize(labelList.join(', '));
    }
  }

  return label;
}
