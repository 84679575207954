import * as React from 'react';

import { ISalesLink } from '../../types/salesLink';
import { SalesLink } from './components/SalesLink';

import * as styles from './SalesLinks.css';

interface ISalesLinksProps {
  links: ISalesLink[];
  onLinkClick?(link: ISalesLink): void;
}

export function SalesLinks({ links, onLinkClick }: ISalesLinksProps) {
  return (
    <section className={styles['container']} data-testid="SalesLinks">
      <div className="cg-container-fluid-xs">
        <div className={styles['links']}>
          {links.map(link => (
            <SalesLink link={link} key={link.salesType} onClick={onLinkClick} />
          ))}
        </div>
      </div>
    </section>
  );
}
