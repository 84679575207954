import { IApplicationState } from '../../types/redux';
import {
  ENewbuildingPromoBannersLoadingStatus,
  IPromoBanners,
} from '../../types/newbuildingBanners/newbuildingBanners';

export const getNewbuildingPromoBannerLoadingStatusSelector = (
  state: IApplicationState,
): ENewbuildingPromoBannersLoadingStatus => {
  return state.newbuildingPromoBanners.status;
};

export const getNewbuildingPromoBannerSelector = (state: IApplicationState): IPromoBanners | null => {
  return state.newbuildingPromoBanners.data;
};
