import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';

export type TSetMortgageRegionType = 'mortgage/set_mortgage_region';

export const SET_MORTGAGE_REGION_TYPE: TSetMortgageRegionType = 'mortgage/set_mortgage_region';

export interface ISetMortgageRegion extends Action<TSetMortgageRegionType> {
  type: TSetMortgageRegionType;
  regionId: number;
  regionTitle: string;
}

export type TSetMortgageRegion = ISetMortgageRegion;

export function setMortgageRegion(regionId: number, regionTitle: string): TThunkAction {
  return dispatch => {
    dispatch<TSetMortgageRegion>({
      type: SET_MORTGAGE_REGION_TYPE,
      regionId,
      regionTitle,
    });
  };
}
