/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetPopularCitiesForGeopopupModel,
  IGetPopularCitiesForGeopopupRequest,
  IMappers,
  IGetPopularCitiesForGeopopupResponse,
  IGetPopularCitiesForGeopopupResponseError,
  TGetPopularCitiesForGeopopupResponse,
} from './types';

const defaultConfig: TGetPopularCitiesForGeopopupModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'geo-suggest',
  pathApi: '/v1/get-popular-cities-for-geopopup/',
  hostType: 'api',
} as TGetPopularCitiesForGeopopupModel;

function createGetPopularCitiesForGeopopupModel(
  parameters: IGetPopularCitiesForGeopopupRequest,
): TGetPopularCitiesForGeopopupModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetPopularCitiesForGeopopupOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetPopularCitiesForGeopopupRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetPopularCitiesForGeopopup<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetPopularCitiesForGeopopupOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetPopularCitiesForGeopopupResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetPopularCitiesForGeopopupModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetPopularCitiesForGeopopupResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetPopularCitiesForGeopopupResponseError);
    }
  }

  return { response, statusCode } as TGetPopularCitiesForGeopopupResponse;
}

export { defaultConfig, createGetPopularCitiesForGeopopupModel, fetchGetPopularCitiesForGeopopup };
