import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectBedsCount } from '../../selectors/filters/beds';
import { useToggleState } from '../../hooks';
import { FilterBeds } from '../../components/FilterBeds';
import { getLabel, getNormalizedValue, useHandleChange } from './utils';
import { MAX_VALUE, MIN_VALUE } from './constants';

export const FilterBedsContainer = () => {
  const [isOpen, toggleIsOpen] = useToggleState();

  const beds = useSelector(selectBedsCount);

  const value = getNormalizedValue(beds?.gte);
  const label = React.useMemo(() => getLabel(value), [value]);

  const handleChange = useHandleChange();

  const handleClick = React.useCallback(() => {
    toggleIsOpen();
  }, [toggleIsOpen, isOpen]);

  return (
    <FilterBeds
      label={label}
      opened={isOpen}
      min={MIN_VALUE}
      max={MAX_VALUE}
      value={value}
      onChange={handleChange}
      onClick={handleClick}
    />
  );
};
