import * as React from 'react';
import { Image } from '@cian/ui-kit';
import cn from 'classnames';

import { ISpecialist } from '../../types/specialist';
import { Link } from '../link/link';

const s = require('./specialist.css');

interface IProps {
  onClick(): void;
  specialist: ISpecialist;
  isRoundPhoto?: boolean;
  getSummary?(spec: ISpecialist): string;
}

export const Specialist = (props: IProps) => (
  <Link href={props.specialist.url} className={`${s['c-specialists-item']}`} onClick={() => props.onClick()}>
    <div className={cn(s['c-specialists-photo'], props.isRoundPhoto && s['c-specialists-photo-round'])}>
      <Image
        src={props.specialist.imageUrl}
        loading="lazy"
        objectFit="cover"
        display="block"
        preloader={false}
        borderRadius={props.isRoundPhoto ? '50%' : 0}
        width={46}
        height={46}
      />
    </div>
    <div className={s['c-specialists-info']}>
      <span className={s['c-specialists-name']}>{props.specialist.title}</span>
      <span className={s['c-specialists-summary']}>
        {props.getSummary ? props.getSummary(props.specialist) : props.specialist.offersCount}
      </span>
    </div>
  </Link>
);
