import * as React from 'react';

import { Link } from '../link/link';

const s = require('./map.css');

interface IProps {
  subdomainRegionId: number;
  class?: string;
}

export const Map = (props: IProps) => (
  <div className={`${s['c-promo-info-block-map']} ${props.class || ''}`}>
    <div className={s['c-promo-info-block-map-content']}>
      <span className={s['c-promo-info-map-title']}>Поиск на карте</span>
      <span className={s['c-promo-info-map-text']}>
        Ищите новостройки рядом с работой,
        <br /> парком или родственниками
      </span>
      <Link href="/map-novostroyki/" className={s['c-promo-info-map-button']}>
        Найти на карте
      </Link>
    </div>
  </div>
);
