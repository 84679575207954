import { Outside } from '@cian/ui-kit/services';
import classNames from 'classnames';
import * as React from 'react';

import { CalendarContainer } from '../../containers/CalendarContainer';
import { FilterButton } from '../FilterButton';
import * as commonStyles from '../FiltersCommon/FiltersCommon.css';
import * as styles from './FilterDates.css';
import { IFilterDatesProps } from './types';

export const FilterDates = (props: IFilterDatesProps) => {
  const { label, opened, min, max, fromValue, toValue, empty, onChange, onClick, onOutsideClick } = props;

  const dropdownRef = React.useRef<HTMLDivElement>(null);

  return (
    <Outside insideRefs={[dropdownRef]} active={opened} onOutside={onOutsideClick}>
      <div className={classNames(commonStyles['container'], styles['container'])} data-name="FilterDates">
        <FilterButton tooltipDisabled label={label} isPlaceholderLabel={empty} isActive={opened} onClick={onClick} />

        {opened && (
          <div className={styles['dropdown']} ref={dropdownRef}>
            <CalendarContainer
              minDate={min}
              maxDate={max}
              fromValue={fromValue}
              toValue={toValue}
              onChange={onChange}
            />
          </div>
        )}
      </div>
    </Outside>
  );
};
