import { TReduxActions } from '../../types/redux';
import {
  ENewbuildingPromoBannersLoadingStatus,
  INewbuildingPromoBannersState,
} from '../../types/newbuildingBanners/newbuildingBanners';
import { EGetNewbuildingBannersStatusActionType } from '../../actions/newbuildingBanners/types';

export const INITIAL_STATE: INewbuildingPromoBannersState = {
  status: ENewbuildingPromoBannersLoadingStatus.Initial,
  data: null,
};

export const newbuildingPromoBannersReducer = (
  state: INewbuildingPromoBannersState = INITIAL_STATE,
  action: TReduxActions,
): INewbuildingPromoBannersState => {
  switch (action.type) {
    case EGetNewbuildingBannersStatusActionType.Loading:
      return {
        status: ENewbuildingPromoBannersLoadingStatus.Loading,
        data: null,
      };

    case EGetNewbuildingBannersStatusActionType.Failed:
      return {
        status: ENewbuildingPromoBannersLoadingStatus.Failure,
        data: null,
      };

    case EGetNewbuildingBannersStatusActionType.Succeed:
      return {
        status: ENewbuildingPromoBannersLoadingStatus.Success,
        data: action.payload,
      };

    default:
      return state;
  }
};
