import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_BS_CENTERS_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';

export type TSetBSCentersType = 'filters/set_bs_centers';

export interface ISetBSCenters extends Action<TSetBSCentersType> {
  type: TSetBSCentersType;
  bsCenterIds: number[];
}

export type TSetBSCenters = ISetBSCenters;

export function setBSCenters(bsCenterIds: number[]): TThunkAction {
  return dispatch => {
    dispatch<TSetBSCenters>({
      type: FILTERS_SET_BS_CENTERS_TYPE,
      bsCenterIds,
    });

    dispatch(getMeta());
  };
}
