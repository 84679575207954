import { EJsonQueryReadyBusinessType } from '../../packages/api-models/common/json_query';
import { FOfferType } from './category';

export const addBusinessTypesToOfferTypes = (
  readuBusinessTypes: EJsonQueryReadyBusinessType[] | undefined,
  offerType: FOfferType,
): FOfferType => {
  if (!readuBusinessTypes) {
    return offerType;
  }

  return readuBusinessTypes.reduce((acc, type) => {
    if (type === EJsonQueryReadyBusinessType.RentalBusiness) {
      return acc | FOfferType.RentalBusiness;
    }

    if (type === EJsonQueryReadyBusinessType.ReadyBusiness) {
      return acc | FOfferType.ReadyBusiness;
    }

    return acc;
  }, offerType);
};
