import React, { Component } from 'react';
import { equals, isNil } from 'ramda';
import { Outside, NumberInput, InputGroup } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import * as styles from './FilterWorkplaceCount.css';
import { IFilterWorkplaceCountProps, IFilterWorkplaceCountState } from './types';
import { getWorkplaceCountLabel } from './helpers';

import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';

const rootElementStyles = mergeStyles(stylesCommon['container'], styles['container']);

export class FilterWorkplaceCount extends Component<IFilterWorkplaceCountProps, IFilterWorkplaceCountState> {
  private static readonly isValidInput = (min?: number, max?: number): boolean =>
    !isNil(min) && !isNil(max) && min > max;

  private validationTimeout: number;

  public constructor(props: IFilterWorkplaceCountProps) {
    super(props);

    const { value } = props;
    const { gte, lte } = value;

    this.state = {
      active: false,
      min: gte,
      max: lte,
      isErroneous: false,
    };
  }

  public componentDidUpdate(prevProps: IFilterWorkplaceCountProps): void {
    const { value } = this.props;
    const { gte, lte } = value;
    const { min, max, isErroneous } = this.state;

    const propsEqual = equals(prevProps, this.props);

    if (!propsEqual && (gte !== min || lte !== max)) {
      this.setState({ min: gte, max: lte });
    }

    if (!isErroneous) {
      if (this.validationTimeout) {
        window.clearTimeout(this.validationTimeout);
      }

      this.validationTimeout = window.setTimeout(this.validate, 500);
    }
  }

  public componentWillUnmount(): void {
    if (this.validationTimeout) {
      window.clearTimeout(this.validationTimeout);
    }
  }

  public render(): JSX.Element {
    const buttonLabel = this.buttonLabel;

    return (
      <Outside onOutside={this.handleOutsideClick}>
        <div {...rootElementStyles} data-mark="FilterWorkplaceCount">
          <button {...this.buttonStyles} title={buttonLabel} onClick={this.handleButtonClick}>
            {buttonLabel || 'Кол-во мест'}
          </button>
          {this.dropdownView}
        </div>
      </Outside>
    );
  }

  private get dropdownView(): React.ReactNode {
    const { active, min, max, isErroneous } = this.state;

    if (active) {
      return (
        <div className={styles['dropdown']}>
          <InputGroup>
            <NumberInput
              placeholder="от"
              invalid={isErroneous}
              value={min}
              min={0}
              onChange={this.handleMinValueChange}
              onBlur={this.handleMinBlur}
            />
            <NumberInput
              placeholder="до"
              invalid={isErroneous}
              value={max}
              min={0}
              onChange={this.handleMaxValueChange}
              onBlur={this.handleMaxBlur}
            />
          </InputGroup>
        </div>
      );
    }

    return null;
  }

  private get buttonStyles(): ReturnType<typeof mergeStyles> {
    const { active } = this.state;

    return mergeStyles(
      stylesCommon['button'],
      active && stylesCommon['button--active'],
      !this.buttonLabel && stylesCommon['button--empty'],
    );
  }

  private get buttonLabel(): string | undefined {
    const { min, max } = this.state;

    return getWorkplaceCountLabel(min, max) ?? undefined;
  }

  private readonly handleButtonClick = (): void => this.setState(({ active }) => ({ active: !active }));

  private readonly handleOutsideClick = (): void => this.setState({ active: false });

  private readonly handleMinValueChange = (value?: number): void => {
    const { isValidInput } = FilterWorkplaceCount;

    this.setState(({ isErroneous, max }) => ({
      min: value,
      isErroneous: isErroneous ? isValidInput(value, max) : false,
    }));
  };

  private readonly handleMaxValueChange = (value?: number): void => {
    const { isValidInput } = FilterWorkplaceCount;

    this.setState(({ isErroneous, min }) => ({
      max: value,
      isErroneous: isErroneous ? isValidInput(min, value) : false,
    }));
  };

  private readonly handleMinBlur = (): void => {
    const { value, setMin } = this.props;
    const { gte } = value;

    const { min } = this.state;

    if (gte !== min) {
      setMin(min);
    }
  };

  private readonly handleMaxBlur = (): void => {
    const { value, setMax } = this.props;
    const { lte } = value;

    const { max } = this.state;

    if (lte !== max) {
      setMax(max);
    }
  };

  private readonly validate = (): void => {
    const { min, max } = this.state;

    const { isValidInput } = FilterWorkplaceCount;

    this.setState({ isErroneous: isValidInput(min, max) });
  };
}
