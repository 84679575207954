import { getSizeProps, TAdFoxBannerTemplate } from '@cian/adfox-component';
import { Base64 } from 'js-base64';
import * as React from 'react';
import { MediaBannerContainer } from '../../../containers/MediaBanner';
import { useApplicationContext } from '../../../utils/applicationContext';
import { IMediaBannerProps } from '../../media_banner/media_banner';
import * as styles from './styles.css';
import { DemandBanner, IAdFoxDemandBannerTemplate } from '../../DemandBanner';

const OWN_BUNDLE_NAMES = ['banner.transfer'];
const OWN_TYPES = ['mainpageBrand', 'BannerDemand'];

export interface IAdFoxTemplateParams {
  type: string;
  newBuildingId: string;
  isApartment: string;
  bgImageUrl: string;
  note: string;
  bannerId: string;
}

export type TAdFoxTemplatesParams = IMediaBannerProps & IAdFoxTemplateParams & IAdFoxDemandBannerTemplate;

export const OwnTemplates: React.FC<TAdFoxBannerTemplate> = props => {
  const ctx = useApplicationContext();
  const { bundleName, bundleParams, containerId } = props;

  if (bundleName && OWN_BUNDLE_NAMES.includes(bundleName) && bundleParams) {
    if (bundleName === 'banner.transfer' && bundleParams.htmlBase64) {
      try {
        const decodedParams = Base64.decode(bundleParams.htmlBase64);
        const parsedDecodedParams = JSON.parse(decodedParams);

        if (isParsedParamsValid(parsedDecodedParams)) {
          if (OWN_TYPES.includes(parsedDecodedParams.type)) {
            switch (parsedDecodedParams.type) {
              case 'mainpageBrand':
                return (
                  <MediaBannerContainer
                    {...parsedDecodedParams}
                    containerId={containerId}
                    bannerId={bundleParams.bannerId}
                  />
                );

              case 'BannerDemand':
                return (
                  <DemandBanner
                    title={parsedDecodedParams.header}
                    text={parsedDecodedParams.text}
                    textButton={parsedDecodedParams.textButton}
                    backgroundColor={parsedDecodedParams.backgroundColor}
                    imageURL={parsedDecodedParams.imageURL}
                    linkHref={parsedDecodedParams.reference}
                  />
                );
            }
          }
        }
      } catch (e) {
        ctx.logger.error(e, {
          message: '[AdFox] Failed to decode/parse bundleParams',
          bannerId: props.bundleParams?.bannerId,
        });
      }
    }
  }

  return <div id={containerId} className={styles['hidden']} style={getSizeProps(props)} />;
};

function isParsedParamsValid(params: object): params is TAdFoxTemplatesParams {
  return 'type' in params;
}
