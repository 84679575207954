import { UIText1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

export interface IPromoItemPropProps {
  label: string;
  value: string;
}

export const PromoItemProp: React.FC<IPromoItemPropProps> = ({ label, value }) => {
  return (
    <div data-testid="PromoItemProp">
      <UIText2 color="gray60_100">{label}</UIText2>
      <UIText1 fontWeight="bold">{value}</UIText1>
    </div>
  );
};
