export enum EMetaStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface IMetaState {
  status: EMetaStatus;
  url: string;
  mapUrl: string;
  count: number;
}
