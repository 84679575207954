import { EDealTypes } from '../../constants/searchRealtors';

export const SET_DEAL_TYPE = 'search_realtors/set_deal_type' as const;

export const setDealType = (payload: EDealTypes) => ({
  type: SET_DEAL_TYPE,
  payload,
});

export type TSetDealTypePayload = Parameters<typeof setDealType>;
export type TSetDealTypeAction = ReturnType<typeof setDealType>;
