import { CustomTooltip, Outside, RadioButtonGroup } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';
import { Checkbox } from '../Checkbox';
import * as React from 'react';

import { FDealType, FOfferType } from '../../utils/category';
import { getOfferTypeLabel } from '../../utils/offerType';
import { normalizeOfferType, getOfferTypeList } from './helpers';
import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';
import * as styles from './FilterOfferType.css';
import { SelectPopupLink } from '../SelectPopupLink';

export function handleOfferTypeSelect(
  prevOfferType: FOfferType,
  nextOfferType: FOfferType,
  onSelect: (offerType: FOfferType) => void,
) {
  if (nextOfferType === prevOfferType) {
    return;
  }

  if ([FOfferType.FlatOld, FOfferType.FlatNew].includes(nextOfferType) && (prevOfferType & FOfferType.Flat) !== 0) {
    if (prevOfferType === FOfferType.Flat) {
      onSelect(nextOfferType === FOfferType.FlatOld ? FOfferType.FlatNew : FOfferType.FlatOld);
    } else {
      onSelect(FOfferType.Flat);
    }
  } else {
    onSelect(nextOfferType);
  }
}

export interface IFilterOfferTypeProps {
  canShowPopupAutoChangeToSuburban: boolean;
  dealType: FDealType;
  offerType: FOfferType;
  showPostfix?: boolean;
  postfixTitle?: string;
  postfixLink?: string;
  onPostfixClick?: VoidFunction;
  isSearchFlatShareEnabled?: boolean;
  hidePopupAutoChangeToSuburban: () => void;
  onSelect: (offerType: FOfferType) => void;
}

const options = [
  { value: String(FOfferType.Residential), label: 'Жилая' },
  {
    value: String(FOfferType.Commercial),
    label: 'Коммерческая',
  },
];

export function FilterOfferType({
  canShowPopupAutoChangeToSuburban,
  dealType,
  offerType,
  showPostfix,
  postfixTitle,
  postfixLink,
  isSearchFlatShareEnabled,
  hidePopupAutoChangeToSuburban,
  onSelect,
  onPostfixClick,
}: IFilterOfferTypeProps) {
  const offerTypes = getOfferTypeList({ dealType, offerType, isSearchFlatShareEnabled });
  const offerTypeLabel = getOfferTypeLabel(dealType, offerType);
  const isPostfixVisible = showPostfix && postfixTitle && postfixLink;

  const [active, setActive] = React.useState(false);

  const withCloseOutsideRef = React.useRef<HTMLButtonElement>(null);

  const handleButtonClick = React.useCallback(() => setActive(active => !active), [setActive]);
  const handleOutsideClick = React.useCallback(() => setActive(false), [setActive]);

  const handleChangeOfferType = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const nextOfferType: FOfferType = Number(e.currentTarget.value);
    handleOfferTypeSelect(offerType, nextOfferType, onSelect);
  };

  const handleRadioButtonClick = (event: React.ChangeEvent<HTMLInputElement>, newOfferTypeString: string): void => {
    const newOfferType: FOfferType = Number(newOfferTypeString);

    if (normalizeOfferType(offerType) !== normalizeOfferType(newOfferType)) {
      const nextOfferType = newOfferType === FOfferType.Residential ? FOfferType.Flat : FOfferType.Office;
      handleOfferTypeSelect(offerType, nextOfferType, onSelect);
    }
  };

  return (
    <>
      <Outside onOutside={handleOutsideClick}>
        <div {...mergeStyles(stylesCommon['container'], styles['container'])} data-mark="FilterOfferType">
          <button
            ref={withCloseOutsideRef}
            {...mergeStyles(stylesCommon['button'], active ? stylesCommon['button--active'] : undefined)}
            title={offerTypeLabel || undefined}
            onClick={handleButtonClick}
          >
            {offerTypeLabel || 'Недвижимость'}
          </button>
          {active && (
            <div className={styles['dropdown']}>
              {dealType !== FDealType.RentDaily && (
                <div className={styles['radio-group']}>
                  <RadioButtonGroup
                    value={String(normalizeOfferType(offerType))}
                    options={options}
                    onChange={handleRadioButtonClick}
                  />
                </div>
              )}
              <ul className={styles['list']}>
                {offerTypes.map((part, index) => (
                  <li key={`part_${index}`}>
                    <ul className={styles['list-inner']}>
                      {part.map(item => (
                        <li key={`item_${item.label}`}>
                          <Checkbox
                            label={item.label}
                            value={item.value}
                            checked={(offerType & item.value) !== 0}
                            onChange={handleChangeOfferType}
                          />
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
              {isPostfixVisible && <SelectPopupLink href={postfixLink} title={postfixTitle} onClick={onPostfixClick} />}
            </div>
          )}
        </div>
      </Outside>
      {!active && canShowPopupAutoChangeToSuburban && (
        <Outside onOutside={hidePopupAutoChangeToSuburban}>
          <CustomTooltip
            content="Тип недвижимости изменен, потому что вы выбрали коттеджный поселок"
            anchorRef={withCloseOutsideRef}
            open
            placement="bottom-start"
            theme="black"
          />
        </Outside>
      )}
    </>
  );
}
