import { getMicrofrontend } from '@cian/mf-registry-legacy/browser/interface';

import { RECOMMENDATIONS_MICROFRONTEND_NAME } from '../../../shared/constants/microfrontend';

export const ensureMicrofrontend = async () => {
  const microfrontend = getMicrofrontend();

  const recommendationsMicrofrontend = microfrontend.getChild(RECOMMENDATIONS_MICROFRONTEND_NAME);

  await recommendationsMicrofrontend.ensure();

  return microfrontend;
};
