interface IParams {
  section: string;
}

/**
 * Если вертикаль НОВОСТРОЙКИ - старые фильтры
 */
export const shouldNewFiltersShow = ({ section }: IParams) => {
  if (section !== 'new-buildings') {
    return true;
  }

  return false;
};
