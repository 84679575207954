import { IJsonQueryDateRangeValue } from '../../../../packages/api-models/common/json_query';
import { IApplicationState } from '../../../types/redux';
import { getDateRangeValue } from '../../../utils/jsonQuery';
import { selectJsonQuery } from '../selectJsonQuery';

export function selectDates(state: IApplicationState): IJsonQueryDateRangeValue | null {
  const jsonQuery = selectJsonQuery(state);

  return getDateRangeValue('dates')(jsonQuery);
}
