import { IMAGE_APPEARANCE, IMAGE_POSITION } from '../../types/promo_block';

const l = IMAGE_POSITION.left;
const r = IMAGE_POSITION.right;
const n = IMAGE_POSITION.none;

// where image should be (left | right | none)
export const COMPOSITION = [[], [l], [l, n], [l, l, r], [r, n, l, l], [l, n, n, n, n, n], [l, l, r, n, n, l]];

const s = IMAGE_APPEARANCE.show;
const h = IMAGE_APPEARANCE.hide;
const w = IMAGE_APPEARANCE.stay;

// which image should hide/show
export const APPEARANCE = [[], [h, w], [s, w], [w, h, h], [w, w, s, h], [w, w, w, w, w, w], [w, h, w, w, w, w]];

// grid sizes for promo blocks
export const SIZES = [
  [12],
  [12, 12],
  [6, 6, 12],
  [12, 12, 12, 12],
  [12, 6, 6, 12, 12],
  [12, 6, 6, 6, 6, 12],
  [12, 12, 12, 6, 6, 12, 12],
];

export const XS_SIZES = [
  [18],
  [6, 18],
  [18, 6, 24],
  [16, 8, 8, 16],
  [16, 8, 16, 8, 24],
  [16, 8, 8, 8, 8, 24],
  [16, 8, 16, 8, 8, 16, 24],
];

// positions of promo blocks
export const POSITIONS: { [propName: string]: number } = {
  indexFlatSale: 0,
  indexFlatRent: 1,
  indexNewobjects: 3,
  indexForDay: 2,
  indexSuburban: 4,
  indexCommerical: 5,
};
