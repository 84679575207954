import { Outside } from '@cian/ui-kit';
import { SuggestionPopup, IItemClickParams, IMultiSuggestResult, IMessageInfo } from '@cian/geosuggest-widget';
import { mergeStyles } from '@cian/utils';
import * as React from 'react';

import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';

import styles from './FilterRegion.css';
import { useBooleanState } from '@cian/react-utils';

export interface IFilterRegionProps extends React.HTMLAttributes<HTMLDivElement> {
  suggestions: IMultiSuggestResult;
  suggestionError?: IMessageInfo;
  isSuggestionsAvailable: boolean;
  userInput: string;
  selectedRegionTitle?: string;
  onUserInput: (query: string) => void;
  setRegion: (regionId: number, regionTitle: string) => void;
}

export function FilterRegion({
  suggestions,
  suggestionError,
  isSuggestionsAvailable,
  userInput,
  selectedRegionTitle,
  onUserInput,
  setRegion,
  ...sharedProps
}: IFilterRegionProps) {
  const { state: isDropdownOpen, setTrue: openDropdown, setFalse: closeDropdown } = useBooleanState(false);
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleValueChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onUserInput(event.currentTarget.value);
    },
    [onUserInput],
  );

  const handleItemClick = React.useCallback(
    (item: IItemClickParams) => {
      if (item.id) {
        setRegion(item.id, item.title);
      }

      closeDropdown();
      onUserInput(item.title);
    },
    [closeDropdown, onUserInput, setRegion],
  );

  return (
    <Outside onOutside={closeDropdown}>
      <div {...mergeStyles(stylesCommon['container'])} data-mark="FilterRegion" {...sharedProps}>
        <input
          {...mergeStyles(stylesCommon['input'], styles['input'])}
          placeholder={'Город'}
          value={isDropdownOpen ? userInput : selectedRegionTitle}
          onFocus={openDropdown}
          onChange={handleValueChange}
        />
        {isDropdownOpen && isSuggestionsAvailable && (
          <SuggestionPopup
            containerStyles={styles['dropdown']}
            suggestions={suggestions}
            selectedItem={selectedItemIndex}
            error={suggestionError}
            onItemSelect={setSelectedItemIndex}
            onItemClick={handleItemClick}
          />
        )}
      </div>
    </Outside>
  );
}
