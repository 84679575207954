import * as React from 'react';
import { CommercialPromoBlock } from '../commercial_promo_block/commercial_promo_block';
import { CommercialPromoSpecial } from '../commercial_promo_block/commercial_promo_special';
import { COMPOSITION_CONFIG } from './promo_config';
import { IPromo } from '../../types/promo';
import { ISeoBlockItem } from '../../types/seo_urls';
import { EmptyRow } from '../empty_row/empty_row';
import { PromoMenu } from '../promo_menu/promo_menu';

interface IProps {
  promoBlocks: IPromo[];
  promoSpecial?: IPromo[];
  promoMenu: ISeoBlockItem[];
}

const s = require('./commercial_promo_blocks.css');

export class CommercialPromoBlocks extends React.Component<IProps> {
  public getBlockClass(index: number) {
    let firstBlockClass = '';
    let columnClass: string;

    const blocksCount = this.props.promoBlocks.length;
    const columnWidth = COMPOSITION_CONFIG[blocksCount][index];

    if (columnWidth === 8) {
      columnClass = `cg-col-8 cg-col-promo-s-12 cg-col-xs-12`;
    } else {
      columnClass = 'cg-col-12';
    }

    if (blocksCount % 2 !== 0 && index === 0) {
      firstBlockClass = 'cg-col-promo-s-24 cg-col-xs-24';
    }

    return `${columnClass} ${firstBlockClass}`;
  }

  public render() {
    if (!COMPOSITION_CONFIG[this.props.promoBlocks.length]) {
      return <EmptyRow />;
    }

    return (
      <div className={s['c-promo-container']}>
        <div className="cg-container-fluid-xs">
          <div className="cg-row">
            <div className="cg-col-18">
              <div className="cg-row">
                {this.props.promoBlocks.map((block: IPromo, i: number) => (
                  <CommercialPromoBlock key={`promo_block_${i}`} blockClassName={this.getBlockClass(i)} {...block} />
                ))}
                {this.renderPromoSpecial()}
              </div>
            </div>
            {<PromoMenu promoUrls={this.props.promoMenu} />}
          </div>
        </div>
      </div>
    );
  }

  private renderPromoSpecial() {
    if (!this.props.promoSpecial || !this.props.promoSpecial.length) {
      return null;
    }
    const columns = this.props.promoSpecial.length === 1 ? 24 : 12;

    return (
      <div>
        {this.props.promoSpecial.map((block, index) => (
          <CommercialPromoSpecial {...block} columns={columns} key={index} />
        ))}
      </div>
    );
  }
}
