import React from 'react';

import { CheckboxLabel } from '../../../components/CheckboxLabel';
import { TCoworkingOfferTypeList } from '../../../types/coworkingOfferType';
import { FCoworkingOfferType } from '../../../utils/category';

export const OFFER_TYPES_RENT_COWORKING: TCoworkingOfferTypeList = [
  [
    {
      label: 'Отдельный офис',
      value: FCoworkingOfferType.FlexibleOffice,
    },
    {
      label: (
        <CheckboxLabel
          label="Закреплённое рабочее место"
          postfixType="info"
          tooltipTitle="Этим столом будете пользоваться только вы"
        />
      ),
      value: FCoworkingOfferType.FixedWorkSpace,
    },
    {
      label: (
        <CheckboxLabel
          label="Незакреплённое рабочее место"
          postfixType="info"
          tooltipTitle="Любое доступное рабочее место в общей зоне"
        />
      ),
      value: FCoworkingOfferType.FreeWorkSpace,
    },
  ],
];
