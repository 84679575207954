import * as React from 'react';

import { throttle } from 'throttle-debounce';
import { checkVisible, ILazyLoad } from './utils/visibility';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let listeners: LazyLoad<any>[];

function scheduleLowPriorityTask(cb: () => void) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const r = (window as any).requestIdleCallback;

  if (r) {
    r(cb, { timeout: 500 });
  } else {
    setTimeout(cb, 500);
  }
}

export interface ILazyLoadProps<T> {
  deferred?: boolean;
  offset?: number;

  onLoad(): Promise<T>;
  renderEmpty(): JSX.Element;
  renderLoading?(): JSX.Element;
  renderLoaded(result: T): JSX.Element;
  renderError?(e: Error): JSX.Element;
}

const lazyLoadHandler = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newListeners: LazyLoad<any>[] = [];

  for (let i = 0; i < listeners.length; ++i) {
    const listener = listeners[i];
    if (!checkVisible(listener)) {
      newListeners.push(listener);
    }
  }

  listeners = newListeners;
};

const loadFirstListener = () => {
  const componentIndex = listeners.findIndex(listener => !!listener.props.deferred);
  if (componentIndex !== -1) {
    const [component] = listeners.splice(componentIndex, 1);

    component.visible = true;
    component.forceUpdate();
  }

  scheduleLowPriorityTask(loadFirstListener);
};

const initLazyLoad = () => {
  // tslint:disable-next-line
  if (typeof window === 'undefined' || listeners) {
    return;
  }

  listeners = [];

  const finalLazyLoadHandler = throttle(300, lazyLoadHandler);

  window.addEventListener('scroll', finalLazyLoadHandler, false);
  window.addEventListener('resize', finalLazyLoadHandler, false);
  window.addEventListener('load', () => scheduleLowPriorityTask(loadFirstListener), false);
};

export class LazyLoad<T> extends React.Component<ILazyLoadProps<T>, {}> implements ILazyLoad<ILazyLoadProps<T>, {}> {
  public visible = false;

  private status: 'waiting' | 'loading' | 'loaded' | 'error' = 'waiting';
  private content!: Promise<T>;
  private loadResult!: T;
  private loadError!: Error;

  public componentDidMount() {
    initLazyLoad();

    if (!checkVisible(this)) {
      listeners.push(this);
    }
  }

  public componentWillUnmount() {
    listeners = listeners.filter(el => el !== this);
  }
  public componentDidUpdate() {
    if (this.visible && this.status === 'waiting') {
      this.status = 'loading';
      this.content = this.props.onLoad();

      this.content
        .then((result: T) => {
          this.status = 'loaded';
          this.loadResult = result;

          this.forceUpdate();
        })
        .catch((e: Error) => {
          this.status = 'error';
          this.loadError = e;

          this.forceUpdate();
        });
    }
  }

  public render() {
    switch (this.status) {
      case 'waiting':
        return this.props.renderEmpty();

      case 'loading':
        if (this.props.renderLoading) {
          return this.props.renderLoading();
        }

        return this.props.renderEmpty();

      case 'loaded':
        return this.props.renderLoaded(this.loadResult);

      case 'error':
        if (this.props.renderError) {
          return this.props.renderError(this.loadError);
        }

        return this.props.renderEmpty();

      default:
        return this.props.renderEmpty();
    }
  }
}
