import BlackFridayIcon from './assets/black-friday.svg';
import SalesStartIcon from './assets/sales-start.svg';
import SalesLeaderIcon from './assets/sales-leader.svg';
import SalesSoonIcon from './assets/sales-soon.svg';

import { ESalesType } from '../../../../types/salesLink';

export const SALES_TYPE_ICONS = new Map<ESalesType, string>([
  [ESalesType.BlackFriday, BlackFridayIcon],
  [ESalesType.SalesStart, SalesStartIcon],
  [ESalesType.SalesLeader, SalesLeaderIcon],
  [ESalesType.UpcomingSale, SalesSoonIcon],
]);
