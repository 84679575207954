import { TReduxActions } from '../../types/redux';
import { RESET_REGION_EXPANSION_TYPE, SET_REGION_EXPANSION_TYPE } from '../../actions/regionExpansion';

const defaultState = null;

export function regionExpansionReducer(state = defaultState, action: TReduxActions): number | null {
  switch (action.type) {
    case SET_REGION_EXPANSION_TYPE:
      return action.payload;

    case RESET_REGION_EXPANSION_TYPE:
      return defaultState;

    default:
      return state;
  }
}
