import { TReduxActions } from '../../../types/redux';
import { IMetaState, EMetaStatus } from '../../../types/filters';
import { FILTERS_GET_META_TYPE } from '../../../constants/action_types';
import { defaultState } from './defaultState';

export function metaReducer(state: IMetaState = defaultState, action: TReduxActions): IMetaState {
  switch (action.type) {
    case FILTERS_GET_META_TYPE:
      if (action.status !== EMetaStatus.Succeed) {
        return {
          ...state,
          status: action.status,
        };
      }

      return {
        ...state,
        status: action.status,
        url: action.url,
        mapUrl: action.mapUrl,
        count: action.count,
      };
  }

  return state;
}
