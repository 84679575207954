/* eslint-disable @typescript-eslint/no-explicit-any */
import * as R from 'ramda';
import { FilterCoworkingOfferType } from '../../components/FilterCoworkingOfferType';

import { FOfferType, FDealType, dealTypeFromJsonQuery, offerTypeFromJsonQuery } from '../../utils/category';
import { setTerm } from '../../utils/jsonQuery';
import { FilterOfferTypeContainer } from '../../containers/FilterOfferTypeContainer';
import { FilterRoomTypeContainer } from '../../containers/FilterRoomsCountContainer';
import { clear as clearRoomType } from '../../components/FilterRoomType';
import { FilterPriceContainer } from '../../containers/FilterPriceContainer';
import { FilterAreaContainer } from '../../containers/FilterAreaContainer';
import { FilterGeoContainer } from '../../containers/FilterGeoContainer';
import { FilterLandAreaContainer } from '../../containers/FilterLandAreaContainer';
import { FilterCoworkingPriceContainer } from '../../containers/FilterCoworkingPriceContainer';
import { FilterWorkplaceCountContainer } from '../../containers/FilterWorkplaceCountContainer';
import { FilterBedsContainer } from '../../containers/FilterBedsContainer';
import { FilterBusinessAppointments } from '../../components/FilterBusinessAppointments';
import { FilterDatesContainer } from '../../containers/FilterDatesContainer';
import { IJsonQuery } from '../../../packages/api-models/common/json_query';

export interface IFilter {
  availability(jsonQuery: IJsonQuery): boolean;
  clear?(jsonQuery: IJsonQuery): IJsonQuery;
  component: React.ComponentType<{ offerType?: FOfferType; dealType?: FDealType }>;
}

export type IAvailableFilters = Record<
  | 'offerType'
  | 'roomType'
  | 'price'
  | 'area'
  | 'landArea'
  | 'geo'
  | 'coworkingOfferType'
  | 'coworkingPrice'
  | 'workplaceCount'
  | 'dates'
  | 'beds'
  | 'businessAppointments',
  IFilter
>;

export const FILTERS: IAvailableFilters = {
  offerType: {
    availability: isAvailable(FDealType.Any, FOfferType.Any),
    component: FilterOfferTypeContainer,
  },
  coworkingOfferType: {
    availability: isAvailable(FDealType.Rent, FOfferType.Coworking),
    clear: clearByKeys(['coworking_offer_type']),
    component: FilterCoworkingOfferType,
  },
  businessAppointments: {
    availability: isAvailable(FDealType.Any, FOfferType.Business),
    component: FilterBusinessAppointments,
  },
  workplaceCount: {
    availability: isAvailable(FDealType.Rent, FOfferType.Coworking),
    clear: clearByKeys(['workplace_count']),
    component: FilterWorkplaceCountContainer,
  },
  coworkingPrice: {
    availability: isAvailable(FDealType.Rent, FOfferType.Coworking),
    component: FilterCoworkingPriceContainer,
  },
  roomType: {
    availability: isAvailable(FDealType.Any, FOfferType.Flat),
    clear: clearRoomType,
    component: FilterRoomTypeContainer as any,
  },
  price: {
    availability: isAvailable(FDealType.Any, FOfferType.Any),
    component: FilterPriceContainer as any,
  },
  area: {
    availability: isAvailable(
      FDealType.Sale | FDealType.RentLongterm,
      FOfferType.Commercial & ~FOfferType.CommercialLand,
    ),
    clear: clearByKeys(['total_area']),
    component: FilterAreaContainer as any,
  },
  landArea: {
    availability: isAvailable(FDealType.Any, FOfferType.CommercialLand),
    clear: clearByKeys(['site']),
    component: FilterLandAreaContainer as any,
  },
  geo: {
    availability: isAvailable(FDealType.Any, FOfferType.Any),
    component: FilterGeoContainer as any,
  },
  dates: {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Any),
    component: FilterDatesContainer,
  },
  beds: {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Any),
    component: FilterBedsContainer,
  },
};

export function isAvailable(dealType: FDealType, offerType: FOfferType): (jsonQuery: IJsonQuery) => boolean {
  return jsonQuery => {
    const nextDealType = dealTypeFromJsonQuery(jsonQuery);
    const nextOfferType = offerTypeFromJsonQuery(jsonQuery);

    return (dealType & nextDealType) !== 0 && (offerType & nextOfferType) !== 0;
  };
}

export function clearByKeys(keys: (keyof IJsonQuery)[]): (jsonQuery: IJsonQuery) => IJsonQuery {
  return jsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    keys.forEach(key => (nextJsonQuery = setTerm(key)(nextJsonQuery)(undefined)));

    return nextJsonQuery;
  };
}
