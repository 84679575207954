/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TApiGeoGeocodeCachedModel,
  IApiGeoGeocodeCachedRequest,
  IMappers,
  IApiGeoGeocodeCachedResponse,
  IApiGeoGeocodeCachedResponseError,
  TApiGeoGeocodeCachedResponse,
} from './types';

const defaultConfig: TApiGeoGeocodeCachedModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'monolith-cian-realty',
  pathApi: '/api/geo/geocode-cached/',
  hostType: 'api',
} as TApiGeoGeocodeCachedModel;

function createApiGeoGeocodeCachedModel(parameters: IApiGeoGeocodeCachedRequest): TApiGeoGeocodeCachedModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IApiGeoGeocodeCachedOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IApiGeoGeocodeCachedRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchApiGeoGeocodeCached<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IApiGeoGeocodeCachedOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TApiGeoGeocodeCachedResponse
> {
  const { response, statusCode } = await httpApi.fetch(createApiGeoGeocodeCachedModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IApiGeoGeocodeCachedResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IApiGeoGeocodeCachedResponseError);
    }
  }

  return { response, statusCode } as TApiGeoGeocodeCachedResponse;
}

export { defaultConfig, createApiGeoGeocodeCachedModel, fetchApiGeoGeocodeCached };
