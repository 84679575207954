import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewSeoUrlsBlok } from '../../components/new_seo_urls_block';
import { SeoBlock } from '../../components/seo_block';
import { IApplicationState } from '../../types/redux';
import { useApplicationContext } from '../../utils/applicationContext';
import { EMPTY_SEO_TEXT_KEY, EMPTY_SEO_URLS_KEY, HAS_SEO_TEXT_KEY, HAS_SEO_URLS_KEY } from './constants';
import { NewbuildingSeoBlockContainer } from '../NewbuildingSeoBlock';
import { selectNewbuildingSeoBlock } from '../../selectors/newbuildingSeoBlock';

const s = require('./seo_container.css');

export const SeoContainer = () => {
  const { telemetry } = useApplicationContext();
  const { seoText, seoTextHeading } = useSelector((state: IApplicationState) => state.mainpage.seoData);
  const newSeoUrls = useSelector((state: IApplicationState) => state.mainpage.newSeoUrls);
  const seoBlock = useSelector(selectNewbuildingSeoBlock);

  const hasSeoText = seoText.length > 0 && seoTextHeading;
  const hasSeoUrls = newSeoUrls.length > 0;
  const isEmpty = !hasSeoText && !hasSeoUrls && !seoBlock.region && !seoBlock.city;

  React.useEffect(() => {
    if (hasSeoText) {
      telemetry.increment(HAS_SEO_TEXT_KEY);
    } else {
      telemetry.increment(EMPTY_SEO_TEXT_KEY);
    }

    if (hasSeoUrls) {
      telemetry.increment(HAS_SEO_URLS_KEY);
    } else {
      telemetry.increment(EMPTY_SEO_URLS_KEY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSeoText, hasSeoUrls]);

  if (isEmpty) {
    return null;
  }

  return (
    <div className={s['c-seo']}>
      <div className="cg-container-fluid-xs">
        <div className="cg-row">
          {hasSeoText && <SeoBlock seoText={seoText} seoTextHeading={seoTextHeading} />}
          {hasSeoUrls && <NewSeoUrlsBlok newSeoUrls={newSeoUrls} />}
        </div>
        <NewbuildingSeoBlockContainer />
      </div>
    </div>
  );
};
