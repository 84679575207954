import * as React from 'react';
import { Image } from '@cian/ui-kit';

import { formatPrice } from '../../../shared/utils/format_helper';
import { LinkArea } from '../LinkArea';
import { Address } from './Address';

import * as styles from './popular_item.css';
import { trackEntityTeaserShow } from './tracking';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

export interface IPopularItemProps {
  metro?: {
    timeType: string;
    name: string;
    lineColor: string;
    time: number;
  };
  title: string;
  url: string;
  priceAmount: number;
  price: {
    amount: number;
    priceType?: string;
  };
  position: number;
  address?: string;
  thumbnailUrl: string;
  onClick(): void;
}

export function PopularItem(props: IPopularItemProps) {
  const formattedPrice = formatPrice(props.price.amount, 4);

  const handleEntityTrackTeaserShow = React.useCallback((url: string, position: number) => {
    // объект объявки содержит id только как часть ссылки
    const numsInUrl = url.match(/\d+/g) || [0];
    const offerId = Number(numsInUrl[numsInUrl?.length - 1]);
    trackEntityTeaserShow(offerId, position);
  }, []);

  return (
    <ActionAfterViewObserver
      percentVisible={0.5}
      callback={() => handleEntityTrackTeaserShow(props.url, props.position)}
      triggerOnce={false}
      callbackDelay={1000}
      wrapperClass={`${styles['c-popular-block']} cg-col-8`}
    >
      <LinkArea href={props.url} onClick={props.onClick} data-name="PopularItem">
        <Image
          src={props.thumbnailUrl}
          loading="lazy"
          preloader={false}
          objectFit="cover"
          display="block"
          borderRadius={0}
          height={200}
        />
        <div className={styles['c-popular-info']}>
          <span className={styles['c-popular-price']}>{formattedPrice} ₽</span>
          <a
            href={props.url}
            className={styles['c-popular-tec-info']}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: props.title }}
          />
          <Address address={props.address} metro={props.metro} />
        </div>
      </LinkArea>
    </ActionAfterViewObserver>
  );
}
