import * as React from 'react';
import { LinkButton, UIHeading2, UIText1 } from '@cian/ui-kit';

import * as styles from './new_buildings_service_block.css';

interface IProps {
  onAdsClick(eventLabel: string): void;
}

export const NewBuildingsServiceBlock = (props: IProps) => (
  <div className={styles['c-selfad-single']}>
    <div className={styles['c-selfad-single-content']}>
      <UIHeading2>Добавьте свой ЖК</UIHeading2>
      <UIText1>Бесплатное размещение объявлений для застройщиков и их официальных представителей</UIText1>
    </div>
    <LinkButton onClick={() => props.onAdsClick('contact')} href="/contacts/" target="_blank">
      Связаться с менеджером
    </LinkButton>
  </div>
);
