import { EOfferTypes } from '../../constants/searchRealtors';

export const SET_OFFER_TYPE = 'search_realtors/set_offer_type' as const;

export const setOfferType = (payload: EOfferTypes[] | null) => ({
  type: SET_OFFER_TYPE,
  payload,
});

export type TSetOfferTypePayload = Parameters<typeof setOfferType>;
export type TSetOfferTypeAction = ReturnType<typeof setOfferType>;
