import { EGeoSuggestStatus, GeoSuggestMappers } from './types';

export const mappers: GeoSuggestMappers = {
  200: state => ({
    data: state.data,
    message: null,
    status: EGeoSuggestStatus.Success,
  }),
  400: ({ message }) => ({
    data: {
      suggestions: {},
      sortOrder: [],
    },
    message,
    status: EGeoSuggestStatus.Error,
  }),
};
