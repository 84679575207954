import { TBannerTypes } from '../../../types/newbuildingBanners/banners';

export type TBannerTheme =
  | 'brand-pink'
  | 'primary-basic'
  | 'brand-peach'
  | 'gradient-linear-primary-brand-pink'
  | 'gradient-linear-diagonal-primary-brand-pink'
  | 'gradient-linear-diagonal-primary-brand-peach';

const typeToThemeDefaultSet: Record<TBannerTypes, TBannerTheme> = {
  promosFlight: 'primary-basic',
  allPromos: 'primary-basic',
  kpn: 'brand-pink',
  topPromos: 'brand-peach',
  lastChanceDiscount: 'brand-pink',
  topPromo: 'gradient-linear-primary-brand-pink',
};

const typeToThemeIndividualSet: Record<TBannerTypes, TBannerTheme> = {
  promosFlight: 'primary-basic',
  allPromos: 'primary-basic',
  kpn: 'brand-pink',
  topPromos: 'gradient-linear-diagonal-primary-brand-peach',
  lastChanceDiscount: 'gradient-linear-diagonal-primary-brand-pink',
  topPromo: 'gradient-linear-primary-brand-pink',
};

export const bannerTypeToDefaultTheme = ({ type }: { type: TBannerTypes }) => {
  return typeToThemeDefaultSet[type];
};

export const bannerTypeToGradientTheme = ({ type }: { type: TBannerTypes }) => {
  return typeToThemeIndividualSet[type];
};
