import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_BUILDER_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';

export type TSetBuilderType = 'filters/set_builder';

export interface ISetBuilder extends Action<TSetBuilderType> {
  type: TSetBuilderType;
  builderIds: number;
}

export type TSetBuilder = ISetBuilder;

export function setBuilder(builderId: number): TThunkAction {
  return dispatch => {
    dispatch<TSetBuilder>({
      type: FILTERS_SET_BUILDER_TYPE,
      builderIds: builderId,
    });

    dispatch(getMeta());
  };
}
