import { TReduxActions } from '../../../types/redux';
import { IVacanciesState } from '../../../types/vacancies';
import { EVacanciesActionType } from '../../../actions/vacancies';

const initialState: IVacanciesState = {
  data: [],
  isError: false,
  isFetching: false,
};

export const vacanciesReducer = (state = initialState, action: TReduxActions) => {
  switch (action.type) {
    case EVacanciesActionType.fetching:
      return { ...state, ...{ isFetching: true } };

    case EVacanciesActionType.success:
      return { ...state, ...{ isFetching: false, data: action.payload } };

    case EVacanciesActionType.failure:
      return { ...state, ...{ isFetching: false, isError: true } };

    default:
      return state;
  }
};
