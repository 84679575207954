import { TMainPageState } from '../../types/mainpage';
import { MAINPAGE_DEFAULT_STATE } from '../../constants/mainpage/defaultState';
import { TReduxActions } from '../../types/redux';
import { SET_PAGE_TITLE } from '../../actions/mainpage';

export function mainpageReducer(state = MAINPAGE_DEFAULT_STATE, action: TReduxActions): TMainPageState {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return {
        ...state,
        seoData: {
          ...state.seoData,
          h1: action.payload,
        },
      };

    default:
      return state;
  }
}
