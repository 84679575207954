import * as R from 'ramda';

import { TReduxActions } from '../../../types/redux';
import { FILTERS, IFilter, IAvailableFilters } from '../../../constants/filters';
import { defaultState } from './defaultState';
import { IJsonQuery } from '../../../../packages/api-models/common/json_query';

export function clearReducer(jsonQuery: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  if (action.type.startsWith('filters/')) {
    let nextJsonQuery = R.clone(jsonQuery);
    // tslint:disable-next-line: no-any
    Object.keys(FILTERS)
      .map(key => FILTERS[key as keyof IAvailableFilters])
      .forEach((filter: IFilter) => {
        if (filter.clear && !filter.availability(nextJsonQuery)) {
          nextJsonQuery = filter.clear(nextJsonQuery);
        }
      });

    return nextJsonQuery;
  }

  return jsonQuery;
}
