import * as React from 'react';
import filter from 'lodash/filter';
import { connect } from 'react-redux';
import { IPromo } from '../../types/promo';
import { ISeoBlockItem } from '../../types/seo_urls';
import { IApplicationState } from '../../types/redux';
import { CommercialPromoBlocks } from '../../components/commercial_promo_blocks/commercial_promo_blocks';

interface IProps {
  promo: IPromo[];
  seoUrlsBlocks: ISeoBlockItem[];
  isFetching: boolean;
}

class CommercialPromo extends React.Component<IProps> {
  public getPromoBlocks(): IPromo[] {
    return filter(this.props.promo, { type: 'group' });
  }

  public getPromoBanner(): IPromo[] | undefined {
    return filter(this.props.promo, { type: 'special' });
  }

  public render() {
    return (
      <CommercialPromoBlocks
        promoBlocks={this.getPromoBlocks()}
        promoSpecial={this.getPromoBanner()}
        promoMenu={this.props.seoUrlsBlocks}
      />
    );
  }
}

const CommercialPromoWrapper = connect((state: IApplicationState) => ({
  promo: state.mainpage.promoBlocks,
  seoUrlsBlocks: state.mainpage.seoUrlsBlocks,
  isFetching: state.mainpage.isFetching,
}))(CommercialPromo);

export { CommercialPromoWrapper as CommercialPromo, CommercialPromo as ComercialPromoClass };
