import { Action } from 'redux';

import { TThunkAction } from '../../../types/redux';
import { FILTERS_CLEAR_GEO_TYPE } from '../../../constants/action_types';
import { getMeta } from '../getMeta';

export type TClearGeoType = 'filters/geo/clear_geo';

export interface IClearGeo extends Action<TClearGeoType> {
  type: TClearGeoType;
}

export type TClearGeo = IClearGeo;

export function clearGeo(): TThunkAction {
  return dispatch => {
    dispatch<TClearGeo>({
      type: FILTERS_CLEAR_GEO_TYPE,
    });

    dispatch(getMeta());
  };
}
