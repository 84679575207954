import * as React from 'react';

import { IAdFoxBannerContext } from '@cian/adfox';
import { Adfox } from './adfox';
import { StringLazyLoad } from '../../../shared/utils/lazy_load_helper';
import { IAdfoxConstants } from './constants';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emptyBanner(): React.ReactElement<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  banner(context: IAdFoxBannerContext): React.ReactElement<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  failureBanner?(error: Error): React.ReactElement<any>;
  constants: IAdfoxConstants;
  section?: string;
}

export const AdfoxLazy = (props: IProps) => (
  <StringLazyLoad
    onLoad={() => Promise.resolve('')}
    renderEmpty={() => props.emptyBanner()}
    renderLoading={() => props.emptyBanner()}
    renderLoaded={() => (
      <Adfox
        constants={props.constants}
        section={props.section}
        banner={props.banner}
        failureBanner={props.failureBanner}
      />
    )}
    renderError={(e: Error) => (props.failureBanner ? props.failureBanner(e) : props.emptyBanner())}
  />
);
