import { connect } from 'react-redux';
import { IMortgageLandingProps, MortgageLanding } from '../../components/MortgageLanding';
import { selectMortgageBanks } from '../../selectors/mortgage/selectMortgageBanks';
import { selectMortgageFilterDownPayment } from '../../selectors/mortgage/selectMortgageFilterDownPayment';
import { selectMortgageFilterRealtyPrice } from '../../selectors/mortgage/selectMortgageFilterRealtyPrice';
import { selectMortgageRates } from '../../selectors/mortgage/selectMortgageRates';
import { IApplicationState } from '../../types/redux';

export const mapStateToProps = (state: IApplicationState): IMortgageLandingProps => {
  const { mortgage } = state;

  return {
    initialPayment: selectMortgageFilterDownPayment(state),
    propertyAmount: selectMortgageFilterRealtyPrice(state),
    loanTerm: mortgage.filterTerms,
    pageType: 'frontend_mainpage',
    banks: selectMortgageBanks(state),
    rates: selectMortgageRates(state),
  };
};

export const MortgageLandingContainer = connect(mapStateToProps)(MortgageLanding);
