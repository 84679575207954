import { connect } from 'react-redux';
import { setMortgageDownPayment } from '../../actions/mortgage';
import { FilterAmountInput, IFilterAmountInputProps } from '../../components/FilterAmountInput';
import { selectMortgageFilterDownPayment } from '../../selectors/mortgage/selectMortgageFilterDownPayment';
import { IApplicationState, TThunkDispatch } from '../../types/redux';

type TFilterMortgageDownPaymentStateProps = Pick<
  IFilterAmountInputProps,
  'integerLimit' | 'placeholder' | 'suffix' | 'value'
>;
type TFilterMortgageDownPaymentDispatchProps = Pick<IFilterAmountInputProps, 'onValueChange'>;

export function mapStateToProps(state: IApplicationState): TFilterMortgageDownPaymentStateProps {
  return {
    integerLimit: 12,
    placeholder: 'Первоначальный взнос',
    suffix: `\u20BD`,
    value: selectMortgageFilterDownPayment(state),
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TFilterMortgageDownPaymentDispatchProps {
  return {
    onValueChange: (value: number) => {
      dispatch(setMortgageDownPayment(value));
    },
  };
}

export const FilterMortgageDownPaymentContainer = connect(mapStateToProps, mapDispatchToProps)(FilterAmountInput);
