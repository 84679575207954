import { Outside, NumberInput, InputGroup, InputAdornment } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';
import * as React from 'react';

import { getPriceLabel, getPriceTitle } from '../../utils/price';
import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';
import * as styles from './FilterPrice.css';
import { isValidInput, type NumberInputValue } from '../../utils/isValidInput';

export interface IFilterPriceProps {
  gte: NumberInputValue;
  lte: NumberInputValue;
  setMin: (value: number | undefined) => void;
  setMax: (value: number | undefined) => void;
}

export interface IFilterPriceState {
  min: number | undefined;
  max: number | undefined;
  isError: boolean;
}

export function FilterPrice({ gte, lte, setMin, setMax }: IFilterPriceProps) {
  const [active, setActive] = React.useState(false);
  const [inputState, setInputState] = React.useState<IFilterPriceState>({
    min: gte,
    max: lte,
    isError: false,
  });

  const handleButtonClick = React.useCallback(() => setActive(active => !active), [setActive]);
  const handleOutsideClick = React.useCallback(() => setActive(false), [setActive]);

  const handleMinValueChange = (value: number | undefined) => {
    setInputState(state => ({
      ...state,
      min: value,
      isError: !isValidInput(value, state.max),
    }));
  };

  const handleMaxValueChange = (value: number | undefined) => {
    setInputState(state => ({
      ...state,
      max: value,
      isError: !isValidInput(state.min, value),
    }));
  };

  const handleBlur = () => {
    if (gte !== inputState.min) {
      setMin(inputState.min);
    }
    if (lte !== inputState.max) {
      setMax(inputState.max);
    }
  };

  const { min, max, isError } = inputState;
  const priceTitle = getPriceTitle(min, max) || undefined;
  const priceLabel = getPriceLabel(min, max);

  return (
    <Outside onOutside={handleOutsideClick}>
      <div {...mergeStyles(stylesCommon['container'], styles['container'])} data-mark="FilterPrice">
        <button
          {...mergeStyles(
            stylesCommon['button'],
            active && stylesCommon['button--active'],
            !priceLabel && stylesCommon['button--empty'],
          )}
          title={priceTitle}
          onClick={handleButtonClick}
        >
          {priceLabel || 'Цена'}
        </button>
        {active && (
          <div onBlur={handleBlur} {...mergeStyles(stylesCommon['dropdown'], styles['dropdown'])}>
            <InputGroup>
              <NumberInput
                placeholder="от"
                invalid={isError}
                rightAdornment={<InputAdornment>{'\u20bd'}</InputAdornment>}
                min={0}
                value={min}
                onChange={handleMinValueChange}
              />
              <NumberInput
                placeholder="до"
                invalid={isError}
                rightAdornment={<InputAdornment>{'\u20bd'}</InputAdornment>}
                min={0}
                value={max}
                onChange={handleMaxValueChange}
              />
            </InputGroup>
          </div>
        )}
      </div>
    </Outside>
  );
}
