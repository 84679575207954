import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { getRegions, IRegion } from '../../../app/api/get_regions';
import { IMessageInfo } from '@cian/geosuggest-widget';

export type TRegionSuggestsGetSuccessTypes = 'region_sugests/GET_SUCCESS';
export type TRegionSuggestsGetFailedTypes = 'region_sugests/GET_FAILED';

export const REGION_SUGGEST_GET_SUCCESS: TRegionSuggestsGetSuccessTypes = 'region_sugests/GET_SUCCESS';
export const REGION_SUGGEST_GET_FAILED: TRegionSuggestsGetFailedTypes = 'region_sugests/GET_FAILED';

export interface IRegionSuggestGetSuccessAction extends Action<TRegionSuggestsGetSuccessTypes> {
  type: TRegionSuggestsGetSuccessTypes;
  regionSuggests: IRegion[];
}

export interface IRegionSuggestGetFailedAction extends Action<TRegionSuggestsGetFailedTypes> {
  type: TRegionSuggestsGetFailedTypes;
  regionSuggestError: IMessageInfo;
}

export type TRegionSuggestsGetSuccess = IRegionSuggestGetSuccessAction;
export type TRegionSuggestsGetFailed = IRegionSuggestGetFailedAction;

export function loadRegionSuggests(query: string): TThunkAction<Promise<void>> {
  return dispatch => {
    return getRegions(query)
      .then((regions: IRegion[]) => {
        if (regions.length === 0) {
          const errorData = {
            title: 'Ничего не найдено',
            text: 'Укажите другой город',
          };
          dispatch<TRegionSuggestsGetFailed>({ type: REGION_SUGGEST_GET_FAILED, regionSuggestError: errorData });
        } else {
          dispatch<TRegionSuggestsGetSuccess>({ type: REGION_SUGGEST_GET_SUCCESS, regionSuggests: regions });
        }
      })
      .catch(() => {
        const errorData = {
          title: 'Не удалось получить возможные варианты',
          text: 'Попробуйте еще раз',
        };
        dispatch<TRegionSuggestsGetFailed>({ type: REGION_SUGGEST_GET_FAILED, regionSuggestError: errorData });
      });
  };
}
