import { selectIsUserProfi } from '../../selectors/user';
import { TThunkAction } from '../../types/redux';
import { getVacancies } from './getVacancies';

export const getProfiVacancies = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { isAuthenticated } = state.user;

    const userIsProfi = selectIsUserProfi(state);

    if (isAuthenticated && userIsProfi) {
      dispatch(getVacancies());
    }
  };
};
