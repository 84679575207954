import {
  IJsonQuery,
  IJsonQueryType,
  ECategoryType,
  ERentTime,
  EOfficeType,
  EObjectType,
} from '../../../packages/api-models/common/json_query';
import * as R from 'ramda';

import { FDealType, offerTypeFromJsonQuery, FOfferType } from '../category';
import { setRootType, setTerm, setTerms } from './helpers';
import { selectOfferType } from './selectOfferType';

const FDealTypeName = new Map<FDealType, string>([
  [FDealType.Sale, 'sale'],
  [FDealType.RentDaily, 'rent'],
  [FDealType.RentLongterm, 'rent'],
]);

const FCategoryMap = new Map<FDealType, ECategoryType>([
  [FDealType.RentLongterm, ECategoryType.CommercialLandRent],
  [FDealType.Sale, ECategoryType.CommercialLandSale],
]);

export function setDealType(jsonQuery: IJsonQuery) {
  return (nextDealType: FDealType): IJsonQuery => {
    let nextJsonQuery = R.clone(jsonQuery);

    const type = jsonQuery._type;
    const category = jsonQuery.category;

    let nextType: IJsonQueryType = 'flatrent';

    const nextDealTypeString = FDealTypeName.get(nextDealType);
    if (nextDealTypeString) {
      nextType = type.replace(/rent|sale/, nextDealTypeString) as IJsonQueryType;
    }

    nextJsonQuery = setRootType(nextType)(nextJsonQuery);

    /**
     * Переключает на валидный offerType в соответствии с _type
     * Например: Выбрали dealType посуточно,
     * когда фильтры были для комерческой - переключит на посуточную аренду вторички
     */
    const offerType = offerTypeFromJsonQuery(jsonQuery);
    const nextOfferType = resetNextOfferType(nextDealType, offerType);
    if (nextOfferType) {
      nextJsonQuery = selectOfferType(nextJsonQuery)(nextOfferType);
    }
    // --------------------------------------------------------------------

    nextJsonQuery = setTerm('category')(nextJsonQuery)(undefined);
    nextJsonQuery = setTerm('for_day')(nextJsonQuery)(undefined);

    if ((nextDealType & FDealType.Rent) !== 0) {
      if (nextDealType === FDealType.RentDaily) {
        nextJsonQuery = setTerm('for_day')(nextJsonQuery)(ERentTime.Daily);
      } else {
        nextJsonQuery = setTerm('for_day')(nextJsonQuery)(ERentTime.NotDaily);
      }
    }

    if (category) {
      nextJsonQuery = setTerms('category')(nextJsonQuery)([FCategoryMap.get(nextDealType) as ECategoryType]);
    }

    if (/rent|sale/.test(nextType) && jsonQuery.office_type?.value.includes(EOfficeType.Coworking)) {
      if (nextJsonQuery.object_type) {
        nextJsonQuery = setTerms('object_type')(nextJsonQuery)([EObjectType.House]);
      }

      if (nextJsonQuery.office_type) {
        nextJsonQuery = setTerms('office_type')(nextJsonQuery)([EOfficeType.Office]);
      }
    }

    if (nextType.includes('rent') && nextJsonQuery.office_type?.value.includes(EOfficeType.Business)) {
      nextJsonQuery = setTerms('ready_business_types')(nextJsonQuery)([]);

      if (nextJsonQuery.office_type) {
        const officeTypeWithoutBusiness = nextJsonQuery.office_type?.value.filter(
          type => type !== EOfficeType.Business,
        );
        nextJsonQuery = setTerms('office_type')(nextJsonQuery)(
          officeTypeWithoutBusiness.length ? officeTypeWithoutBusiness : [EOfficeType.Office],
        );
      }
    }

    return nextJsonQuery;
  };
}

function resetNextOfferType(dealType: FDealType, offerType: FOfferType) {
  switch (dealType) {
    case FDealType.RentLongterm:
      if (offerType & (FOfferType.FlatNew | FOfferType.FlatShared)) {
        return FOfferType.Flat;
      }
      break;
    case FDealType.RentDaily:
      if (offerType & (FOfferType.Any & ~FOfferType.FlatOld & ~FOfferType.Room & ~FOfferType.Bed & ~FOfferType.House)) {
        return FOfferType.Flat;
      }
      break;
    case FDealType.Sale:
      if (offerType & (FOfferType.FlatOld | FOfferType.Bed)) {
        return FOfferType.Flat;
      }
      break;
  }

  return null;
}
