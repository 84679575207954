import * as React from 'react';
import { Link } from '../link/link';
import { ITopGroup } from '../../../shared/types/top_three';

interface IPopularHeaderProps {
  title: string;
  groups: ITopGroup[];
  onTabClick(eventLabel: string): void;
  selectGroupItem(id: string): void;
  groupItemSelected(id: string): boolean;
}

const PopularHeader = (props: IPopularHeaderProps) => {
  const s = require('./popular_header.css');

  return (
    <div className={s['c-tabs-heading']}>
      <span className={s['c-tabs-title']}>{props.title}:</span>
      <ul className={s['c-tabs-list']}>
        {props.groups.map(groupItem => (
          <li className={s['c-tabs-tab']} key={groupItem.id}>
            <Link
              onClick={e => {
                e.preventDefault();
                props.selectGroupItem(groupItem.id);
                props.onTabClick(groupItem.id);
              }}
              href="#"
              className={
                `${s['c-tabs-link']} ` + `${props.groupItemSelected(groupItem.id) ? s['c-tabs-link-active'] : ''}`
              }
            >
              {groupItem.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export { PopularHeader };
