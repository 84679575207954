import { InputGroup, NumberInput, Outside } from '@cian/ui-kit';
import * as React from 'react';
import map from 'lodash/map';

import { onInsideClick } from './helpers';
import { OptionalDropdown } from './optional_dropdown';
import { filterChange, filterClick } from './tracking';

const s = require('../search_filters.css');

interface IProps {
  valueFrom?: number;
  valueTo?: number;
  metric: string;
  options: {
    value: string;
    label: string;
  }[];
  onChangeFrom(value: number | undefined): void;
  onChangeTo(value: number | undefined): void;
  onChangeMetric(value: string): void;
}

interface IState {
  opened: boolean;
}

export class SquareFilter extends React.Component<IProps, IState> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private input: any;
  private minSquare: number | undefined;
  private maxSquare: number | undefined;

  public constructor(props: IProps) {
    super(props);

    this.state = { opened: false };
    this.minSquare = props.valueFrom;
    this.maxSquare = props.valueTo;
  }
  public componentDidMount() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const optionKeys = map(this.props.options, (option: any) => option.value);

    if (optionKeys.indexOf(this.props.metric) === -1) {
      this.props.onChangeMetric(optionKeys[0]);
    }
  }

  public render() {
    return (
      <div className={s['c-filters-field']} onClick={onInsideClick(() => filterClick('ObjectsArea'))}>
        <Outside onOutside={() => this.close()}>
          <div
            className={`${s['c-several-filters-field']}
                        ${this.props.options.length > 1 ? '' : s['c-several-filters-field_disabled']}`}
          >
            <div
              className={`${s['c-filters-field-square']}
                              ${s['c-several-filters-dropdown-container']}`}
            >
              <button
                className={`${s['c-filters-dropdown-button']} ${this.hasSquare() ? '' : s['c-filters-field-mute']}`}
                onClick={() => {
                  this.togglePopup(() => this.focus(true));
                }}
              >
                {this.formatCaption() || 'Площадь'}
              </button>
              {this.renderPopup()}
            </div>
            <OptionalDropdown
              onChange={(value: string) => this.props.onChangeMetric(value)}
              onClick={() => {
                this.togglePopup(() => this.focus(false));
              }}
              values={this.props.options}
              value={this.props.metric}
            />
          </div>
        </Outside>
      </div>
    );
  }

  private focus(first: boolean) {
    if (this.input) {
      if (first) {
        this.input.focusMinField();
      } else {
        this.input.focusMaxField();
      }
    }
  }

  private togglePopup(callback: () => void, opened: boolean = !this.state.opened) {
    this.setState({ opened }, callback);
  }

  private close() {
    this.togglePopup(() => null, false);
  }

  private renderPopup() {
    if (this.state.opened) {
      return (
        <div className={`${s['c-filters-dropdown']}`}>
          <div className={`${s['c-filters-area-content']}`}>
            <InputGroup>
              <NumberInput
                placeholder="от"
                value={this.props.valueFrom}
                min={0}
                onChange={value => this.props.onChangeFrom(value ? Math.abs(value) : undefined)}
                onBlur={() => {
                  const nextMinSquare = this.props.valueFrom ? Math.abs(this.props.valueFrom) : undefined;
                  if (this.minSquare !== nextMinSquare) {
                    filterChange(
                      'ObjectsAreaMin',
                      typeof this.props.valueFrom !== 'undefined' ? String(Math.abs(this.props.valueFrom)) : 'deleted',
                    );

                    this.minSquare = nextMinSquare;
                  }
                }}
              />
              <NumberInput
                placeholder="до"
                value={this.props.valueTo}
                min={0}
                onChange={value => this.props.onChangeTo(value || undefined)}
                onBlur={() => {
                  let { valueTo } = this.props;
                  valueTo = valueTo ? Math.max(this.props.valueFrom || 0, Math.abs(valueTo)) : undefined;

                  this.props.onChangeTo(valueTo);

                  if (this.maxSquare !== valueTo) {
                    filterChange('ObjectsAreaMax', valueTo ? String(valueTo) : 'deleted');

                    this.maxSquare = valueTo;
                  }
                }}
              />
            </InputGroup>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  private hasSquare() {
    return this.props.valueFrom && this.props.valueFrom > 0;
  }

  private formatCaption() {
    const { valueFrom, valueTo } = this.props;

    if (valueFrom && valueTo) {
      return `${valueFrom} - ${Math.max(valueFrom, valueTo)}`;
    } else if (valueFrom) {
      return `от ${valueFrom}`;
    } else if (valueTo) {
      return `до ${valueTo}`;
    } else {
      return null;
    }
  }
}
