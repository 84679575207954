import { Checkbox } from '../Checkbox';
import { Outside } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';
import * as React from 'react';
import * as R from 'ramda';

import { COMMON_ROOM_TYPE_NAMES, SPECIAL_ROOM_TYPE_NAMES } from '../../constants/roomType';
import { offerTypeFromJsonQuery, FOfferType } from '../../utils/category';
import { setTerm } from '../../utils/jsonQuery';
import { getRoomTypeLabel } from '../../utils/roomType';
import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';
import * as styles from './FilterRoomType.css';
import { ERoomType, IJsonQuery } from '../../../packages/api-models/common/json_query';

export interface IFilterRoomTypeProps {
  value: ERoomType[];
  onSelect(roomType: ERoomType): void;
}

const COMMON_ROOM_TYPES: { label: string; value: ERoomType }[] = [
  { label: COMMON_ROOM_TYPE_NAMES[ERoomType.FlatOneRoom], value: ERoomType.FlatOneRoom },
  { label: COMMON_ROOM_TYPE_NAMES[ERoomType.FlatTwoRooms], value: ERoomType.FlatTwoRooms },
  { label: COMMON_ROOM_TYPE_NAMES[ERoomType.FlatThreeRooms], value: ERoomType.FlatThreeRooms },
  { label: COMMON_ROOM_TYPE_NAMES[ERoomType.FlatFourRooms], value: ERoomType.FlatFourRooms },
  { label: COMMON_ROOM_TYPE_NAMES[ERoomType.FlatFiveRooms], value: ERoomType.FlatFiveRooms },
  { label: COMMON_ROOM_TYPE_NAMES[ERoomType.FlatManyRooms], value: ERoomType.FlatManyRooms },
];

const SPECIAL_ROOM_TYPES: { label: string; value: ERoomType }[] = [
  { label: SPECIAL_ROOM_TYPE_NAMES[ERoomType.Studio], value: ERoomType.Studio },
  { label: SPECIAL_ROOM_TYPE_NAMES[ERoomType.FlatFreeLayout], value: ERoomType.FlatFreeLayout },
];

export function FilterRoomType({ value, onSelect }: IFilterRoomTypeProps) {
  const [active, setActive] = React.useState(false);

  const handleButtonClick = React.useCallback(() => setActive(active => !active), [setActive]);
  const handleOutsideClick = React.useCallback(() => setActive(false), [setActive]);

  const roomTypeLabel = getRoomTypeLabel(value);

  return (
    <Outside onOutside={handleOutsideClick}>
      <div {...mergeStyles(stylesCommon['container'], styles['container'])} data-mark="FilterRoomsCount">
        <button
          {...mergeStyles(
            stylesCommon['button'],
            active && stylesCommon['button--active'],
            !roomTypeLabel && stylesCommon['button--empty'],
          )}
          title={roomTypeLabel || undefined}
          onClick={handleButtonClick}
        >
          {roomTypeLabel || 'Комнат'}
        </button>
        {active && (
          <div className={styles['dropdown']}>
            <ul className={styles['list']}>
              {COMMON_ROOM_TYPES.map(item => (
                <li key={`item_${item.label}`}>
                  <button
                    {...mergeStyles(styles['button'], value.includes(item.value) && styles['button--active'])}
                    onClick={() => onSelect(item.value)}
                  >
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
            <ul className={styles['list-other']}>
              {SPECIAL_ROOM_TYPES.map(item => (
                <li key={`item_${item.label}`}>
                  <Checkbox
                    label={item.label}
                    checked={value.includes(item.value)}
                    onChange={() => onSelect(item.value)}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Outside>
  );
}

export const clear = (jsonQuery: IJsonQuery): IJsonQuery => {
  const nextOfferType = offerTypeFromJsonQuery(jsonQuery);

  let nextJsonQuery = R.clone(jsonQuery);

  if ((nextOfferType & (FOfferType.Room | FOfferType.FlatShared | FOfferType.Bed)) === 0) {
    nextJsonQuery = setTerm('room')(nextJsonQuery)(undefined);
  }

  return nextJsonQuery;
};
