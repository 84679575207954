import { Dispatch } from 'redux';

import { IHousingInfo, IHousingInfoAction } from '../../../types/housing_info';
import { TThunkAction } from '../../../types/redux';

// Action Types
export const GET_REQUEST = 'housingInfo/GET_REQUEST';
export const GET_SUCCESS = 'housingInfo/GET_SUCCESS';
export const GET_FAILURE = 'housingInfo/GET_FAILURE';

// Initial State
const initialState: IHousingInfo = {
  isFetching: false,
  address: '',
  builders: [],
  offers_count: 0,
  name: '',
  min_price2: 0,
};

const defaultAction: IHousingInfoAction = {
  type: '',
};

// Reducer
export const housingInfoReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case GET_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
      });

    case GET_SUCCESS:
      return Object.assign({}, state, action.housing_info, {
        isFetching: false,
      });

    case GET_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
      });

    default:
      return state;
  }
};

// Action Creator
export const housingInfoRequest = (): IHousingInfoAction => {
  return {
    type: GET_REQUEST,
  };
};

export const housingInfoSuccess = (response: { data: IHousingInfo }): IHousingInfoAction => {
  return {
    type: GET_SUCCESS,
    housing_info: response.data,
  };
};

export const housingInfoFailure = (): IHousingInfoAction => {
  return {
    type: GET_FAILURE,
  };
};

// Async Action Creator
export const getHousingInfo = (host = null, newBuildingId: number | string): TThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (dispatch: Dispatch<any>, getState, context) => {
    dispatch(housingInfoRequest());

    return context
      .makeRequest({
        method: 'GET',
        uri: {
          host,
          path: '/ajax/sections_housing_info/',
          query: `id=${newBuildingId}`,
        },
      })
      .then((res: { data: IHousingInfo }) => {
        dispatch(housingInfoSuccess(res));
      })
      .catch(() => dispatch(housingInfoFailure()));
  };
};
