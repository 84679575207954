import * as React from 'react';
import * as styles from '../../vertical_promo_blocks.css';
import { trackDomrfBlockClick } from './tracking';

interface IDomrfPromoBlock {
  title: string;
  description: string;
  url: string;
}

export function DomrfPromoBlock({ title, description, url }: IDomrfPromoBlock) {
  return (
    <a className={styles['c-domrf_promo_container']} href={url} onClick={() => trackDomrfBlockClick(url)}>
      <div className={styles['c-domrf_promo_content']}>
        <div className={styles['c-domrf_promo_description']}>
          <div className={styles['c-domrf_promo_title']}>{title}</div>
          <div className={styles['c-domrf_promo_text']}>{description}</div>
        </div>
      </div>
    </a>
  );
}
