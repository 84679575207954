import * as R from 'ramda';

import { commarize, getUnit, deunit, round, beautify } from './number';

const SHRUG = '¯\\_(ツ)_/¯';

export function getPriceLabel(min: number | undefined, max: number | undefined): string | null {
  let priceMin: string | null = null;
  const priceMax: string | null = !R.isNil(max) ? commarize(max) : null;

  if (!R.isNil(min) && !R.isNil(max)) {
    if (min === 0 && max === 0) {
      return SHRUG;
    }

    const minUnit = getUnit(min);
    const maxUnit = getUnit(max);
    const minWithUnit = min > max || minUnit !== maxUnit;

    const minDeunited = deunit(min, minUnit);

    if (minDeunited >= 100 && maxUnit - minUnit === 1) {
      priceMin = round(minDeunited / 1000, 1)
        .toString()
        .replace(/\./g, ',');
    } else {
      priceMin = commarize(min, !minWithUnit);
    }
  } else if (!R.isNil(min)) {
    priceMin = commarize(min);
  }

  if (priceMin && !priceMax) {
    return `от ${priceMin}`;
  } else if (!priceMin && priceMax) {
    return `до ${priceMax}`;
  } else if (priceMin && priceMax) {
    return `${priceMin} - ${priceMax}`;
  }

  return null;
}

export function getPriceTitle(min: number | undefined, max: number | undefined): string | null {
  if (!R.isNil(min) && R.isNil(max)) {
    return `от ${beautify(min)} \u20bd`;
  } else if (R.isNil(min) && !R.isNil(max)) {
    return `до ${beautify(max)} \u20bd`;
  } else if (!R.isNil(min) && !R.isNil(max)) {
    return `${beautify(min)} - ${beautify(max)} \u20bd`;
  }

  return null;
}
