import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_LAST_ROOMS_TYPES_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';
import { ERoomType } from '../../../packages/api-models/common/json_query';

export type TSetLastRoomsTypeType = 'filters/set_last_room_type';

export interface ISetLastRoomsTypeType extends Action<TSetLastRoomsTypeType> {
  type: TSetLastRoomsTypeType;
  payload: ERoomType[];
}

export type TSetLastRooms = ISetLastRoomsTypeType;

export function setLastRoomTypes(callGetMeta = false): TThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    const { lastRooms } = state.filters;
    // если сохраненных после перехода на посутку фильтров нет, выходим
    if (!lastRooms.length) {
      return;
    }
    dispatch<TSetLastRooms>({
      type: FILTERS_SET_LAST_ROOMS_TYPES_TYPE,
      payload: lastRooms,
    });

    if (callGetMeta) {
      dispatch(getMeta());
    }
  };
}
