/* eslint-disable @typescript-eslint/no-explicit-any */

import { TReduxActions } from '../../../../shared/types/redux';
import {
  IClearCommercialGeo,
  IClearNewbuildingsGeo,
  ISelectGeoAction,
  IUserGeo,
  IUserGeoAction,
  IUserGeoDefinitionWithFilter,
  IUserGeoPolygon,
  IUserGeoSearch,
} from '../../../../shared/types/filters/user_geo';
import { IGeoObject } from '../../../../shared/components/search_filters/filters/geo_suggest/geo_helpers';

export const USER_GEO_UPDATE = 'search/USER_GEO_UPDATE';
export const SELECT_GEO_UPDATE = 'search/SELECT_GEO_UPDATE';
export const CLEAR_NEWBUILDINGS_GEO = 'search/CLEAR_NEWBUILDINGS_GEO';
export const CLEAR_COMMERCIAL_GEO = 'search/CLEAR_COMMERCIAL_GEO';

const initialState: IUserGeo = {
  bsCenterId: undefined,
  userGeo: 1,
  isDirty: false,
  geoValue: undefined,
  userGeoDefinitionWithGeoFilter: {
    regionId: null,
    mainTownId: null,
  },
  geoSuggest: '',
};

function isPolygon(userSearch: IUserGeoPolygon | IUserGeoSearch): userSearch is IUserGeoPolygon {
  return 'coordinates' in userSearch;
}

export const userGeoReducer = (state = initialState, action: TReduxActions): IUserGeo => {
  switch (action.type) {
    case SELECT_GEO_UPDATE: {
      const { regionId } = (action as ISelectGeoAction).payload.value;

      return {
        ...state,
        geoType: undefined,
        bsCenterId: (action as ISelectGeoAction).payload.value.bsCenterId,
        text: (action as ISelectGeoAction).payload.value.text,
        // tslint:disable-next-line: no-any
        geoValue: (action as ISelectGeoAction).payload.value as any,
        userGeo: regionId,
      };
    }

    case USER_GEO_UPDATE:
      /**
       * Обновление гео из браузера, когда изменился гео фильтр
       */
      if (action.payload.userGeoSearch || action.payload.userGeoDefinitionWithGeoFilter || action.payload.geoSuggest) {
        const newState = Object.assign({}, state);

        if (action.payload.userGeoSearch) {
          Object.assign(newState, { geoValue: undefined, text: undefined });

          if (isPolygon(action.payload.userGeoSearch)) {
            const polygon = action.payload.userGeoSearch;
            Object.assign(newState, {
              geoType: 'polygon',
              coordinates: polygon.coordinates,
              name: polygon.name,
            });
          } else {
            const searchInfo = action.payload.userGeoSearch;
            Object.assign(newState, {
              geoValue: searchInfo.geoId,
              geoType: searchInfo.geoType,
            });
          }
        }

        if (action.payload.userGeoDefinitionWithGeoFilter) {
          Object.assign(newState, {
            userGeoDefinitionWithGeoFilter: {
              regionId: action.payload.userGeoDefinitionWithGeoFilter.regionId,
              mainTownId: action.payload.userGeoDefinitionWithGeoFilter.mainTownId,
            },
          });
        }

        if (action.payload.geoSuggest) {
          Object.assign(newState, {
            geoSuggest: action.payload.geoSuggest,
          });
        }

        return newState;
      }

      /**
       * Обновление гео на ноде из корневой апи - get-{section}-data-v2
       */
      return Object.assign({}, state, action.payload);

    case CLEAR_NEWBUILDINGS_GEO:
      /**
       * Обнуление гео по ЖК
       */
      if (state.geoValue && state.geoValue.type === 'newobject') {
        return Object.assign({}, state, {
          geoValue: undefined,
          text: undefined,
        });
      }

      return state;

    case CLEAR_COMMERCIAL_GEO:
      if (state.bsCenterId) {
        return Object.assign({}, state, {
          bsCenterId: undefined,
          geoValue: undefined,
          text: undefined,
        });
      }

      return state;

    default:
      return state;
  }
};

export const setUserGeoSuggest = (geoSuggest: string): IUserGeoAction => {
  return {
    type: USER_GEO_UPDATE,
    payload: {
      geoSuggest,
    },
  };
};

export interface ISetUserGeo {
  (userGeo: number): IUserGeoAction;
}

export const setUserGeo = (userGeo: number): IUserGeoAction => {
  return {
    type: USER_GEO_UPDATE,
    payload: {
      userGeo,
    },
  };
};

export const selectGeo = (value: IGeoObject): ISelectGeoAction => {
  return {
    type: SELECT_GEO_UPDATE,
    payload: {
      value,
    },
  };
};

export const clearNewbuildingsGeo = (): IClearNewbuildingsGeo => {
  return {
    type: CLEAR_NEWBUILDINGS_GEO,
  };
};

export const clearCommercialGeo = (): IClearCommercialGeo => {
  return {
    type: CLEAR_COMMERCIAL_GEO,
  };
};

export interface ISetUserGeoWithGeoType {
  (userGeoDefinitionWithGeoFilter: IUserGeoDefinitionWithFilter, userGeoSearch: IUserGeoSearch): IUserGeoAction;
}

export const setUserGeoWithGeoType: ISetUserGeoWithGeoType = (userGeoDefinitionWithGeoFilter, userGeoSearch) => {
  return {
    type: USER_GEO_UPDATE,
    payload: {
      userGeoDefinitionWithGeoFilter,
      userGeoSearch,
    },
  };
};

export interface ISetDirty {
  (isDirty: boolean): IUserGeoAction;
}

export const setDirty: ISetDirty = isDirty => {
  return {
    type: USER_GEO_UPDATE,
    payload: {
      isDirty,
    },
  };
};
