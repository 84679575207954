import * as React from 'react';
import loadable from '@loadable/component';
import { useApplicationContext } from '../../utils/applicationContext';
import { useDispatch, useSelector } from 'react-redux';
import { ModalWindow } from '@cian/ui-kit/modal';
import { GeoSwitcher } from '../../components/GeoSwitcher';
import { LoadableErrorBoundary } from '../../components/LoadableErrorBoundary';
import { LoadableFallback } from '../../components/LoadableFallback';
import { trackGeoSwitcherClick } from './tracking';
import { IApplicationState } from '../../types/redux';
import { IGeoSwitcherState } from '../../types/geoSwitcher';
import { getRegionIdFromCookie } from './utils/cookie';
import { getPopularCities, getRegion } from '../../actions/geoSwitcher';

const GeoSwitcherContainerInnerLoadable = loadable(
  () => import(/* webpackChunkName: "GeoSwitcherContainerInnerLoadable" */ './GeoSwitcherContainer'),
  {
    ssr: false,
    fallback: <LoadableFallback />,
  },
);

export const GeoSwitcherWrapperContainer = () => {
  const context = useApplicationContext();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const { region, popularCities } = useSelector<IApplicationState, IGeoSwitcherState>(state => state.geoSwitcher);
  const handleOpenGeoSwitcher = React.useCallback(() => {
    setOpen(true);
    trackGeoSwitcherClick();
  }, []);

  const handleCloseGeoSwitcher = React.useCallback(() => {
    setOpen(false);
  }, []);

  const retryLoadContent = React.useCallback(() => {
    GeoSwitcherContainerInnerLoadable.load();
  }, []);

  const geoSwitcherEnabled = context.config?.get<boolean>('frontendMainPage.geoSwitcherEnabled');

  React.useEffect(() => {
    if (geoSwitcherEnabled) {
      const regionIdFromCookie = getRegionIdFromCookie();
      dispatch(getRegion(regionIdFromCookie));
      dispatch(getPopularCities());
    }
  }, [dispatch, geoSwitcherEnabled]);

  if (!geoSwitcherEnabled || popularCities.length === 0 || !region) {
    return null;
  }

  return (
    <>
      <GeoSwitcher title={region.displayName} onClick={handleOpenGeoSwitcher} />
      <ModalWindow
        open={open}
        onClose={handleCloseGeoSwitcher}
        header={null}
        content={
          <LoadableErrorBoundary onRetry={retryLoadContent}>
            <GeoSwitcherContainerInnerLoadable closeGeoSuggest={handleCloseGeoSwitcher} />
          </LoadableErrorBoundary>
        }
        width={1179}
        size="M"
        fixed={false}
      />
    </>
  );
};
