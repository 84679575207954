import { FILTERS } from '../../constants/filters';
import { FDealType, FOfferType } from '../../utils/category';

export const FILTERS_TYPES: { dealType: FDealType; offerType: FOfferType; list: (keyof typeof FILTERS)[] }[] = [
  {
    dealType: FDealType.RentDaily,
    offerType: FOfferType.Any,
    list: ['geo', 'offerType', 'beds', 'dates'],
  },
  {
    dealType: FDealType.Any,
    offerType: FOfferType.Flat,
    list: ['offerType', 'roomType', 'price', 'geo'],
  },
  {
    dealType: FDealType.Any,
    offerType: FOfferType.Urban & ~FOfferType.Flat,
    list: ['offerType', 'price', 'geo'],
  },
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Suburban,
    list: ['offerType', 'price', 'geo'],
  },
  {
    dealType: FDealType.RentLongterm,
    offerType: FOfferType.Suburban,
    list: ['offerType', 'price', 'geo'],
  },
  {
    dealType: FDealType.Sale | FDealType.RentLongterm,
    offerType: FOfferType.Garage,
    list: ['offerType', 'price', 'area', 'geo'],
  },
  {
    dealType: FDealType.Sale | FDealType.RentLongterm,
    offerType: FOfferType.Commercial & ~FOfferType.CommercialLand & ~FOfferType.Coworking & ~FOfferType.Business,
    list: ['offerType', 'price', 'area', 'geo'],
  },
  {
    dealType: FDealType.Any,
    offerType: FOfferType.CommercialLand,
    list: ['offerType', 'price', 'landArea', 'geo'],
  },
  {
    dealType: FDealType.Rent,
    offerType: FOfferType.Coworking,
    list: ['offerType', 'coworkingOfferType', 'workplaceCount', 'coworkingPrice', 'geo'],
  },
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Business,
    list: ['offerType', 'businessAppointments', 'price', 'geo'],
  },
  {
    dealType: FDealType.Rent,
    offerType: FOfferType.Business,
    list: ['offerType', 'price', 'area', 'geo'],
  },
];
interface IGetFiltersListParams {
  dealType: FDealType;
  offerType: FOfferType;
}

export function getFiltersList(params: IGetFiltersListParams): Array<keyof typeof FILTERS> {
  const { dealType, offerType } = params;

  const typeList = FILTERS_TYPES.find(t => (t.dealType & dealType) !== 0 && (t.offerType & offerType) !== 0)?.list;

  return typeList || <Array<keyof typeof FILTERS>>[];
}
