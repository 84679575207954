import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { FlatTypes } from '../../components/search_filters/building_types';
import { BuildingTypeFilterInput } from '../../components/search_filters/filters/building_type_filter';
import { setBuildingType, setDirty } from '../../reducers/modules/search/building_types';
import { GenericFilter } from './generic_filter';
import { ISearchType } from '../../types/filters/type';
import { IBuildingTypes } from '../../types/filters/building_types';
import { IApplicationState } from '../../types/redux';
import { AbstractFlatSearchConfig } from '../search_block/search_config';

interface IProps {
  type: ISearchType;
  buildingType: IBuildingTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
}

interface IState {
  isDirty: boolean;
}

class FlatTypeFilter extends GenericFilter<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = { isDirty: false };
  }

  protected setDirty(isDirty: boolean) {
    this.props.dispatch(setDirty(isDirty));
  }

  public render() {
    const customButtonText = this.state.isDirty ? '' : 'В категории';

    return (
      <BuildingTypeFilterInput
        isShortMode
        type={this.props.type.searchType}
        value={this.props.buildingType.buildingType}
        buildingTypes={FlatTypes}
        searchConfig={AbstractFlatSearchConfig}
        customButtonText={customButtonText}
        onChange={this.onChange}
      />
    );
  }

  private onChange = (type: string) => {
    this.props.dispatch(setBuildingType(type));
    this.setState({ isDirty: true });
  };
}

export const mapStateToProps = (state: IApplicationState) => {
  return {
    buildingType: state.buildingType,
    type: state.type,
  };
};

const FlatTypeFilterWrapper = connect(mapStateToProps)(FlatTypeFilter);

export { FlatTypeFilterWrapper as FlatTypeFilter, FlatTypeFilter as FlatTypeFilterClass };
