import * as React from 'react';
import { mergeStyles } from '@cian/utils';

import * as styles from './styles.css';

export interface IMarqueeProps {
  mediaNote: string | undefined;
  hasNewPlacement?: boolean;
}

export class Marquee extends React.Component<IMarqueeProps, {}> {
  private container: HTMLDivElement | null = null;

  public render() {
    const { mediaNote } = this.props;

    if (!mediaNote) {
      return null;
    }

    return (
      <div
        data-testid="Marquee"
        ref={container => (this.container = container)}
        {...mergeStyles(
          styles['c-filters-text'],
          this.props.hasNewPlacement && styles['c-filters-text--new-placement'],
        )}
        onMouseOver={this.mouseOverHandler}
        onMouseLeave={this.mouseLeaveHandler}
      >
        <span>{this.props.mediaNote}</span>
      </div>
    );
  }

  private mouseOverHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.container) {
      const target = (e.target as HTMLSpanElement) || ({ offsetWidth: 0 } as HTMLSpanElement);
      const containerWith = this.container.offsetWidth;
      const childWith = target.offsetWidth;

      const diff = childWith - containerWith;

      if (diff > 0) {
        target.style.transition = `transform ${diff / 100}s linear`;
        target.style.transform = `translate(-${diff}px, 0)`;
      }
    }
  };

  private mouseLeaveHandler = (e: React.MouseEvent<HTMLDivElement>) => {
    const target = e.target as HTMLSpanElement;

    if (target) {
      target.style.transform = '';
      target.style.transition = '';
    }
  };
}
