import { ca } from '@cian/analytics';
import { INewbuildingPromo } from '../../../types/newbuildingPromo';
import { mapPromoToProduct } from '../../../utils/newbuildingPromo';

export const trackPromoShow = (promos: INewbuildingPromo[]) => {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'main_page',
    label: 'Promo',
    name: 'oldevent',
    products: promos.map(mapPromoToProduct),
  });
};
