import * as React from 'react';
import { CommercialPromoLink } from './commercial_promo_link';

import { IPromoLink } from '../../types/promo';
import { BACKGROUND_CONFIG } from '../commercial_promo_blocks/promo_config';

const s = require('./commercial_promo_block.css');

interface IProps {
  id: string;
  title: string;
  titleLink?: IPromoLink;
  links: IPromoLink[];
  type?: string;
  blockClassName: string;
}

export class CommercialPromoBlock extends React.Component<IProps> {
  public getBackgroundClass() {
    return s[BACKGROUND_CONFIG[this.props.id]];
  }

  public render() {
    return (
      <div
        className={`${s['c-com-promo-block']}
            ${this.props.blockClassName}
            ${this.getBackgroundClass()}`}
        key={this.props.id}
      >
        <div className={s['c-com-promo-block-content']}>
          {/* eslint-disable-next-line react/no-danger */}
          <h5 className={s['c-com-promo-block-title']} dangerouslySetInnerHTML={{ __html: this.props.title }} />
          <div className={s['c-com-promo-block-links']}>
            {this.props.links.map((link: IPromoLink, i: number) => (
              <CommercialPromoLink key={`promo_link_${i}`} {...link} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
