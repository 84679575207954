import * as React from 'react';
import { useSelector } from 'react-redux';

import { useRecommendationsEventsSubscribe } from './hooks/useRecommendationsEventsSubscribe';
import { Recommendations } from '../../components/Recommendations';
import { mapSectionToLandingType } from '../../types/landingType';
import { selectRegionsData } from '../../selectors/mainpage/selectRegionsData';
import { selectPageSection } from '../../selectors/pageSection';
import { useMicrofrontend } from '@cian/mf-react-legacy';
import { isRecommendationsMicrofrontendExists } from './utils/isRecommendationsMicrofrontendExists';

export const RecommendationsContainer = () => {
  const microfrontend = useMicrofrontend();
  const section = useSelector(selectPageSection);

  const landingType = React.useMemo(() => mapSectionToLandingType(section), [section]);

  const { subdomainCityId, subdomainRegionId } = useSelector(selectRegionsData);

  useRecommendationsEventsSubscribe(section);

  if (!isRecommendationsMicrofrontendExists(microfrontend)) {
    return null;
  }

  if (section === 'new-buildings' || section === 'commercial') {
    return null;
  }

  return <Recommendations regionId={subdomainRegionId || subdomainCityId} landingType={landingType} />;
};
