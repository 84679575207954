import { ca } from '@cian/analytics';

export type TFilter =
  | 'DealType'
  | 'OfferType'
  | 'MinPrice'
  | 'MaxPrice'
  | 'Category'
  | 'RoomsCount'
  | 'FinishDate'
  | 'Currency'
  | 'PaymentType'
  | 'ObjectsArea'
  | 'ObjectsAreaMin'
  | 'ObjectsAreaMax';

export function filterClick(filter: TFilter) {
  ca('event', {
    action: `${filter}_click`,
    category: `${window.__mainpage_section__}_page`,
    label: '',
    name: 'filterevent',
  });
}

export function filterChange(filter: TFilter, value: string) {
  ca('event', {
    action: `${filter}_select`,
    category: `${window.__mainpage_section__}_page`,
    label: value,
    name: 'filterevent',
  });
}
