import { parseCookies } from '@cian/utils';

import { FOREVER_REGION_ID_COOKIE_KEY, SESSION_REGION_ID_COOKIE_KEY } from '../../../../constants/geoSwitcher';

export function getRegionIdFromCookie() {
  return (
    Math.abs(
      parseInt(parseCookies(document.cookie)[SESSION_REGION_ID_COOKIE_KEY], 10) ||
        parseInt(parseCookies(document.cookie)[FOREVER_REGION_ID_COOKIE_KEY], 10),
    ) || 1
  );
}
