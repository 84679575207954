import { TReduxActions } from '../../../types/redux';

export interface IDomain {
  domain: string;
}

const initialState: IDomain = {
  domain: 'www',
};

export const domainReducer = (state: IDomain = initialState, action: TReduxActions) => {
  switch (action.type) {
    default:
      return state;
  }
};
