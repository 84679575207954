import * as React from 'react';

import { PromoMenuLink } from './promo_menu_link';
import { ISeoBlockLinks } from '../../types/seo_urls';

const s = require('./promo_menu.css');

interface IProps {
  id: string;
  links: ISeoBlockLinks[];
  title: string;
  opened: boolean;
  onOpened(): void;
}

export class PromoSubmenu extends React.Component<IProps> {
  public render() {
    return (
      <div
        className={`${s['c-popular-links-group']}
          ${this.props.opened ? '' : s['c-popular-links-group_collapsed']}`}
      >
        <h3 className={s['c-popular-links-group-title']}>{this.props.title}</h3>
        <div className={s['c-popular-links-group-list']}>
          {this.props.links.map(
            (link: ISeoBlockLinks, i: number) =>
              // url может быть пустой строкой
              Boolean(link.url) && <PromoMenuLink key={`promo_link_${i}`} {...link} />,
          )}
        </div>

        {this.props.links.length > 5 ? (
          <a role="button" className={s['c-popular-links-group-toggle']} onClick={() => this.props.onOpened()}>
            ещё
            <span className={s['c-popular-links-group-toggle-icon']} />
          </a>
        ) : undefined}
      </div>
    );
  }
}
