import { plural } from '@cian/utils';

interface IFormatOfferCountParams {
  offerCount: number;
}

export function formatOfferCount(params: IFormatOfferCountParams): string {
  const { offerCount } = params;

  return `Доступно ${offerCount} ${plural(offerCount, ['предложение', 'предложения', 'предложений'])}`;
}
