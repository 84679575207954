import * as React from 'react';
import { IMagazineItem } from '../../types/magazine';
import { cropText, replaceNBSP } from '../../utils/format_helper';

const s = require('./magazine.css');

interface IProps {
  article: IMagazineItem;
}

interface IState {
  isHover: boolean;
}

const MAX_SUBTITLE = 85;
const MAX_TITLE = 85;

export class ArticleItem extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      isHover: false,
    };
  }

  public render() {
    const item = this.props.article;

    return (
      <div className={`${s['c-articles-item']} ${s.clearfix}`}>
        {item.attributes.image && (
          <a
            href={`/stati-${item.attributes.slug}-${item.id}`}
            className={s['c-articles-image-link']}
            onMouseOver={this.onHover}
            onMouseOut={this.offHover}
          >
            <img
              className={s['c-articles-image']}
              style={{
                backgroundImage: `url('${item.attributes.image}')`,
              }}
            ></img>
          </a>
        )}
        <a
          href={`/stati-${item.attributes.slug}-${item.id}`}
          className={`${s['c-articles-title']} ${this.state.isHover ? s['s-articles-title-hover'] : ''}`}
          onMouseOver={this.onHover}
          onMouseOut={this.offHover}
        >
          {cropText(item.attributes.title, MAX_TITLE)}
        </a>
        <a
          href={`/stati-${item.attributes.slug}-${item.id}`}
          className={s['c-articles-caption']}
          onMouseOver={this.onHover}
          onMouseOut={this.offHover}
        >
          {replaceNBSP(cropText(item.attributes.subtitle, MAX_SUBTITLE))}
        </a>
      </div>
    );
  }

  private onHover = () => {
    this.setState({ isHover: true });
  };

  private offHover = () => {
    this.setState({ isHover: false });
  };
}
