import * as R from 'ramda';

import { ISetPriceTypeActionType } from '../../actions/filters';

import { FCoworkingOfferType, FDealType, FOfferType } from '../category';

import { clearGeo } from './clearGeo';
import { selectGeo } from './selectGeo';
import { selectOfferType } from './selectOfferType';
import { setArea } from './setArea';
import { setBSCenters } from './setBSCenters';
import { setBuilderId } from './setBuilderId';
import { setCoworkingOfferType } from './setCoworkingOfferType';
import { setDealType } from './setDealType';
import { setFilterSpecialtyTypes } from './setFilterSpecialtyTypes';
import { setLandArea } from './setLandArea';
import { setMaxArea } from './setMaxArea';
import { setMaxLandArea } from './setMaxLandArea';
import { setMaxPrice } from './setMaxPrice';
import { setMinArea } from './setMinArea';
import { setMinLandArea } from './setMinLandArea';
import { setMinPrice } from './setMinPrice';
import { setPosessor } from './setPosessor';
import { setPrice } from './setPrice';
import { setRegionId } from './setRegionId';
import { setRoomTypes } from './setRoomTypes';
import { setVillage } from './setVillage';
import { setMaxWorkplaceCount, setMinWorkplaceCount } from './setWorkplaceCount';
import { setPriceType } from './setPriceType';
import { setCoworkingIds } from './setCoworkingId';
import { setBeds } from './setBeds';
import { setDates } from './setDates';
import { ERoomType, ESuburbanOfferFilter, IJsonQuery, TGeoValue } from '../../../packages/api-models/common/json_query';

export class JsonQuery {
  private jsonQuery: IJsonQuery;

  public constructor(jsonQuery: IJsonQuery) {
    this.jsonQuery = jsonQuery;
  }

  public getSerializable = () => R.clone(this.jsonQuery);

  public setRegion = (region: number[]): this => this.wrap(setRegionId(this.jsonQuery)(region));
  public setDealType = (dealType: FDealType): this => this.wrap(setDealType(this.jsonQuery)(dealType));
  public selectOfferType = (offerType: FOfferType): this => this.wrap(selectOfferType(this.jsonQuery)(offerType));
  public readonly setCoworkingOfferType = (coworkingOfferType: FCoworkingOfferType): this =>
    this.wrap(setCoworkingOfferType(this.jsonQuery)(coworkingOfferType));

  public setFilterSpecialtyTypes(specialtyTypes: number | Array<number>): this {
    return this.wrap(setFilterSpecialtyTypes(this.jsonQuery)(specialtyTypes));
  }

  public readonly setMinWorkplaceCount = (minWorkplaceCount?: number): this =>
    this.wrap(setMinWorkplaceCount(this.jsonQuery)(minWorkplaceCount));

  public readonly setMaxWorkplaceCount = (maxWorkplaceCount?: number): this =>
    this.wrap(setMaxWorkplaceCount(this.jsonQuery)(maxWorkplaceCount));

  public setRoomTypes = (roomTypes: ERoomType[]): this => this.wrap(setRoomTypes(this.jsonQuery)(roomTypes));
  public setPrice = (gte: number | undefined, lte: number | undefined): this =>
    this.wrap(setPrice(this.jsonQuery)(gte, lte));
  public setMinPrice = (minPrice: number | undefined): this => this.wrap(setMinPrice(this.jsonQuery)(minPrice));
  public setMaxPrice = (maxPrice: number | undefined): this => this.wrap(setMaxPrice(this.jsonQuery)(maxPrice));

  public readonly setPriceType = (payload: ISetPriceTypeActionType['payload']): this =>
    this.wrap(setPriceType(this.jsonQuery)(payload));

  public setArea = (gte: number | undefined, lte: number | undefined): this =>
    this.wrap(setArea(this.jsonQuery)(gte, lte));
  public setMinArea = (minArea: number | undefined): this => this.wrap(setMinArea(this.jsonQuery)(minArea));
  public setMaxArea = (maxArea: number | undefined): this => this.wrap(setMaxArea(this.jsonQuery)(maxArea));
  public setLandArea = (gte: number | undefined, lte: number | undefined): this =>
    this.wrap(setLandArea(this.jsonQuery)(gte, lte));
  public setMinLandArea = (minLandArea: number | undefined): this =>
    this.wrap(setMinLandArea(this.jsonQuery)(minLandArea));
  public setMaxLandArea = (maxLandArea: number | undefined): this =>
    this.wrap(setMaxLandArea(this.jsonQuery)(maxLandArea));
  public setPosessor = (possesor: ESuburbanOfferFilter): this => this.wrap(setPosessor(this.jsonQuery)(possesor));
  public setVillage = (villageId: number | undefined): this => this.wrap(setVillage(this.jsonQuery)(villageId));
  public setBSCenters = (bsCenterIds: number[]): this => this.wrap(setBSCenters(this.jsonQuery)(bsCenterIds));
  public selectGeo = (geoValue: TGeoValue): this => this.wrap(selectGeo(this.jsonQuery)(geoValue));
  public clearGeo = (): this => this.wrap(clearGeo(this.jsonQuery)());
  public setBuilder = (builderId: number): this => this.wrap(setBuilderId(this.jsonQuery)(builderId));
  public setCoworkingIds = (coworkingIds: number[]): this => this.wrap(setCoworkingIds(this.jsonQuery)(coworkingIds));

  public setBeds = (gte: number | undefined, lte: number | undefined): this =>
    this.wrap(setBeds(this.jsonQuery)(gte, lte));
  public setDates = (gte: string, lt: string): this => this.wrap(setDates(this.jsonQuery)(gte, lt));

  private wrap = (nextJsonQuery: IJsonQuery) => {
    this.jsonQuery = nextJsonQuery;

    return this;
  };
}
