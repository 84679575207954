/* eslint-disable react/no-danger */
import * as React from 'react';

import { Link } from '../link/link';

const s = require('./jk_block.css');

interface IProps {
  title?: string;
  location?: string;
  price?: string;
  phone?: string;
  developer?: string;
  imageURL?: string;
  reference?: string;
  class?: string;
}

export class JkBlock extends React.Component<IProps> {
  public static defaultProps: IProps = {
    title: '',
    location: '',
    price: '',
    phone: '',
    developer: '',
    imageURL: require('./img/banner.jpg'),
    reference: '#',
  };

  public render() {
    const props = this.props;

    return (
      <div
        className={`${s['c-promo-info-block']} ${props.class || ''}`}
        style={{
          backgroundImage: `url(${props.imageURL})`,
        }}
      >
        <Link href={props.reference}>
          <div className={s['c-promo-info-block-content']}>
            <span className={s['c-promo-info-title']} dangerouslySetInnerHTML={{ __html: props.title as string }} />
            <span
              className={s['c-promo-info-location']}
              dangerouslySetInnerHTML={{ __html: props.location as string }}
            />
            <span className={s['c-promo-info-cost']} dangerouslySetInnerHTML={{ __html: props.price as string }} />
            <span className={s['c-promo-info-phone']} dangerouslySetInnerHTML={{ __html: props.phone as string }} />
            <span
              className={s['c-promo-info-developer']}
              dangerouslySetInnerHTML={{ __html: props.developer as string }}
            />
          </div>
        </Link>
      </div>
    );
  }
}
