import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';

export type TSetMortgageRealtyPriceType = 'mortgage/set_realty_price';

export const SET_MORTGAGE_REALTY_PRICE_TYPE: TSetMortgageRealtyPriceType = 'mortgage/set_realty_price';

export interface ISetMortgageRealtyPrice extends Action<TSetMortgageRealtyPriceType> {
  type: TSetMortgageRealtyPriceType;
  price: number;
}

export type TSetMortgageRealtyPrice = ISetMortgageRealtyPrice;

export function setMortgageRealtyPrice(price: number): TThunkAction {
  return dispatch => {
    dispatch<TSetMortgageRealtyPrice>({
      type: SET_MORTGAGE_REALTY_PRICE_TYPE,
      price,
    });
  };
}
