import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { getTermsValue } from '../../utils/jsonQuery';
import { fetchExtensionRegionCached } from '../../services/fetchExtensionRegion';
import { setRegion } from '../filters';
import { trackRegionExpansion } from '../../utils/analytics_helper';

export const SET_REGION_EXPANSION_TYPE: TSetRegionExpansionType = 'region_expansion/set_region_expansion';

export type TSetRegionExpansionType = 'region_expansion/set_region_expansion';

export interface ISetRegionExpansion extends Action<TSetRegionExpansionType> {
  type: TSetRegionExpansionType;
  payload: number;
}

export type TSetReiongExpansion = ISetRegionExpansion;

export function setRegionExpansion(): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const {
      filters: { jsonQuery },
    } = getState();

    const regionValues: number[] = getTermsValue('region')(jsonQuery);

    const expansionRegion = await fetchExtensionRegionCached(context, {
      regionId: regionValues[0],
    });

    if (expansionRegion) {
      dispatch(setRegion([expansionRegion.id]));

      dispatch<ISetRegionExpansion>({
        type: SET_REGION_EXPANSION_TYPE,
        payload: expansionRegion.id,
      });

      trackRegionExpansion({ prevRegion: regionValues[0], currentRegion: expansionRegion.id });
    }
  };
}
