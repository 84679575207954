import * as React from 'react';
import { mergeStyles } from '@cian/utils';

const s = require('./search_filters.css');
const image = require('./dobroshrift.svg');

interface IProps {
  title: string;
  isDobroshrift: boolean;
  onSubmit(): void;
  onMapSubmit(): void;
  children: React.ReactNode;
}

export class SearchFilters extends React.Component<IProps> {
  public render() {
    return (
      <div className={s['c-filters-inner']}>
        <div {...mergeStyles(s['c-filters-title'], s['c-filters-title_dobroshrift'])}>
          {this.props.isDobroshrift ? (
            <div className={s['c-dobroshrift']}>
              <a
                className={s['c-dobroshrift-link']}
                href="https://dobroshrift.ru/"
                rel="noopener noreferrer"
                target="_blank"
              >
                #доброшрифт
              </a>
              <img src={image} title="Проверенное жильё находится на ЦИАН" />
            </div>
          ) : (
            <span className={s['c-filters-title-text']}>{this.props.title}</span>
          )}
        </div>
        <div className={s['c-filters-form']}>{this.props.children}</div>
        <div className={s['c-filters-form-buttons']}>
          <button className={s['c-filters-field-button']} onClick={() => this.props.onSubmit()}>
            Найти
          </button>
          <a role="button" className={`${s['c-filters-field-map-button']}`} onClick={() => this.props.onMapSubmit()}>
            <span>Показать на карте</span>
          </a>
        </div>
      </div>
    );
  }
}
