import { batch } from 'react-redux';
import { Action } from 'redux';

import { FILTERS_SELECT_COWORKING_OFFER_TYPE_TYPE } from '../../constants/action_types';
import { TThunkAction } from '../../types/redux';
import { FCoworkingOfferType } from '../../utils/category';

import { getMeta } from './getMeta';

export interface ISelectCoworkingOfferType extends Action<typeof FILTERS_SELECT_COWORKING_OFFER_TYPE_TYPE> {
  coworkingOfferType: FCoworkingOfferType;
}

export function selectCoworkingOfferType(coworkingOfferType: FCoworkingOfferType): TThunkAction {
  return dispatch => {
    batch(() => {
      dispatch<ISelectCoworkingOfferType>({
        type: FILTERS_SELECT_COWORKING_OFFER_TYPE_TYPE,
        coworkingOfferType,
      });

      dispatch(getMeta());
    });
  };
}
