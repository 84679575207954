/* eslint-disable prettier/prettier */
import { FCoworkingOfferType, FDealType, FOfferType } from '../../../../utils/category';
import { capitalize } from '../../../../utils/string';

import { coworkingOfferTypeNames } from '../mappers/сoworkingOfferTypeNames';

export function getCoworkingOfferTypeLabel(
  _dealType: FDealType,
  offerType: FOfferType,
  coworkingOfferType: FCoworkingOfferType,
): string {
  if (offerType in coworkingOfferTypeNames) {
    return capitalize(coworkingOfferTypeNames[coworkingOfferType]);
  }

  const isEveryValuesIncluded = Object.keys(coworkingOfferTypeNames).every(
    key => (Number(key) & coworkingOfferType) !== 0,
  );

  if (isEveryValuesIncluded) {
    return 'Все типы';
  }

  const coworkingOfferTypeName = Object.entries(coworkingOfferTypeNames)
    .reduce(
      (accumulator, [type, value]) => {
        if ((Number(type) & coworkingOfferType) !== 0) {
          return accumulator.concat(value);
        }

        return accumulator;
      },
      <string[]>[],
    )
    .join(', ');

  return capitalize(coworkingOfferTypeName);
}
