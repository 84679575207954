import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';

export type TSetMortgageDownPaymentType = 'mortgage/set_down_payment';

export const SET_MORTGAGE_DOWN_PAYMENT_TYPE: TSetMortgageDownPaymentType = 'mortgage/set_down_payment';

export interface ISetMortgageDownPayment extends Action<TSetMortgageDownPaymentType> {
  type: TSetMortgageDownPaymentType;
  downPayment: number;
}

export type TSetMortgageDownPayment = ISetMortgageDownPayment;

export function setMortgageDownPayment(downPayment: number): TThunkAction {
  return dispatch => {
    dispatch<TSetMortgageDownPayment>({
      type: SET_MORTGAGE_DOWN_PAYMENT_TYPE,
      downPayment,
    });
  };
}
