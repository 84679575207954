import { actionGenerator } from '../../../utils/redux/actionGenerator';

interface ISetDatesActionPayload {
  gte: string;
  lt: string;
}

export const FILTERS_SET_DATES = 'filters/set_dates' as const;

export const setDates = actionGenerator<typeof FILTERS_SET_DATES, ISetDatesActionPayload>(FILTERS_SET_DATES);

export type TSetDatesAction = ReturnType<typeof setDates>;
