import { IPromoBanner } from './promoBanner';
import { ICompilation } from './compilationBanner';
import { ITopPromoBanner } from './topPromoBanner';

export interface IPromoBanners {
  /** Баннер Все акции и скидки в новостройках **/
  allPromos: IPromoBanner;
  /** Баннеры с подборками акций **/
  compilations: ICompilation[];
  /** Баннер КпН **/
  consultant: IPromoBanner;
  /** Баннеры топ-акций **/
  promos: ITopPromoBanner[];
}

export enum ENewbuildingPromoBannersLoadingStatus {
  Initial = 'initial',
  Loading = 'loading',
  Success = 'success',
  Failure = 'failure',
}

export interface INewbuildingPromoBannersState {
  status: ENewbuildingPromoBannersLoadingStatus;
  data: IPromoBanners | null;
  // allBanners: []; - todo banners array
}
