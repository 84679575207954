import { IRegion } from '../../../types/geoSwitcher';
import { ITypedReduxAction } from '../../../types/redux/actionType';

export enum EGetGeoSuggestActionTypes {
  Loading = 'getGeoSuggest/loading',
  Succeed = 'getGeoSuggest/succeed',
  Failed = 'getGeoSuggest/failed',
}

export type TGetGeoSuggestLoading = ITypedReduxAction<EGetGeoSuggestActionTypes.Loading>;
export type TGetGeoSuggestSucceed = ITypedReduxAction<EGetGeoSuggestActionTypes.Succeed, IRegion[]>;
export type TGetGeoSuggestFailed = ITypedReduxAction<EGetGeoSuggestActionTypes.Failed>;

export type TGetSuggests = TGetGeoSuggestLoading | TGetGeoSuggestSucceed | TGetGeoSuggestFailed;
