import { INewbuildingPromoPopup } from './newbuildingPromoPopup';

export interface INewbuildingPromo {
  /** Действительна до */
  activeDateToText: string;
  /** Ставка, проценты */
  interestRateText?: string;
  /** Флаг выделения акции **/
  isHighlighted: boolean;
  /** Макс сумма кредита */
  maxCreditText?: string;
  /** В скольких ЖК проходит акция */
  newbuildingCountText: string;
  /** Название акции */
  promoName: string;
  /** Данные для подробной информации по акции в попапе */
  promoPopup: INewbuildingPromoPopup;
  /** Тип акции */
  promoType: ENewbuildingPromoType;
  /** Тип акции (текстом) */
  promoTypeText: string;
  /** Лого автора акции */
  providerLogo: string;
  /** Название автора акции (застройщика или банка) */
  providerName: string;
  /** Id акции на стороне провайдера(может повторяться) */
  providerPromoId: number;
  /** Поставщик акции */
  providerType: ENewbuildingPromoProviderType;
  /** id акции */
  uniquePromoId: string;
}

export enum ENewbuildingPromoType {
  /** Бонусы **/
  Bonus = 'bonus',
  /** Циан ипотека **/
  CianMortgage = 'cianMortgage',
  /** Скидки **/
  Discount = 'discount',
  /** Материнский капитал **/
  MaternalCapital = 'maternalCapital',
  /** Военная ипотека **/
  Military = 'military',
  /** Ипотека **/
  Mortgage = 'mortgage',
}

export enum ENewbuildingPromoProviderType {
  /** Автосгенеренная акция **/
  Autogen = 'autogen',
  /** Банк **/
  Bank = 'bank',
  /** Застройщик **/
  Builder = 'builder',
}
