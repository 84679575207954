import * as React from 'react';

import { ColumnTextBlock } from '../column_text_block';

export interface ISeoBlockProps {
  seoText: string[];
  seoTextHeading: string;
}

export const SeoBlock = ({ seoText, seoTextHeading }: ISeoBlockProps) => {
  return <ColumnTextBlock columns={seoText} title={seoTextHeading} />;
};
