import * as React from 'react';

import { IAdFoxBannerContext } from '@cian/adfox';
import { AdfoxLazy } from '../adfox/adfox_lazy';
import { adfoxJKConstants } from '../adfox/constants';
import { JkBlock } from '../promo_banner/jk_block';
import { Map } from '../promo_banner/map';
import { PromoBlock } from '../promo_block/promo_block';
import { IPromoBanner, IPromoBlock } from '../../types/promo_block';

const s = require('./promo_blocks.css');

interface IProps {
  promoBlocks: IPromoBlock[];
  promoBanner: IPromoBanner;
  isFetching: boolean;
  subdomainRegionId: number;
  onLinkClick(url: string): void;
}

export const PromoBlocks = (props: IProps) => {
  if (props.isFetching) {
    return null;
  }

  const { promoBanner, promoBlocks, subdomainRegionId, onLinkClick } = props;
  const bannerClass =
    `cg-col-${promoBanner.size} ${s['cg-col-promo-s-' + promoBanner.xsSize]} ` + `cg-col-xs-${promoBanner.xsSize}`;

  return (
    <div className={s['c-promo-container']} data-testid="PromoBlocks">
      <div className="cg-container-fluid-xs">
        <h2 className={s['c-promo-title']}>Полезные ссылки</h2>

        <div className={`cg-row ${s['c-promo-count-' + promoBlocks.length]}`}>
          {promoBlocks.map((promoBlock: IPromoBlock, index: number) => (
            <PromoBlock
              key={promoBlock.promo.id}
              isFirstBlock={index === 0}
              promoBlock={promoBlock}
              onLinkClick={onLinkClick}
              class={
                `cg-col-${promoBlock.size} ${s['cg-col-promo-s-' + promoBlock.xsSize]} ` +
                `cg-col-xs-${promoBlock.xsSize}`
              }
            />
          ))}
          {promoBanner.promo ? (
            <AdfoxLazy
              constants={adfoxJKConstants}
              banner={(context: IAdFoxBannerContext) => {
                if (context['title']) {
                  return <JkBlock {...context} class={bannerClass} />;
                } else {
                  return <Map class={bannerClass} subdomainRegionId={subdomainRegionId} />;
                }
              }}
              emptyBanner={() => <Map class={bannerClass} subdomainRegionId={subdomainRegionId} />}
              failureBanner={() => <Map class={bannerClass} subdomainRegionId={subdomainRegionId} />}
            />
          ) : (
            <Map class={bannerClass} subdomainRegionId={subdomainRegionId} />
          )}
        </div>
      </div>
    </div>
  );
};
