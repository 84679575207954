import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';

export type TSetRegionUserInputType = 'mortgage/set_region_user_input';

export const SET_REGION_USER_INPUT_TYPE: TSetRegionUserInputType = 'mortgage/set_region_user_input';

export interface ISetRegionUserInput extends Action<TSetRegionUserInputType> {
  type: TSetRegionUserInputType;
  userInput: string;
}

export type TSetRegionUserInput = ISetRegionUserInput;

export function setRegionUserInput(userInput: string): TThunkAction {
  return dispatch => {
    dispatch<TSetRegionUserInput>({
      type: SET_REGION_USER_INPUT_TYPE,
      userInput,
    });
  };
}
