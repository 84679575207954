import { Dispatch } from 'redux';

import * as queryString from 'qs';
import { IDigestAction, IDigestState } from '../../../types/digest';
import { TThunkAction } from '../../../types/redux';

export const DIGEST_RESET = 'digest/DIGEST_SUBMIT';
export const DIGEST_SUBMIT = 'digest/DIGEST_SUBMIT';
export const DIGEST_FAILURE = 'digest/DIGEST_FAILURE';
export const DIGEST_SUCCESS = 'digest/DIGEST_SUCCESS';

const initialState: IDigestState = {
  isFetching: false,
  isSent: false,
  error: '',
};

const defaultAction: IDigestAction = {
  type: '',
};

export const digestReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case DIGEST_RESET:
      return Object.assign({}, state, initialState);

    case DIGEST_SUBMIT:
      return Object.assign({}, state, {
        isFetching: true,
        isSent: false,
        error: null,
      });

    case DIGEST_SUCCESS:
      return Object.assign({}, state, {
        isFetchnig: false,
        isSent: true,
        error: null,
      });

    case DIGEST_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isSent: false,
        isFetching: false,
      });

    default:
      return state;
  }
};

export const digestReset = (): IDigestAction => {
  return {
    type: DIGEST_RESET,
  };
};

export const digestSubmit = (): IDigestAction => {
  return {
    type: DIGEST_SUBMIT,
  };
};

export const digestFailure = (): IDigestAction => {
  return {
    type: DIGEST_FAILURE,
    error: 'Не удалось получить ссылку',
  };
};

export const digestSuccess = (): IDigestAction => {
  return {
    type: DIGEST_SUCCESS,
  };
};

export interface ISubmitDigestResponse {
  data: {
    type: string;
    id: string;
    attributes?: {
      email?: string;
      userId?: number;
      password?: string;
    };
  };
}

export interface ISubmitDigestSuccess {
  subscribe: boolean;
  auth: boolean;
}

export const submitDigest = (email: string): TThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (dispatch: Dispatch<any>, getState, context): Promise<ISubmitDigestSuccess> => {
    const {
      config: { backContentUrl, subdomain },
    } = getState();
    dispatch(digestSubmit());

    return context
      .makeRequest({
        method: 'POST',
        uri: {
          scheme: 'https',
          host: `${subdomain}.${backContentUrl}`,
          path: '/content-api/v2/add-digest',
        },
        headers: [['Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8']],
        body: queryString.stringify({
          email,
        }),
      })
      .then((res: ISubmitDigestResponse) => {
        dispatch(digestSuccess());

        return {
          subscribe: true,
          auth: Boolean(res.data.attributes && res.data.attributes.password),
        };
      })
      .catch(() => {
        dispatch(digestFailure());

        return {
          subscribe: false,
          auth: false,
        };
      });
  };
};
