import React, { memo } from 'react';

import { Checkbox } from '../../../../Checkbox';

import * as styles from './CoworkingOfferTypeDropdown.css';
import { ICoworkingOfferTypeDropdownProps } from './types';

export const CoworkingOfferTypeDropdown = memo<ICoworkingOfferTypeDropdownProps>(
  function CoworkingOfferTypeDropdown(props) {
    const { coworkingOfferType, coworkingOfferTypes, onChangeOfferType } = props;

    return (
      <div className={styles['container']}>
        <ul className={styles['list']}>
          {coworkingOfferTypes.map((part, index) => (
            <li key={`part_${index}`}>
              <ul className={styles['list-inner']}>
                {part.map(item => {
                  const checkboxChecked = (coworkingOfferType & item.value) !== 0;

                  return (
                    <li key={`item_${item.value}`}>
                      <Checkbox
                        disabled={item.disabled}
                        label={item.label}
                        value={item.value}
                        checked={checkboxChecked}
                        onChange={onChangeOfferType}
                      />
                    </li>
                  );
                })}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    );
  },
);
