/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetRegionExtensionItemModel,
  IGetRegionExtensionItemRequest,
  IMappers,
  IGetRegionExtensionItemResponse,
  IGetRegionExtensionItemResponseError,
  TGetRegionExtensionItemResponse,
} from './types';

const defaultConfig: TGetRegionExtensionItemModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside-region-extension',
  pathApi: '/v1/get-region-extension-item/',
  hostType: 'api',
} as TGetRegionExtensionItemModel;

function createGetRegionExtensionItemModel(parameters: IGetRegionExtensionItemRequest): TGetRegionExtensionItemModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetRegionExtensionItemOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetRegionExtensionItemRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetRegionExtensionItem<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetRegionExtensionItemOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetRegionExtensionItemResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetRegionExtensionItemModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetRegionExtensionItemResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetRegionExtensionItemResponseError);
    }
  }

  return { response, statusCode } as TGetRegionExtensionItemResponse;
}

export { defaultConfig, createGetRegionExtensionItemModel, fetchGetRegionExtensionItem };
