/* eslint-disable */


//tslint:disable


import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {TGetRegionModel,IGetRegionRequest,IMappers,IGetRegionResponse,IGetRegionResponseError,TGetRegionResponse} from './types';


const defaultConfig: TGetRegionModel = {
apiType: 'legacy',
assertStatusCodes: [200,400],
method: 'GET',
microserviceName: 'monolith-python',
pathApi: '/cian-api/site/v1/get-region/',
hostType: 'api'
} as TGetRegionModel;

function createGetRegionModel(parameters: IGetRegionRequest): TGetRegionModel {
return {
...defaultConfig,
parameters
};
}


export interface IGetRegionOptions<TResponse200,TResponse400> {
httpApi: IHttpApi;
parameters: IGetRegionRequest;
config?: IHttpApiFetchConfig;
mappers?: IMappers<TResponse200,TResponse400>
}


async function fetchGetRegion<TResponse200,TResponse400>(
{ httpApi, parameters, config, mappers }: IGetRegionOptions<TResponse200,TResponse400>,
): Promise<TResponse200|TResponse400 | TGetRegionResponse> {
const { response, statusCode } = await httpApi.fetch(createGetRegionModel(parameters), config);


if (mappers && statusCode in mappers) {
if (statusCode === 200) {


return mappers[200](response as IGetRegionResponse);
}

if (statusCode === 400) {


return mappers[400](response as IGetRegionResponseError);
}
}


return { response, statusCode } as TGetRegionResponse;
}


export {defaultConfig, createGetRegionModel, fetchGetRegion};

