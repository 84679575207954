import * as React from 'react';
import { Image } from '@cian/ui-kit';

import { changeDotToComma } from '../../../shared/utils/format_helper';
import { shortenNumber } from '@cian/utils/lib/shared/shorten_number';
import { ca } from '@cian/analytics';
import { ITrackingProductData } from '../../../shared/types/top_three';
import { LinkArea } from '../LinkArea';
import { Address } from './Address';

import * as styles from './popular_item.css';

interface IDeveloper {
  url: string;
  title: string;
}

export interface IJKPopularItemProps {
  metroInfo?: {
    timeType: string;
    name: string;
    lineColor: string;
    time: number;
  };
  id: number;
  title: string;
  url: string;
  isComplete: boolean;
  deadlineYear?: string;
  priceFrom?: number;
  priceTo?: number;
  address?: string;
  imageUrl: string;
  promoText: string;
  developers: IDeveloper[];
  trackingProductData: ITrackingProductData;
  position: number;
  onClick(): void;
}

export const eventObject = {
  name: 'oldevent',
  category: 'main_newobjects',
  action: 'pop_ads',
  label: 'sale/JK',
  ecommerce: {
    click: {
      actionField: {
        list: 'main_newobjects',
      },
    },
  },
};

function formatCompleteYear(isComplete: boolean, deadlineYear?: string) {
  if (isComplete) {
    return 'ОБЪЕКТ СДАН';
  } else if (deadlineYear) {
    return `СДАЧА В  ${deadlineYear}`;
  } else {
    return 'ПРОЕКТ';
  }
}

export const Developers = ({ developers }: { developers: IDeveloper[] }) => {
  return (
    <div className={styles['c-popular-developer']}>
      <span className={styles['c-popular-developer-text']}>
        {`${developers.length > 1 ? 'Застройщики' : 'Застройщик'}
          ${developers.map(developer => `«${developer.title}»`).join(', ')}`}
      </span>
    </div>
  );
};

interface IDispatchCAEventProps {
  id: number;
  title: string;
  position: number;
  productDataBrand?: string;
  onClick(): void;
}

export function dispatchCAEvent(props: IDispatchCAEventProps) {
  const brand = props.productDataBrand ? props.productDataBrand : '';
  const brandForEvent = brand ? brand.slice(0, brand.indexOf('/')) + '/developer_company' : 'none';

  Object.assign(eventObject.ecommerce.click, {
    products: [
      {
        name: props.title,
        id: props.id,
        brand: brandForEvent,
        category: 'sale/JK',
        position: props.position,
      },
    ],
  });

  ca('event', eventObject);

  props.onClick();
}

export function JKPopularItem(props: IJKPopularItemProps) {
  const { id, title, position, onClick } = props;

  const onLinkClick = React.useCallback(
    () => dispatchCAEvent({ id, title, position, productDataBrand: props.trackingProductData.brand, onClick }),
    [id, title, position, props.trackingProductData.brand, onClick],
  );

  return (
    <LinkArea
      href={props.url}
      className={`${styles['c-popular-block']} ${styles['c-popular-block-jk']} cg-col-8`}
      onClick={onLinkClick}
    >
      <Image
        src={props.imageUrl}
        loading="lazy"
        preloader={false}
        objectFit="cover"
        display="block"
        borderRadius={0}
        height={200}
      />
      {props.promoText && (
        <span className={styles['c-popular-discount']}>
          <span className={styles['c-popular-discount-content']}>{props.promoText}</span>
        </span>
      )}
      <div className={`${styles['c-popular-info']} ${styles['c-popular-info-jk']}`}>
        <span className={styles['c-popular-top']}>
          <span className={styles['c-popular-block-title']}>
            <a href={props.url} className={styles['c-popular-block-title-text']}>
              {props.title}
            </a>
            <span className={styles['c-popular-block-title-class']}>
              {formatCompleteYear(props.isComplete, props.deadlineYear)}
            </span>
          </span>
          {props.priceFrom && props.priceTo && (
            <span className={styles['c-popular-price-range']}>
              от&nbsp;
              {changeDotToComma(shortenNumber(props.priceFrom))}
              &nbsp;до&nbsp;
              {changeDotToComma(shortenNumber(props.priceTo))}
              &nbsp;руб.
            </span>
          )}
        </span>
        <Address address={props.address} metro={props.metroInfo} />
      </div>
      {props.developers.length > 0 && <Developers developers={props.developers} />}
    </LinkArea>
  );
}
