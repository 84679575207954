import * as React from 'react';

import { IMagazineItem } from '../../types/magazine';
import { cropText, escapeHtml, replaceNBSP } from '../../utils/format_helper';

const s = require('./magazine.css');

interface IProps {
  contentUrl: string;
  blogs: IMagazineItem[];
}

const MAX_TITLE = 165;

export const MagazineBlogs = (props: IProps) => (
  <div className={`cg-col-12 cg-col-xs-12 ${s['c-white-pad']} ${s['c-white-blogs']}`}>
    <a href={`/blogs/`} className={s['c-magazine-subtitle']}>
      ЗАПИСИ В БЛОГАХ
    </a>
    <div className={s['c-magazine-delimiter']}></div>
    <div className={s['c-blogs-container']}>
      {props.blogs.map((item: IMagazineItem, i: number) => (
        <div className={`${s['c-articles-item']} ${s['clearfix']}`} key={`article-${i}`}>
          {item.attributes.image && (
            <a href={`/blogs-${item.attributes.slug}-${item.id}`} className={s['c-articles-image-link']}>
              <img
                className={s['c-articles-image']}
                style={{
                  backgroundImage: `url('${item.attributes.image}')`,
                }}
              ></img>
            </a>
          )}
          <a href={`/blogs-${item.attributes.slug}-${item.id}`} className={s['c-articles-title']}>
            {item.attributes.title}
          </a>
          <div
            className={s['c-articles-caption']}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: escapeHtml(replaceNBSP(cropText(item.attributes.subtitle, MAX_TITLE))) }}
          />
          {item.attributes.authorObject && (
            <span className={s['c-articles-author']}>
              {item.attributes.authorObject.fullName || item.attributes.authorObject.companyName || 'Аноним'}
            </span>
          )}
        </div>
      ))}
      <a href={`/blogs/`} className={s['c-news-view-all']}>
        Все записи
      </a>
    </div>
  </div>
);
