import { EJsonQueryReadyBusinessType } from '../../packages/api-models/common/json_query';

export function getIsBusinessCategory(
  readyBusinessTypes: EJsonQueryReadyBusinessType[] | undefined = [],
): Record<`is${Capitalize<'all' | 'readyBusiness' | 'rentalBusiness'>}`, boolean> {
  let isRentalBusiness = false;
  let isReadyBusiness = false;

  for (const readyBusinessType of readyBusinessTypes) {
    if (readyBusinessType === EJsonQueryReadyBusinessType.RentalBusiness) {
      isRentalBusiness = true;
    } else if (readyBusinessType === EJsonQueryReadyBusinessType.ReadyBusiness) {
      isReadyBusiness = true;
    }
  }

  return {
    isAll: isReadyBusiness && isRentalBusiness,
    isReadyBusiness,
    isRentalBusiness,
  };
}
