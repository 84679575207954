import {
  fetchApiGeoGeocodeCached,
  TApiGeoGeocodeCachedResponse,
} from '../repositories/monolith-cian-realty/unversioned/api-geo-geocode-cached';
import { IGeocodeResult } from '../types/geocode';
import { IHttpApi } from '@cian/http-api/shared';

interface IGeocodeCachedParameters {
  httpApi: IHttpApi;
  subdomain: string;
  query: string;
}

export async function geocodeCached(parameters: IGeocodeCachedParameters): Promise<IGeocodeResult> {
  const { httpApi, subdomain, query } = parameters;

  const geocodeCachedResponse: TApiGeoGeocodeCachedResponse = await fetchApiGeoGeocodeCached({
    httpApi,
    parameters: { request: query },
    config: {
      subdomain,
    },
  });

  if (geocodeCachedResponse.statusCode !== 200 || !geocodeCachedResponse.response.items) {
    throw new Error(`Failed to geocode '${query}'`);
  }

  return geocodeCachedResponse.response as IGeocodeResult;
}
