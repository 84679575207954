import * as React from 'react';
import * as ReactDOM from 'react-dom';

export interface ILazyLoad<P, S> extends React.Component<P, S> {
  visible: boolean;
}

export const scrollParent = (node: HTMLElement) => {
  if (!node) {
    return document;
  }

  const excludeStaticParent = node.style.position === 'absolute';
  const overflowRegex = /(scroll|auto)/;

  let parent = node;
  while (parent) {
    if (!parent.parentNode) {
      return node.ownerDocument || document;
    }

    const style = window.getComputedStyle(parent);
    const position = style.position;
    const overflow = style.overflow;
    const overflowX = style.getPropertyValue('overflow-x');
    const overflowY = style.getPropertyValue('overflow-y');

    if (position === 'static' && excludeStaticParent) {
      continue;
    }

    if (overflowRegex.test(overflow + overflowX + overflowY)) {
      return parent;
    }

    parent = parent.parentNode as HTMLElement;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return node.ownerDocument || (node as any).documentElement || document;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkOverflowVisible = (component: ILazyLoad<any, any>, parent: HTMLElement) => {
  // eslint-disable-next-line react/no-find-dom-node
  const node = ReactDOM.findDOMNode(component) as HTMLElement;

  const { top: parentTop, height: parentHeight } = parent.getBoundingClientRect();
  const windowInnerHeight = window.innerHeight || document.documentElement.clientHeight;

  // calculate top and height of the intersection of the element's scrollParent and viewport
  const intersectionTop = Math.max(parentTop, 0); // intersection's top relative to viewport
  const intersectionHeight = Math.min(windowInnerHeight, parentTop + parentHeight) - intersectionTop; // height

  // check whether the element is visible in the intersection
  const { top, height } = node.getBoundingClientRect();
  const offsetTop = top - intersectionTop; // element's top relative to intersection

  return (
    offsetTop - (component.props.offset || 0) <= intersectionHeight &&
    offsetTop + height + (component.props.offset || 0) >= 0
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkNormalVisible = (component: ILazyLoad<any, any>) => {
  // eslint-disable-next-line react/no-find-dom-node
  const node = ReactDOM.findDOMNode(component) as HTMLElement;

  const { top, height: elementHeight } = node.getBoundingClientRect();

  const windowInnerHeight = window.innerHeight || document.documentElement.clientHeight;

  return (
    top - (component.props.offset || 0) <= windowInnerHeight && top + elementHeight + (component.props.offset || 0) >= 0
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkVisible = (component: ILazyLoad<any, any>) => {
  // eslint-disable-next-line react/no-find-dom-node
  const node = ReactDOM.findDOMNode(component) as HTMLElement;
  if (!node) {
    return false;
  }

  const parent = scrollParent(node);
  const isOverflow = parent !== node.ownerDocument && parent !== document && parent !== document.documentElement;

  const visible = isOverflow ? checkOverflowVisible(component, parent as HTMLElement) : checkNormalVisible(component);

  if (visible) {
    if (!component.visible) {
      component.visible = true;
      component.forceUpdate();

      return true;
    }
  } else if (component.visible) {
    component.visible = false;
  }

  return false;
};
