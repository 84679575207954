/* eslint-disable max-lines */
import {
  GeoSuggest as StructuredGeoSuggest,
  IMultiSuggestResult,
  IMessageInfo,
  IItemClickParams,
  EInputType,
  IBuildingSuggest,
  ILocationSuggest,
  IUndergroundSuggest,
} from '@cian/geosuggest-widget';
import * as React from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { getOfferType } from './utils';

// eslint-disable-next-line import/no-restricted-paths
import { createMakeRequest } from '../../../../browser/client';
import * as Analytics from '../../../utils/analytics_helper';
import { IGeoValue } from '../../../reducers/modules/search/search';
import { getGeoBoundedBy, IGeoObject, sendGeocode } from './geo_suggest/geo_helpers';
import { getGeoRegionName } from './geo_suggest/suggests_helpers';
import { getMultiSuggest, IAddressSuggestion } from '../../../utils/geo_suggest';
import { IUserGeoDefinitionWithFilter, TUserGeoSearch } from '../../../types/filters/user_geo';
import { IMakeRequest } from '../../../utils/network_helper';
import { IDropdownValue } from '../../../types/dropdown';
import classNames from 'classnames';

const styles = require('../search_filters.css');

export type TGroupItemsType = ILocationSuggest[] | IAddressSuggestion[] | IBuildingSuggest[] | IUndergroundSuggest[];

interface IUserGeoMultiFilterProps {
  abGroup?: number;
  buildingType: string;
  searchType: string;
  section: string;
  regionOrMaintownId: number;
  regions: IDropdownValue[];
  cities: IDropdownValue[];
  geoValue?: IGeoValue;
  bsCenterId?: number;
  placeholder: string;

  onChange(userGeoDefinitionWithFilter: IUserGeoDefinitionWithFilter, userGeoSearch: TUserGeoSearch): void;

  onGeoSelected(value: IGeoObject): void;
  onGeoSuggestChange(geoSuggest: string | void): void;
}

interface IUserGeoMultiFilterState {
  suggestData?: IMultiSuggestResult;
  value: string;
  selectedValue: string;
  loading: boolean;
  error: IMessageInfo;
  geoBoundedBy: [IYmapsCoord, IYmapsCoord];
  memoizedProps?: {
    regionOrMaintownId: number;
    bsCenterId?: number;
  };
}

const MAX_LENGTH_INPUT_TEXT = 255;
// default displayed Moscow
const BOUNDED_BY_DEFAULT: [IYmapsCoord, IYmapsCoord] = [
  [56.021224, 37.967428],
  [55.142175, 36.803101],
];

const ERROR = { title: 'Не удалось получить возможные варианты', text: 'Попробуйте еще раз' };
const NOT_FOUND = { title: 'Ничего не найдено', text: 'Укажите другой адрес' };

const getValueFromProps = (props: IUserGeoMultiFilterProps): string =>
  getGeoRegionName(props.cities, props.regions, props.regionOrMaintownId, props.geoValue) || '';

export class UserGeoMultiFilter extends React.Component<IUserGeoMultiFilterProps, IUserGeoMultiFilterState> {
  private makeRequest: IMakeRequest;

  public constructor(props: IUserGeoMultiFilterProps) {
    super(props);

    const value = getValueFromProps(props) || props.regions[0].label;

    this.state = {
      geoBoundedBy: BOUNDED_BY_DEFAULT,
      suggestData: undefined,
      value,
      selectedValue: value,
      loading: false,
      error: {},
    };
  }

  public static getDerivedStateFromProps = (props: IUserGeoMultiFilterProps, state: IUserGeoMultiFilterState) => {
    const { regionOrMaintownId, bsCenterId } = props;
    const { memoizedProps } = state;
    const nextState = {
      memoizedProps: {
        regionOrMaintownId,
        bsCenterId,
      },
    };

    if (!memoizedProps) {
      return nextState;
    }

    if (
      memoizedProps.regionOrMaintownId !== props.regionOrMaintownId ||
      (!memoizedProps.bsCenterId && props.bsCenterId)
    ) {
      const value = getValueFromProps(props);

      if (value) {
        return {
          ...nextState,
          value,
          selectedValue: value,
        };
      }
    }

    return nextState;
  };

  public componentDidMount() {
    this.makeRequest = createMakeRequest(window.__mainpage_api_host__ || window.location.host);

    this.ensureGeoBoundedBy();
  }

  public render() {
    return (
      <div role="search" className={classNames(styles['c-filters-field'], styles['c-filters-field-region'])}>
        <StructuredGeoSuggest
          suggestData={this.state.suggestData}
          placeholder={this.props.placeholder}
          value={this.state.value}
          selectedValue={this.state.selectedValue}
          onValueChange={this.handleValueChange}
          onItemClick={this.handleItemClick}
          onFocus={this.handleFocus}
          isLoading={this.state.loading}
          error={this.state.error}
          inputType={EInputType.mainpage}
          inputStyle={EInputType.mainpage}
        />
      </div>
    );
  }

  private handleFocus = () => {
    const { selectedValue } = this.state;

    this.setState({
      error: {},
      value: `${selectedValue} `,
    });

    Analytics.trackClickGeoSuggest();

    this.getGeoSuggestionDebounced(selectedValue);
  };

  private getTextDisplayed = (value: string): string => {
    if (value.length > MAX_LENGTH_INPUT_TEXT) {
      return `${value.slice(0, MAX_LENGTH_INPUT_TEXT).trim()}...`;
    }

    return value;
  };

  private formatType = (type: string): string => {
    switch (type) {
      case 'addresses':
      case 'railway':
        return type;
      case 'city':
        return 'cities';
      case 'geo':
        return 'addresses';
      default:
        return type + 's';
    }
  };

  private getQueryTypeLive = (type: string) => {
    switch (type) {
      case 'newbuildings':
        return 'newobject';
      case 'districts':
        return 'district';
      case 'undergrounds':
        return 'underground';
      case 'railway':
        return type;
      case 'roads':
        return 'highway';
      case 'villages':
        return 'village';
      default:
        return 'location';
    }
  };

  private setErrorState = () => {
    this.setState({
      error: ERROR,
      loading: false,
    });
  };

  private handleItemClick = (params: IItemClickParams) => {
    const { title, group, id, regionId } = params;

    const type = this.formatType(group);
    const value = title;
    const newValue = this.getTextDisplayed(value);
    const isCommercial = type === 'shoppingCenters' || type === 'businessCenters';

    this.setState({
      selectedValue: value,
      value: newValue,
    });

    this.props.onGeoSuggestChange(value);

    if (type === 'addresses') {
      sendGeocode(this.makeRequest, value, this.props.section, this.props.onChange, this.setErrorState, true);
    } else if (id) {
      const selectedRegionId = regionId || this.props.regionOrMaintownId;

      if (!isCommercial) {
        this.props.onGeoSelected({
          bsCenterId: undefined,
          id,
          regionId: selectedRegionId,
          text: newValue,
          type: this.getQueryTypeLive(type),
        } as IGeoObject);
      } else {
        this.props.onGeoSelected({
          bsCenterId: id,
          id: this.props.regionOrMaintownId,
          regionId: selectedRegionId,
          text: newValue,
          type: 'location',
        } as IGeoObject);
      }
      Analytics.trackSelectGeoSuggestItem(type, 'cian');
    }
  };

  private handleValueChange = (value: string) => {
    this.setState({ value });

    if (value.length > 2) {
      this.getGeoSuggestionDebounced(value);
    } else {
      this.setState({
        loading: false,
        error: {},
        suggestData: undefined,
      });
    }
  };

  private getGeoSuggestion = async (valueRaw: string) => {
    const { buildingType } = this.props;
    const value = valueRaw.trim();

    if (!value) {
      return;
    }

    this.setState({ loading: true });

    const offerType = getOfferType(buildingType);

    try {
      const result = await getMultiSuggest({
        structured: {
          offerType,
          query: value,
          regionId: this.props.regionOrMaintownId,
          searchType: this.props.searchType,
        },
        yandex: {
          options: { boundedBy: this.state.geoBoundedBy, results: 10 },
          value,
        },
        buildingType,
      });

      if (isEmpty(result.suggestions)) {
        Analytics.trackGeoSuggestNotFound(value);

        this.setState({ error: NOT_FOUND });
      }

      this.setState({
        suggestData: result,
        loading: false,
        error: {},
      });
    } catch (error) {
      this.setErrorState();
    }
  };

  private getGeoSuggestionDebounced = debounce(this.getGeoSuggestion, 300);

  private async ensureGeoBoundedBy() {
    const response = await getGeoBoundedBy(this.makeRequest, this.props.regionOrMaintownId);

    this.setState({
      geoBoundedBy: [
        [response.data.boundedBy.upperCorner.lng, response.data.boundedBy.upperCorner.lat],
        [response.data.boundedBy.lowerCorner.lng, response.data.boundedBy.lowerCorner.lat],
      ],
    });
  }
}
