import * as React from 'react';
import * as styles from './style.css';

interface ICategoryButtonProps {
  isActive: boolean;
  onClick: () => void;
  text: string;
}

export const CategoryButton = ({ isActive, onClick, text }: ICategoryButtonProps) => {
  if (isActive) {
    return null;
  }

  return (
    <button onClick={onClick} className={styles['button']}>
      {text}
    </button>
  );
};
