import { IconFunctionalInformationCircle16, Tooltip } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import React, { FC, useMemo } from 'react';

import * as styles from './CheckboxLabel.css';

import { ICheckboxLabelProps } from './types';

const postfixNewClassNames = mergeStyles(styles['postfix'], styles['postfix-new']).className;
const postfixSoonClassNames = mergeStyles(styles['postfix'], styles['postfix-soon']).className;

export const CheckboxLabel: FC<ICheckboxLabelProps> = props => {
  const { label, postfixType, tooltipTitle } = props;

  const postfixView = useMemo(() => {
    switch (postfixType) {
      case 'new':
        return <span className={postfixNewClassNames}>Новое</span>;
      case 'soon':
        return <span className={postfixSoonClassNames}>Скоро</span>;
      case 'info':
        return (
          <Tooltip placement="top" title={tooltipTitle}>
            <span className={styles['postfix-info']}>
              <IconFunctionalInformationCircle16 color="icon-secondary-default" />
            </span>
          </Tooltip>
        );

      default:
        return null;
    }
  }, [postfixType, tooltipTitle]);

  return (
    <div className={styles['container']}>
      <span className={styles['label']}>{label}</span>
      {postfixView}
    </div>
  );
};
