export interface IReview {
  reviewCount: number;
  totalRate: number;
}

export type TTransportType = 'walk' | 'transport';

export type TUndergroundType = 'mow' | 'spb' | 'smr' | 'ekb' | 'kzn' | 'nn' | 'nsk';

export interface IUnderground {
  distance: string;
  lineColor: string;
  transportType: TTransportType;
  undergroundName: string;
  undergroundType: TUndergroundType;
}

export interface IMedia {
  url: string;
}

export interface IRecommendations {
  type?: string;
  id: number;
  media: IMedia[];
  minPrice?: string | null;
  name: string;
  review: IReview;
  status: string;
  underground?: IUnderground | null;
  url: string;
}

export interface INewbuildingsRecommendations {
  recommendations: IRecommendations[];
  allRecommendations: { link: string };
}

export enum ENewbuildingRecommendationsLoadingStatus {
  Initial = 'initial',
  Loading = 'loading',
  Success = 'success',
  Failure = 'failure',
}

export interface INewbuildingsRecommendationsState {
  status: ENewbuildingRecommendationsLoadingStatus;
  data: INewbuildingsRecommendations | null;
}
