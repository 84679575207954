import { ENewbuildingPromoType, INewbuildingPromo } from '../../types/newbuildingPromo';

interface INewbuildingPromoProduct {
  id: number;
  offerType: 'promo';
  position: number;
  extra: {
    type: string;
    providerType: string;
  };
}

const PROMO_TYPE_MAP: Record<ENewbuildingPromoType, string> = {
  [ENewbuildingPromoType.Bonus]: 'bonus',
  [ENewbuildingPromoType.CianMortgage]: 'cian_mortgage',
  [ENewbuildingPromoType.Discount]: 'discount',
  [ENewbuildingPromoType.MaternalCapital]: 'maternal_capital',
  [ENewbuildingPromoType.Military]: 'military_mortgage',
  [ENewbuildingPromoType.Mortgage]: 'mortgage',
};

export const mapPromoToProduct = (promo: INewbuildingPromo, index: number): INewbuildingPromoProduct => {
  return {
    id: promo.providerPromoId,
    offerType: 'promo',
    position: index + 1,
    extra: {
      providerType: promo.providerType,
      type: PROMO_TYPE_MAP[promo.promoType],
    },
  };
};
