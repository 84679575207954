/* eslint-disable import/no-default-export */
export const MoscowRegions = [1, -1, 4593];
export const SPBRegions = [2, -2, 4588];

// tslint:disable-next-line:no-default-export
export default function smartCompareValues(valueA: number, valueB: number): boolean {
  if (valueA === valueB) {
    return true;
  }

  if (MoscowRegions.indexOf(valueA) >= 0 && MoscowRegions.indexOf(valueB) >= 0) {
    return true;
  }

  if (SPBRegions.indexOf(valueA) >= 0 && SPBRegions.indexOf(valueB) >= 0) {
    return true;
  }

  return false;
}
