import { ITypedReduxAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EDatesModalActionType {
  Open = 'filters/datesModal/open',
  Close = 'filters/datesModal/close',
}

export type TDatesModalOpen = ITypedReduxAction<EDatesModalActionType.Open>;
export type TDatesModalClose = ITypedReduxAction<EDatesModalActionType.Close>;
export type TDatesModalActions = TDatesModalOpen | TDatesModalClose;

export const openDatesModal = actionGenerator<EDatesModalActionType.Open>(EDatesModalActionType.Open);
export const closeDatesModal = actionGenerator<EDatesModalActionType.Close>(EDatesModalActionType.Close);
