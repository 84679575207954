export interface INewbuildingPromoPopup {
  /** Тип баннера в попапе */
  bannerType: EPromoBannerType;
  /** Ссылка на выдачу ЖК desktop */
  newbuildingListingUrlDesktop: string;
  /** Описание акции */
  promoDescription?: string;
  /** Название акции */
  promoName: string;
  /** Пары (заголовок, значение) для свойств акции в попапе (Ставка, сумма кредита, срок действия и т.д.) */
  promoProps: IPromoProp[];
  /** Лого автора акции */
  providerLogo?: string;
}

export interface IPromoProp {
  title: string;
  value: string;
}

export enum EPromoBannerType {
  /** Циан.Ипотека */
  CianMortgage = 'cianMortgage',
  /** Без баннера */
  Empty = 'empty',
}
