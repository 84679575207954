import * as React from 'react';

const s = require('./link.css');

interface IProps {
  className?: string;
  target?: string;
  title?: string;
  href?: string;
  rel?: string;
  role?: string;
  children?: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?(e?: any): void;
}

export class Link extends React.Component<IProps> {
  public static defaultProps: IProps = {
    className: '',
    target: undefined,
    title: undefined,
    href: undefined,
  };

  public render() {
    return (
      <a
        title={this.props.title}
        target={this.props.target}
        href={this.props.href}
        rel={this.props.rel}
        role={this.props.role}
        className={`${s['c-link-default']} ${this.props.className}`}
        onClick={e => (this.props.onClick ? this.props.onClick(e) : null)}
      >
        {this.props.children}
      </a>
    );
  }
}
