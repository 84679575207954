import { connect } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { IProps, Vacancies } from '../../components/vacancies/vacancies';

const mapStateToProps = (state: IApplicationState) => ({
  cityId: state.mainpage.regionsData.subdomainCityId,
  cityName: state.mainpage.regionsData.subdomainRegionInflectedTitleLoct,
  vacancies: state.vacancies,
});

export const VacanciesContainer = connect<IProps, {}, {}>(mapStateToProps)(Vacancies);
