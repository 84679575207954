import * as React from 'react';
import { LinkButton, UIHeading2, UIText1 } from '@cian/ui-kit';

import * as styles from './service_ads.css';

interface IProps {
  onClick(): void;
  title: string;
  description: string;
  buttonUrl: string;
  buttonText: string;
  class: string;
}

export const ServiceAds = (props: IProps) => (
  <div className={`${styles['container']} ${props.class}`}>
    <UIHeading2>{props.title}</UIHeading2>
    <div className={styles['description']}>
      <UIText1>{props.description}</UIText1>
    </div>
    <LinkButton onClick={() => props.onClick()} href={props.buttonUrl} target="_blank">
      {props.buttonText}
    </LinkButton>
  </div>
);
