import {
  IJsonQueryRange,
  IJsonQueryTerm,
  IJsonQueryTerms,
  IJsonQueryGeo,
  TGeoValue,
  IJsonQueryDateRange,
} from '../../../packages/api-models/common/json_query';

export function term<T>(value: T | undefined): IJsonQueryTerm<T> | undefined {
  if (value === undefined) {
    return undefined;
  }

  return {
    type: 'term',
    value,
  };
}

export function terms<T>(value: T[]): IJsonQueryTerms<T> | undefined {
  if (value.length === 0) {
    return undefined;
  }

  return {
    type: 'terms',
    value,
  };
}

export function range(gte: number | undefined, lte: number | undefined): IJsonQueryRange | undefined {
  if (gte === undefined && lte === undefined) {
    return undefined;
  }

  return {
    type: 'range',
    value: {
      gte,
      lte,
    },
  };
}

export function dateRange(gte: string, lt: string): IJsonQueryDateRange | undefined {
  return {
    type: 'date_range',
    value: {
      gte,
      lt,
    },
  };
}

export function geo(value: TGeoValue[]): IJsonQueryGeo | undefined {
  if (value.length === 0) {
    return undefined;
  }

  return {
    type: 'geo',
    value,
  };
}
