import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import {
  setSuburbanOfferFilter,
  setSuburbanOfferFilterDirty,
} from '../../reducers/modules/search/suburban_offer_filter';
import { GenericFilter } from './generic_filter';
import { IApplicationState } from '../../types/redux';
import { TSuburbanOfferFilter } from '../../types/filters/suburban_offer_filter';
import { SearchConfig } from '../search_block/search_config';
import { BuildingTypeFilterInput } from '../../components/search_filters/filters/building_type_filter';
import {
  SuburbanOfferFilterOptions,
  SuburbanOfferFilterTypes,
} from '../../components/search_filters/suburban_offer_filter_types';

interface IProps {
  suburbanOfferFilter: TSuburbanOfferFilter;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
}

interface IState {
  isDirty: boolean;
}

export class SuburbanOfferFilterComponent extends GenericFilter<IProps, IState> {
  public state = {
    isDirty: false,
  };

  protected setDirty(isDirty: boolean) {
    this.props.dispatch(setSuburbanOfferFilterDirty(isDirty));
  }

  public render() {
    const { suburbanOfferFilter } = this.props;

    const { isDirty } = this.state;

    return (
      <BuildingTypeFilterInput
        options={SuburbanOfferFilterOptions}
        type={'buy'}
        value={[suburbanOfferFilter]}
        buildingTypes={SuburbanOfferFilterTypes}
        searchConfig={SearchConfig}
        customButtonText={isDirty ? '' : 'В категории'}
        onChange={this.onChange}
      />
    );
  }

  private onChange = (type: TSuburbanOfferFilter) => {
    this.props.dispatch(setSuburbanOfferFilter(type));
    this.setState({ isDirty: true });
  };
}

export const SuburbanOfferFilter = connect((state: IApplicationState) => {
  return {
    suburbanOfferFilter: state.suburbanOfferFilter.suburbanOfferFilter,
  };
})(SuburbanOfferFilterComponent);
