import { DEFAULT_FILTERS } from './default_filters';
import { TReduxActions } from '../../../../shared/types/redux';
import { IRooms, IRoomsAction } from '../../../../shared/types/filters/rooms';

export const ROOMS_UPDATE = 'search/ROOMS_UPDATE';

const initialState: IRooms = {
  rooms: ['1', '2'],
  isDirty: false,
};

export const roomsReducer = (state = initialState, action: TReduxActions) => {
  switch (action.type) {
    case ROOMS_UPDATE:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
};

export const setSelectedRooms = (rooms: string[]): IRoomsAction => {
  return {
    type: ROOMS_UPDATE,
    payload: {
      rooms,
    },
  };
};

export const setDefaultRooms = (section: string): IRoomsAction => {
  return {
    type: ROOMS_UPDATE,
    payload: {
      rooms: DEFAULT_FILTERS[section].rooms_value,
    },
  };
};

export const setDirty = (isDirty: boolean): IRoomsAction => {
  return {
    type: ROOMS_UPDATE,
    payload: {
      isDirty,
    },
  };
};
