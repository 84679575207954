class DataLayer {
  public push(hash: {}) {
    if (
      typeof window === 'undefined' ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      typeof (window as any).dataLayer === 'undefined'
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-console
      console.log(JSON.stringify(hash));
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).dataLayer.push(hash);
    }
  }
}

export const dataLayer = new DataLayer();
