import { deleteCookie, setCookie } from '@cian/utils/lib/browser/cookie';
import {
  FOREVER_REGION_ID_COOKIE_KEY,
  SESSION_REGION_ID_COOKIE_KEY,
  SESSION_REGION_NAME_COOKIE_KEY,
  FOREVER_REGION_NAME_COOKIE_KEY,
  SESSION_MAIN_TOWN_REGION_ID_COOKIE_KEY,
  FOREVER_MAIN_TOWN_REGION_ID_COOKIE_KEY,
} from '../../../../constants/geoSwitcher';
import { IRegion } from '../../../../types/geoSwitcher';

export const setRegionCookies = (selectedRegion: IRegion) => {
  const { mainTownId, displayName } = selectedRegion;
  // Math.abs тут до тех пор, пока саджесты не научатся в отрицательные id
  const id = Math.abs(selectedRegion.id);
  const currentDate = new Date();
  const dateThroughOneYear = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));

  setCookie(SESSION_REGION_ID_COOKIE_KEY, `${id}`, {
    secure: false,
  });
  setCookie(SESSION_REGION_NAME_COOKIE_KEY, `${displayName}`, {
    secure: false,
  });

  setCookie(FOREVER_REGION_ID_COOKIE_KEY, `${id}`, {
    expires: dateThroughOneYear,
    secure: false,
  });
  setCookie(FOREVER_REGION_NAME_COOKIE_KEY, `${displayName}`, {
    expires: dateThroughOneYear,
    secure: false,
  });

  if (mainTownId) {
    setCookie(SESSION_MAIN_TOWN_REGION_ID_COOKIE_KEY, `${mainTownId}`, {
      secure: false,
    });
    setCookie(FOREVER_MAIN_TOWN_REGION_ID_COOKIE_KEY, `${mainTownId}`, {
      expires: dateThroughOneYear,
      secure: false,
    });
  } else {
    deleteCookie(SESSION_MAIN_TOWN_REGION_ID_COOKIE_KEY);
    deleteCookie(FOREVER_MAIN_TOWN_REGION_ID_COOKIE_KEY);
  }
};
