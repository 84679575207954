import {
  TClearGeoType,
  TGeocodeType,
  TGetGeoSuggestionsType,
  TGetMetaType,
  TResetRoomTypeType,
  TSelectGeoType,
  TSelectOfferTypeType,
  TSelectRoomTypeType,
  TSetBSCentersType,
  TSetBuilderType,
  TSetDealTypeType,
  TSetJsonQueryType,
  TSetMaxAreaType,
  TSetMaxLandAreaType,
  TSetMaxPriceType,
  TSetMinAreaType,
  TSetMinLandAreaType,
  TSetMinPriceType,
  TSetPosessorType,
  TSetRegionType,
  TSetLastRoomsTypeType,
  TSetVillageType,
} from '../../actions/filters';

export const FILTERS_SET_DEAL_TYPE_TYPE: TSetDealTypeType = 'filters/set_deal_type';
export const FILTERS_SELECT_OFFER_TYPE_TYPE: TSelectOfferTypeType = 'filters/select_offer_type';
export const FILTERS_SELECT_COWORKING_OFFER_TYPE_TYPE = 'filters/select_coworking_offer_type' as const;
export const FILTERS_SELECT_ROOM_TYPE_TYPE: TSelectRoomTypeType = 'filters/select_room_type';
export const FILTERS_RESET_ROOM_TYPE_TYPE: TResetRoomTypeType = 'filters/reset_room_type';
export const FILTERS_SET_LAST_ROOMS_TYPES_TYPE: TSetLastRoomsTypeType = 'filters/set_last_room_type';
export const FILTERS_SET_MIN_PRICE_TYPE: TSetMinPriceType = 'filters/set_min_price';
export const FILTERS_SET_MAX_PRICE_TYPE: TSetMaxPriceType = 'filters/set_max_price';
export const FILTERS_SET_PRICE_TYPE = 'filters/set_price_type' as const;
export const FILTERS_SET_MIN_AREA_TYPE: TSetMinAreaType = 'filters/set_min_area';
export const FILTERS_SET_MAX_AREA_TYPE: TSetMaxAreaType = 'filters/set_max_area';
export const FILTERS_SET_MIN_LAND_AREA_TYPE: TSetMinLandAreaType = 'filters/set_min_land_area';
export const FILTERS_SET_MAX_LAND_AREA_TYPE: TSetMaxLandAreaType = 'filters/set_max_land_area';
export const FILTERS_SET_POSESSOR_TYPE: TSetPosessorType = 'filters/set_posessor';
export const FILTERS_GET_META_TYPE: TGetMetaType = 'filters/get_meta/status_changed';
export const FILTERS_SET_REGION_ID_TYPE: TSetRegionType = 'filters/set_region';
export const FILTERS_SET_JSON_QUERY_TYPE: TSetJsonQueryType = 'filters/set_json_query';
export const FILTERS_GET_GEO_SUGGESTIONS_TYPE: TGetGeoSuggestionsType = 'filters/geo/get_suggestions/status_changed';
export const FILTERS_SET_VILLAGE_TYPE: TSetVillageType = 'filters/set_village';
export const FILTERS_SET_BS_CENTERS_TYPE: TSetBSCentersType = 'filters/set_bs_centers';
export const FILTERS_SELECT_GEO_TYPE: TSelectGeoType = 'filters/geo/select_geo';
export const FILTERS_CLEAR_GEO_TYPE: TClearGeoType = 'filters/geo/clear_geo';
export const FILTERS_GEOCODE_TYPE: TGeocodeType = 'filters/geocode/status_changed';
export const FILTERS_SET_BUILDER_TYPE: TSetBuilderType = 'filters/set_builder';
export const FILTERS_SET_MIN_WORKPLACE_COUNT_TYPE = 'filters/set_min_workplace_count';
export const FILTERS_SET_MAX_WORKPLACE_COUNT_TYPE = 'filters/set_max_workplace_count';
export const FILTER_SET_COWORKING_ID_TYPE = 'filters/set_coworking_id';
export const FILTERS_SET_SPECIALTY_TYPES_TYPE = 'filters/set_specialty_types_type' as const;
export const SET_LAST_ROOMS = 'filters/set_last_rooms';
export const RESET_LAST_ROOMS = 'filters/reset_last_rooms';
