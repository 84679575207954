import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';
import { getTelemetry } from '@cian/telemetry/browser';

import { IApplicationContext, IApplicationContextCustom } from '../../../shared/types/application';
import { createMakeRequest } from '../../client';

export function createContext(): IApplicationContext {
  const config = getConfig();

  return {
    makeRequest: createMakeRequest(window.__mainpage_api_host__ || window.location.host),
    config,
    logger: getLogger(),
    httpApi: getHttpApi(),
    telemetry: getTelemetry(),
    custom: config.getStrict<IApplicationContextCustom>('applicationContext.custom'),
  };
}
