import { Spinner } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';
import * as React from 'react';
import * as styles from './FiltersSearchButton.css';

export interface IFiltersSearchButtonProps {
  href: string;
  title?: string;
  text: string;
  dataMark: string;
  isLoading?: boolean;
  isLight?: boolean;
  isExternal?: boolean;
  onClick?(e: React.SyntheticEvent<HTMLElement>): void;
}

// eslint-disable-next-line react/display-name
export const FiltersSearchButton = React.forwardRef<HTMLElement, IFiltersSearchButtonProps>(
  ({ href, text, title, dataMark, isLight, isLoading, isExternal, onClick, ...props }, ref) => {
    return (
      <span {...props} className={styles['container']} ref={ref}>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          {...mergeStyles(styles['button'], isLoading && styles['button--loading'], isLight && styles['button--light'])}
          href={href}
          title={title}
          data-mark={dataMark}
          onClick={onClick}
          rel={isExternal ? 'nofollow noreferrer' : undefined}
        >
          {text}
          {isLoading && (
            <span className={styles['preloader']}>
              <Spinner size={18} />
            </span>
          )}
        </a>
      </span>
    );
  },
);
