import { ERoomType } from '../../packages/api-models/common/json_query';

export const COMMON_ROOM_TYPE_NAMES = {
  [ERoomType.FlatOneRoom]: '1',
  [ERoomType.FlatTwoRooms]: '2',
  [ERoomType.FlatThreeRooms]: '3',
  [ERoomType.FlatFourRooms]: '4',
  [ERoomType.FlatFiveRooms]: '5',
  [ERoomType.FlatManyRooms]: '6+',
};

export const SPECIAL_ROOM_TYPE_NAMES = {
  [ERoomType.Studio]: 'Студия',
  [ERoomType.FlatFreeLayout]: 'Свободная планировка',
};
