import { IApplicationState } from '../../types/redux';
import { isSuburban, offerTypeFromJsonQuery } from '../../utils/category';

export const selectDisableRegionExpansion = (state: IApplicationState): boolean => {
  const {
    filters: { jsonQuery, geoSearch },
  } = state;

  return isSuburban(offerTypeFromJsonQuery(jsonQuery)) && !!geoSearch;
};
