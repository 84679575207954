import { IconLocation16 } from '@cian/ui-kit/icons';
import * as React from 'react';

import * as styles from './GeoSwitcher.css';

interface IGeoSwitcherProps {
  title: string;
  onClick(): void;
}

export const GeoSwitcher: React.FC<IGeoSwitcherProps> = ({ title, onClick }) => {
  // Замыкаем тайтл, чтобы не менять, пока не перезагрузится страница при смене региона
  const regionTitle = React.useRef(title);

  return (
    <div className={styles['wrapper']}>
      <button onClick={onClick} className={styles['button']}>
        <IconLocation16 color="icon-inverted-default" /> <span className={styles['text']}>{regionTitle.current}</span>
      </button>
    </div>
  );
};
