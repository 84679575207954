import { IFilterVacanciesResponse, IMappers } from '../../repositories/vacancies/v1/filter-vacancies';

import { IApplicationContext } from '../../types/application';

export enum EVacanciesStatus {
  CriticalError,
  Error,
  Pending,
  Success,
}

export interface IVacanciesResult {
  data: IFilterVacanciesResponse;
  message: string | null;
  status: EVacanciesStatus;
}

export type VacanciesDependencies = Pick<IApplicationContext, 'logger' | 'httpApi'>;

export type VacanciesMappers = IMappers<IVacanciesResult, IVacanciesResult>;
