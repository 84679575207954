import { IInitialPropsSsr, MainpageMortgageWidgetSsr } from '@cian/finance-mainpage-mortgage-widget-ssr';
import cn from 'classnames';
import React, { useMemo } from 'react';
import { IMortgageBankShort } from '../../types/mortgage/bank';
import { IMortgagePropertyRates } from '../../types/mortgage/data';
import * as styles from './MortgageLanding.css';

export interface IMortgageLandingProps {
  loanTerm?: number;
  propertyAmount?: number;
  initialPayment?: number;
  pageType?: string;
  banks: IMortgageBankShort[];
  rates: IMortgagePropertyRates;
}

export const MortgageLanding = ({
  loanTerm,
  propertyAmount,
  initialPayment,
  pageType,
  banks,
  rates,
}: IMortgageLandingProps) => {
  const calculatorState = useMemo<IInitialPropsSsr['calculatorWidgetState']>(
    () => ({
      loanTerm,
      propertyAmount,
      initialPayment,
    }),
    [initialPayment, loanTerm, propertyAmount],
  );

  return (
    <div className={styles.wrapper}>
      <div className={cn('cg-container-fluid-xs', styles.root)}>
        <MainpageMortgageWidgetSsr
          pageType={pageType}
          calculatorWidgetState={calculatorState}
          banks={banks}
          rates={rates}
        />
      </div>
    </div>
  );
};
