import { IMicrofrontend, IChildMicrofrontend } from '@cian/mf-registry-legacy/shared';
import { RECOMMENDATIONS_MICROFRONTEND_NAME } from '../../../constants/microfrontend';

export const isRecommendationsMicrofrontendExists = (microfrontend: IMicrofrontend<IChildMicrofrontend>): boolean => {
  try {
    return !!microfrontend.getChild(RECOMMENDATIONS_MICROFRONTEND_NAME);
  } catch (e) {
    return false;
  }
};
