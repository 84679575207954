import * as React from 'react';

import * as styles from './UndergroundIcon.css';
import { getRegionUndergroundIcon } from './utils/getRegionUndergroundIcon';
import { TUndergroundType } from '../../../../../types/newbuildingRecommendations';

interface IUndergroundIconProps {
  color: Exclude<React.CSSProperties['color'], undefined>;
  undergroundType: TUndergroundType;
}

export const UndergroundIcon: React.FC<IUndergroundIconProps> = ({ color, undergroundType }) => {
  const Icon = React.useMemo(() => getRegionUndergroundIcon(undergroundType), [undergroundType]);

  return (
    <div className={styles['container']} style={{ color }}>
      <Icon display="block" color="current_color" />
    </div>
  );
};
