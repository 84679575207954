import { EBehavior, NestedCheckboxGroupsWithSearchDesktop } from '@cian/nested-checkbox-groups';
import { Outside } from '@cian/ui-kit';
import cn from 'classnames';
import React, { memo, useCallback } from 'react';
import { useToggleState } from '../../hooks';
import * as styles from './FilterNestedCheckboxGroupsWithSearchDropdown.css';
import { FilterButton } from '../FilterButton';
import { IFilterNestedCheckboxGroupsWithSearchDropdownProps } from './types';
export const FilterNestedCheckboxGroupsWithSearchDropdown = memo<IFilterNestedCheckboxGroupsWithSearchDropdownProps>(
  function FilterDropdown(props) {
    const { checkboxGroups, checkboxValues, onNestedCheckboxClick, onTitleCheckboxClick, label, isPlaceholderLabel } =
      props;

    const [isDropdownVisible, toggleVisibility] = useToggleState();

    const handleFilterButtonClick = useCallback<VoidFunction>(() => {
      if (checkboxGroups.length) {
        toggleVisibility();
      }
    }, [checkboxGroups.length, toggleVisibility]);

    return (
      <Outside onOutside={toggleVisibility} active={isDropdownVisible}>
        <div className={styles['container']}>
          <FilterButton
            label={label}
            onClick={handleFilterButtonClick}
            isActive={isDropdownVisible}
            isPlaceholderLabel={isPlaceholderLabel}
          />
          <div className={cn(styles['dropdown-wrapper'], { [styles['hidden']]: !isDropdownVisible })}>
            <NestedCheckboxGroupsWithSearchDesktop
              behavior={EBehavior.Multiply}
              classNameNestedCheckboxGroups={styles['nested-checkbox-groups']}
              groups={checkboxGroups}
              values={checkboxValues}
              onNestedCheckboxClick={onNestedCheckboxClick}
              onTitleCheckboxClick={onTitleCheckboxClick}
            />
          </div>
        </div>
      </Outside>
    );
  },
);
