import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { BuildingTypeFilterInput } from '../../components/search_filters/filters/building_type_filter';
import { isNewbuildingsSuggestEnabledForBuildingType } from '../../components/search_filters/filters/geo_suggest/suggests_helpers';
import { getOfferType } from '../../components/search_filters/filters/utils';
import {
  removeBuildingType,
  setBuildingType,
  setDirty,
  togglePopupAutoChangeToSuburban,
} from '../../reducers/modules/search/building_types';
import { clearCommercialGeo, clearNewbuildingsGeo } from '../../reducers/modules/search/user_geo';
import { GenericFilter } from './generic_filter';
import { ISearchType } from '../../types/filters/type';
import { IBuildingTypesConfig } from '../../types/filters/building_types';
import { ISearchConfig } from '../../types/search';
import { IApplicationState } from '../../types/redux';

interface IStoreProps {
  type: ISearchType;
  canShowPopupAutoChangeToSuburban?: boolean;
}

interface IDispatchProps {
  onChange(type: string, deselect?: boolean): void;
  setDirty(isDirty: boolean): void;
  hidePopupAutoChangeToSuburban(): void;
}

interface IOwnProps {
  buildingType: string[];
  buildingTypes: IBuildingTypesConfig;
  searchConfig: ISearchConfig;
}

interface IProps extends IStoreProps, IDispatchProps, IOwnProps {}

class BuildingTypeFilter extends GenericFilter<IProps> {
  protected setDirty(isDirty: boolean) {
    this.props.setDirty(isDirty);
  }

  public render() {
    const {
      type,
      onChange,
      buildingType,
      buildingTypes,
      searchConfig,
      hidePopupAutoChangeToSuburban,
      canShowPopupAutoChangeToSuburban,
    } = this.props;

    return (
      <BuildingTypeFilterInput
        type={type.searchType}
        value={buildingType}
        buildingTypes={buildingTypes}
        searchConfig={searchConfig}
        onChange={onChange}
        hidePopupAutoChangeToSuburban={hidePopupAutoChangeToSuburban}
        canShowPopupAutoChangeToSuburban={canShowPopupAutoChangeToSuburban}
      />
    );
  }
}

export function mapStateToProps(state: IApplicationState): IStoreProps {
  return {
    type: state.type,
    canShowPopupAutoChangeToSuburban: state.buildingType.canShowPopupAutoChangeToSuburban,
  };
}

export function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return {
    onChange: (type: string, deselect?: boolean) => {
      if (deselect) {
        dispatch(removeBuildingType(type));
      } else {
        dispatch(setBuildingType(type));
      }
      // Сбрасываем гео фильтр по ЖК, если комерческая или загородная недвижимость
      if (!isNewbuildingsSuggestEnabledForBuildingType(type)) {
        dispatch(clearNewbuildingsGeo());
      }

      if (getOfferType(type) === 'flat') {
        dispatch(clearCommercialGeo());
      }
    },
    setDirty: (isDirty: boolean) => dispatch(setDirty(isDirty)),
    hidePopupAutoChangeToSuburban() {
      dispatch(togglePopupAutoChangeToSuburban(false));
    },
  };
}

const BuildingTypeFilterWrapper = connect<IStoreProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)(BuildingTypeFilter as any);

export { BuildingTypeFilterWrapper as BuildingTypeFilter, BuildingTypeFilter as BuildingTypeFilterClass };
