export enum Regions {
  Moscow = 1,
  Spb = 2,
  MoscowRegion = 4593,
  SpbRegion = 4588,
  MoscowAndRegion = -1,
  SpbAndRegion = -2,
}

export const EXPANSION_REGION_DISABLE_COOKIE = 'expansible_region_disable';
