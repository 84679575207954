import * as queryString from 'qs';
import * as Analytics from '../../../../utils/analytics_helper';
import { Suggestions } from './suggests_helpers';
import {
  IGeoBoundedBy,
  IGeocodeError,
  IGeocodeForSearchRequest,
  IGeocodeForSearchResult,
  IGeocodeResult,
  IUserGeoDefinitionWithFilter,
  IUserGeoPolygon,
  IUserGeoSearch,
  TGeoType,
  TUserGeoSearch,
} from '../../../../types/filters/user_geo';
import { IMakeRequest, isSuccessResponse } from '../../../../utils/network_helper';

export type TCoordinates = [number, number];

export interface ILocation {
  id: number;
  name: string;
  displayName: string;
  fullName: string;
  hasDistricts: boolean;
  hasHighway: boolean;
  hasMetro: boolean;
  lat: number;
  lng: number;
  boundedBy: {
    lowerCorner: {
      lat: number;
      lng: number;
    };
    upperCorner: {
      lat: number;
      lng: number;
    };
  } | null;
  parentId: number | null;
  mainTownId: number | null;
}

export interface IGeoObject {
  type: TGeoType;
  id: number;
  bsCenterId?: number;
  text: string;
  coordinates?: TCoordinates;
  regionId: number;
  regionForMeta: number;
  locationId?: number;
  locationInfo?: ILocation;
  district?: {
    name: string;
    id: number;
  };
}

export function getGeoBoundedBy(makeRequest: IMakeRequest, regionOrMaintownId: number): Promise<IGeoBoundedBy> {
  return makeRequest({
    method: 'GET',
    uri: {
      path: '/cian-api/site/v1/get-location/',
      query: `regionId=${regionOrMaintownId}`,
    },
  }).then((response: IGeoBoundedBy | IGeocodeError) => {
    if (!isSuccessResponse<IGeoBoundedBy, IGeocodeError>(response, 'data')) {
      throw new Error();
    }

    return response;
  });
}

function geocodeRequest(makeRequest: IMakeRequest, value: string): Promise<IGeocodeResult> {
  return makeRequest({
    method: 'GET',
    uri: {
      path: '/api/geo/geocode-cached/',
      query: `request=${encodeURIComponent(value)}`,
    },
  }).then((response: IGeocodeResult | IGeocodeError) => {
    if (!isSuccessResponse<IGeocodeResult, IGeocodeError>(response, 'items')) {
      throw new Error('Ошибка запроса geocode-cached');
    }

    return response;
  });
}

function geocodeForSearchRequest(
  makeRequest: IMakeRequest,
  request: IGeocodeForSearchRequest,
): Promise<IGeocodeForSearchResult> {
  return makeRequest({
    body: queryString.stringify({
      Address: request.address,
      Kind: request.kind,
      Lat: request.coordinates[0],
      Lng: request.coordinates[1],
    }),
    headers: [['Content-type', 'application/x-www-form-urlencoded']],
    method: 'POST',
    uri: {
      path: '/api/geo/geocoded-for-search/',
    },
  }).then((response: IGeocodeForSearchResult | IGeocodeError) => {
    if (!isSuccessResponse<IGeocodeForSearchResult, IGeocodeError>(response, 'details')) {
      throw new Error('Ошибка запроса geocode-for-search');
    }

    return response;
  });
}

export function sendGeocode(
  makeRequest: IMakeRequest,
  address: string,
  section: string,

  onChange: (userGeoDefinitionWithFilter: IUserGeoDefinitionWithFilter, userGeoSearch: TUserGeoSearch) => void,

  onError: () => void,
  isExperiment: boolean,
) {
  let boundedBy: TCoordinates[] | undefined;
  geocodeRequest(makeRequest, address)
    .then(response => {
      boundedBy = response.items[0].boundedBy;

      return geocodeForSearchRequest(makeRequest, {
        address: response.items[0].text,
        coordinates: [response.items[0].coordinates[1], response.items[0].coordinates[0]],
        kind: response.items[0].kind,
      });
    })
    .then(response => {
      let userGeoDefinitionWithFilter: IUserGeoDefinitionWithFilter = {
        mainTownId: null,
        regionId: null,
      };

      /**
       * Если в response.details больше 1 элемента,
       * значит гео поиск идет не по региону/городу,
       * нулевой элемент является корневым гео для поиска - регион/город
       */
      if (response.details.length > 1) {
        const { id, locationInfo } = response.details[0];

        userGeoDefinitionWithFilter = {
          mainTownId: locationInfo && locationInfo.mainTownId ? locationInfo.mainTownId : id,
          regionId: id,
        };
      }

      /**
       * Точное гео поиска - для фильтра
       */
      let userGeoSearch: TUserGeoSearch;
      let geoType: string;

      //выбранный из яндекс саджеста объект есть в наших справочниках
      if (response.isParsed) {
        let geoId: number;
        if (response.geoLocationCatalogLink) {
          geoId = response.geoLocationCatalogLink.id;
          geoType = response.geoLocationCatalogLink.objectType.toLowerCase();
        } else {
          geoId = response.details[response.details.length - 1].id || response.details[0].id;
          geoType = response.details[response.details.length - 1].geoType.toLowerCase();
        }

        /**
         * Если в response.details, только 1 элемент,
         * значит гео поиск идет по региону/городу,
         * и нужно обновить гео пользователя в соответствии с запросом
         */
        if (!userGeoDefinitionWithFilter.regionId && !userGeoDefinitionWithFilter.mainTownId) {
          userGeoDefinitionWithFilter = {
            mainTownId: geoId,
            regionId: geoId,
          };
        }

        userGeoSearch = { geoId, geoType } as IUserGeoSearch;
      } else {
        //для случаев когда приходит неизвестный тип объекта, например house
        //чтобы search-offers не отдавал 500 делаем тип polygon по аналогии с frontend-serp
        const coordinates = boundedBy && [
          [boundedBy[0][0], boundedBy[0][1]],
          [boundedBy[0][0], boundedBy[1][1]],
          [boundedBy[1][0], boundedBy[1][1]],
          [boundedBy[1][0], boundedBy[0][1]],
          [boundedBy[0][0], boundedBy[0][1]],
        ];
        geoType = 'polygon';
        userGeoSearch = { coordinates, name: address } as IUserGeoPolygon;
      }

      /**
       * Точное гео поиска - для фильтра
       */

      onChange(userGeoDefinitionWithFilter, userGeoSearch);
      if (isExperiment) {
        Analytics.trackSelectGeoSuggestItem(geoType, 'yandex');
      } else {
        Analytics.changeValueUserGeoStrictFilter(section, geoType);
        if (Analytics.isYandexGeo(section)) {
          Analytics.trackYandexGeoSuggestChange(geoType);
        }
      }
    })
    .catch(error => {
      console.error(error);
      onError();
    });
}

export function hasNoSuggestions(suggestions: Suggestions): boolean {
  return suggestions.length === 0;
}
