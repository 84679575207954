import { TReduxActions } from '../../../types/redux';
import { FILTERS_SET_JSON_QUERY_TYPE } from '../../../constants/action_types';
import { defaultState } from './defaultState';
import { IJsonQuery } from '../../../../packages/api-models/common/json_query';

export function setJsonQueryReducer(jsonQuery: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  switch (action.type) {
    case FILTERS_SET_JSON_QUERY_TYPE:
      return action.jsonQuery;
  }

  return jsonQuery;
}
