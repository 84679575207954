export type NumberInputValue = number | undefined;

function isNumber(value: number | null | undefined): value is number {
  return Number.isFinite(value);
}

export function isValidInput(min?: number | null, max?: number | null): boolean {
  if (!isNumber(min) || !isNumber(max)) {
    return true;
  }

  return max >= min;
}
