import { IAdFoxBannerOnLoadData } from '@cian/adfox-component';
import { Base64 } from 'js-base64';
import { TAdFoxTemplatesParams } from '../../components/NewAdfoxBanner/OwnTemplates';

export function parseAdfoxParams({ bundleParams, bundleName }: IAdFoxBannerOnLoadData) {
  if (!bundleName || !bundleParams || !bundleParams.htmlBase64) {
    return null;
  }

  try {
    const decodedParams = Base64.decode(bundleParams.htmlBase64);
    const parsedDecodedParams = JSON.parse(decodedParams);

    if (isParsedParamsValid(parsedDecodedParams)) {
      return parsedDecodedParams;
    }
  } catch (e) {
    return null;
  }

  return null;
}

function isParsedParamsValid(params: object): params is TAdFoxTemplatesParams {
  return 'type' in params;
}
