import * as React from 'react';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import { MagazineArticles } from './magazine_articles';
import { MagazineNews } from './magazine_news';
import { MagazineBlogs } from './magazine_blogs';
import { MagazineQuestions } from './magazine_questions';
import { DigestFormContainer } from './digest_form';
import { trackShow } from './tracking';
import { IMagazineItem } from '../../types/magazine';
import { IDigestState } from '../../types/digest';

const styles = require('./magazine.css');

interface IProps {
  contentUrl: string;
  news: IMagazineItem[];
  articles: IMagazineItem[];
  blogs: IMagazineItem[];
  questions: IMagazineItem[];
  digestState: IDigestState;
}

export const Magazine = (props: IProps) => (
  <div className={`${styles['c-magazine-container']}`}>
    <div className={styles['c-tabs-tab-content']}>
      <div className={`cg-container-fluid-xs ${styles['c-magazine-container-fluid']}`}>
        <a href="/magazine/" className={styles['c-magazine-link']}>
          Журнал
        </a>

        {props.news && props.news.length > 0 && props.articles && props.articles.length > 0 && (
          <div className={`cg-row ${styles['c-white-wrap']}`}>
            <MagazineArticles contentUrl={props.contentUrl} articles={props.articles} />
            <MagazineNews news={props.news} />
          </div>
        )}

        <div className={`cg-row ${styles['c-white-wrap']} ${styles['c-white-wrap-bottom']}`}>
          <div className={`cg-col-16 cg-col-xs-24 ${styles['c-bottom-wrap']}`}>
            <MagazineBlogs contentUrl={props.contentUrl} blogs={props.blogs} />

            <MagazineQuestions contentUrl={props.contentUrl} questions={props.questions} />
          </div>

          <div className={`cg-col-8 cg-col-xs-24 ${styles['c-digest']}`}>
            <ActionAfterViewObserver callback={trackShow} triggerOnce>
              <DigestFormContainer isSubscribed={props.digestState.isSent} error={props.digestState.error} />
            </ActionAfterViewObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
);
