import { Outside } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import { EJsonQueryPriceType } from '../../../packages/api-models/common/json_query';
import { getPriceLabel, getPriceTitle } from '../../utils/price';
import { isValidInput, type NumberInputValue } from '../../utils/isValidInput';

import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';
import * as styles from './FilterCoworkingPrice.css';
import { CoworkingPriceDropdown } from './internal/components/CoworkingPriceDropdown';
import { CoworkingPricePreview } from './internal/components/CoworkingPricePreview';

export interface IFilterCoworkingPriceProps {
  gte: NumberInputValue;
  lte: NumberInputValue;
  onMaxPriceChange: (gte: IFilterCoworkingPriceProps['gte']) => void;
  onMinPriceChange: (lte: IFilterCoworkingPriceProps['lte']) => void;
  onPriceTypeChange: (coworkingPriceType: EJsonQueryPriceType | undefined) => void;
  onDestroy?: () => void;
}

interface IFilterCoworkingPriceState {
  max: IFilterCoworkingPriceProps['gte'];
  min: IFilterCoworkingPriceProps['lte'];
  isError: boolean;
}

export function FilterCoworkingPrice({
  gte,
  lte,
  onMaxPriceChange,
  onMinPriceChange,
  onPriceTypeChange,
  onDestroy,
}: IFilterCoworkingPriceProps) {
  const [active, setActive] = React.useState(false);
  const [inputState, setInputState] = React.useState<IFilterCoworkingPriceState>({
    max: lte,
    min: gte,
    isError: false,
  });

  const { min, max, isError } = inputState;

  const handleButtonClick = React.useCallback(() => setActive(active => !active), [setActive]);
  const handleOutsideClick = React.useCallback(() => setActive(false), [setActive]);

  const handleMinValueChange = (value: NumberInputValue): void => {
    setInputState(state => ({
      ...state,
      min: value,
      isError: !isValidInput(value, state.max),
    }));
  };

  const handleMaxValueChange = (value: NumberInputValue): void => {
    setInputState(state => ({
      ...state,
      max: value,
      isError: !isValidInput(state.min, value),
    }));
  };

  const handleMinBlur = (): void => {
    if (gte !== min) {
      onMinPriceChange(min);
    }
  };

  const handleMaxBlur = (): void => {
    if (lte !== max) {
      onMaxPriceChange(max);
    }
  };

  React.useEffect(() => {
    return onDestroy;
  }, []);

  return (
    <Outside active insideRefs={[]} onOutside={handleOutsideClick}>
      <div
        className={mergeStyles(stylesCommon['container'], styles['container']).className}
        data-mark="FilterCoworkingPrice"
      >
        <CoworkingPricePreview
          isActive={active}
          label={getPriceLabel(min, max)}
          onClick={handleButtonClick}
          title={getPriceTitle(min, max)}
        />
        {active && (
          <CoworkingPriceDropdown
            isErroneous={isError}
            max={max}
            min={min}
            onMaxBlur={handleMaxBlur}
            onMaxValueChange={handleMaxValueChange}
            onMinBlur={handleMinBlur}
            onMinValueChange={handleMinValueChange}
            onPriceTypeChange={onPriceTypeChange}
          />
        )}
      </div>
    </Outside>
  );
}
