import * as React from 'react';
import { Link } from '../link/link';

interface IProps {
  titleLink?: {
    url: string;
    objectCount: number;
    h1: string;
    title: string;
  };
  type?: string;
  id: string;
  title: string;
  columns: 12 | 24;
}

const s = require('./commercial_promo_block.css');

export const CommercialPromoSpecial = (props: IProps) => (
  <Link
    className={`${s['c-com-promo-block']}
        ${s[`c-com-promo-${props.id}`]}
        cg-col-promo-s-${props.columns}
        cg-col-${props.columns}`}
    href={props.titleLink ? props.titleLink.url : ''}
  >
    <span className={s['c-com-promo-block-content']}>
      <span className={s['c-com-promo-block-catalog-wrap']}>
        <span className={s['c-com-promo-block-catalog-text']}>{props.title}</span>
      </span>
    </span>
  </Link>
);
