import { useCallback, useState } from 'react';

export function useDropdownVisibilityState() {
  const [dropdownIsVisible, setDropdownVisibility] = useState<boolean>(false);

  const toggleVisibility = useCallback<VoidFunction>(() => setDropdownVisibility(visible => !visible), []);

  const closeDropdown = useCallback<VoidFunction>(() => setDropdownVisibility(false), []);

  const openDropdown = useCallback<VoidFunction>(() => setDropdownVisibility(true), []);

  return {
    closeDropdown,
    dropdownIsVisible,
    openDropdown,
    setDropdownVisibility,
    toggleVisibility,
  };
}
