import { mergeStyles } from '@cian/utils';
import * as React from 'react';

import * as styles from './FiltersTabs.css';

export interface IFiltersTabsProps {
  items: IFiltersTabProps[];
}

export interface IFiltersTabProps {
  label: string;
  url: string;
  isSelected: Boolean;
  clickHandler(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
  target?: string;
  dataTestid?: string;
}

export class FiltersTabs extends React.Component<IFiltersTabsProps> {
  public render() {
    return (
      <ul className={styles['list']} data-mark="FiltersTabs">
        {this.props.items.map(({ label, isSelected, url, clickHandler, target, dataTestid }) => (
          <li
            key={`item_${label}`}
            {...mergeStyles(styles['list-element'], isSelected && styles['list-element--active'])}
          >
            <a href={url} onClick={event => clickHandler(event)} target={target} data-testid={dataTestid}>
              {label}
            </a>
          </li>
        ))}
      </ul>
    );
  }
}
