import * as React from 'react';
import { UIText2 } from '@cian/ui-kit';

import * as styles from './DefaultAttributes.css';

export interface IDefaultAttributesProps {
  newbuildingsCount: string;
  type: string;
}

export const DefaultAttributes: React.VFC<IDefaultAttributesProps> = ({ newbuildingsCount, type }) => {
  return (
    <div className={styles['container']} data-testid="DefaultAttributes">
      <UIText2 color="gray60_100">{newbuildingsCount}</UIText2>
      <UIText2 color="gray60_100">{type}</UIText2>
    </div>
  );
};
