import { connect } from 'react-redux';
import { FilterSingleChoose, IFilterSingleChooseProps } from '../../components/FilterSingleChoose';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { setMortgageTerms } from '../../actions/mortgage';

type TFilterMortgageTermStateProps = Pick<IFilterSingleChooseProps<number>, 'items' | 'selectedItemKey' | 'label'>;
type TFilterMortgageTermDispatchProps = Pick<IFilterSingleChooseProps<number>, 'onSelectItem'>;

export function mapStateToProps(state: IApplicationState): TFilterMortgageTermStateProps {
  return {
    items: new Map([
      [5, '5 лет'],
      [10, '10 лет'],
      [15, '15 лет'],
      [20, '20 лет'],
      [25, '25 лет'],
      [30, '30 лет'],
    ]),
    label: 'Срок',
    selectedItemKey: state.mortgage.filterTerms,
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TFilterMortgageTermDispatchProps {
  return {
    onSelectItem: (itemKey: number) => dispatch(setMortgageTerms(itemKey)),
  };
}

export const FilterMortgageTermContainer = connect(mapStateToProps, mapDispatchToProps)(FilterSingleChoose);
