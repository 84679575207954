import { CHANGE_GEO_SEARCH } from '../../../actions/filters';
import { FILTERS_CLEAR_GEO_TYPE } from '../../../constants/action_types';
import { TReduxActions } from '../../../types/redux';

export function geoSearchReducer(state: string = '', action: TReduxActions): string {
  switch (action.type) {
    case CHANGE_GEO_SEARCH: {
      return action.payload;
    }

    case FILTERS_CLEAR_GEO_TYPE: {
      return '';
    }

    default:
      return state;
  }
}
