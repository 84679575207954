import * as React from 'react';
import { trackPopularAds } from '../../utils/helpers/metrics_helper';
import { ITopJK } from '../../types/top_three';
import { Link } from '../link/link';
import { PopularContainer } from '../popular_list/popular_container';
import { PopularContent } from '../popular_list/popular_content';
import { JKPopularItem } from '../popular_item/jk_popular_item';

interface IProps {
  offers: ITopJK[];
  allJkLink?: string;
  locationName: string;
  services?: React.ReactNode;
}

export class TopJK extends React.Component<IProps> {
  public render() {
    const s = require('./top_three.css');

    return (
      <div className={s['c-popular-container']}>
        <h2 className={s['c-popular-title']}>{`Популярные ЖК ${this.props.locationName}`}</h2>
        <PopularContainer>
          <PopularContent
            onItemClick={() => trackPopularAds('sale/flat_new', undefined)}
            popularItem={JKPopularItem}
            offers={this.props.offers}
          />
        </PopularContainer>
        <Link className={`${s['c-link-default']} ${s['c-all-propositions']}`} href={this.props.allJkLink || '#'}>
          Все предложения
        </Link>
        {this.props.services}
      </div>
    );
  }
}
