import { ISearchCatalogItemSchema } from '../../../../repositories/monolith-python/entities/schemas/SearchCatalogItemSchema';
import { IRegion } from '../../../../types/geoSwitcher';

export function prepareSuggestions(regions?: ISearchCatalogItemSchema[] | null): IRegion[] {
  if (!regions) {
    return [];
  }

  return regions.map(region => ({
    id: region.id,
    displayName: region.displayName,
    mainTownId: region.mainTownId || null,
    baseHost: null,
  }));
}
