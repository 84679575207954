export interface IScrollToPos {
  cancel(): void;
}

export function scrollToPos(container: HTMLElement, to: number, duration: number = 0): IScrollToPos | null {
  if (!window.requestAnimationFrame || !duration) {
    // scroll without animation as fallback
    container.scrollLeft = to;
    container.dispatchEvent(new Event('scrollEnd'));

    return null;
  }

  let startTime: number;
  let isCanceled = false;
  const from = container.scrollLeft;

  // scroll looping over a frame
  function step(time: number) {
    if (isCanceled) {
      return;
    }

    if (startTime === undefined) {
      startTime = time;
      requestAnimationFrame(step);

      return;
    }

    let elapsed = (time - startTime) / duration;

    // avoid elapsed times higher than one
    elapsed = Math.min(elapsed, 1);

    // apply easing to elapsed time
    const value = 0.5 * (1 - Math.cos(Math.PI * elapsed));

    const currentPos = from + (to - from) * value;

    container.scrollLeft = currentPos;

    // scroll more if we have not reached our destination
    if (currentPos !== to) {
      window.requestAnimationFrame(step);

      return;
    }

    container.dispatchEvent(new Event('scrollEnd'));
  }

  window.requestAnimationFrame(step);

  return {
    cancel: () => {
      isCanceled = true;
    },
  };
}
