export interface ISalesLink {
  salesType: ESalesType;
  text: string;
  title: string;
  url: string;
}

export enum ESalesType {
  BlackFriday = 'blackFriday',
  SalesLeader = 'salesLeader',
  SalesStart = 'salesStart',
  UpcomingSale = 'upcomingSale',
}
