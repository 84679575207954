import { ILogger } from '@cian/logger';
import * as React from 'react';

interface IErrorBoundaryProps {
  logger?: ILogger;
  component?: React.ReactNode;
  onError?(error: Error, info: React.ErrorInfo): void;
  children?: React.ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(): IErrorBoundaryState {
    return {
      hasError: true,
    };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    if (this.props.onError) {
      this.props.onError(error, info);
    }

    if (this.props.logger) {
      this.props.logger.error(error, { ...info });
    }
  }

  public render() {
    if (this.state.hasError) {
      return this.props.component || null;
    }

    return this.props.children;
  }
}
