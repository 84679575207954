import * as React from 'react';
import { IPromoLink } from '../../../types/promo';
import { declineNumeric, formatPrice } from '../../../utils/format_helper';
import classNames from 'classnames';

import styles from './GeneralPromoBlock.css';

interface IProps {
  id: string;
  title: string;
  links: IPromoLink[];
  type?: string;
  backgroundClassName: string;
  blockClassName: string;
  withIcon?: boolean;
}

const s = require('../vertical_promo_blocks.css');

export const GeneralPromoBlock: React.FC<IProps> = ({
  backgroundClassName,
  blockClassName,
  withIcon = false,
  links,
}) => {
  // Ни в одной вертикали нет массивов с несколькими ссылками. Про это забыли
  const { objectCount, url, title } = links[0];

  const offerCount = React.useMemo(
    () =>
      objectCount
        ? `${formatPrice(objectCount, 4)} ${declineNumeric(objectCount, ['предложение', 'предложения', 'предложений'])}`
        : '',
    [objectCount],
  );

  return (
    <div
      className={`${s['c-new-promo-block']}
                        ${s[backgroundClassName]}
                        ${blockClassName}`}
    >
      <a className={classNames(styles['container'], withIcon && styles['with-icon'])} href={url}>
        <h5
          className={styles['title']}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {offerCount && <span className={styles['text']}>{offerCount}</span>}
      </a>
    </div>
  );
};
