import cn from 'classnames';
import { UIHeading4, UIText2, LinkButton } from '@cian/ui-kit';
import React from 'react';
import * as styles from './DemandBanner.css';
import { IDemandBannerProps } from './types';

export const DemandBanner = (props: IDemandBannerProps) => {
  const { title, text, textButton, linkHref, imageURL, backgroundColor } = props;

  const backgroundImage = `url(${imageURL})`;

  return (
    <div style={{ backgroundColor, backgroundImage }} className={cn(styles['container'])}>
      <UIHeading4>{title}</UIHeading4>

      <div className={styles['description']}>
        <UIText2>{text}</UIText2>
      </div>

      <LinkButton size="M" href={linkHref}>
        {textButton}
      </LinkButton>
    </div>
  );
};

DemandBanner.displayName = 'DemandBanner';
