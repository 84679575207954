/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TSuggestModel,
  ISuggestRequest,
  IMappers,
  ISuggestResponse,
  ISuggestResponseError,
  TSuggestResponse,
} from './types';

const defaultConfig: TSuggestModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'geo-suggest',
  pathApi: '/v2/suggest/',
  hostType: 'api',
} as TSuggestModel;

function createSuggestModel(parameters: ISuggestRequest): TSuggestModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISuggestOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ISuggestRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchSuggest<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ISuggestOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TSuggestResponse> {
  const { response, statusCode } = await httpApi.fetch(createSuggestModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISuggestResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ISuggestResponseError);
    }
  }

  return { response, statusCode } as TSuggestResponse;
}

export { defaultConfig, createSuggestModel, fetchSuggest };
