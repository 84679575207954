import * as React from 'react';
import { Image, UIHeading5, UIText2 } from '@cian/ui-kit';
import cn from 'classnames';

import { ENTER_KEY } from '../../../../constants/keyboard';
import * as styles from './PromoItem.css';

export interface IPromoItemProps {
  activeDateTo: string;
  attributes?: React.ReactNode;
  highlighted: boolean;
  name: string;
  onClick?(): void;
  providerLogo: string;
  providerName: string;
}

export const PromoItem: React.FC<IPromoItemProps> = ({
  activeDateTo,
  attributes,
  highlighted,
  name,
  onClick,
  providerLogo,
  providerName,
}) => {
  const handleKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault();
      event.currentTarget.click();
    }
  }, []);

  return (
    <div
      className={cn(styles['container'], { [styles['highlighted']]: highlighted })}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      data-testid="PromoItem"
      role="button"
      tabIndex={0}
    >
      <div className={styles['main']}>
        <div className={styles['content']}>
          <UIText2 color="gray60_100">{providerName}</UIText2>
          <div className={styles['name']}>
            <UIHeading5 as="h3">{name}</UIHeading5>
          </div>
          <UIText2>{activeDateTo}</UIText2>
        </div>

        <div className={styles['logo']}>
          <Image src={providerLogo} borderRadius={0} height="100%" width="100%" objectFit="contain" />
        </div>
      </div>

      <div className={styles['footer']}>{attributes}</div>
    </div>
  );
};
