import { connect } from 'react-redux';

import { TThunkDispatch, IApplicationState } from '../../types/redux';
import { setMinLandArea, setMaxLandArea } from '../../actions/filters';
import { FilterArea, IFilterAreaProps } from '../../components/FilterArea';

type TFilterAreaStateProps = Pick<IFilterAreaProps, 'value' | 'adornmentPlaceholder'>;
type TFilterAreaDispatchProps = Pick<IFilterAreaProps, 'setMin' | 'setMax'>;

const adornmentPlaceholder = 'сот.';

export function mapStateToProps(state: IApplicationState): TFilterAreaStateProps {
  const value = state.filters.jsonQuery.site?.value || {};

  return {
    value,
    adornmentPlaceholder,
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TFilterAreaDispatchProps {
  return {
    setMin: (value: number | undefined) => dispatch(setMinLandArea(value)),
    setMax: (value: number | undefined) => dispatch(setMaxLandArea(value)),
  };
}

export const FilterLandAreaContainer = connect(mapStateToProps, mapDispatchToProps)(FilterArea);
