export enum ESectionType {
  Index = 'index',
  Rent = 'rent',
  Sale = 'sale',
  NewBuildings = 'new-buildings',
  DailyRent = 'daily-rent',
  Commercial = 'commercial',
}

export type TSectionType = `${ESectionType}`;
