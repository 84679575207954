import * as React from 'react';

import { FiltersTabsContainer } from '../../containers/FiltersTabsContainer';
import { FiltersContainer } from '../../containers/FiltersContainer';
import * as styles from './Hero.css';
import { mergeStyles } from '@cian/utils';
import { DobroshriftTitle } from './DobroshriftTitle';
import { usePlacementTabProps } from './hooks/usePlacementTabProps';

export interface IHeroProps {
  title: string;
  isDobroshriftEnabled: boolean;
  isChildrenDayEnabled: boolean;
}

export function Hero({ title, isDobroshriftEnabled, isChildrenDayEnabled }: IHeroProps) {
  const isTitleTransparent = isDobroshriftEnabled || isChildrenDayEnabled;

  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  const placementTabProps = usePlacementTabProps();

  return (
    <div className={styles['wrapper']}>
      <div className={styles['container']} data-mark="Hero">
        {isDobroshriftEnabled && (
          <div className={styles['dobroshrift']}>
            <DobroshriftTitle />
            <div className={styles['dobrostrift-caption']}>
              <a
                className={styles['dobroshrift-link']}
                href="https://dobroshrift.ru/"
                rel="noopener noreferrer"
                target="_blank"
              >
                #доброшрифт
              </a>
            </div>
          </div>
        )}
        {isChildrenDayEnabled && (
          <div {...mergeStyles(styles['title'], styles['children-day'])}>
            <a
              href="https://doma.otkazniki.ru/?utm_source=cian_ru&utm_medium=zagolovok&utm_campaign=domoy_cian"
              rel="noopener noreferrer"
              target="_blank"
              className={styles['children-day-link']}
            >
              #детихотятдомой поможем каждому ребенку расти в семье!
            </a>
          </div>
        )}
        <div {...mergeStyles(styles['title'], isTitleTransparent && styles['title-transparent'])}>{title}</div>
        <FiltersTabsContainer placementTabProps={placementTabProps} />
        <FiltersContainer />
      </div>
    </div>
  );
}
