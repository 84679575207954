import { EGeocodeStatus } from '../../types/filters';
import { IApplicationState } from '../../types/redux';

export function selectIsGeoLoading(state: IApplicationState): boolean {
  const {
    filters: { geocode },
  } = state;

  const isGeoLoading = geocode.status === EGeocodeStatus.Loading;

  return isGeoLoading;
}
