import { ESpecialistTypes } from '../../constants/searchRealtors';

export const SET_SPECIALIST_TYPE = 'search_realtors/set_specialist_type' as const;

export const setSpecialistType = (payload: ESpecialistTypes) => ({
  type: SET_SPECIALIST_TYPE,
  payload,
});

export type TSetSpecialistTypePayload = Parameters<typeof setSpecialistType>;
export type TSetSpecialistTypeAction = ReturnType<typeof setSpecialistType>;
