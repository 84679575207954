import * as R from 'ramda';

import { setTerms } from './helpers';

import { FCoworkingOfferType } from '../category';
import { ECoworkingOfferType, IJsonQuery } from '../../../packages/api-models/common/json_query';

const toggleCoworkingOfficeType =
  (jsonQuery: IJsonQuery) =>
  (coworkingOfficeType: ECoworkingOfferType): IJsonQuery => {
    if (jsonQuery.coworking_offer_type) {
      if (!jsonQuery.coworking_offer_type.value.includes(coworkingOfficeType)) {
        return setTerms('coworking_offer_type')(jsonQuery)(
          jsonQuery.coworking_offer_type.value.concat(coworkingOfficeType),
        );
      }

      return setTerms('coworking_offer_type')(jsonQuery)(
        jsonQuery.coworking_offer_type.value.filter(type => type !== coworkingOfficeType),
      );
    }

    return setTerms('coworking_offer_type')(jsonQuery)([coworkingOfficeType]);
  };

export const setCoworkingOfferType =
  (jsonQuery: IJsonQuery) =>
  (nextCoworkingOfferType: FCoworkingOfferType): IJsonQuery => {
    const clonedJsonQuery = R.clone(jsonQuery);

    switch (nextCoworkingOfferType) {
      case FCoworkingOfferType.FixedWorkSpace:
        return toggleCoworkingOfficeType(clonedJsonQuery)(ECoworkingOfferType.FixedWorkSpace);

      case FCoworkingOfferType.FlexibleOffice:
        return toggleCoworkingOfficeType(clonedJsonQuery)(ECoworkingOfferType.FlexibleOffice);

      case FCoworkingOfferType.FreeWorkSpace:
        return toggleCoworkingOfficeType(clonedJsonQuery)(ECoworkingOfferType.FreeWorkSpace);

      default:
        return clonedJsonQuery;
    }
  };
