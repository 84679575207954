/* eslint-disable max-lines */
import findKey from 'lodash/findKey';
import includes from 'lodash/includes';
import map from 'lodash/map';
import intersection from 'lodash/intersection';

import { ISearchType, SearchType } from '../../types/filters/type';
import { IBuildingClasses, IBuildingTypes, IBuildingTypesRow } from '../../types/filters/building_types';
import { ISearchPrice } from '../../types/filters/price';
import { ISquare } from '../../types/filters/square';
import { ISuburbanOfferFilter } from '../../types/filters/suburban_offer_filter';
import { IRooms } from '../../types/filters/rooms';
import { IUserGeo } from '../../types/filters/user_geo';
import { IYear } from '../../types/filters/year';
import {
  IJsonPartialQuery,
  IJsonQuery,
  IJsonQueryLocation,
  IJsonQueryNewbuilding,
  IJsonQueryPolygon,
  IJsonQueryRange,
  IJsonQueryTerm,
  IJsonQueryTerms,
  TStringCoordinates,
} from '../../reducers/modules/search/search';
import { TCoordinates } from '../../components/search_filters/filters/geo_suggest/geo_helpers';
import { BuildingTypes } from '../../components/search_filters/building_types';

interface IBTNumbers {
  [Identifier: string]: number;
}

const roomTypeNumbers: IBTNumbers = {
  room: 0,
  part: 8,
  bed: 10,
};

const objectTypeNumbers: IBTNumbers = {
  house: 1,
  houserent: 1,
  housepart: 2,
  townhouse: 4,
  sector: 3,
  comsector: 3,
};

const officeTypeNumbers: IBTNumbers = {
  office: 1,
  trade_area: 2,
  warehouse: 3,
  psn: 5,
  fastfood: 4,
  garage: 6,
  enterprise: 7,
  auto: 9,
  ready: 10,
  building: 11,
  domestic: 12,
};

const suburbanExclusions = ['house', 'housepart', 'townhouse', 'sector', 'comsector'];

const matchType = (searchType: SearchType): IJsonQuery => {
  switch (searchType) {
    case 'buy':
      return { _type: 'sale' };
    case 'rent_long':
      return { _type: 'rent', for_day: { type: 'term', value: '!1' } };
    case 'rent_short':
      return { _type: 'rent', for_day: { type: 'term', value: '1' } };
    default:
      return { _type: '' };
  }
};

const coordsArrayNumberToString = (coords: TCoordinates[]): TStringCoordinates[] =>
  coords.map(coord => [String(coord[0]), String(coord[1])] as TStringCoordinates);

const matchBuildingType = (buildingType: string) => {
  const buildingClasses: IBuildingClasses = {
    living: 'flat',
    countryside: 'suburban',
    commerce: 'commercial',
  };

  if (includes(suburbanExclusions, buildingType)) {
    return buildingClasses['countryside'];
  } else {
    return buildingClasses[
      findKey(
        BuildingTypes,
        (option: IBuildingTypesRow) => includes(Object.keys(option.options), buildingType),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any
    ];
  }
};

const applyBuildingType = (type: string, buildingTypes: string[]): IJsonQuery => {
  if (buildingTypes.includes('new_flat')) {
    return {
      _type: type,
      building_status: { type: 'term', value: 2 },
      room: { type: 'terms', value: [roomTypeNumbers.new_flat] },
    };
  } else if (buildingTypes.includes('secondary_flat')) {
    return {
      _type: type,
      building_status: { type: 'term', value: 1 },
      room: { type: 'terms', value: [roomTypeNumbers.secondary_flat] },
    };
  }

  let buildingClass = findKey(
    BuildingTypes,
    (option: IBuildingTypesRow) => intersection(Object.keys(option.options), buildingTypes).length,
  );
  if (intersection(suburbanExclusions, buildingTypes).length) {
    buildingClass = 'countryside';
  }

  switch (buildingClass) {
    case 'living':
      return {
        _type: type,
        room: { type: 'terms', value: buildingTypes.map(buildingType => roomTypeNumbers[buildingType]) },
      };
    case 'countryside':
      return {
        _type: type,
        object_type: { type: 'terms', value: buildingTypes.map(buildingType => objectTypeNumbers[buildingType]) },
      };
    case 'commerce':
      return {
        _type: type,
        office_type: { type: 'terms', value: buildingTypes.map(buildingType => officeTypeNumbers[buildingType]) },
      };
    default:
      return { _type: type };
  }
};

const matchRooms = (rooms: string[]): IJsonQueryTerms<number> => {
  return {
    type: 'terms',
    value: map(rooms, (room: string) => {
      if (room === 'studio') {
        return 9;
      }
      if (room === 'free') {
        return 7;
      }

      return Number(room);
    }),
  };
};

const matchPrice = (type: string, price: ISearchPrice): IJsonQuery => {
  const result: IJsonQuery = { _type: type };

  if (!(price.fromPrice || price.toPrice)) {
    return result;
  }

  const priceRange: IJsonQueryRange = {
    type: 'range',
    value: {},
  };

  if (price.fromPrice) {
    priceRange.value.gte = price.fromPrice;
  }
  if (price.toPrice) {
    priceRange.value.lte = price.toPrice;
  }

  result.price = priceRange;

  if (price.priceType === 'meters') {
    result.price_sm = {
      type: 'term',
      value: true,
    };
  }
  switch (price.currency) {
    case 'usd':
      result.currency = { type: 'term', value: 1 };
      break;
    case 'rub':
      result.currency = { type: 'term', value: 2 };
      break;
    case 'eur':
      result.currency = { type: 'term', value: 3 };
      break;
    default:
      break;
  }

  return result;
};

const matchSquare = (buildingType: string, square: ISquare) => {
  const squareKey = ['comsector', 'sector'].includes(buildingType) ? 'site' : 'total_area';
  const squareValue: IJsonQueryRange = {
    type: 'range',
    value: {},
  };
  const result: IJsonPartialQuery = {};

  if (square.squareFrom) {
    squareValue.value.gte = square.squareFrom;
  }
  if (square.squareTo) {
    squareValue.value.lte = square.squareTo;
  }

  result[squareKey] = squareValue;

  return result;
};

const matchYear = (type: string, years: string[]): IJsonQuery => {
  const result: IJsonQuery = { _type: type };
  const currentYear = new Date().getFullYear();

  if (includes(years, 'done')) {
    result.hand_over = {
      type: 'term',
      value: true,
    };
    result.status = {
      type: 'terms',
      value: [5004],
    };
  }
  if (includes(years, '3')) {
    result.yeargte = {
      type: 'term',
      value: currentYear + 3,
    };
  }
  result.year = {
    type: 'terms',
    value: map(
      intersection(years, ['0', '1', '2']),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (value: any) => currentYear + Number(value),
    ),
  };

  return result;
};

const mapSuburbanOfferFilter: { [key: string]: IJsonQueryTerm<number> | undefined } = {
  all: undefined,
  from_builder: {
    type: 'term',
    value: 1,
  },
  from_individual: {
    type: 'term',
    value: 2,
  },
};

function matchSuburbanOfferFilter(suburbanOfferFilter: ISuburbanOfferFilter) {
  return mapSuburbanOfferFilter[suburbanOfferFilter.suburbanOfferFilter];
}

export const matchQueryParams = (
  type: ISearchType,
  buildingType: IBuildingTypes,
  square: ISquare,
  rooms: IRooms,
  price: ISearchPrice,
  userGeo: IUserGeo,
  year: IYear,
  isNewObjects: boolean,
  suburbanOfferFilter: ISuburbanOfferFilter,
): IJsonQuery => {
  const result: IJsonQuery = {
    _type: '',
    engine_version: {
      type: 'term',
      value: 2,
    },
  };
  const typeMatch = matchType(type.searchType);

  if (suburbanOfferFilter.isDirty) {
    result.suburban_offer_filter = matchSuburbanOfferFilter(suburbanOfferFilter);
  }
  result._type = `${matchBuildingType(buildingType.buildingType[0])}${typeMatch._type}`;
  result.for_day = typeMatch.for_day;
  Object.assign(result, applyBuildingType(result._type, buildingType.buildingType));

  if (rooms.isDirty) {
    result.room = matchRooms(rooms.rooms);
  }
  Object.assign(result, matchPrice(result._type, price));
  if (square.isDirty) {
    Object.assign(result, matchSquare(buildingType.buildingType[0], square));
  }
  if (year.isDirty) {
    Object.assign(result, matchYear(buildingType.buildingType[0], year.years));
  }

  if (userGeo.isDirty) {
    result.geo = {
      type: 'geo',
      value: [],
    };

    result.bs_center = userGeo.bsCenterId ? { type: 'terms', value: [userGeo.bsCenterId] } : undefined;
    result.kp_id =
      userGeo.geoValue && userGeo.geoValue.type === 'village'
        ? { type: 'term', value: userGeo.geoValue.id }
        : undefined;

    if (userGeo.geoType === 'polygon') {
      result.geo.value.push({
        coordinates: userGeo.coordinates && coordsArrayNumberToString(userGeo.coordinates),
        type: userGeo.geoType,
        name: userGeo.name,
      } as IJsonQueryPolygon);
    } else {
      if (userGeo.geoValue && typeof userGeo.geoValue !== 'number') {
        result.geo.value.push({
          id: userGeo.geoValue.id,
          type: userGeo.geoValue.type,
        } as IJsonQueryNewbuilding);
      }
      if (userGeo.geoType) {
        result.geo.value.push({
          id: typeof userGeo.geoValue === 'number' ? userGeo.geoValue : Number(userGeo.userGeo),
          type: userGeo.geoType,
        } as IJsonQueryLocation);
      } else {
        result.region = {
          type: 'terms',
          value: [Number(userGeo.userGeo)],
        };
      }
    }
  }

  if (isNewObjects) {
    result._type = 'newobjectsale';
  }

  return result;
};
