import { Action } from 'redux';
import { batch } from 'react-redux';

import { TThunkAction } from '../../types/redux';
import { FILTER_SET_COWORKING_ID_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';

export interface ISetCoworkingID extends Action<typeof FILTER_SET_COWORKING_ID_TYPE> {
  coworkingIds: number[];
}

export function setCoworkingIds(coworkingIds: number[]): TThunkAction {
  return dispatch =>
    batch(() => {
      dispatch<ISetCoworkingID>({
        type: FILTER_SET_COWORKING_ID_TYPE,
        coworkingIds,
      });

      dispatch(getMeta());
    });
}
