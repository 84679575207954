import { TThunkAction } from '../../types/redux';
import { getMeta } from './getMeta';
import { Action } from 'redux';
import { SET_LAST_ROOMS } from '../../constants/action_types';
import { ERoomType } from '../../../packages/api-models/common/json_query';

export type TSetLastRoomsType = 'filters/set_last_rooms';

export interface ISetLastRoomsType extends Action<TSetLastRoomsType> {
  type: TSetLastRoomsType;
  payload: ERoomType[];
}

export type TSetLastRoomsFilter = ISetLastRoomsType;

export function setLastRooms(callGetMeta = false): TThunkAction {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.filters.jsonQuery.room) {
      return;
    }
    const lastRooms = state.filters.jsonQuery.room.value;
    dispatch<TSetLastRoomsFilter>({
      type: SET_LAST_ROOMS,
      payload: lastRooms,
    });

    if (callGetMeta) {
      dispatch(getMeta());
    }
  };
}
