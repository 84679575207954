import { IApplicationState } from '../../../types/redux';

export function selectMapUrl(state: IApplicationState): string {
  const {
    filters: {
      meta: { mapUrl },
    },
  } = state;

  return mapUrl;
}
