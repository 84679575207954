import { IJsonQuery } from '../../../../packages/api-models/common/json_query';
import { FILTERS_SET_BEDS_COUNT } from '../../../actions/filters/beds';

import { TReduxActions } from '../../../types/redux';
import { JsonQuery } from '../../../utils/jsonQuery';
import { defaultState } from './defaultState';

export function setBedsReducer(state: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  switch (action.type) {
    case FILTERS_SET_BEDS_COUNT:
      return new JsonQuery(state).setBeds(action.payload, undefined).getSerializable();

    default:
      return state;
  }
}
