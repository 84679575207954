import { formatDate } from './formatDate';

export const getLabel = (from: Date | null, to: Date | null): string => {
  if (!from || !to) {
    return 'Заезд — Отъезд';
  }

  const today = new Date();
  const isSameYear = from.getFullYear() === to.getFullYear();
  const isCurrentYear = from.getFullYear() === today.getFullYear();
  const shouldPrintYear = !isSameYear || !isCurrentYear;

  return `${formatDate(from, shouldPrintYear)} — ${formatDate(to, shouldPrintYear)}`;
};
