import * as React from 'react';

const s = require('./empty_row.css');

export const EmptyRow = () => (
  <div className={`cg-row ${s['empty-row']}`}>
    <div className="cg-col-18" />
    <div className="cg-col-5 cg-skip-1" />
  </div>
);
