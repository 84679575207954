import * as React from 'react';
import { getNewbuildingRecommendations } from '../../actions/newbuildingRecommendations/getNewbuildingRecommendations';
import { useDispatch, useSelector } from 'react-redux';
import { getNewbuildingRecommendationsSelector } from '../../selectors/newbuildingRecommendations';
import { INewbuildingsRecommendations } from '../../types/newbuildingRecommendations';
import { getNewbuildingBanners } from '../../actions/newbuildingBanners/getNewbuildingBanners';

import { getNewbuildingPromoBannerSelector } from '../../selectors/newbuildingPromoBanner/getNewbuildingPromoBanner';
import { IPromoBanners } from '../../types/newbuildingBanners/newbuildingBanners';
import { NewbuildingRecommendations } from './NewbuildingRecommendations';
import { selectNewbuildingRecommendationCExperimentEnabled } from '../../selectors/newbuildingRecommendations/isNewbuildingRecommendationsExperimentEnabled';

export const NewbuildingRecommendationsContainer = () => {
  const dispatch = useDispatch();
  const isEnabled = useSelector(selectNewbuildingRecommendationCExperimentEnabled);
  const newbuildings: INewbuildingsRecommendations | null = useSelector(getNewbuildingRecommendationsSelector);
  const banners: IPromoBanners | null = useSelector(getNewbuildingPromoBannerSelector);

  React.useEffect(() => {
    dispatch(getNewbuildingRecommendations());
    dispatch(getNewbuildingBanners());
  }, []);

  if (!newbuildings || !isEnabled || !banners) {
    return null;
  }

  const bannersData = [banners.allPromos, ...banners.compilations, banners.consultant, ...banners.promos];

  return <NewbuildingRecommendations newbuildings={newbuildings} banners={bannersData} />;
};
