export type TGeoType =
  | 'location'
  | 'street'
  | 'highway'
  | 'district'
  | 'underground'
  | 'house'
  | 'newobject'
  | 'builder'
  | 'railway'
  | 'village';

export interface IGeocodeItem {
  kind: YMaps.TGeoKind;
  name: string;
  text: string;
  coordinates: [number, number];
  boundedBy?: [number, number][];
}

export interface IGeocodeResult {
  items: IGeocodeItem[];
}

export interface IGeocodeError {
  key: string;
  message: string;
}

export interface IGeocodeErrorResult {
  message: string;
  errors: IGeocodeError[];
}

export interface ILocationInfo {
  mainTownId: number | null;
}

export type TGeocodeForSearchGeoType =
  | 'Country'
  | 'Location'
  | 'Street'
  | 'Road'
  | 'District'
  | 'Underground'
  | 'House';

export interface IGeocodeForSearchDetails {
  fullName: string;
  geoType: TGeocodeForSearchGeoType;
  hasMetro: boolean;
  hasRoad: boolean;
  id: number;
  isLocality: boolean;
  name: string;
  streetRequired: boolean;
  locationInfo: ILocationInfo | null;
}

export enum ECatalogObjectType {
  Newobject = 'NewObject',
}

export interface IGeoLocationCatalogLink {
  id: number;
  objectType: ECatalogObjectType;
}

export interface IGeocodeForSearchResult {
  details: IGeocodeForSearchDetails[];
  isParsed: boolean;
  geoLocationCatalogLink: IGeoLocationCatalogLink | null;
  regionId?: number;
}
