import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBusinessAppointmentsAction,
  setFilterBusinessAppointmentsIds,
} from '../../../../actions/businessAppointments';
import { businessAppointmentsIsLoadedSelector } from '../../../../selectors/businessAppointments/businessAppointmentsIsLoadedSelector';

export function useFilterBusinessAppointmentsMountEffect(): void {
  const dispatch = useDispatch();

  const businessAppointmentsIsLoaded = useSelector(businessAppointmentsIsLoadedSelector);

  useEffect(() => {
    if (!businessAppointmentsIsLoaded) {
      dispatch(setBusinessAppointmentsAction());
    }

    return () => {
      dispatch(setFilterBusinessAppointmentsIds([]));
    };
    // Это эффект только маунта/демаунта компонента
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
