import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SELECT_ROOM_TYPE_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';
import { ERoomType } from '../../../packages/api-models/common/json_query';

export type TSelectRoomTypeType = 'filters/select_room_type';

export interface ISelectRoomType extends Action<TSelectRoomTypeType> {
  type: TSelectRoomTypeType;
  roomType: ERoomType;
}

export type TSelectRoomType = ISelectRoomType;

export function selectRoomType(roomType: ERoomType): TThunkAction {
  return dispatch => {
    dispatch<TSelectRoomType>({
      type: FILTERS_SELECT_ROOM_TYPE_TYPE,
      roomType,
    });

    dispatch(getMeta());
  };
}
