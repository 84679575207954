import { ca } from '@cian/analytics';

export function trackShow() {
  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'Show',
    label: 'news_from_main_page',
  });
}

export function trackSubscribe() {
  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'Send',
    label: 'news_from_main_page',
  });
}

export function trackRegistration() {
  ca('event', {
    name: 'oldevent',
    category: 'Registartion',
    action: 'subscription',
    label: 'news_from_main_page',
  });
}
