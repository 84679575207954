import { createMakeRequest } from '../../browser/client';

export function getRegions(query: string = '') {
  const request = createMakeRequest(window.__mainpage_api_host__ || window.location.host);

  return request({
    method: 'GET',
    uri: {
      path: '/cian-api/site/v1/search-regions-cities-simplified/',
      query: `text=${query}`,
    },
  }).then((response: IResponse) => {
    return response.data.items;
  });
}

export interface IRegion {
  id: number;
  displayName: string;
}

export interface IResponse {
  status: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
}
