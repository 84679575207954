import Cache from 'lru-cache';

import { IDependencies, ILruCacheParameters, TCacheKey } from './types';
import { DEFAULT_CACHE_BYPASS, DEFAULT_CACHE_OPTIONS, DEFAULT_MAX_AGE } from './constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function lruCache<F extends (...args: any) => any>(fn: F, parameters: ILruCacheParameters<ReturnType<F>>) {
  const { key: functionKey, options = DEFAULT_CACHE_OPTIONS } = parameters;
  const cache = new Cache<TCacheKey, ReturnType<F>>(options);

  return async function funcWrapper(
    key: TCacheKey,
    dependencies: IDependencies,
    ...parameters: Parameters<F>
  ): Promise<ReturnType<F>> {
    const { config, logger, telemetry } = dependencies;

    const cacheMaxAge = config.get<number>(`${functionKey}.cache.maxAge`) || DEFAULT_MAX_AGE;
    if (cache.maxAge !== cacheMaxAge) {
      cache.reset();

      cache.maxAge = cacheMaxAge;
    }

    const cacheBypass = config.get<boolean>(`${functionKey}.cache.bypass`) || DEFAULT_CACHE_BYPASS;

    const cachedData = cache.get(key);
    if (!cacheBypass && cachedData && cache.has(key)) {
      telemetry.increment(`${functionKey}.cache.hit`);

      return cachedData;
    }

    try {
      const result: ReturnType<F> = await fn(...parameters);

      if (!cacheBypass) {
        cache.set(key, result);
      }

      telemetry.increment(`${functionKey}.cache.miss`);

      return result;
    } catch (ex) {
      if (!cacheBypass && cachedData) {
        // Если происходит ошибка при вызове функции fn и мы попадаем в catch, то предыдущее значение,
        // которое лежало в кеше, остаётся. Кроме того оно помечается как "протухшее" (maxAge: -1).
        // При настройке stale: true протухшее значение лежит там до тех пор, пока его не заберём.
        // https://github.com/isaacs/node-lru-cache
        cache.set(key, cachedData, -1);

        telemetry.increment(`${functionKey}.cache.failed`);
        logger.warning(`Failed to update ${functionKey} cache`, ex);

        return cachedData;
      }

      throw ex;
    }
  };
}
