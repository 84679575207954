import React from 'react';

import * as styles from './ChatbotButton.css';

interface IChatbotButtonProps {
  onClick: () => void;
}

export const ChatbotButton: React.FC<IChatbotButtonProps> = ({ onClick }) => {
  return <button className={styles['button']} onClick={onClick} />;
};
