import { IconButton, IconClose16, Spinner } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './ChatPopup.css';

interface IChatPopupProps {
  onClose(): void;
}

export const ChatPopup: React.FC<IChatPopupProps> = ({ onClose }) => {
  const [isLoading, setIsLoading] = React.useState(true);

  const onLoad = () => setIsLoading(false);

  const src = '/dialogs/?hostType=frame&chatbot=1&source=Home&pageType=Home';

  return (
    <div className={styles['container']}>
      <span className={styles['close']}>
        <IconButton icon={<IconClose16 />} onClick={onClose} />
      </span>
      {isLoading && (
        <span className={styles['spinner']} data-testid="spinner">
          <Spinner size={16} />
        </span>
      )}
      <iframe className={styles['iframe']} src={src} onLoad={onLoad} onError={onLoad} />
    </div>
  );
};
