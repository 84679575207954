/* tslint:disable:max-file-line-count */
import { Dispatch } from 'redux';

import { TCoordinates } from '../../../components/search_filters/filters/geo_suggest/geo_helpers';
import { TThunkAction } from '../../../types/redux';

export interface IJsonQueryTerm<T> {
  type: 'term';
  value: T;
}

export interface IJsonQueryTerms<T> {
  type: 'terms';
  value: T[];
}

export interface IJsonQueryRange {
  type: 'range';
  value: { gte?: number; lte?: number };
}

export interface IJsonQueryGeo {
  type: 'geo';
  value: IGeoValue[];
}

export type IGeoValue =
  | IJsonQueryLocation
  | IJsonQueryNewbuilding
  | IJsonQueryDeveloper
  | IJsonQueryDistrict
  | IJsonQueryUnderground
  | IJsonQueryVillage
  | IJsonQueryPolygon;

export interface IJsonQueryLocation {
  type: 'location';
  id: number;
}

export type TStringCoordinates = [string, string];

export interface IJsonQueryPolygon {
  type: 'polygon';
  coordinates: TStringCoordinates[];
  name: string;
  id?: number;
}

export interface IJsonQueryCommercial {
  bsCenterType: 'businessCenter' | 'shoppingCenter';
  bsCenter: number;
}

export interface IJsonQueryNewbuilding {
  type: 'newobject';
  id: number;
  coordinates: TCoordinates;
  locationId: number;
  text: string;
}

export interface IJsonQueryUnderground {
  type: 'underground';
  id: number;
}

export interface IJsonQueryVillage {
  type: 'village';
  id: number;
}
export interface IJsonQueryDistrict {
  type: 'district';
  id: number;
}

export interface IJsonQueryDeveloper {
  type: 'builder';
  id: number;
}

export interface IJsonPartialQuery {
  [Identifier: string]: IJsonQueryTerm<{}> | IJsonQueryTerms<{}> | IJsonQueryRange | IJsonQueryGeo;
}

export interface IJsonQuery {
  _type: string;
  bs_center?: IJsonQueryTerms<number>;
  engine_version?: IJsonQueryTerm<number>;
  building_status?: IJsonQueryTerm<number>;
  for_day?: IJsonQueryTerm<string>;
  kp_id?: IJsonQueryTerm<number>;
  room?: IJsonQueryTerms<number>;
  object_type?: IJsonQueryTerms<number>;
  office_type?: IJsonQueryTerms<number>;
  rooms_count?: IJsonQueryTerms<number>;
  price?: IJsonQueryRange;
  currency?: IJsonQueryTerm<number>;
  total_area?: IJsonQueryRange;
  site?: IJsonQueryRange;
  region?: IJsonQueryTerms<number>;
  price_sm?: IJsonQueryTerm<boolean>;
  geo?: IJsonQueryGeo;
  year?: IJsonQueryTerms<number>;
  yeargte?: IJsonQueryTerm<number>;
  hand_over?: IJsonQueryTerm<boolean>;
  status?: IJsonQueryTerms<number>;
  location?: IJsonQueryTerms<number>;
  newobject?: IJsonQueryTerms<number>;
  street?: IJsonQueryTerms<number>;
  house?: IJsonQueryTerms<number>;
  underground?: IJsonQueryTerms<number>;
  district?: IJsonQueryTerms<number>;
  highway?: IJsonQueryTerms<number>;
  builder?: IJsonQueryTerms<number>;
  suburban_offer_filter?: IJsonQueryTerm<number>;
}

export interface ISearchResponse {
  status: string;
  data: {
    fullUrlWithoutSeo: string;
    fullUrl: string;
    url: string;
    mapUrl: string;
  };
}

export const getSearchUrl = (query: IJsonQuery): TThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (dispatch: Dispatch<any>, getState, context) => {
    const url =
      query._type === 'newobjectsale' ? '/cian-api/site/v1/jk/search/meta/' : '/cian-api/site/v1/offers/search/meta/';

    return context
      .makeRequest({
        method: 'POST',
        uri: {
          path: url,
        },
        headers: [
          ['Accept', 'application/json'],
          ['Content-Type', 'application/json'],
        ],
        body: JSON.stringify(query),
      })
      .then((res: ISearchResponse) => {
        return Promise.resolve({
          searchUrl: res.data.fullUrl || res.data.fullUrlWithoutSeo || res.data.url,
          queryStringOrPathName: res.data.url,
        });
      })
      .catch((err: Error) => Promise.reject(err));
  };
};

export const getMapUrl = (query: IJsonQuery): TThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (dispatch: Dispatch<any>, getState, context) => {
    const url =
      query._type === 'newobjectsale' ? '/cian-api/site/v1/jk/search/meta/' : '/cian-api/site/v1/offers/search/meta/';

    return context
      .makeRequest({
        method: 'POST',
        uri: {
          path: url,
        },
        headers: [
          ['Accept', 'application/json'],
          ['Content-Type', 'application/json'],
        ],
        body: JSON.stringify(query),
      })
      .then((res: ISearchResponse) => {
        return Promise.resolve(res.data.mapUrl);
      })
      .catch((err: Error) => Promise.reject(err));
  };
};
