import * as styles from './RoundButtonLink.css';
import { IconActionArrowRight16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

interface IRoundButtonLinkProps {
  url: string;
}

export const RoundButtonLink = ({ url }: IRoundButtonLinkProps) => {
  return (
    <a href={url} className={styles['link']}>
      <IconActionArrowRight16 color="current_color" />
    </a>
  );
};
