import {
  ENewbuildingRecommendationsLoadingStatus,
  INewbuildingsRecommendationsState,
} from '../../types/newbuildingRecommendations';
import { TReduxActions } from '../../types/redux';
import { EGetNewbuildingRecommendationsStatusActionType } from '../../actions/newbuildingRecommendations/types';

export const INITIAL_STATE: INewbuildingsRecommendationsState = {
  status: ENewbuildingRecommendationsLoadingStatus.Initial,
  data: null,
};

export const newbuildingRecommendationsReducer = (
  state: INewbuildingsRecommendationsState = INITIAL_STATE,
  action: TReduxActions,
): INewbuildingsRecommendationsState => {
  switch (action.type) {
    case EGetNewbuildingRecommendationsStatusActionType.Loading:
      return {
        status: ENewbuildingRecommendationsLoadingStatus.Loading,
        data: null,
      };

    case EGetNewbuildingRecommendationsStatusActionType.Failed:
      return {
        status: ENewbuildingRecommendationsLoadingStatus.Failure,
        data: null,
      };

    case EGetNewbuildingRecommendationsStatusActionType.Succeed:
      return {
        status: ENewbuildingRecommendationsLoadingStatus.Success,
        data: action.payload,
      };

    default:
      return state;
  }
};
