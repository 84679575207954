import { TMainPageState } from '../../types/mainpage';
import { DEFAULT_REGIONS_DATA } from './defaultRegionsData';

export const MAINPAGE_DEFAULT_STATE: TMainPageState = {
  agencies: [],
  contentPartData: [],
  developers: [],
  geoSeoLinks: [],
  isFetching: false,
  error: false,
  newSeoUrls: [],
  promoBlocks: [],
  realtors: [],
  regionsData: {
    cities: [],
    regions: DEFAULT_REGIONS_DATA,
    subdomainCityId: 0,
    subdomainRegionAllowed: true,
    subdomainRegionCoordinates: undefined,
    subdomainRegionId: 0,
    subdomainRegionInflectedTitle: '',
    subdomainRegionInflectedTitleLoct: '',
    subdomainRegionTitle: '',
  },
  section: '',
  seoData: {
    description: '',
    h1: '',
    keywords: '',
    seoText: [],
    seoTextHeading: '',
    title: '',
  },
  top3: [],
  urls: {
    addform: '',
    agencies: '',
    developers: '',
    jks: '',
    realtors: '',
  },
};
