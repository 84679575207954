import { TReduxActions } from '../../../../shared/types/redux';
import {
  ISuburbanOfferFilter,
  ISuburbanOfferFilterAction,
  TSuburbanOfferFilter,
} from '../../../../shared/types/filters/suburban_offer_filter';

export const SUBURBAN_OFFER_FILTER_UPDATE = 'search/SUBURBAN_OFFER_FILTER_UPDATE';

const initialState: ISuburbanOfferFilter = {
  suburbanOfferFilter: 'all',
  isDirty: false,
};

export const suburbanOfferFilterReducer = (state = initialState, action: TReduxActions) => {
  switch (action.type) {
    case SUBURBAN_OFFER_FILTER_UPDATE: {
      const newState = Object.assign({}, state, action.payload);
      if (!newState.isDirty) {
        newState.suburbanOfferFilter = 'all';
      }

      return newState;
    }

    default:
      return state;
  }
};

export const setSuburbanOfferFilter = (
  suburbanOfferFilter: TSuburbanOfferFilter = 'all',
): ISuburbanOfferFilterAction => {
  return {
    type: SUBURBAN_OFFER_FILTER_UPDATE,
    payload: {
      suburbanOfferFilter,
    },
  };
};

export const setSuburbanOfferFilterDirty = (isDirty: boolean): ISuburbanOfferFilterAction => {
  return {
    type: SUBURBAN_OFFER_FILTER_UPDATE,
    payload: {
      isDirty,
    },
  };
};
