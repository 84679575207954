import { IAdFoxBannerOnLoadData } from '@cian/adfox-component';

interface IAdFoxBannerContext {
  [key: string]: string;
}

export function isLoaderContext(
  context: IAdFoxBannerContext | IAdFoxBannerOnLoadData,
): context is IAdFoxBannerOnLoadData {
  return 'bundleName' in context && 'bundleParams' in context;
}
