import * as React from 'react';
import join from 'lodash/join';
import map from 'lodash/map';

import { onInsideClick } from './helpers';
import { OptionalDropdown } from './optional_dropdown';
import { filterChange, filterClick } from './tracking';

const s = require('../search_filters.css');

interface IProps {
  values: string[];
  onChange(value: string[]): void;
}

const formatValue = (value: string) => {
  if (value === 'done') {
    return 'Сдан';
  } else if (value === '3') {
    return `${new Date().getFullYear() + 3} и позднее`;
  } else {
    return `${new Date().getFullYear() + Number(value)}`;
  }
};

const getValues = () =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  map(['done', '0', '1', '2', '3'], (value: any) => {
    return {
      value,
      label: formatValue(value),
    };
  });

const formatLabel = (values: string[]) => {
  if (values.length === 0) {
    return 'Срок сдачи';
  } else {
    return join(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map(values, (value: any) => formatValue(value)),
      ', ',
    );
  }
};

export const YearFilter = (props: IProps) => (
  <div
    className={`${s['c-filters-field']} ${s['c-filters-field-year']}`}
    onClick={onInsideClick(() => filterClick('FinishDate'))}
  >
    <OptionalDropdown
      value={props.values}
      multiple={true}
      label={formatLabel(props.values)}
      popupContentStyle={s['c-filters-operations-content-check']}
      onChange={(values: string[]) => {
        filterChange('FinishDate', values.sort().join(',') || 'deleted');
        props.onChange(values);
      }}
      values={getValues()}
    />
  </div>
);
