import { fetchFilterVacancies, IFilterVacanciesRequest } from '../../repositories/vacancies/v1/filter-vacancies';

import { mappers } from './mappers';
import { EVacanciesStatus, VacanciesDependencies, IVacanciesResult } from './types';

export const getFilterVacancies = async (
  dependencies: VacanciesDependencies,
  parameters: IFilterVacanciesRequest,
): Promise<IVacanciesResult> => {
  const { httpApi, logger } = dependencies;

  try {
    return (await fetchFilterVacancies({
      httpApi,
      parameters,
      mappers,
    })) as IVacanciesResult;
  } catch (error) {
    logger.error(error, {
      degradation: 'Запрашивались вакансии',
      description: 'vacancies /v1/filter-vacancies/ rejection error',
      requestParameters: JSON.stringify(parameters),
    });

    return {
      data: {
        pageCount: 0,
        vacancies: [],
        vacancyCount: 0,
      },
      message: 'vacancies /v1/filter-vacancies/ rejection error',
      status: EVacanciesStatus.CriticalError,
    };
  }
};
