import { TReduxActions } from '../../../../shared/types/redux';
import { ITopThree, ITopThreeAction } from '../../../../shared/types/top_three';

/** Action Types */
export const SALE_ACTION = 'top_three/SALE_ACTION';
export const RENT_ACTION = 'top_three/RENT_ACTION';

/** Initial State */
const initialState: ITopThree = {
  groupSaleSelected: 'flat_sale',
  groupRentSelected: 'flat_rent',
};

/** Reducer */
export const topThreeReducer = (state = initialState, action: TReduxActions) => {
  switch (action.type) {
    case SALE_ACTION:
      return Object.assign({}, state, {
        groupSaleSelected: action.payload.groupSelected,
      });

    case RENT_ACTION:
      return Object.assign({}, state, {
        groupRentSelected: action.payload.groupSelected,
      });

    default:
      return state;
  }
};

/** Action Creator */
export const setSaleGroupSelected = (index: string): ITopThreeAction => {
  return {
    type: SALE_ACTION,
    payload: {
      groupSelected: index,
    },
  };
};

/** Action Creator */
export const setRentGroupSelected = (index: string): ITopThreeAction => {
  return {
    type: RENT_ACTION,
    payload: {
      groupSelected: index,
    },
  };
};
