import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_MAX_AREA_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';

export type TSetMaxAreaType = 'filters/set_max_area';

export interface ISetMaxArea extends Action<TSetMaxAreaType> {
  type: TSetMaxAreaType;
  maxArea: number | undefined;
}

export type TSetMaxArea = ISetMaxArea;

export function setMaxArea(maxArea: number | undefined): TThunkAction {
  return dispatch => {
    dispatch<TSetMaxArea>({
      type: FILTERS_SET_MAX_AREA_TYPE,
      maxArea,
    });

    dispatch(getMeta());
  };
}
