import { TReduxActions } from '../../../types/redux';
import {
  FILTERS_RESET_ROOM_TYPE_TYPE,
  FILTERS_SELECT_ROOM_TYPE_TYPE,
  FILTERS_SET_LAST_ROOMS_TYPES_TYPE,
} from '../../../constants/action_types';
import { JsonQuery } from '../../../utils/jsonQuery';
import { defaultState } from './defaultState';
import { IJsonQuery } from '../../../../packages/api-models/common/json_query';

export function selectRoomTypeReducer(jsonQuery: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  switch (action.type) {
    case FILTERS_SELECT_ROOM_TYPE_TYPE: {
      const roomTypes = (jsonQuery.room && jsonQuery.room.value) || [];
      const nextRoomTypes = roomTypes.includes(action.roomType)
        ? roomTypes.filter(type => type !== action.roomType)
        : roomTypes.concat(action.roomType);

      return new JsonQuery(jsonQuery).setRoomTypes(nextRoomTypes).getSerializable();
    }

    case FILTERS_RESET_ROOM_TYPE_TYPE: {
      return new JsonQuery(jsonQuery).setRoomTypes([]).getSerializable();
    }

    case FILTERS_SET_LAST_ROOMS_TYPES_TYPE: {
      return new JsonQuery(jsonQuery).setRoomTypes(action.payload).getSerializable();
    }
  }

  return jsonQuery;
}
