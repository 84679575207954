import * as React from 'react';

const s = require('./column_text_block.css');

interface IProps {
  title: string;
  columns: string[];
}

const ColumnTextBlock = (props: IProps) => (
  <div className="cg-col-18">
    <div className="cg-row">
      <div className="cg-col-24">
        <h1 className={s['c-seo-text-title']}>{props.title}</h1>
      </div>
    </div>
    <div className="cg-row">
      {props.columns.map((col: string, i: number) => (
        <div
          className={`${s['c-seo-text-col']} cg-col-12`}
          key={`column_${i}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: col }}
        />
      ))}
    </div>
  </div>
);

export { ColumnTextBlock };
