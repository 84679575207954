/**
 * Определяет по типу объекта плейсхолдер для гео поиска
 * Если загородка - без ЖК, но с ЖД
 * Если городская - с ЖК и ЖД
 * Если не загородка и не городская, значит коммерческая - без ЖД и ЖК
 * @param buildingType тип объекта (Квартира, гараж, дом и т.д.)
 */
export function getMainPlaceholder(buildingType: string) {
  const suburbanBuildingTypes = ['house', 'housepart', 'townhouse', 'sector', 'houserent'];
  const urbanBuildingTypes = ['flat', 'secondary_flat', 'new_flat', 'room', 'part', 'bed'];

  const isSuburban = suburbanBuildingTypes.includes(buildingType);
  const isUrban = urbanBuildingTypes.includes(buildingType);

  if (isSuburban) {
    return 'Город, адрес, метро, район, ж/д, шоссе';
  }

  if (isUrban) {
    return 'Город, адрес, метро, район, ж/д, шоссе или ЖК';
  }

  return 'Город, адрес, метро, район или шоссе';
}
