/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetPromoBannersModel,
  IGetPromoBannersRequest,
  IMappers,
  IGetPromoBannersResponse,
  IGetPromoBannersResponseError,
  TGetPromoBannersResponse,
} from './types';

const defaultConfig: TGetPromoBannersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'newbuilding-promos',
  pathApi: '/v1/get-promo-banners/',
  hostType: 'api',
} as TGetPromoBannersModel;

function createGetPromoBannersModel(parameters: IGetPromoBannersRequest): TGetPromoBannersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetPromoBannersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetPromoBannersRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetPromoBanners<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetPromoBannersOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetPromoBannersResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetPromoBannersModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetPromoBannersResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetPromoBannersResponseError);
    }
  }

  return { response, statusCode } as TGetPromoBannersResponse;
}

export { defaultConfig, createGetPromoBannersModel, fetchGetPromoBanners };
