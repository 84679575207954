import { batch } from 'react-redux';
import { Action } from 'redux';
import { TThunkAction } from '../../types/redux';
import { getMeta } from '../filters';

export const SET_FILTER_BUSINESS_APPOINTMENTS_IDS =
  'businessAppointments/set_filter_business_appointments_ids' as const;

export interface ISetFilterBusinessAppointmentsIds extends Action<typeof SET_FILTER_BUSINESS_APPOINTMENTS_IDS> {
  payload: Array<number>;
}

export function setFilterBusinessAppointmentsIds(payload: ISetFilterBusinessAppointmentsIds['payload']): TThunkAction {
  return dispatch => {
    batch(() => {
      dispatch<ISetFilterBusinessAppointmentsIds>({
        type: SET_FILTER_BUSINESS_APPOINTMENTS_IDS,
        payload,
      });

      dispatch(getMeta());
    });
  };
}
