import cx from 'classnames';
import * as React from 'react';
import { Image, UIHeading2, UIText1 } from '@cian/ui-kit';
import { TBannerTheme } from '../utils/bannerTypeToTheme';

import * as styles from './TopPromoBanner.css';

export interface IBannerTopPromo {
  title: string;
  provider: string;
  footer: React.ReactElement;
  labels: React.ReactElement | null;
  imageUrl?: string | null;
  theme: TBannerTheme;
  url: string;
}

export const TopPromoBanner = ({ title, provider, labels, imageUrl, footer, theme, url }: IBannerTopPromo) => {
  return (
    <a href={url} className={cx(styles['banner'], styles[theme])}>
      <div className={styles['content']}>
        {imageUrl && (
          <div className={styles['illustration']}>
            <Image width="56px" height="56px" src={imageUrl} />
          </div>
        )}
        <div className={styles['description']}>
          <UIText1>{provider}</UIText1>
          <div className={styles['title']}>
            <UIHeading2>{title}</UIHeading2>
          </div>
          <div className={styles['labels']}>{labels}</div>
        </div>
      </div>
      <div className={styles['footer']}>{footer}</div>
    </a>
  );
};
