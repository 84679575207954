import * as React from 'react';
import cx from 'classnames';
import * as styles from './style.css';
import { ArticleHeading5 } from '@cian/ui-kit';
import { INewbuildingSeoBlockData } from '../../types/newbuildingSeoBlock';

interface IFullLinksBlockProps {
  isActive: boolean;
  seoBlock: INewbuildingSeoBlockData;
}

export const FullLinksBlock = ({ isActive, seoBlock }: IFullLinksBlockProps) => {
  if (!isActive) {
    return null;
  }

  return (
    <div data-testid="NewbuildingSeoFullLinksBlock">
      <div className={styles['heading']}>
        <ArticleHeading5 as="h5">{seoBlock.title}</ArticleHeading5>
      </div>
      <ul className={cx(styles['list'], styles['grid'])}>
        {seoBlock.urls.map((link, linkIndex) => (
          <li key={`${seoBlock.title}-${linkIndex}`}>
            <a className={styles['link']} href={link.url} title={link.name}>
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
