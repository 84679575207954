import {
  IconMetroMsk16,
  IconMetroSpb16,
  IconMetroEkb16,
  IconMetroKzn16,
  IconMetroNN16,
  IconMetroNovosib16,
  IconMetroSam16,
} from '@cian/ui-kit';
import { IIconProps } from '@cian/ui-kit-design-tokens/internal';
import * as React from 'react';
import { TUndergroundType } from '../../../../../../types/newbuildingRecommendations';

const REGION_METRO_ICON_MAP = new Map<TUndergroundType, React.ComponentType<IIconProps>>([
  ['mow', IconMetroMsk16],
  ['spb', IconMetroSpb16],
  ['ekb', IconMetroEkb16],
  ['kzn', IconMetroKzn16],
  ['nn', IconMetroNN16],
  ['nsk', IconMetroNovosib16],
  ['smr', IconMetroSam16],
]);

export function getRegionUndergroundIcon(undergroundType: TUndergroundType): React.ComponentType<IIconProps> {
  const availableRegionId = REGION_METRO_ICON_MAP.has(undergroundType);

  return (availableRegionId && REGION_METRO_ICON_MAP.get(undergroundType)) || IconMetroMsk16;
}
