import * as React from 'react';
import { Link } from '../link/link';
import { IPromoLink } from '../../../shared/types/promo';
import classNames from 'classnames';

const s = require('./promo_image.css');

interface IProps {
  id: string;
  extraLinks?: IPromoLink[];
}

export const PROMO_CLASS_NAMES: { [key: string]: string } = {
  indexFlatSale: 'c-promo-block-image-content_sale',
  indexFlatRent: 'c-promo-block-image-content_rent',
  indexNewobjects: 'c-promo-block-image-content_newbuidings',
  indexForDay: 'c-promo-block-image-content_daily-rent',
  indexSuburban: 'c-promo-block-image-content_suburban',
  indexCommerical: 'c-promo-block-image-content_commercial',
};

export const PromoImage = (props: IProps) => (
  <div className={classNames(s['c-promo-block-image-content'], s[PROMO_CLASS_NAMES[props.id]])}>
    {props.extraLinks && (
      <div className={s['c-promo-catalog-list']}>
        {props.extraLinks.map((link: IPromoLink, i: number) => (
          <Link key={i} href={link.url} className={`${s['c-promo-catalog-list-item']}`} title={link.h1}>
            {link.title}
          </Link>
        ))}
      </div>
    )}
  </div>
);
