import { FDealType, FOfferType } from './category';

interface IGetGeoPlaceholderParams {
  dealType: FDealType;
  offerType: FOfferType;
}

export function getGeoPlaceholder(params: IGetGeoPlaceholderParams): string {
  const { dealType, offerType } = params;

  if (dealType === FDealType.RentDaily) {
    return 'Куда вы хотите поехать?';
  }

  if ((offerType & FOfferType.Urban) !== 0) {
    return 'Город, адрес, метро, район, ж/д, шоссе или ЖК';
  }

  if ((offerType & FOfferType.Suburban) !== 0) {
    return 'Город, адрес, метро, район, ж/д или шоссе';
  }

  return 'Город, адрес, метро, район или шоссе';
}
