import intersection from 'lodash/intersection';
import { IBuildingTypes, IBuildingTypesConfig } from '../../shared/types/filters/building_types';
import { ISearchConfig } from '../../shared/types/search';
import { AbstractFlatSearchConfig, SearchConfig } from '../../shared/containers/search_block/search_config';
import { BuildingTypes, BuildingTypesWithAbstractFlat } from '../../shared/components/search_filters/building_types';
import { IApplicationState } from '../types/redux';

/*
  0: Контрольная группа
  1: Селектор с выбранным "Во вторичке" по умолчанию и старым гео-саджестом
  2: Селектор с выбранным "В категории" по умолчанию и старым гео-саджестом
  3: Старый селектор и новый гео-саджест
*/

export const experimentSections = ['index', 'sale'];

const abstractFlatTypeFromBuildingType = (buildingTypes: string[]): string[] => {
  if (intersection(['new_flat', 'secondary_flat'], buildingTypes).length) {
    return ['flat'];
  }

  return buildingTypes;
};

export const getFiltersConfiguration = (
  state: IApplicationState,
  section: string,
): {
  buildingType: IBuildingTypes;
  actualBuildingType: IBuildingTypes;
  buildingTypes: IBuildingTypesConfig;
  searchConfig: ISearchConfig;
} => {
  let searchConfig = SearchConfig;
  let buildingType = state.buildingType;
  let buildingTypes = BuildingTypes;

  if (experimentSections.includes(section)) {
    // подмена new_flat и secondary_flat на flat для первого дропдауна
    // при наличии отдельного дропдауна выбора типа квартиры
    buildingType = {
      buildingType: abstractFlatTypeFromBuildingType(state.buildingType.buildingType),
      isDirty: false,
    };

    // вынесение типов "квартира во вторичке" "квартира в новостройке"
    // для первого дропдауна при наличии отдельного дропдауна выбора типа квартиры
    buildingTypes = BuildingTypesWithAbstractFlat;

    // показ дропдауна выбора типа квартиры
    // для типа сделки "купить" и трех типов квартир (общая / в новостройке / во вторичке)
    searchConfig = AbstractFlatSearchConfig;
  }

  return {
    actualBuildingType: state.buildingType,
    buildingType,
    buildingTypes,
    searchConfig,
  };
};
