import { createReduxStore } from '../../shared/utils/redux';
import { patchCookieRegion } from '../utils/patchCookieRegion';
import { IApplicationState } from '../../shared/types/redux';
import { IApplicationContext } from '../../shared/types/application';

export function createApplicationState(context: IApplicationContext) {
  const { config } = context;

  const initialState = config.getStrict<IApplicationState>('initialState');

  const store = createReduxStore(initialState, context, true);

  patchCookieRegion(store);

  return store;
}
