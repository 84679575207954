import * as React from 'react';
import { ISpecialists } from '../../types/specialist';
import { Link } from '../link/link';
import { Specialist } from '../specialist/specialist';

const s = require('./specialist_list.css');

interface IProps {
  popularPresented: boolean;
  specialists: ISpecialists;
  onItemClick(): void;
}

export const SpecialistList = (props: IProps) => (
  <div className={s['c-specialists-section']}>
    <h3 className={s['c-specialists-section-title']}>{props.specialists.title}</h3>
    <div className="cg-row">
      {props.specialists.specialists.map(specialist => (
        <div
          className={`${s['c-specialists-section-info']}
                          ${props.popularPresented ? 'cg-col-24 cg-col-xs-8' : 'cg-col-8'}`}
          key={specialist.id}
        >
          <Specialist
            onClick={() => props.onItemClick()}
            specialist={specialist}
            isRoundPhoto={props.specialists.isRoundPhoto}
            getSummary={props.specialists.getSummary}
          />
        </div>
      ))}
    </div>
    <Link href={props.specialists.url} onClick={() => props.onItemClick()} className={`${s['c-specialists-all']}`}>
      {props.specialists.urlTitle}
    </Link>
  </div>
);
