import { IMultiSuggestResult, IResultSuggestions, ILocationSuggest } from '@cian/geosuggest-widget';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import { getStructuredSuggestions, IStructuredSuggestRequest } from './structured_suggest';

// eslint-disable-next-line import/no-restricted-paths
import { loadYMapsApi } from '../../browser/utils/ymaps';

export interface IAddressSuggestionOptions {
  boundedBy: [IYmapsCoord, IYmapsCoord];
  results: number;
}

export interface IAddressSuggestion {
  displayName: string;
  type: string;
  value: string;
  hl: IYmapsCoord[];
}

let suggestions: IResultSuggestions = {};

function getRailways(railways: ILocationSuggest[], offerType: string): ILocationSuggest[] | undefined {
  return offerType === 'commercial' ? undefined : railways;
}

export interface IMultiSuggestParams {
  structured: IStructuredSuggestRequest;
  yandex: {
    value: string;
    options?: IAddressSuggestionOptions;
  };
  buildingType: string;
}

export function getMultiSuggest(params: IMultiSuggestParams): Promise<IMultiSuggestResult> {
  const {
    yandex: { value, options },
    structured,
  } = params;
  const yandexSuggest = loadYMapsApi({ require: ['suggest'] })
    .then(ymaps => ymaps.suggest(`Россия, ${value}`, options))
    .then(items => items || [])
    .catch(() => {});
  const structuredSuggest = getStructuredSuggestions(structured).catch(() => {});
  const promises = [yandexSuggest, structuredSuggest];

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Promise.all(promises as any)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((results: any[]) => {
        const [yandex, structured] = results;
        suggestions = {};
        if (structured && structured.suggestions) {
          const {
            businessCenters,
            cities,
            districts,
            newbuildings,
            shoppingCenters,
            undergrounds,
            railways,
            villages,
            roads,
          } = structured.suggestions;

          suggestions = {
            businessCenters: businessCenters && businessCenters.items,
            cities: cities && cities.items,
            districts: districts && districts.items,
            newbuildings: newbuildings && newbuildings.items,
            railways: railways && getRailways(railways.items, structured.offerType),
            shoppingCenters: shoppingCenters && shoppingCenters.items,
            undergrounds: undergrounds && undergrounds.items,
            villages: (villages && villages.items) || undefined,
            roads: roads && roads.items,
          };
        }

        suggestions = omitBy(suggestions, isEmpty);

        if (yandex && !isEmpty(yandex)) {
          suggestions.addresses = Object.keys(suggestions).length ? yandex.slice(0, 5) : yandex;
        }

        if (!suggestions.addresses && !(structured && structured.suggestions)) {
          throw {};
        }

        return {
          suggestions,
          sortOrder: isEmpty(suggestions) ? [] : (structured && structured.sortOrder) || ['addresses'],
        };
      })
  );
}
