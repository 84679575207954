import { TReduxActions } from '../../../types/redux';
import { FILTERS_SET_REGION_ID_TYPE } from '../../../constants/action_types';
import { JsonQuery } from '../../../utils/jsonQuery';
import { defaultState } from './defaultState';
import { IJsonQuery } from '../../../../packages/api-models/common/json_query';

export function setRegionReducer(jsonQuery: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  switch (action.type) {
    case FILTERS_SET_REGION_ID_TYPE:
      return new JsonQuery(jsonQuery).setRegion(action.region).getSerializable();
  }

  return jsonQuery;
}
