import * as React from 'react';
import { SummaryPromoLink } from './summary_promo_link';
import { IPromoLink } from '../../../types/promo';

interface IProps {
  id: string;
  title: string;
  titleLink?: IPromoLink;
  links: IPromoLink[];
  type?: string;
  backgroundClassName: string;
  blockClassName: string;
}

const s = require('../vertical_promo_blocks.css');

export class SummaryPromoBlock extends React.Component<IProps> {
  public render() {
    return (
      <div
        className={`${s['c-new-promo-block']}
                        ${s[this.props.backgroundClassName]}
                        ${this.props.blockClassName}`}
      >
        <div className={s['c-new-promo-block-content']}>
          {/* eslint-disable-next-line react/no-danger */}
          <h5 className={s['c-new-promo-block-title']} dangerouslySetInnerHTML={{ __html: this.props.title }} />
          <div className={s['c-new-promo-block-links']}>
            {this.props.links.map((link: IPromoLink, i: number) => (
              <SummaryPromoLink key={`promo-link-${i}`} {...link} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
