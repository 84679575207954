import * as R from 'ramda';
import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_REGION_ID_TYPE } from '../../constants/action_types';

export type TSetRegionType = 'filters/set_region';

export interface ISetRegion extends Action<TSetRegionType> {
  type: TSetRegionType;
  region: number[];
}

export type TSetRegion = ISetRegion;

export function setRegion(region: number[]): TThunkAction {
  return (dispatch, getState) => {
    const {
      filters: { jsonQuery },
    } = getState();
    if (jsonQuery.region && R.equals(jsonQuery.region.value, region)) {
      return;
    }

    dispatch<TSetRegion>({
      type: FILTERS_SET_REGION_ID_TYPE,
      region,
    });
  };
}
