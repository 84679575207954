import { IApplicationState } from '../../types/redux';
import {
  ENewbuildingRecommendationsLoadingStatus,
  INewbuildingsRecommendations,
} from '../../types/newbuildingRecommendations';

export const getNewbuildingRecommendationsLoadingStatusSelector = (
  state: IApplicationState,
): ENewbuildingRecommendationsLoadingStatus => {
  return state.newbuildingRecommendations.status;
};

export const getNewbuildingRecommendationsSelector = (
  state: IApplicationState,
): INewbuildingsRecommendations | null => {
  return state.newbuildingRecommendations.data;
};
