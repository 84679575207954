import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_DEAL_TYPE_TYPE } from '../../constants/action_types';
import { FDealType } from '../../utils/category';
import { getMeta } from './getMeta';

export type TSetDealTypeType = 'filters/set_deal_type';

export interface ISetDealType extends Action<TSetDealTypeType> {
  type: TSetDealTypeType;
  dealType: FDealType;
}

export type TSetDealType = ISetDealType;

export function setDealType(dealType: FDealType): TThunkAction {
  return dispatch => {
    dispatch<TSetDealType>({
      type: FILTERS_SET_DEAL_TYPE_TYPE,
      dealType,
    });

    dispatch(getMeta());
  };
}
