import * as R from 'ramda';
import { plural } from '@cian/utils';

import { beautify } from '../../utils/number';

const SHRUG = '¯\\_(ツ)_/¯';

export function getWorkplaceCountLabel(min?: number, max?: number): string | null {
  if (min === 0 && max === 0) {
    return SHRUG;
  }

  if (!R.isNil(min) && R.isNil(max)) {
    return `от ${beautify(min)} ${plural(min, ['места', 'мест', 'мест'])}`;
  }

  if (R.isNil(min) && !R.isNil(max)) {
    return `до ${beautify(max)} ${plural(max, ['места', 'мест', 'мест'])}`;
  }

  if (!R.isNil(min) && !R.isNil(max)) {
    return `${beautify(min)} - ${beautify(max)} ${plural(max, ['место', 'места', 'мест'])}`;
  }

  return null;
}
