import { useDispatch } from 'react-redux';
import { TThunkDispatch } from '../../../types/redux';
import * as React from 'react';
import { setBedsCount } from '../../../actions/filters/beds';
import { getMeta } from '../../../actions/filters';

export const useHandleChange = () => {
  const dispatch = useDispatch<TThunkDispatch>();

  return React.useCallback(
    (value: number) => {
      dispatch(setBedsCount(value > 1 ? value : undefined));
      dispatch(getMeta());
    },
    [dispatch],
  );
};
