/* eslint-disable @typescript-eslint/no-explicit-any, max-lines */
import { IAdFoxBannerOnLoadData } from '@cian/adfox-component';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { compose } from 'ramda';
import includes from 'lodash/includes';
import { ITelemetryLogger } from '@cian/telemetry/shared';
import { IConfig } from '@cian/config/shared';

import { setCookie } from '@cian/utils/lib/browser/cookie';
import { isLoaderContext, parseAdfoxParams } from '../../utils/adfox';

import * as Analytics from '../../utils/analytics_helper';
import { matchQueryParams } from './query_params_matcher';
import { renderFilters } from './search_config';
import { IApplicationState } from '../../types/redux';
import { shouldNewFiltersShow } from '../../utils/filters';
import { TUser } from '../../types/user';
import { IHousingInfo } from '../../types/housing_info';
import { ISearchType } from '../../types/filters/type';
import { ISquare } from '../../types/filters/square';
import { IBuildingTypes, IBuildingTypesConfig } from '../../types/filters/building_types';
import { IRooms } from '../../types/filters/rooms';
import { ISearchPrice } from '../../types/filters/price';
import { IYear } from '../../types/filters/year';
import { IRegionsData } from '../../types/regions_data';
import { IUserGeo } from '../../types/filters/user_geo';
import { ISearchConfig } from '../../types/search';
import { ISuburbanOfferFilter } from '../../types/filters/suburban_offer_filter';
import { IApplicationContext } from '../../types/application';
import { IDomain } from '../../reducers/modules/domain/domain';
import { getSearchUrl, IJsonQueryTerms, getMapUrl } from '../../reducers/modules/search/search';
import { getHousingInfo } from '../../reducers/modules/housing_info';
import { SearchFilters } from '../../components/search_filters/search_filters';
import { FiltersBlock } from '../../components/filters_block/filters_block';
import { getFiltersConfiguration } from '../../utils/ab_mainpage_newbuilding_search';
import { declineNumeric, formatPrice } from '../../utils/format_helper';
import { TypeFilter } from '../filters/type_filter';
import { BuildingTypeFilter } from '../filters/building_type_filter';
import { withApplicationContext } from '../../utils/applicationContext';

interface IProps {
  abGroup: number;
  // tslint:disable-next-line: no-any
  dispatch?: Dispatch<any>;
  housingInfo: IHousingInfo;
  type: ISearchType;
  buildingType: IBuildingTypes;
  actualBuildingType: IBuildingTypes;
  square: ISquare;
  subdomain: string;
  rooms: IRooms;
  price: ISearchPrice;
  userGeo: IUserGeo;
  year: IYear;
  regionsData: IRegionsData;
  domain: IDomain;
  section: string;
  title: string;
  user: TUser;
  searchConfig: ISearchConfig;
  buildingTypes: IBuildingTypesConfig;
  suburbanOfferFilter: ISuburbanOfferFilter;
  isNewFilters: boolean;
  isDobroshrift: boolean;
  telemetry: ITelemetryLogger;
  config: IConfig;
  defaultBackground: string;
}

type TContextProps = Pick<IProps, 'telemetry' | 'config'>;

type TStateProps = Omit<IProps, 'telemetry' | 'config'>;

interface IState {
  isApartment?: boolean;
  backgroundImageUrl?: string;
  backgroundShown: boolean;
  mediaNote?: string;
}

interface IFilterProps {
  section: string;
}

interface ISearchUrl {
  searchUrl: string;
  queryStringOrPathName: string;
}

const MSC_REGION_IDS = [-1, 1, 4593];
const SPB_REGION_IDS = [-2, 2, 4588];
const TOP_REGIONS = [...MSC_REGION_IDS, ...SPB_REGION_IDS];

export class FiltersComponent extends React.Component<IProps, IState> {
  private timer: ReturnType<typeof setTimeout>;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      backgroundShown: false,
      backgroundImageUrl: undefined,
    };
  }

  public componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({
        backgroundImageUrl: undefined,
        backgroundShown: true,
        isApartment: false,
      });
    }, 1500);
  }

  // istanbul ignore next
  public submitSearch = () => {
    if (!this.props.dispatch) {
      return;
    }

    const params = matchQueryParams(
      this.props.type,
      this.props.actualBuildingType,
      this.props.square,
      this.props.rooms,
      this.props.price,
      this.props.userGeo,
      this.props.year,
      this.props.section === 'new-buildings',
      this.props.suburbanOfferFilter,
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.props.dispatch(getSearchUrl(params)) as any).then((url: ISearchUrl) => {
      let searchUrl = url.searchUrl;
      // istanbul ignore next
      if (
        (params.region && includes(TOP_REGIONS, (params.region as IJsonQueryTerms<number>).value[0])) ||
        this.props.section === 'new-buildings'
      ) {
        searchUrl = replaceSearchPath(params._type, searchUrl, this.props.section);
      }

      Analytics.trackSearchEvent(
        'button_search',
        this.props.section,
        url.queryStringOrPathName,
        params,
        this.props.user,
        this.props.abGroup,
      );

      /**
       * Ставим сессионую гео-куку на основе полученных данных от гео фильтра
       */
      const { regionId, mainTownId } = this.props.userGeo.userGeoDefinitionWithGeoFilter;

      if (regionId) {
        setCookie('session_region_id', String(regionId), { secure: false });
      }

      if (mainTownId) {
        setCookie('session_main_town_region_id', String(mainTownId), { secure: false });
      }

      window.location.href = searchUrl;
    });
  };
  // istanbul ignore next
  public submitMapSearch = () => {
    if (!this.props.dispatch) {
      return;
    }

    const params = matchQueryParams(
      this.props.type,
      this.props.actualBuildingType,
      this.props.square,
      this.props.rooms,
      this.props.price,
      this.props.userGeo,
      this.props.year,
      this.props.section === 'new-buildings',
      this.props.suburbanOfferFilter,
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.props.dispatch(getMapUrl(params)) as any).then((url: string) => {
      Analytics.trackSearchEvent('search_on_map', this.props.section, url, params, this.props.user, this.props.abGroup);

      Analytics.clickSearchOnMapButtonOnFilter(this.props.section, url);

      window.location.href = url;
    });
  };

  public render() {
    let mediaInfoText: string | undefined;
    const hi = this.props.housingInfo;
    // istanbul ignore next
    if (hi.offers_count) {
      mediaInfoText = `В продаже ${formatPrice(hi.offers_count)}
     ${declineNumeric(
       hi.offers_count,
       this.state.isApartment ? ['апартамент', 'апартамента', 'апартаментов'] : ['квартира', 'квартиры', 'квартир'],
     )} по цене от ${formatPrice(hi.min_price2)} ₽`;
    }

    return (
      <FiltersBlock
        section={this.props.section}
        backgroundShown={this.state.backgroundShown}
        mediaInfoText={mediaInfoText}
        backgroundImageUrl={this.state.backgroundImageUrl}
        defaultBackgroundImageUrl={this.props.defaultBackground}
        mediaNote={this.state.mediaNote}
        onLoadAdfox={this.onLoadAdfox}
        onStubAdfox={this.onStubAdfox}
        isNewFilters={this.props.isNewFilters}
      >
        <SearchFilters
          isDobroshrift={this.props.isDobroshrift}
          title={this.props.title}
          onSubmit={this.submitSearch}
          onMapSubmit={this.submitMapSearch}
        >
          {this.props.section !== 'new-buildings' && (
            <TypeFilter buildingTypes={this.props.buildingTypes} searchConfig={this.props.searchConfig} />
          )}
          {this.props.section !== 'new-buildings' && (
            <BuildingTypeFilter
              buildingType={this.props.buildingType.buildingType}
              buildingTypes={this.props.buildingTypes}
              searchConfig={this.props.searchConfig}
            />
          )}
          {renderFilters(
            this.props.type.searchType,
            this.props.buildingType.buildingType,
            this.props.section === 'new-buildings',
            this.props.searchConfig,
          )}
        </SearchFilters>
      </FiltersBlock>
    );
  }

  private onStubAdfox = () => {
    clearTimeout(this.timer);
    this.setState({
      backgroundShown: true,
      isApartment: false,
    });
  };

  // istanbul ignore next
  private onLoadAdfox = (
    context:
      | {
          newBuildingId: string;
          isApartment: string;
          bgImageUrl: string;
          note: string;
          analyticsBannerId: string;
        }
      | IAdFoxBannerOnLoadData,
  ) => {
    const params = isLoaderContext(context) ? parseAdfoxParams(context) : context;

    if (!params) {
      return;
    }

    if (this.props.dispatch) {
      if (!this.props.housingInfo.isFetching) {
        this.props.dispatch(getHousingInfo(void 0, params.newBuildingId));
      }
      clearTimeout(this.timer);
      this.setState({
        backgroundImageUrl: params.bgImageUrl,
        backgroundShown: true,
        isApartment: !!+params.isApartment,
        mediaNote: params.note,
      });

      this.props.telemetry.increment('filters.adFox.show');
    }
  };
}

function replaceSearchPath(type: string, url: string, section: string) {
  const searchUrlRe = section === 'new-buildings' ? /\/newobjects\/list\// : /\/cat\.php/;
  const filtersFormUrl = section === 'new-buildings' ? '/newobjects/search/' : '/nd/search/';

  return url.replace(searchUrlRe, filtersFormUrl);
}

export const mapContextToProps = (context: IApplicationContext): TContextProps => ({
  telemetry: context.telemetry,
  config: context.config,
});

export const mapStateToProps = (state: IApplicationState, props: IFilterProps): TStateProps => {
  const { section } = props;
  const filtersConfiguration = getFiltersConfiguration(state, section);

  const isNewFilters = shouldNewFiltersShow({ section });

  return {
    ...filtersConfiguration,
    abGroup: state.config.abGroup,
    domain: state.domain,
    housingInfo: state.housingInfo,
    price: state.price,
    regionsData: state.mainpage.regionsData,
    rooms: state.rooms,
    section,
    square: state.square,
    subdomain: state.config.subdomain,
    title: state.mainpage.seoData.h1,
    type: state.type,
    user: state.user,
    userGeo: state.userGeo,
    year: state.year,
    suburbanOfferFilter: state.suburbanOfferFilter,
    isNewFilters,
    isDobroshrift: state.features.isDobroshriftEnabled,
    defaultBackground: state.defaultBackground,
  };
};

export const Filters = compose(
  withApplicationContext(mapContextToProps),
  connect(mapStateToProps),
)(FiltersComponent) as React.ComponentType<IFilterProps>;
