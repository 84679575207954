import { Outside } from '@cian/ui-kit/services';
import classNames from 'classnames';
import * as React from 'react';
import { FilterButton } from '../FilterButton';
import { NumberInputWithButtons } from '../NumberInputWithButtons';
import * as styles from './FilterBeds.css';
import * as commonStyles from '../FiltersCommon/FiltersCommon.css';
import { IFilterBedsProps } from './types';

export const FilterBeds = (props: IFilterBedsProps) => {
  const { label, opened, min, max, value, onClick, onChange } = props;

  return (
    <Outside active={opened} onOutside={onClick}>
      <div className={classNames(commonStyles['container'], styles['container'])} data-name="FilterBeds">
        <FilterButton tooltipDisabled label={label} isActive={opened} onClick={onClick} />

        {opened && (
          <div className={styles['dropdown']}>
            <div className={styles['heading']}>Количество гостей</div>
            <NumberInputWithButtons size="XS" value={value} onChange={onChange} min={min} max={max} />
          </div>
        )}
      </div>
    </Outside>
  );
};
