import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { GenericFilter } from './generic_filter';
import { SquareFilter as SquareFilterInput } from '../../components/search_filters/filters/square_filter';

import { setSquareFrom, setSquareTo, setSquareMetric, setDirty } from '../../reducers/modules/search/square';
import { ISquare } from '../../types/filters/square';
import { IApplicationState } from '../../types/redux';

interface IProps {
  square: ISquare;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
}

class FlatSquareFilter extends GenericFilter<IProps> {
  protected setDirty(isDirty: boolean) {
    this.props.dispatch(setDirty(isDirty));
  }

  public render() {
    return (
      <SquareFilterInput
        valueFrom={this.props.square.squareFrom}
        onChangeFrom={(value: number | undefined) => this.props.dispatch(setSquareFrom(value))}
        valueTo={this.props.square.squareTo}
        onChangeTo={(value: number | undefined) => this.props.dispatch(setSquareTo(value))}
        metric={this.props.square.metric}
        onChangeMetric={(value: string) => this.props.dispatch(setSquareMetric(value))}
        options={[{ value: 'square_meter', label: 'м²' }]}
      />
    );
  }
}

class GroundSquareFilter extends GenericFilter<IProps> {
  protected setDirty(isDirty: boolean) {
    this.props.dispatch(setDirty(isDirty));
  }

  public render() {
    return (
      <SquareFilterInput
        valueFrom={this.props.square.squareFrom}
        onChangeFrom={(value: number) => this.props.dispatch(setSquareFrom(value))}
        valueTo={this.props.square.squareTo}
        onChangeTo={(value: number) => this.props.dispatch(setSquareTo(value))}
        metric={this.props.square.metric}
        onChangeMetric={(value: string) => this.props.dispatch(setSquareMetric(value))}
        options={[{ value: 'ap', label: 'сот.' }]}
      />
    );
  }
}

class CommercialSquareFilter extends React.Component<IProps> {
  protected setDirty(isDirty: boolean) {
    this.props.dispatch(setDirty(isDirty));
  }

  public render() {
    return (
      <SquareFilterInput
        valueFrom={this.props.square.squareFrom}
        onChangeFrom={(value: number) => this.props.dispatch(setSquareFrom(value))}
        valueTo={this.props.square.squareTo}
        onChangeTo={(value: number) => this.props.dispatch(setSquareTo(value))}
        metric={this.props.square.metric}
        onChangeMetric={(value: string) => this.props.dispatch(setSquareMetric(value))}
        options={[
          { value: 'ap', label: 'сот.' },
          { value: 'hectare', label: 'га' },
        ]}
      />
    );
  }
}

const FlatSquareFilterWrapper = connect((state: IApplicationState) => ({
  square: state.square,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}))(FlatSquareFilter as any);

const GroundSquareFilterWrapper = connect((state: IApplicationState) => ({
  square: state.square,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}))(GroundSquareFilter as any);

const CommercialSquareFilterWrapper = connect((state: IApplicationState) => ({
  square: state.square,
}))(CommercialSquareFilter);

export {
  FlatSquareFilterWrapper as FlatSquareFilter,
  FlatSquareFilter as FlatSquareFilterClass,
  GroundSquareFilterWrapper as GroundSquareFilter,
  GroundSquareFilter as GroundSquareFilterClass,
  CommercialSquareFilterWrapper as CommercialSquareFilter,
  CommercialSquareFilter as CommercialSquareFilterClass,
};
