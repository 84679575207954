import { Action } from 'redux';
import { IGetPossibleAppointmentItem } from '../../repositories/announcements/entities/AnnouncementReferences/GetPossibleAppointmentItem';
import { getPossibleAppointments } from '../../services/getPossibleAppointments';
import { TThunkAction } from '../../types/redux';

export const SET_SPECIALITY_TYPES_TYPE = 'filters/set_speciality_types_type' as const;

export interface ISpecialtyTypes extends Action<typeof SET_SPECIALITY_TYPES_TYPE> {
  payload: Array<IGetPossibleAppointmentItem>;
}

export function setSpecialtyTypes(): TThunkAction {
  return async (dispatch, getState, context) => {
    const specialtyTypes = await getPossibleAppointments(context);

    dispatch<ISpecialtyTypes>({
      type: SET_SPECIALITY_TYPES_TYPE,
      payload: specialtyTypes,
    });
  };
}
