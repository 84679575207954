import * as React from 'react';
import { IconChevronLeft16, IconChevronRight16 } from '@cian/ui-kit/icons';

import * as styles from './ArrowButton.css';

export interface IArrowButtonProps {
  direction: -1 | 1;
  disabled?: boolean;
  offset?: number;
  verticalOffset?: number;
  onClick?(): void;
}

type TArrowStyle = {
  left?: string;
  right?: string;
  top?: string;
};

const DEFAULT_OFFSET = -20;

export const ArrowButton: React.VFC<IArrowButtonProps> = ({
  direction,
  disabled,
  offset = DEFAULT_OFFSET,
  onClick,
  verticalOffset,
}) => {
  const ArrowIcon = direction < 0 ? IconChevronLeft16 : IconChevronRight16;
  const style: TArrowStyle = direction < 0 ? { left: `${offset}px` } : { right: `${offset}px` };

  if (verticalOffset) {
    style.top = `calc(50% ${verticalOffset > 0 ? '+' : '-'} ${Math.abs(verticalOffset)}px)`;
  }

  const label = direction < 0 ? 'Назад' : 'Вперед';

  return (
    <button
      className={styles['arrow']}
      style={style}
      onClick={onClick}
      disabled={disabled}
      aria-label={label}
      data-testid="ArrowButton"
      type="button"
    >
      <ArrowIcon color={'icon-secondary-default'} />
    </button>
  );
};
