import {
  fetchOffersSearchMeta,
  TOffersSearchMetaResponse,
  IOffersSearchMetaRequest,
} from '../repositories/monolith-python/v1/offers-search-meta';
import { IApplicationContext } from '../types/application';

interface IGetMetaParameters {
  context: IApplicationContext;
  subdomain: string;
  parameters: IOffersSearchMetaRequest;
}

export function getMeta({ context, subdomain, parameters }: IGetMetaParameters): Promise<TOffersSearchMetaResponse> {
  const { httpApi } = context;

  return fetchOffersSearchMeta({
    httpApi,
    parameters,
    config: {
      requestConfig: {
        headers: [['Content-Type', 'application/json']],
      },
      subdomain,
    },
  });
}
