import * as React from 'react';

import * as styles from './Week.css';

interface IWeekProps {
  children: React.ReactNode;
}

export const Week: React.FC<IWeekProps> = props => {
  const { children } = props;

  return <div className={styles['week']}>{children}</div>;
};
