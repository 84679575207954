import * as React from 'react';
import { declineNumeric, formatPrice } from '../../../utils/format_helper';

interface IProps {
  id: string;
  rootTitle: string;
  url?: string;
  objectCount?: number;
  h1?: string;
  title?: string;
  description?: string;
}

const s = require('../vertical_promo_blocks.css');

export class IconicPromoLink extends React.Component<IProps> {
  public getIconClass() {
    const linkID = this.props.id.toLowerCase();
    if (linkID.includes('flat')) {
      return s['c-new-promo-block-link-icon_flat'];
    } else if (linkID.includes('house')) {
      return s['c-new-promo-block-link-icon_house'];
    } else if (linkID.includes('garage')) {
      return s['c-new-promo-block-link-icon_garage'];
    } else if (linkID.includes('commercial') || linkID.includes('bscenter')) {
      return s['c-new-promo-block-link-icon_commerce'];
    } else if (linkID.includes('hostel')) {
      return s['c-new-promo-block-link-icon_hostel'];
    } else if (linkID.includes('builders')) {
      return s['c-new-promo-block-link-icon_catalog'];
    } else if (linkID.includes('promos')) {
      return s['c-new-promo-block-link-icon_sale'];
    } else if (linkID.includes('salesleaders')) {
      return s['c-new-promo-block-link-icon_sales-leaders'];
    } else if (linkID.includes('newbuildingsbooking')) {
      return s['c_new_promo_block-newbuildings_booking'];
    }

    return '';
  }

  public render() {
    return (
      <a className={s['c-new-promo-block-content-link-item']} href={this.props.url}>
        <div
          className={`${s['c-new-promo-block-link-icon-content']}
                      ${this.getIconClass()}`}
        >
          <h5
            className={s['c-new-promo-block-content-link-title']}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: this.props.rootTitle }}
          />
          {this.props.objectCount ? (
            <span className={s['c-new-promo-block-content-link-text']}>
              {/* tslint:disable-next-line:max-line-length */}
              {`${formatPrice(this.props.objectCount, 4)} ${declineNumeric(this.props.objectCount, [
                'предложение',
                'предложения',
                'предложений',
              ])}`}
            </span>
          ) : null}
          {this.props.description ? (
            <span
              className={`${s['c-new-promo-block-content-link-text']}${
                this.props.id === 'newBuildingSalesLeaders' ? ' ' + s['c-new-promo-block-content-link-text--gray'] : ''
              }`}
            >
              {this.props.description}
            </span>
          ) : null}
        </div>
      </a>
    );
  }
}
