import * as React from 'react';
import { connect } from 'react-redux';
import { ITopJK } from '../../types/top_three';
import { IRegionsData } from '../../types/regions_data';
import { IApplicationState } from '../../types/redux';
import { TopJK } from '../../components/top_three/top_jk';

interface IProps {
  services?: React.ReactNode;
  popularJks: ITopJK[] | null;
  allJKLink?: string;
  regionsData: IRegionsData;
  isCrashPage?: boolean;
}

interface IPopularProps {
  services?: React.ReactNode;
}

export class PopularList extends React.Component<IProps> {
  public render() {
    const top = this.props.popularJks;

    if (this.props.isCrashPage) {
      return null;
    }

    if (top && top.length < 3) {
      if (this.props.services) {
        return this.props.services;
      } else {
        return <div />;
      }
    }

    return (
      <TopJK
        services={this.props.services}
        offers={top || []}
        locationName={
          this.props.regionsData.subdomainRegionAllowed
            ? this.props.regionsData.subdomainRegionInflectedTitleLoct
            : 'в Москве'
        }
        allJkLink={this.props.allJKLink}
      />
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PopularListWrapper = connect<{}, {}, any>((state: IApplicationState, props: IPopularProps) => ({
  popularJks: state.mainpage.popularJks,
  isFetching: state.mainpage.isFetching,
  allJKLink: state.mainpage.urls.jks,
  regionsData: state.mainpage.regionsData,
  services: props.services,
  isCrashPage: state.mainpage.error,
}))(PopularList);

export { PopularListWrapper as JKPopularList, PopularList as JKPopularListClass };
