import {
  IJsonQuery,
  ERentTime,
  ERoomType,
  EBuildingStatus,
  EObjectType,
  EOfficeType,
  ECategoryType,
  IJsonQueryType,
  ECoworkingOfferType,
  EJsonQueryReadyBusinessType,
} from '../../../packages/api-models/common/json_query';
import * as R from 'ramda';

import {
  FDealType,
  FOfferType,
  dealTypeFromJsonQuery,
  offerTypeFromJsonQuery,
  isSuburban,
  isCommercial,
} from '../category';
import { setTerm, setTerms, setRootType } from './helpers';
import { setDealType } from './setDealType';
import { setRoomTypes } from './setRoomTypes';
import { toggleReadyBusinessTypes } from './toggleReadyBusinessTypes';

export function selectOfferType(jsonQuery: IJsonQuery): (nextOfferType: FOfferType) => IJsonQuery {
  let nextJsonQuery = R.clone(jsonQuery);

  return nextOfferType => {
    const offerType = offerTypeFromJsonQuery(jsonQuery);
    const dealType = dealTypeFromJsonQuery(jsonQuery);
    const newObject = jsonQuery.geo && jsonQuery.geo.value.find(geo => geo.type === 'newobject');

    if (!isCommercial(nextOfferType)) {
      if (isCommercial(offerType)) {
        nextJsonQuery = setTerm('office_type')(nextJsonQuery)(undefined);
        nextJsonQuery = setTerm('category')(nextJsonQuery)(undefined);
        nextJsonQuery = setTerm('ready_business_types')(nextJsonQuery)(undefined);

        if (dealType & FDealType.Rent) {
          nextJsonQuery = setTerm('for_day')(nextJsonQuery)(ERentTime.NotDaily);
        }
      }

      if (!(isSuburban(offerType) && isSuburban(nextOfferType))) {
        nextJsonQuery = setTerm('object_type')(nextJsonQuery)(undefined);
      }
      nextJsonQuery = setTerm('building_status')(nextJsonQuery)(undefined);

      if (!newObject || nextOfferType === FOfferType.FlatOld) {
        nextJsonQuery = setTerm('from_developer')(nextJsonQuery)(undefined);
      }
      nextJsonQuery = setTerm('with_newobject')(nextJsonQuery)(undefined);

      const offerTypeIsRoomType = (offerType & (FOfferType.FlatShared | FOfferType.Room | FOfferType.Bed)) !== 0;
      const nextOfferTypeIsRoomType = [FOfferType.FlatShared, FOfferType.Room, FOfferType.Bed].includes(nextOfferType);
      if ((offerTypeIsRoomType && !nextOfferTypeIsRoomType) || (!offerTypeIsRoomType && nextOfferTypeIsRoomType)) {
        nextJsonQuery = setTerm('room')(nextJsonQuery)(undefined);
      }

      if (nextJsonQuery.office_type) {
        nextJsonQuery = setTerm('office_type')(nextJsonQuery)(undefined);
      }

      switch (nextOfferType) {
        case FOfferType.Flat:
          nextJsonQuery = setOfferType(nextJsonQuery)('flat');
          break;
        case FOfferType.FlatOld:
          nextJsonQuery = setOfferType(nextJsonQuery)('flat');
          if ((dealType & FDealType.Rent) === 0) {
            nextJsonQuery = setTerm('building_status')(nextJsonQuery)(EBuildingStatus.Old);
          }
          break;
        case FOfferType.FlatNew:
          nextJsonQuery = setOfferType(nextJsonQuery)('flat');
          nextJsonQuery = setTerm('building_status')(nextJsonQuery)(EBuildingStatus.New);
          break;
        case FOfferType.FlatShared:
          nextJsonQuery = setOfferType(nextJsonQuery)('flat');
          nextJsonQuery = selectRoomType(nextJsonQuery)(ERoomType.FlatShared);
          break;
        case FOfferType.Room:
          nextJsonQuery = setOfferType(nextJsonQuery)('flat');
          nextJsonQuery = selectRoomType(nextJsonQuery)(ERoomType.Room);
          break;
        case FOfferType.Bed:
          nextJsonQuery = setOfferType(nextJsonQuery)('flat');
          nextJsonQuery = selectRoomType(nextJsonQuery)(ERoomType.Bed);
          break;
        case FOfferType.House:
          nextJsonQuery = setOfferType(nextJsonQuery)('suburban');
          nextJsonQuery = selectSuburbanObjectType(nextJsonQuery)(EObjectType.House);
          break;
        case FOfferType.Townhouse:
          nextJsonQuery = setOfferType(nextJsonQuery)('suburban');
          nextJsonQuery = selectSuburbanObjectType(nextJsonQuery)(EObjectType.Townhouse);
          break;
        case FOfferType.HousePart:
          nextJsonQuery = setOfferType(nextJsonQuery)('suburban');
          nextJsonQuery = selectSuburbanObjectType(nextJsonQuery)(EObjectType.Housepart);
          break;
        case FOfferType.Land:
          nextJsonQuery = setDealType(nextJsonQuery)(FDealType.Sale);
          nextJsonQuery = setOfferType(nextJsonQuery)('suburban');
          nextJsonQuery = selectSuburbanObjectType(nextJsonQuery)(EObjectType.Land);
          break;
        case FOfferType.Garage:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = setTerms('office_type')(nextJsonQuery)([EOfficeType.Garage]);
          break;
        default:
      }
    } else {
      if (dealType & FDealType.Rent) {
        nextJsonQuery = setTerm('for_day')(nextJsonQuery)(ERentTime.NotDaily);
      }

      nextJsonQuery = setTerm('category')(nextJsonQuery)(undefined);
      nextJsonQuery = setTerm('object_type')(nextJsonQuery)(undefined);
      nextJsonQuery = setTerm('room')(nextJsonQuery)(undefined);
      nextJsonQuery = setTerm('building_status')(nextJsonQuery)(undefined);

      if (nextOfferType !== FOfferType.Coworking && jsonQuery.office_type?.value.includes(EOfficeType.Coworking)) {
        nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.Coworking);
      }

      if (
        (nextOfferType &
          (FOfferType.Office | FOfferType.Warehouse | FOfferType.TradeArea | FOfferType.FreeAppointmentObject)) ===
        0
      ) {
        nextJsonQuery = setTerm('from_offrep')(nextJsonQuery)(undefined);
      }
      if (
        nextJsonQuery.office_type &&
        nextJsonQuery.office_type.value.includes(EOfficeType.Garage) &&
        nextOfferType !== FOfferType.Garage
      ) {
        nextJsonQuery = setTerm('office_type')(nextJsonQuery)(undefined);
      }

      switch (nextOfferType) {
        case FOfferType.Office:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.Office);
          break;

        case FOfferType.Coworking: {
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = setTerms('office_type')(nextJsonQuery)([EOfficeType.Coworking]);
          nextJsonQuery = setTerms('coworking_offer_type')(nextJsonQuery)([ECoworkingOfferType.FlexibleOffice]);
          nextJsonQuery = setTerm('price')(nextJsonQuery)(undefined);

          break;
        }

        case FOfferType.TradeArea:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.TradeArea);
          break;
        case FOfferType.Warehouse:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.Warehouse);
          break;
        case FOfferType.FreeAppointmentObject:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.FreeAppointmentObject);
          break;
        case FOfferType.PublicCatering:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.PublicCatering);
          break;
        case FOfferType.Manufacture:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.Manufacture);
          break;
        case FOfferType.AutoService:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.AutoService);
          break;
        case FOfferType.Business:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.Business);
          break;
        case FOfferType.Building:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.Building);
          break;
        case FOfferType.DomesticServices:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.DomesticServices);
          break;
        case FOfferType.CommercialLand:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = setTerm('office_type')(nextJsonQuery)(undefined);
          nextJsonQuery = setTerm('ready_business_types')(nextJsonQuery)(undefined);

          if (dealType & FDealType.Rent) {
            nextJsonQuery = setTerms('category')(nextJsonQuery)([ECategoryType.CommercialLandRent]);
          } else if (dealType & FDealType.Sale) {
            nextJsonQuery = setTerms('category')(nextJsonQuery)([ECategoryType.CommercialLandSale]);
          }
          break;
        case FOfferType.RentalBusiness:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleReadyBusinessTypes(nextJsonQuery)(EJsonQueryReadyBusinessType.RentalBusiness);
          break;
        case FOfferType.ReadyBusiness:
          nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
          nextJsonQuery = toggleReadyBusinessTypes(nextJsonQuery)(EJsonQueryReadyBusinessType.ReadyBusiness);
          break;
        default:
      }
    }

    return nextJsonQuery;
  };
}

function selectSuburbanObjectType(jsonQuery: IJsonQuery) {
  return (objectType: EObjectType): IJsonQuery => {
    if (jsonQuery.object_type) {
      if (!jsonQuery.object_type.value.includes(objectType)) {
        return setTerms('object_type')(jsonQuery)(jsonQuery.object_type.value.concat(objectType));
      } else {
        return setTerms('object_type')(jsonQuery)(jsonQuery.object_type.value.filter(type => type !== objectType));
      }
    } else {
      return setTerms('object_type')(jsonQuery)([objectType]);
    }
  };
}

function selectRoomType(jsonQuery: IJsonQuery) {
  return (roomType: ERoomType): IJsonQuery => {
    if (jsonQuery.room) {
      if (!jsonQuery.room.value.includes(roomType)) {
        return setRoomTypes(jsonQuery)(jsonQuery.room.value.concat(roomType));
      } else {
        return setRoomTypes(jsonQuery)(jsonQuery.room.value.filter(type => type !== roomType));
      }
    } else {
      return setRoomTypes(jsonQuery)([roomType]);
    }
  };
}

function toggleOfficeType(jsonQuery: IJsonQuery) {
  return (officeType: EOfficeType): IJsonQuery => {
    if (jsonQuery.office_type) {
      if (!jsonQuery.office_type.value.includes(officeType)) {
        return setTerms('office_type')(jsonQuery)(jsonQuery.office_type.value.concat(officeType));
      }

      return setTerms('office_type')(jsonQuery)(jsonQuery.office_type.value.filter(type => type !== officeType));
    }

    return setTerms('office_type')(jsonQuery)([officeType]);
  };
}

function setOfferType(jsonQuery: IJsonQuery) {
  return (offerType: 'flat' | 'commercial' | 'suburban' | 'newobject'): IJsonQuery => {
    const currentType = jsonQuery._type;

    const nextType: IJsonQueryType = currentType.replace(
      /flat|commercial|suburban|newobject/,
      offerType,
    ) as IJsonQueryType;

    return setRootType(nextType)(jsonQuery);
  };
}
