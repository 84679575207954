import { IGeocodeForSearchResult, IGeocodeErrorResult } from '../types/geocode';
import { IMakeRequest, isSuccessResponse } from '../utils/network_helper';

export interface IGeocodeForSearchParameters {
  address: string;
  kind: YMaps.TGeoKind;
  coordinates: [number, number];
}

export function geocodeForSearch(
  makeRequest: IMakeRequest,
  parameters: IGeocodeForSearchParameters,
): Promise<IGeocodeForSearchResult> {
  return makeRequest({
    method: 'POST',
    headers: [['Content-type', 'application/x-www-form-urlencoded']],
    uri: {
      path: '/api/geo/geocoded-for-search/',
    },
    body: [
      `Address=${parameters.address}`,
      `Kind=${parameters.kind}`,
      `Lat=${parameters.coordinates[0]}`,
      `Lng=${parameters.coordinates[1]}`,
    ].join('&'),
  }).then((response: IGeocodeForSearchResult | IGeocodeErrorResult) => {
    if (!isSuccessResponse<IGeocodeForSearchResult, IGeocodeErrorResult>(response, 'details')) {
      throw new Error(`Failed to geocodeForSearch ${JSON.stringify(parameters)}`);
    }

    return response;
  });
}
