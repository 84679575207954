import { TReduxActions } from '../../../types/redux';
import { FILTERS_SET_MIN_PRICE_TYPE, FILTERS_SET_MAX_PRICE_TYPE } from '../../../constants/action_types';
import { JsonQuery } from '../../../utils/jsonQuery';
import { defaultState } from './defaultState';
import { IJsonQuery } from '../../../../packages/api-models/common/json_query';

export function setPriceReducer(jsonQuery: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  switch (action.type) {
    case FILTERS_SET_MIN_PRICE_TYPE:
      return new JsonQuery(jsonQuery).setMinPrice(action.minPrice).getSerializable();

    case FILTERS_SET_MAX_PRICE_TYPE:
      return new JsonQuery(jsonQuery).setMaxPrice(action.maxPrice).getSerializable();
  }

  return jsonQuery;
}
