import * as React from 'react';

import { IMagazineItem } from '../../types/magazine';
import { cropText, formatDate, replaceNBSP } from '../../utils/format_helper';

const s = require('./magazine.css');

interface IProps {
  news: IMagazineItem[];
}

const MAX_TITLE = 85;

export const MagazineNews = (props: IProps) => (
  <div className={`cg-col-8 cg-col-xs-12 ${s['c-white-pad']} ${s['c-white-news']}`}>
    <a href="/novosti" className={s['c-magazine-subtitle']}>
      НОВОСТИ
    </a>
    <div className={s['c-magazine-delimiter']}></div>
    <div className={s['c-news-container']}>
      {props.news.map((item: IMagazineItem, i: number) => (
        <div className={s['c-news-item']} key={`magazine-news-${i}`}>
          <div className={s['c-news-time']}>{formatDate(item.attributes.datePublish, false)}</div>
          <a href={`/novosti-${item.attributes.slug}-${item.id}`} className={s['c-news-title']}>
            {replaceNBSP(cropText(item.attributes.title, MAX_TITLE))}
          </a>
        </div>
      ))}
      <a href={`/novosti`} className={s['c-news-view-all']}>
        Все новости
      </a>
    </div>
  </div>
);
