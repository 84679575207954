import * as R from 'ramda';
import { Action } from 'redux';

import { TThunkAction } from '../../../types/redux';
import { FILTERS_SELECT_GEO_TYPE } from '../../../constants/action_types';
import { getMeta } from '../getMeta';
import { TGeoValue } from '../../../../packages/api-models/common/json_query';

export type TSelectGeoType = 'filters/geo/select_geo';

export interface ISelectGeo extends Action<TSelectGeoType> {
  type: TSelectGeoType;
  geoValue: TGeoValue;
}

export type TSelectGeo = ISelectGeo;

export function selectGeo(geoValue: TGeoValue): TThunkAction {
  return (dispatch, getState) => {
    const {
      filters: { jsonQuery },
    } = getState();
    if (jsonQuery.geo && jsonQuery.geo.value && !!R.find(R.equals(geoValue), jsonQuery.geo.value)) {
      return;
    }

    dispatch<TSelectGeo>({
      type: FILTERS_SELECT_GEO_TYPE,
      geoValue,
    });

    dispatch(getMeta());
  };
}
