import { IControlOption } from '@cian/ui-kit/types/controls/IControlGroupProps';
import { EJsonQueryPriceType } from '../../../../../packages/api-models/common/json_query';

export class CoworkingSwitchButtonModel implements IControlOption<EJsonQueryPriceType | 'all'> {
  public readonly label: string;

  public readonly value: EJsonQueryPriceType | 'all';

  public constructor(label: CoworkingSwitchButtonModel['label'], value: CoworkingSwitchButtonModel['value']) {
    this.label = label;

    this.value = value;
  }
}
