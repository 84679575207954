import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';

export type TSetMortgageTermsType = 'mortgage/set_terms';

export const SET_MORTGAGE_TERMS_TYPE: TSetMortgageTermsType = 'mortgage/set_terms';

export interface ISetMortgageTerms extends Action<TSetMortgageTermsType> {
  type: TSetMortgageTermsType;
  term: number;
}

export type TSetMortgageTerms = ISetMortgageTerms;

export function setMortgageTerms(term: number): TThunkAction {
  return dispatch => {
    dispatch<TSetMortgageTerms>({
      type: SET_MORTGAGE_TERMS_TYPE,
      term,
    });
  };
}
