interface IParams {
  startMonth: number;
  endMonth: number;
  startYear: number;
  endYear: number;
}

export const getMonthsCount = (params: IParams): number => {
  const { startMonth, endMonth, startYear, endYear } = params;

  return endMonth - startMonth + 12 * (endYear - startYear) + 1;
};
