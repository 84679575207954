import { UIText2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './Underground.css';
import { UndergroundIcon } from './components/UndergroundIcon';
import { GeoTravelTime } from './GeoTravelTime';
import { IUnderground } from '../../../types/newbuildingRecommendations';

export interface IUndergroundProps {
  underground: IUnderground;
}

export const Underground: React.FC<IUndergroundProps> = ({ underground }) => {
  const { lineColor, undergroundName, distance, transportType } = underground;

  return (
    <div className={styles['container']}>
      <div className={styles['underground']}>
        {lineColor && <UndergroundIcon color={`#${lineColor}`} undergroundType={underground.undergroundType} />}
        <span className={styles['name']}>
          <UIText2 display="inline">{undergroundName}</UIText2>
        </span>
      </div>
      {distance && transportType && <GeoTravelTime distance={distance} transportType={transportType} />}
    </div>
  );
};
