import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_MIN_LAND_AREA_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';

export type TSetMinLandAreaType = 'filters/set_min_land_area';

export interface ISetMinLandArea extends Action<TSetMinLandAreaType> {
  type: TSetMinLandAreaType;
  minLandArea: number | undefined;
}

export type TSetMinLandArea = ISetMinLandArea;

export function setMinLandArea(minLandArea: number | undefined): TThunkAction {
  return dispatch => {
    dispatch<TSetMinLandArea>({
      type: FILTERS_SET_MIN_LAND_AREA_TYPE,
      minLandArea,
    });

    dispatch(getMeta());
  };
}
