import * as React from 'react';

import { Link } from '../link/link';

const s = require('./popular_item.css');

export const DefaultPopularItem = () => (
  <div className={`${s['c-popular-how-to']} cg-col-8`}>
    <span className={s['c-popular-how-to-icon']} />
    <span className={s['c-popular-how-to-title']}>Топ 3 на главной</span>
    <span className={s['c-popular-how-to-text']}>
      Разместите объявление в Топ3
      <br />и оно появится на главной странице!
    </span>
    <Link href="/promo/adv/#top3" className={s['c-popular-how-to-btn']}>
      Узнать подробнее
    </Link>
  </div>
);
