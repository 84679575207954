import * as R from 'ramda';
import { IJsonQuery, TGeoValue } from '../../../packages/api-models/common/json_query';

import { setGeo } from './helpers';

export function selectGeo(jsonQuery: IJsonQuery): (geoValue: TGeoValue) => IJsonQuery {
  return geoValue => {
    if (jsonQuery.geo && jsonQuery.geo.value && !!R.find(R.equals(geoValue), jsonQuery.geo.value)) {
      return jsonQuery;
    }

    const nextGeo = (jsonQuery.geo && R.clone(jsonQuery.geo.value)) || [];

    return setGeo()(jsonQuery)([...nextGeo, geoValue]);
  };
}
