import { IDatesModalState } from '../../../types/filters/datesModal';
import { EDatesModalActionType } from '../../../actions/filters/datesModal';
import { TReduxActions } from '../../../types/redux';

export const defaultState: IDatesModalState = {
  isOpen: false,
  wasOpenedAtLeastOnce: false,
};

export function datesModalReducer(state = defaultState, action: TReduxActions): IDatesModalState {
  switch (action.type) {
    case EDatesModalActionType.Open:
      return {
        ...state,
        isOpen: true,
        wasOpenedAtLeastOnce: true,
      };
    case EDatesModalActionType.Close:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}
