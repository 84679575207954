import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_MIN_PRICE_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';

export type TSetMinPriceType = 'filters/set_min_price';

export interface ISetMinPrice extends Action<TSetMinPriceType> {
  type: TSetMinPriceType;
  minPrice: number | undefined;
}

export type TSetMinPrice = ISetMinPrice;

export function setMinPrice(minPrice: number | undefined): TThunkAction {
  return dispatch => {
    dispatch<TSetMinPrice>({
      type: FILTERS_SET_MIN_PRICE_TYPE,
      minPrice,
    });

    dispatch(getMeta());
  };
}
