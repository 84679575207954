import * as React from 'react';
import { IMagazineItem } from '../../types/magazine';
import { cropText, declineNumeric, escapeHtml, replaceNBSP } from '../../utils/format_helper';

const s = require('./magazine.css');

interface IProps {
  contentUrl: string;
  questions: IMagazineItem[];
}

const MAX_SUBTITLE = 155;

export const MagazineQuestions = (props: IProps) => (
  <div className={`cg-col-12 cg-col-xs-12 ${s['c-white-pad']} ${s['c-white-blogs']}`}>
    <a href={`/forum-rieltorov/`} className={s['c-magazine-subtitle']}>
      ВОПРОСЫ РИЭЛТОРУ
    </a>
    <div className={s['c-magazine-delimiter']}></div>
    <div className={s['c-blogs-container']}>
      {props.questions.map((item: IMagazineItem, i: number) => (
        <div className={s['c-articles-item']} key={`article-${i}`}>
          <a href={`/forum-rieltorov-${item.attributes.slug}-${item.id}`} className={s['c-articles-title']}>
            {item.attributes.title}
          </a>
          <div
            className={s['c-articles-caption']}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: escapeHtml(replaceNBSP(cropText(item.attributes.subtitle, MAX_SUBTITLE))),
            }}
          />
          {item.attributes.authorObject && (
            <span className={s['c-articles-author']}>
              {item.attributes.authorObject.fullName || item.attributes.authorObject.companyName || 'Аноним'}
            </span>
          )}
          <span className={s['c-comments-icon']} />
          <span className={s['c-comments-count']}>
            {`${item.attributes.commentsCount} `}
            {declineNumeric(item.attributes.commentsCount, ['совет', 'совета', 'советов'])}
          </span>
        </div>
      ))}
      <a href={`/forum-rieltorov/`} className={s['c-news-view-all']}>
        Все вопросы
      </a>
    </div>
  </div>
);
