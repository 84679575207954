import { IOption, TOptionValue } from '@cian/ui-kit';
import * as React from 'react';
import { FilterDropdown } from '../../FilterDropdown';

const s = require('../search_filters.css');

interface IProps {
  label?: string;
  multiple?: boolean;
  values: IOption[];
  popupContentStyle?: string;
  value: TOptionValue | TOptionValue[];
  onClick?(): void;
  onChange(value: string | number | (string | number)[]): void;
}

export class OptionalDropdown extends React.Component<IProps> {
  public render() {
    if (this.props.values.length < 2) {
      return (
        <div
          className={`${s['c-several-filters-dropdown-container']}
                          ${s['c-several-filters-dropdown-container-disabled']}`}
        >
          <button
            className={`${s['c-filters-dropdown-button']}`}
            onClick={() => (this.props.onClick ? this.props.onClick() : null)}
          >
            {this.props.values.length > 0 ? this.props.values[0].label : ''}
          </button>
        </div>
      );
    }

    return (
      <div className={`${s['c-several-filters-dropdown-container']}`}>
        <FilterDropdown
          multiple={this.props.multiple || false}
          label={this.props.label || ''}
          selected={this.props.value}
          onValueChange={value => this.props.onChange(value)}
          options={this.props.values}
        />
      </div>
    );
  }
}
