import { useSelector } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { coworkingOfferTypeFromJsonQuery, FCoworkingOfferType } from '../../utils/category';

export function useCoworkingOfferTypeSelector() {
  return useSelector<IApplicationState, FCoworkingOfferType>(state =>
    coworkingOfferTypeFromJsonQuery(state.filters.jsonQuery),
  );
}
