import { connect } from 'react-redux';
import { IMediaBannerProps, MediaBanner } from '../../components/media_banner/media_banner';
import { IApplicationState } from '../../types/redux';
import { shouldNewFiltersShow } from '../../utils/filters';

type TStateProps = Pick<IMediaBannerProps, 'hasNewPlacement' | 'housingInfo'>;

export const mapStateToProps = (state: IApplicationState): TStateProps => {
  const { page } = state;

  const isNewFiltersVisible = shouldNewFiltersShow({ section: page.section });

  return {
    hasNewPlacement: isNewFiltersVisible,
    housingInfo: state.housingInfo,
  };
};

export const MediaBannerContainer = connect<TStateProps>(mapStateToProps)(MediaBanner);
