import * as React from 'react';

type TUseToggleStateResult = [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>];

export function useToggleState(initialState: boolean = false): TUseToggleStateResult {
  const [state, setState] = React.useState<boolean>(initialState);

  const toggleState = React.useCallback(() => setState(state => !state), [setState]);

  return [state, toggleState, setState];
}
