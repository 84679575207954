import React, { useState, HTMLAttributes } from 'react';
import { mergeStyles } from '@cian/utils';
import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';
import { Outside } from '@cian/ui-kit';
import styles from './FilterSingleChoose.css';

export interface IFilterSingleChooseProps<KEY> extends HTMLAttributes<HTMLDivElement> {
  items: Map<KEY, string>;
  selectedItemKey?: KEY;
  label: string;

  onSelectItem(key: KEY): void;
}

export function FilterSingleChoose<KEY>(props: IFilterSingleChooseProps<KEY>) {
  const { selectedItemKey, items, onSelectItem, label, className, ...sharedProps } = props;
  const [isActive, setIsActive] = useState<boolean>(false);

  const selectedItemString = (selectedItemKey != null && items.get(selectedItemKey)) || label;

  return (
    <Outside onOutside={() => setIsActive(false)}>
      <div {...mergeStyles(stylesCommon['container'], className)} data-mark="FilterSingleChoose" {...sharedProps}>
        <button
          {...mergeStyles(
            stylesCommon['button'],
            isActive && stylesCommon['button--active'],
            selectedItemKey == null && stylesCommon['button--empty'],
          )}
          onClick={() => setIsActive(!isActive)}
        >
          {selectedItemString}
        </button>
        {isActive && (
          <div className={styles['dropdown']}>
            <ul className={styles['list']}>
              {Array.from(items).map(item => (
                <li key={`item_${item[0]}`}>
                  <button
                    {...mergeStyles(
                      styles['dropdown_item'],
                      item[0] === selectedItemKey && styles['dropdown_item--active'],
                    )}
                    onClick={() => {
                      onSelectItem(item[0]);
                      setIsActive(false);
                    }}
                  >
                    {item[1]}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Outside>
  );
}
