import { batch } from 'react-redux';

import { TThunkDispatch } from '../../types/redux';

import { setMaxPrice } from './setMaxPrice';
import { setMinPrice } from './setMinPrice';
import { setPriceTypeAction } from './setPriceTypeAction';

export const purgePriceDataAction =
  () =>
  (dispatch: TThunkDispatch): void => {
    batch(() => {
      [setMinPrice(undefined), setMaxPrice(undefined), setPriceTypeAction(undefined)].forEach(dispatch);
    });
  };
