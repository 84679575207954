import { TThunkAction } from '../../types/redux';

export const persistFilters = (): TThunkAction => {
  return (dispatch, getState, context) => {
    const { logger } = context;
    const localstorageKey = window.__mainpage_persist_filters_key__;

    try {
      const {
        filters: { jsonQuery },
      } = getState();

      window.localStorage.setItem(localstorageKey, JSON.stringify(jsonQuery));
    } catch (error) {
      logger.warning('Unable to persist filters state to localStorage', error);
    }
  };
};
