import * as React from 'react';

import { ISeoUrl } from '../../types/seo_data';
import { NewSeoUrlsContainer } from '../new_seo_urls';

export interface INewSeoUrlsBlokProps {
  newSeoUrls: ISeoUrl[];
}

export const NewSeoUrlsBlok = ({ newSeoUrls }: INewSeoUrlsBlokProps) => <NewSeoUrlsContainer links={newSeoUrls} />;
