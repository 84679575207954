import { INewbuildingPromo } from '../../types/newbuildingPromo';
import { ITypedReduxAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum ENewbuildingPromosActionTypes {
  OpenDetails = 'newbuilding_promo/open_details',
  CloseDetails = 'newbuilding_promo/close_details',
}

export type TPromoOpenDetails = ITypedReduxAction<ENewbuildingPromosActionTypes.OpenDetails, INewbuildingPromo>;
export type TPromoCloseDetails = ITypedReduxAction<ENewbuildingPromosActionTypes.CloseDetails>;

export const openPromoDetails: (promo: INewbuildingPromo) => TPromoOpenDetails = actionGenerator<
  ENewbuildingPromosActionTypes.OpenDetails,
  INewbuildingPromo
>(ENewbuildingPromosActionTypes.OpenDetails);

export const closePromoDetails: () => TPromoCloseDetails = actionGenerator<ENewbuildingPromosActionTypes.CloseDetails>(
  ENewbuildingPromosActionTypes.CloseDetails,
);

export type TNewbuildingPromoActionTypes = TPromoOpenDetails | TPromoCloseDetails;
