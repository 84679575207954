import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_MIN_AREA_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';

export type TSetMinAreaType = 'filters/set_min_area';

export interface ISetMinArea extends Action<TSetMinAreaType> {
  type: TSetMinAreaType;
  minArea: number | undefined;
}

export type TSetMinArea = ISetMinArea;

export function setMinArea(minArea: number | undefined): TThunkAction {
  return dispatch => {
    dispatch<TSetMinArea>({
      type: FILTERS_SET_MIN_AREA_TYPE,
      minArea,
    });

    dispatch(getMeta());
  };
}
