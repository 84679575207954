/* eslint-disable */


//tslint:disable


import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {TSearchRegionsCitiesModel,ISearchRegionsCitiesRequest,IMappers,ISearchRegionsCitiesResponse,ISearchRegionsCitiesResponseError,TSearchRegionsCitiesResponse} from './types';


const defaultConfig: TSearchRegionsCitiesModel = {
apiType: 'legacy',
assertStatusCodes: [200,400],
method: 'GET',
microserviceName: 'monolith-python',
pathApi: '/cian-api/site/v1/search-regions-cities/',
hostType: 'api'
} as TSearchRegionsCitiesModel;

function createSearchRegionsCitiesModel(parameters: ISearchRegionsCitiesRequest): TSearchRegionsCitiesModel {
return {
...defaultConfig,
parameters
};
}


export interface ISearchRegionsCitiesOptions<TResponse200,TResponse400> {
httpApi: IHttpApi;
parameters: ISearchRegionsCitiesRequest;
config?: IHttpApiFetchConfig;
mappers?: IMappers<TResponse200,TResponse400>
}


async function fetchSearchRegionsCities<TResponse200,TResponse400>(
{ httpApi, parameters, config, mappers }: ISearchRegionsCitiesOptions<TResponse200,TResponse400>,
): Promise<TResponse200|TResponse400 | TSearchRegionsCitiesResponse> {
const { response, statusCode } = await httpApi.fetch(createSearchRegionsCitiesModel(parameters), config);


if (mappers && statusCode in mappers) {
if (statusCode === 200) {


return mappers[200](response as ISearchRegionsCitiesResponse);
}

if (statusCode === 400) {


return mappers[400](response as ISearchRegionsCitiesResponseError);
}
}


return { response, statusCode } as TSearchRegionsCitiesResponse;
}


export {defaultConfig, createSearchRegionsCitiesModel, fetchSearchRegionsCities};

