import { HttpBadStatusCodeError } from '@cian/peperrors/shared';
import {
  fetchSearchRegionsCities,
  TSearchRegionsCitiesResponse,
} from '../../../repositories/monolith-python/v1/search-regions-cities';
import { TThunkAction } from '../../../types/redux';
import { getSuggestionsFailed } from './getSuggestionsFailed';
import { getSuggestionsLoading } from './getSuggestionsLoading';
import { getSuggestionsSucceed } from './getSuggestionsSucceed';
import { prepareSuggestions } from './utils';

export const getSuggestions = (text: string): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    if (text.length < 4) {
      dispatch(getSuggestionsSucceed([]));

      return;
    }

    const {
      httpApi,
      logger,
      custom: { subdomain },
    } = context;

    dispatch(getSuggestionsLoading());
    try {
      const response: TSearchRegionsCitiesResponse = await fetchSearchRegionsCities({
        httpApi,
        parameters: {
          text,
        },
        config: { subdomain },
      });

      if (response.statusCode !== 200) {
        throw new HttpBadStatusCodeError(
          'Не удалось загрузить список городов',
          response.statusCode,
          'actions.getSuggestions',
        );
      }

      const suggestions = prepareSuggestions(response.response.data.items);

      dispatch(getSuggestionsSucceed(suggestions));
    } catch (ex) {
      logger.error(ex, {
        domain: 'actions.getSuggestions',
      });

      dispatch(getSuggestionsFailed());
    }
  };
};
