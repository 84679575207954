import * as React from 'react';
import { ArticleItem } from './magazine_articles_item';
import { IMagazineItem } from '../../types/magazine';
import { cropText } from '../../utils/format_helper';

const s = require('./magazine.css');

interface IProps {
  contentUrl: string;
  articles: IMagazineItem[];
}

const MAX_TITLE = 85;

export class MagazineArticles extends React.Component<IProps> {
  public render() {
    const articles = [...this.props.articles];
    if (articles && articles.length > 0) {
      const firstArticle = articles.shift();

      if (!firstArticle) {
        return <div />;
      }

      return (
        <div className={`cg-col-16 cg-col-xs-12 ${s['c-white-pad']} ${s['c-white-articles']}`}>
          <a href={`/magazine`} className={s['c-magazine-subtitle']}>
            СТАТЬИ
          </a>
          <div className={`${s['c-magazine-delimiter']} ${s['c-articles-delimiter']}`}></div>
          <div className={`cg-row ${s['c-articles-widget']}`}>
            <a
              href={`/stati-${firstArticle.attributes.slug}-${firstArticle.id}`}
              className={`cg-col-12 cg-col-xs-24 ${s['c-articles-first']}`}
            >
              <div
                className={s['c-articles-first-image']}
                style={{ backgroundImage: `url(${firstArticle.attributes.image})` }}
              />
              <div className={s['c-articles-first-content']}>
                <div className={s['c-articles-type']}>СТАТЬИ</div>
                <div className={s['c-articles-title']}>{cropText(firstArticle.attributes.title, MAX_TITLE)}</div>
                <div className={s['c-articles-bottom']}>
                  <span className={s['c-articles-views']}>{firstArticle.attributes.numViews}</span>
                  {firstArticle.attributes.commentsCount > 0 && (
                    <span className={s['c-articles-comments']}>{firstArticle.attributes.commentsCount}</span>
                  )}
                </div>
              </div>
            </a>
            <div className={`cg-col-11 cg-col-xs-24 ${s['c-articles-container']}`}>
              {articles.map((item: IMagazineItem, i: number) => (
                <ArticleItem key={`article-${i}`} article={item} />
              ))}
              <a href={`/magazine`} className={s['c-articles-view-all']}>
                Все статьи
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}
