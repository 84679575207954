import { ca } from '@cian/analytics';

import { EPageKind } from '../../types/page';
import { withoutCianDomain } from '../../utils/url';
import { getEventCategoryFromPageKind } from '../../utils/tracking';

export function trackSearchButtonClick(pageKind: EPageKind, url: string) {
  ca('eventSite', {
    category: getEventCategoryFromPageKind(pageKind),
    action: 'button_search',
    label: withoutCianDomain(url),
  });
}

export function trackSearchOnMapButtonClick(pageKind: EPageKind, url: string) {
  ca('eventSite', {
    category: getEventCategoryFromPageKind(pageKind),
    action: 'button_search_on_map',
    label: withoutCianDomain(url),
  });
}
