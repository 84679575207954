import * as React from 'react';

import { ca } from '@cian/analytics';
import { Spinner } from '@cian/ui-kit';
import { numberToPrettyString } from '@cian/utils';

import * as styles from './vacancies.css';
import { IVacanciesState, TVacancy } from '../../types/vacancies';

export interface IProps {
  cityId: number;
  cityName: string;
  vacancies: IVacanciesState;
}

const trackClick = (action: string) => {
  ca('event', {
    action: 'vacanciesBlock',
    category: 'main_page',
    label: action,
    name: 'oldevent',
  });
};

function getSalaryLabel(minSalary?: number | null, maxSalary?: number | null) {
  if (minSalary && maxSalary) {
    return `От ${numberToPrettyString(minSalary || 0)} до ${numberToPrettyString(maxSalary || 0)} ₽/мес.`;
  }

  if (minSalary) {
    return `От ${numberToPrettyString(minSalary || 0)} ₽/мес.`;
  }

  if (maxSalary) {
    return `До ${numberToPrettyString(maxSalary || 0)} ₽/мес.`;
  }

  return 'Оплата по договорённости';
}

export const Vacancies = (props: IProps) => {
  const { cityId, cityName, vacancies } = props;

  if (!vacancies.isFetching && vacancies.data.length < 2) {
    return null;
  }

  return vacancies.isFetching ? (
    <div className={`cg-container-fluid-xs ${styles['container']} ${styles['center']}`}>
      <Spinner />
    </div>
  ) : (
    <div className={`cg-container-fluid-xs ${styles['container']}`} data-testid="Vacancies">
      <a
        href={'/vacancies-realtors/?region=' + cityId}
        className={styles['link']}
        onClick={() => trackClick('click_header')}
      >
        Вакансии {cityName}
      </a>

      <div className={styles['vacancies']}>
        {vacancies.data.map((vacancy: TVacancy) => {
          const { id, company, minSalary, maxSalary, specialization } = vacancy;
          const salary = getSalaryLabel(minSalary, maxSalary);

          const specializationTrimmed =
            specialization.length > 44 ? specialization.substr(0, 43) + '…' : specialization;

          return (
            <div className={styles['vacancy']} key={id}>
              <a
                href={`/vacancies/?region=${cityId}#${id}`}
                className={styles['vacancy_link']}
                onClick={() => trackClick('click_vacancy')}
              >
                <div className={styles['vacancy_title']}>{specializationTrimmed}</div>

                <div className={styles['vacancy_salary']}>{salary}</div>
              </a>

              {company && (
                <div className={styles['company']}>
                  <a href={company.profileUrl || ''} className={styles['company_link']}>
                    {company.avatarUrl && <img src={company.avatarUrl} alt="" className={styles['company_logo']} />}

                    <ul>
                      <li className={styles['company_name']}>{company.name || `ID: ${company.id}`}</li>

                      {company.age && <li className={styles['company_age']}>{company.age} на ЦИАН</li>}
                    </ul>
                  </a>
                </div>
              )}
            </div>
          );
        })}

        <div className={styles['new_vacancy']}>
          <div className={styles['text']}>
            Вы можете опубликовать
            <br />
            вакансию бесплатно
          </div>

          <div>
            <a href="/vacancies-realtors/add" className={styles['button']} onClick={() => trackClick('add_vacancy')}>
              + Опубликовать вакансию
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
