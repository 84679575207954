import * as R from 'ramda';

import { beautify } from './number';

const SHRUG = '¯\\_(ツ)_/¯';

export function getAreaLabel(min: number | undefined, max: number | undefined, placeholder: string): string | null {
  if (min === 0 && max === 0) {
    return SHRUG;
  }

  if (!R.isNil(min) && R.isNil(max)) {
    return `от ${beautify(min)} ${placeholder}`;
  } else if (R.isNil(min) && !R.isNil(max)) {
    return `до ${beautify(max)} ${placeholder}`;
  } else if (!R.isNil(min) && !R.isNil(max)) {
    return `${beautify(min)} - ${beautify(max)} ${placeholder}`;
  }

  return null;
}
