import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { SalesLinks } from '../../components/SalesLinks';
import { getSalesLinks, selectHasBlackFridaySalesLink } from '../../selectors/mainpage';
import { ISalesLink } from '../../types/salesLink';
import { trackBlackFridaySalesLinkShow, trackSalesLinkClick } from './tracking';

interface ISalesLinksContainerProps {
  section: string;
}

export function SalesLinksContainer({ section }: ISalesLinksContainerProps) {
  const salesLinks = useSelector(getSalesLinks);
  const hasBlackFridayLink = useSelector(selectHasBlackFridaySalesLink);

  const handleLinkClick = React.useCallback((link: ISalesLink) => {
    trackSalesLinkClick(link.salesType);
  }, []);

  const handleLinksShow = React.useCallback(() => {
    if (hasBlackFridayLink) {
      trackBlackFridaySalesLinkShow();
    }
  }, [hasBlackFridayLink]);

  if (!salesLinks.length || section !== 'new-buildings') {
    return null;
  }

  return (
    <ActionAfterViewObserver callback={handleLinksShow} triggerOnce>
      <SalesLinks links={salesLinks} onLinkClick={handleLinkClick} />
    </ActionAfterViewObserver>
  );
}
