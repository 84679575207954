import { SET_DEFAULT_BACKGROUND } from '../../actions/defaultBackground';
import { TReduxActions } from '../../types/redux';

const initialState = '';

export function defaultBackgroundReducer(state = initialState, action: TReduxActions): string {
  switch (action.type) {
    case SET_DEFAULT_BACKGROUND:
      return action.payload;

    default:
      return state;
  }
}
