import { ILogger } from '@cian/logger';
import get from 'lodash/get';
import { IHttpApi, IHTTPCompletedResponse, IHTTPRequestConfig, THTTPRequestMethod } from '@cian/http-api/shared';
import { parseContent } from './parseContent';

const LOGIN_CODE = 202;

export interface IRequestConfig {
  uri: {
    port?: number;
    scheme?: 'http' | 'https';
    host?: string | null;
    query?: string;
    path: string;
  };
  method?: THTTPRequestMethod;
  headers?: [string, string][];
  body?: string;
  withCredentials?: boolean;
  timeout?: number;
}

export interface IMakeRequest {
  <T>(requestConfig: IRequestConfig): Promise<T>;
}

function formatHeader(header?: [string, string | string[] | undefined][]) {
  return header !== undefined ? header.map(value => value.join(': ')) : null;
}

export function createMakeRequest(
  httpApi: IHttpApi,
  baseHost: string,
  baseScheme: 'http' | 'https',
  logger: ILogger,
): IMakeRequest {
  return async (requestConfig: IRequestConfig) => {
    if (!requestConfig.uri.host) {
      requestConfig.uri.host = baseHost;
    }
    if (!requestConfig.uri.scheme) {
      requestConfig.uri.scheme = baseScheme;
    }

    function handleError(error: Error, responseResult?: IHTTPCompletedResponse | undefined) {
      const responseStatusCode = get(responseResult, ['responseStatusCode']);
      const responseBody = get(responseResult, ['responseBody']);
      const headers = requestConfig !== undefined ? formatHeader(requestConfig.headers) : [];
      const responseHeaders = responseResult !== undefined ? formatHeader(responseResult.responseHeaders) : [];
      logger.error(error, {
        uri: {
          scheme: requestConfig.uri.scheme,
          host: requestConfig.uri.host,
          query: requestConfig.uri.query,
          path: requestConfig.uri.path,
        },
        method: requestConfig.method,
        headers,
        body: requestConfig.body,
        withCredentials: requestConfig.withCredentials,
        responseStatusCode,
        responseHeaders,
        responseBody,
      });

      throw error;
    }

    try {
      const { timeout, ...reqConfig } = requestConfig;
      const ecnhancersConfig = {
        timeout,
      };
      const result = await httpApi.httpRequest(reqConfig as IHTTPRequestConfig, ecnhancersConfig);

      try {
        if (result.responseStatusCode === LOGIN_CODE) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (typeof window !== 'undefined' && (window as any).triggerLogin) {
            const loginDelay = 200;

            setTimeout(() => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (window as any).triggerLogin(
                parseContent(result.responseBody).data.attributes.email,
                parseContent(result.responseBody).data.attributes.password,
              );
            }, loginDelay);
          }
        }

        return parseContent(result.responseBody);
      } catch (exc) {
        handleError(exc, result);
      }
    } catch (exc) {
      handleError(exc);
    }
  };
}
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSuccessResponse<S extends {}, E extends {}>(response: S | E, key: string): response is S {
  return key in response;
}
