const s = require('../shared/components/geo_links/geo_links.css');

const toggleOnClick = (container: HTMLElement, title: HTMLSpanElement) => {
  if (` ${container.className} `.indexOf(` ${s['c-geo-section-items-collapsed']} `) > -1) {
    container.className = `
      ${s['c-geo-section']}
      cg-row
      ${s['c-geo-section-items']}`;
    title.innerText = 'Скрыть';
  } else {
    container.className = `
      ${s['c-geo-section']}
      cg-row
      ${s['c-geo-section-items']}
      ${s['c-geo-section-items-collapsed']}`;
    title.innerText = 'Показать все';
  }
};

const prepareGeoLinks = () => {
  return Array.from(document.querySelectorAll('.geo-row')).forEach((container: HTMLElement) => {
    const button = container.querySelector('.toggle-button') as HTMLButtonElement;
    const title = container.querySelector('.toggle-button-title') as HTMLSpanElement;

    container.className = `
    ${s['c-geo-section']}
    cg-row
    ${s['c-geo-section-items']}
    ${s['c-geo-section-items-collapsed']}`;
    if (title && title.innerText) {
      title.innerText = 'Показать все';
    }

    const handler = () => {
      toggleOnClick(container, title);
    };

    if (button) {
      if (button.addEventListener) {
        button.addEventListener('click', handler, false);
      } else {
        button['onclick'] = handler;
      }
    }
  });
};

export const initializeGeoLinks = () => {
  if (document.readyState !== 'loading') {
    prepareGeoLinks();
  } else {
    window.addEventListener(
      'DOMContentLoaded',
      () => {
        prepareGeoLinks();
      },
      true,
    );
  }
};
