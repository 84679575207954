import { createComponent } from '@cian/mf-react-legacy';
import { IParameters } from '@cian/mf-registry-legacy/shared';
import * as React from 'react';

import * as styles from './Recommendations.css';
import { RECOMMENDATIONS_MICROFRONTEND_NAME, RECOMMENTATIONS_MICROFRONTEND_PATH } from '../../constants/microfrontend';
import classNames from 'classnames';

interface IRecommendationsProps extends IParameters {
  regionId: number;
  landingType: string;
}

const RecommendationsComponent = createComponent<IRecommendationsProps>({
  mcs: RECOMMENDATIONS_MICROFRONTEND_NAME,
  path: RECOMMENTATIONS_MICROFRONTEND_PATH,
});

export const Recommendations: React.VFC<IRecommendationsProps> = ({ regionId, landingType }) => (
  <section className={classNames('cg-container-fluid-xs', styles['container'])}>
    <RecommendationsComponent regionId={regionId} landingType={landingType}></RecommendationsComponent>
  </section>
);
