import { findRightElement } from './findRightElement';

export function getFullRightScrollOffset(container: HTMLElement, containerOffsetLeft: number) {
  const rightBorderPosition = containerOffsetLeft + container.offsetWidth;
  const rightChild = findRightElement(container.children, rightBorderPosition, container.offsetLeft);
  const maxLeftOffset = container.scrollWidth - container.offsetWidth;

  if (!rightChild) {
    return maxLeftOffset;
  }

  const leftOffset = rightChild.offsetLeft - container.offsetLeft;

  return leftOffset <= maxLeftOffset ? leftOffset : maxLeftOffset;
}
