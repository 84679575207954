import { Outside } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import React, { memo } from 'react';

import { useCoworkingOfferTypeSelector } from '../../hooks/selectors/useCoworkingOfferTypeSelector';

import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';

import * as styles from './FilterCoworkingOfferType.css';
import { CoworkingOfferTypeDropdown } from './internal/components/CoworkingOfferTypeDropdown';
import { getCoworkingOfferTypeLabel } from './internal/helpers/getCoworkingOfferTypeLabel';
import { getCoworkingOfferTypeList } from './internal/helpers/getCoworkingOfferTypeList';
import { useCoworkingOfferTypeChangeEffect } from './internal/hooks/useCoworkingOfferTypeChangeEffect';
import { useDropdownVisibilityState } from './internal/hooks/useDropdownVisibilityState';
import { useHandleChangeOfferType } from './internal/hooks/useHandleChangeOfferType';
import { IFilterCoworkingOfferTypeProps } from './types';

const containerClassName = mergeStyles(stylesCommon['container'], styles['container']).className;

export const FilterCoworkingOfferType = memo<IFilterCoworkingOfferTypeProps>(function FilterCoworkingOfferType(props) {
  const { dealType, offerType } = props;

  const coworkingOfferType = useCoworkingOfferTypeSelector();

  useCoworkingOfferTypeChangeEffect(coworkingOfferType);

  const coworkingOfferTypes = getCoworkingOfferTypeList(dealType, offerType);
  const coworkingOfferTypeLabel = getCoworkingOfferTypeLabel(dealType, offerType, coworkingOfferType);

  const {
    closeDropdown: handleOutsideClick,
    dropdownIsVisible,
    toggleVisibility: handleButtonClick,
  } = useDropdownVisibilityState();

  const handleChangeOfferType = useHandleChangeOfferType(coworkingOfferType);

  return (
    <Outside onOutside={handleOutsideClick} active insideRefs={[]}>
      <div className={containerClassName} data-mark="FilterCoworkingOfferType">
        <button
          className={mergeStyles(stylesCommon['button'], dropdownIsVisible && stylesCommon['button--active']).className}
          title={coworkingOfferTypeLabel}
          onClick={handleButtonClick}
        >
          {coworkingOfferTypeLabel || 'Тип коворкинга'}
        </button>
        {dropdownIsVisible && (
          <CoworkingOfferTypeDropdown
            coworkingOfferType={coworkingOfferType}
            coworkingOfferTypes={coworkingOfferTypes}
            onChangeOfferType={handleChangeOfferType}
          />
        )}
      </div>
    </Outside>
  );
});
