import * as React from 'react';
import { connect } from 'react-redux';
import { APPEARANCE, COMPOSITION, POSITIONS, SIZES, XS_SIZES } from './composition_config';
import { trackLink } from '../../utils/helpers/metrics_helper';
import { IApplicationState } from '../../types/redux';
import { IPromo } from '../../types/promo';
import { IPromoBanner, IPromoBlock } from '../../types/promo_block';
import { PromoBlocks } from '../../components/promo_blocks/promo_blocks';

interface IProps {
  promo: IPromo[];
  isFetching: boolean;
  subdomainRegionId: number;
}

type TPromoItems = {
  promoBlocks: IPromoBlock[];
  promoBanner: IPromoBanner;
};

class Promo extends React.Component<IProps> {
  private getPromosAndBanner = (): TPromoItems => {
    const promo = [...this.props.promo];
    let jkBanner: IPromo | undefined;

    if (promo.length && promo[promo.length - 1].id === 'indexJkBanner') {
      jkBanner = promo.pop(); // last block may has special purpose
    }

    promo.sort((a: IPromo, b: IPromo) => POSITIONS[a.id] - POSITIONS[b.id]);

    const count = promo.length;
    const composition = COMPOSITION[count];
    const imageAppearance = APPEARANCE[count];
    const sizes = SIZES[count];
    const xsSizes = XS_SIZES[count];
    const promoBanner: IPromoBanner = {
      size: sizes[sizes.length - 1],
      xsSize: xsSizes[xsSizes.length - 1],
      promo: jkBanner,
    };
    const promoBlocks: IPromoBlock[] = promo.map((promoInfo: IPromo, i: number) => ({
      promo: promoInfo,
      imagePosition: composition[i],
      id: promoInfo.id,
      imageAppearance: imageAppearance[i],
      size: sizes[i],
      xsSize: xsSizes[i],
    }));

    return {
      promoBlocks,
      promoBanner,
    };
  };

  public render() {
    const { isFetching, subdomainRegionId } = this.props;
    const promoItems = this.getPromosAndBanner();

    if (promoItems.promoBlocks.length > 0 && promoItems.promoBanner) {
      return (
        <PromoBlocks
          {...promoItems}
          isFetching={isFetching}
          onLinkClick={trackLink}
          subdomainRegionId={subdomainRegionId}
        />
      );
    }

    return null;
  }
}

const PromoWrapper = connect(({ mainpage }: IApplicationState) => {
  return {
    promo: mainpage.promoBlocks,
    isFetching: mainpage.isFetching,
    subdomainRegionId: mainpage.regionsData.subdomainRegionId,
  };
})(Promo);

export { PromoWrapper as Promo, Promo as PromoClass };
