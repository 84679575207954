import { IJsonQuery } from '../../../../packages/api-models/common/json_query';
import { FILTERS_SET_SPECIALTY_TYPES_TYPE } from '../../../constants/action_types';
import { TReduxActions } from '../../../types/redux';
import { JsonQuery } from '../../../utils/jsonQuery';
import { defaultState } from './defaultState';

export function setFilterSpecialtyTypesReducer(
  jsonQuery: IJsonQuery = defaultState,
  action: TReduxActions,
): IJsonQuery {
  switch (action.type) {
    case FILTERS_SET_SPECIALTY_TYPES_TYPE:
      return new JsonQuery(jsonQuery).setFilterSpecialtyTypes(action.payload).getSerializable();
  }

  return jsonQuery;
}
