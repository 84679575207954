import {
  MOSCOW_AREA_REGION_ID,
  MOSCOW_AREA_REGION_NAME,
  MOSCOW_REGION_ID,
  MOSCOW_REGION_NAME,
  MOSCOW_WITH_AREA_REGION_ID,
  ST_PETERSBURG_AREA_REGION_ID,
  ST_PETERSBURG_AREA_REGION_NAME,
  ST_PETERSBURG_REGION_ID,
  ST_PETERSBURG_REGION_NAME,
  ST_PETERSBURG_WITH_AREA_REGION_ID,
} from '../../../../constants/geoSwitcher';
import { IPopularCityForGeoPopupSchema } from '../../../../repositories/geo-suggest/entities/entities/PopularCityForGeoPopupSchema';

/**
 * Временно заменяем Москву и МО на Москву, Санкт-Петербург и ЛО на Санкт-Петербург
 * @param cities
 * @todo выпилить, когда апишка перестанет возвращать кастомные регионы, которые отсутствуют в базе
 */
export function preparePopularCities(cities: IPopularCityForGeoPopupSchema[]) {
  return cities.reduce((result, item) => {
    if (item.id === MOSCOW_WITH_AREA_REGION_ID) {
      result.push({
        displayName: MOSCOW_REGION_NAME,
        id: MOSCOW_REGION_ID,
      });

      if (!cities.find(location => location.id === MOSCOW_AREA_REGION_ID)) {
        result.push({
          displayName: MOSCOW_AREA_REGION_NAME,
          id: MOSCOW_AREA_REGION_ID,
        });
      }

      return result;
    }

    if (item.id === ST_PETERSBURG_WITH_AREA_REGION_ID) {
      result.push({
        displayName: ST_PETERSBURG_REGION_NAME,
        id: ST_PETERSBURG_REGION_ID,
      });

      if (!cities.find(location => location.id === ST_PETERSBURG_AREA_REGION_ID)) {
        result.push({
          displayName: ST_PETERSBURG_AREA_REGION_NAME,
          id: ST_PETERSBURG_AREA_REGION_ID,
        });
      }

      return result;
    }

    result.push(item);

    return result;
  }, [] as IPopularCityForGeoPopupSchema[]);
}
