import { TThunkAction } from '../../types/redux';
import { getMeta } from './getMeta';
import { Action } from 'redux';
import { RESET_LAST_ROOMS } from '../../constants/action_types';

export type TResetLastRoomsType = 'filters/reset_last_rooms';

export interface IResetLastRooms extends Action<TResetLastRoomsType> {
  type: TResetLastRoomsType;
}

export type TResetLastRooms = IResetLastRooms;

export function resetLastRooms(callGetMeta = false): TThunkAction {
  return dispatch => {
    dispatch<TResetLastRooms>({
      type: RESET_LAST_ROOMS,
    });

    if (callGetMeta) {
      dispatch(getMeta());
    }
  };
}
