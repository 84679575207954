import { ICalendarMonth, IDateIndex } from '../../types/calendar';

interface IParams {
  calendar: Array<ICalendarMonth>;
  fromIndex: IDateIndex;
  toIndex: IDateIndex;
}

export const getRangeIndexes = (params: IParams): Array<IDateIndex> => {
  const { calendar, fromIndex, toIndex } = params;

  const months = [...new Array(toIndex.month - fromIndex.month + 1).keys()].map(i => i + fromIndex.month);

  return months.reduce((monthsStash, month) => {
    const days = calendar[month].days.reduce((daysStash, day, index) => {
      if (day?.type === 'disabled') {
        return daysStash;
      }

      if (fromIndex.month === toIndex.month && fromIndex.day < index && index < toIndex.day) {
        daysStash.push({
          month,
          day: index,
        });
      } else if (
        fromIndex.month !== toIndex.month &&
        ((month === fromIndex.month && index > fromIndex.day) ||
          (month === toIndex.month && index < toIndex.day) ||
          (fromIndex.month < month && month < toIndex.month))
      ) {
        daysStash.push({
          month,
          day: index,
        });
      }

      return daysStash;
    }, [] as Array<IDateIndex>);

    return monthsStash.concat(days);
  }, [] as Array<IDateIndex>);
};
