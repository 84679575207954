import { IFiltersClientErrors } from '../../../types/filters';
import { IApplicationState } from '../../../types/redux';

export function selectFiltersClientErrors(state: IApplicationState): IFiltersClientErrors {
  const {
    filters: { clientErrors },
  } = state;

  return clientErrors;
}
