import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { setRegion } from '../filters';

export const RESET_REGION_EXPANSION_TYPE: TResetRegionExpansionType = 'region_expansion/reset_region_expansion';

export type TResetRegionExpansionType = 'region_expansion/reset_region_expansion';

export interface IResetRegionExpansion extends Action<TResetRegionExpansionType> {
  type: TResetRegionExpansionType;
}

export type TResetReiongExpansion = IResetRegionExpansion;

export function resetRegionExpansion(): TThunkAction {
  return async (dispatch, getState) => {
    const {
      geoSwitcher,
      mainpage: { regionsData },
    } = getState();

    dispatch<IResetRegionExpansion>({
      type: RESET_REGION_EXPANSION_TYPE,
    });

    const regionId = geoSwitcher.region?.id ?? regionsData.subdomainRegionId;

    dispatch(setRegion([regionId]));
  };
}
