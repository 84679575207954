import * as React from 'react';
import { BACKGROUND_CONFIG, COMPOSITION_CONFIG } from './promo_config';
import { blocksClicksHandlers } from './tracking';
import { GeneralPromoBlock } from './vertical_blocks/GeneralPromoBlock';
import { IconicPromoBlock } from './vertical_blocks/iconic_promo_block';
import { MapPromoBlock } from './vertical_blocks/map_promo_block';
import { SummaryPromoBlock } from './vertical_blocks/summary_promo_block';
import { DomrfPromoBlock } from './vertical_blocks/domrf_promo_block';
import { IPromo, IPromoLink } from '../../types/promo';
import { ISeoBlockItem } from '../../types/seo_urls';
import { IRegionsData } from '../../types/regions_data';
import { EmptyRow } from '../empty_row/empty_row';
import { PromoMenu } from '../promo_menu/promo_menu';

interface IProps {
  promoBlocks: IPromo[];
  promoMenu: ISeoBlockItem[];
  regionsData: IRegionsData;
  section: string;
  domrfPromo: IPromo | null;
}

const s = require('./vertical_promo_blocks.css');

export class VerticalPromoBlocks extends React.Component<IProps> {
  public getBlockClassName(block: IPromo, index: number, blocks: IPromo[]) {
    const blocksCount = this.props.promoBlocks.length;
    const backgroundClass = BACKGROUND_CONFIG[block.id];
    let sizeClass = COMPOSITION_CONFIG[blocksCount][index];

    if (blocksCount === 2) {
      if (blocks[0].type !== 'iconic' && blocks[1].type !== 'iconic') {
        sizeClass = COMPOSITION_CONFIG[blocksCount][2];
      } else {
        if (block.type === 'iconic') {
          sizeClass = COMPOSITION_CONFIG[blocksCount][0];
        } else {
          sizeClass = COMPOSITION_CONFIG[blocksCount][1];
        }
      }
    }

    return {
      background: backgroundClass,
      size: sizeClass,
    };
  }

  public renderBlock(block: IPromo, index: number, blocks: IPromo[]) {
    const classes = this.getBlockClassName(block, index, blocks);

    const clickHandler = this.getClickHandler(block.id.toLowerCase());

    if (
      block.id === 'rentHouse' ||
      block.id === 'saleHouse' ||
      block.id === 'rentGarage' ||
      block.id === 'saleGarage' ||
      block.id === 'dailyRentFlat' ||
      block.id === 'dailyRentHostel'
    ) {
      return (
        <GeneralPromoBlock
          key={`promo-block-${index}`}
          backgroundClassName={classes.background}
          blockClassName={classes.size}
          withIcon
          {...block}
        />
      );
    }

    switch (block.type) {
      case 'summary':
        return (
          <SummaryPromoBlock
            key={`promo-block-${index}`}
            backgroundClassName={classes.background}
            blockClassName={classes.size}
            {...block}
          />
        );
      case 'general':
        return (
          <GeneralPromoBlock
            key={`promo-block-${index}`}
            backgroundClassName={classes.background}
            blockClassName={classes.size}
            {...block}
          />
        );
      case 'iconic':
        return (
          <IconicPromoBlock
            key={`promo-block-${index}`}
            backgroundClassName={classes.background}
            blockClassName={classes.size}
            clickHandler={clickHandler}
            {...block}
          />
        );
      case 'special':
        if (block.id.toLowerCase().includes('bscenter')) {
          return (
            <GeneralPromoBlock
              key={`promo-block-${index}`}
              backgroundClassName={classes.background}
              blockClassName={classes.size}
              withIcon
              {...block}
              links={[{ ...block.titleLink, title: block.title } as IPromoLink]}
            />
          );
        }

        return (
          <MapPromoBlock
            key={`promo-block-${index}`}
            blockClassName={classes.size}
            coordinates={this.props.regionsData.subdomainRegionCoordinates}
            section={this.props.section}
            subdomainId={this.props.regionsData.subdomainRegionId}
          />
        );
      default:
        return null;
    }
  }

  public render() {
    if (!COMPOSITION_CONFIG[this.props.promoBlocks.length]) {
      return <EmptyRow />;
    }
    const { domrfPromo } = this.props;

    return (
      <div className={s['c-promo-container']}>
        <div className="cg-container-fluid-xs">
          <h2 className={s['c-promo-title']}>Полезные ссылки</h2>

          <div className="cg-row">
            <div className="cg-col-18">
              <div className="cg-row" data-testid="UsefulLinks">
                {domrfPromo && !!domrfPromo.titleLink && (
                  <DomrfPromoBlock
                    url={domrfPromo.titleLink.url}
                    title={domrfPromo.title}
                    description={domrfPromo.titleLink.h1}
                  />
                )}
                {this.props.promoBlocks.map((block: IPromo, i: number) =>
                  this.renderBlock(block, i, this.props.promoBlocks),
                )}
              </div>
            </div>
            {<PromoMenu promoUrls={this.props.promoMenu} />}
          </div>
        </div>
      </div>
    );
  }

  private getClickHandler = (blockId: string) => {
    return blocksClicksHandlers[blockId];
  };
}
