/* eslint-disable max-lines*/
import * as React from 'react';
import * as Analytics from '../../../utils/analytics_helper';

import { CustomTooltip, Outside, Spinner } from '@cian/ui-kit';
import { scrollTo } from '@cian/utils/lib/shared/scroll_to';

import { getRegions, IRegion } from '../../../../app/api/get_regions';
import smartCompareValues, { MoscowRegions, SPBRegions } from '../../../utils/smart_compare_regions_id';
import { getCookie } from '../../../utils/helpers/cookie_helper';
import { IDropdownValue } from '../../../types/dropdown';
import { mergeStyles } from '@cian/utils';

const style = require('../search_filters.css');

interface IProps {
  section: string;
  value: number;
  values: IDropdownValue[];
  cities: IDropdownValue[];
  onChange(value: number): void;
}

interface IState {
  selectedIndex?: number;
  selectedValue?: string;
  value?: string;
  isPopupOpen?: boolean;
  regionsAndCities?: IDropdownValue[];
  byClick?: boolean;
  requestSent?: boolean;
}

interface IDropdownList {
  favorites: IDropdownValue[];
  other: IDropdownValue[];
}

function checkDropdownValues(
  dropdownList: IDropdownList | IDropdownValue[],
  key: string,
): dropdownList is IDropdownList {
  return key in dropdownList;
}

let values: IDropdownValue[];
let dropdownList: IDropdownList | IDropdownValue[] = [];

/* istanbul ignore next */
export class UserGeoFilter extends React.Component<IProps, IState> {
  protected container: HTMLDivElement;
  protected values: HTMLDivElement[];
  private mouseOverDisabled: boolean;
  private timeout: number;
  private geoInput: HTMLInputElement;
  private geoInputRef = React.createRef<HTMLInputElement>();

  public constructor(props: IProps) {
    super(props);

    let currentValue = '';

    values = props.values.slice();

    let geoId: string | undefined;

    if (props.section !== 'new-buildings') {
      const sessionRegionMainTownId = this.getRegionCookie('session_main_town_region_id');
      const sessionRegionId = this.getRegionCookie('session_region_id');
      const foreverRegionMainTownId = this.getRegionCookie('forever_main_town_region_id');
      const foreverRegionId = this.getRegionCookie('forever_region_id');

      geoId = sessionRegionMainTownId || sessionRegionId || foreverRegionMainTownId || foreverRegionId;
    }

    const mainTown = props.cities.find(city => city.value === Number(geoId || props.value));

    if (mainTown) {
      dropdownList = values.reduce(
        (acc, currentRegion) => {
          if (MoscowRegions.includes(Number(currentRegion.value)) || SPBRegions.includes(Number(currentRegion.value))) {
            acc.favorites.push(currentRegion);
          } else {
            acc.other.push(currentRegion);
          }

          return acc;
        },
        {
          favorites: [],
          other: [],
        } as IDropdownList,
      );

      dropdownList.other.push(mainTown);

      // sort alphabet order
      dropdownList.other = dropdownList.other.sort((A, B) => {
        if (A.label > B.label) {
          return 1;
        } else if (A.label < B.label) {
          return -1;
        }

        return 0;
      });
    }

    dropdownList = checkDropdownValues(dropdownList, 'favorites')
      ? [...dropdownList.favorites, ...dropdownList.other]
      : values;

    const selectedValues = dropdownList.find((region: IDropdownValue) => {
      return smartCompareValues(Number(region.value), Number(geoId || props.value));
    });

    if (selectedValues) {
      currentValue = selectedValues.label;
    } else {
      currentValue = dropdownList[0].label;
    }

    let selectedIndex = dropdownList.findIndex((region: IDropdownValue) => region.value === currentValue);

    selectedIndex = selectedIndex === -1 ? 0 : selectedIndex;

    this.state = {
      selectedIndex,
      selectedValue: currentValue,
      value: currentValue,
      isPopupOpen: false,
      regionsAndCities: [] as IDropdownValue[],
      byClick: false,
      requestSent: false,
    };
  }

  public render() {
    const value = (this.state.value || '').trim();

    const searchList = this.renderSearchList(value);

    // массив ref'ов
    this.values = [];

    return (
      <div
        role="search"
        onClick={() => this.onInputContainerClick()}
        className={`${style['c-filters-field']} ${style['c-filters-field-region']}`}
      >
        <Outside onOutside={() => this.onOutsideClick()}>
          <div>
            <input
              ref={this.geoInputRef}
              type="text"
              className={style['c_filters-suggest_input_field']}
              placeholder="Введите регион или город"
              value={this.state.value ? this.state.value : ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onTextChanged(event.currentTarget.value)}
              onKeyDown={event => this.onKeyDown(event)}
            />
            <button
              type="button"
              {...mergeStyles(
                style['c_filters-suggest_input-drop_icon'],
                style['c_filters-suggest_input-drop_icon--simple_filter'],
                this.state.isPopupOpen && style['c_filters-suggest_input-drop_icon--opened'],
              )}
              onClick={() => this.handleDropDownChevronClick()}
            ></button>
            {this.state.isPopupOpen && (
              <CustomTooltip
                content={searchList}
                anchorRef={this.geoInputRef}
                open
                placement="bottom-start"
                portal={false}
              />
            )}
          </div>
        </Outside>
      </div>
    );
  }

  public scrollTo(index: number, center: boolean = false) {
    const containerElement = this.container;
    const valueElement = this.values[index];

    if (!valueElement) {
      return;
    }

    window.clearTimeout(this.timeout);
    this.mouseOverDisabled = true;

    scrollTo({ center, containerElement, targetElement: valueElement });

    this.timeout = window.setTimeout(() => {
      this.mouseOverDisabled = false;
    }, 100);
  }

  public onIndexMouseOver(index: number) {
    this.setState({ selectedIndex: index });
  }

  protected handleValueMouseOver(index: number) {
    if (this.mouseOverDisabled) {
      window.clearTimeout(this.timeout);
      this.mouseOverDisabled = false;

      return;
    }

    this.onIndexMouseOver(index);
  }

  private handleDropDownChevronClick = () => {
    if (this.state.isPopupOpen) {
      this.geoInput.blur();
      this.onOutsideClick();
    } else {
      this.geoInput.focus();
    }
  };

  private getRegionCookie = (key: string): string => {
    if (typeof window !== 'undefined') {
      return getCookie(document.cookie, key);
    }

    return '';
  };

  private renderSearchList(searchText: string): JSX.Element {
    const searchList = this.getSource();

    const fullList = this.renderFullList(searchList);
    const filteredList = this.renderFilteredList(searchList, searchText);

    return (
      <div
        ref={(container: HTMLDivElement) => (this.container = container)}
        className={style['c_filters-suggest_container']}
      >
        {this.state.requestSent && (
          <div className={style['c_filters-suggest_loader']}>
            <Spinner />
          </div>
        )}
        {filteredList.length !== 0 && this.state.byClick && <div>{fullList}</div>}
        {filteredList.length !== 0 && !this.state.byClick && <div>{filteredList}</div>}
        {!this.state.requestSent && filteredList.length === 0 && !this.state.byClick && (
          <div className={style['c_filters-suggested_message']}>
            <div className={style['c_filters-suggested_message_text']}>
              <div className={style['c_filters-suggested_message_text--title']}>
                <b>Ничего не найдено</b>
              </div>
              <div className={style['c_filters-suggested_message_text--light']}>
                Введите другой город
                <br />
                или выберите регион из списка
              </div>
            </div>
            <hr className={style['c_filters-suggested_delimiter']} />
            {fullList}
          </div>
        )}
      </div>
    );
  }

  private renderFullList(source: IDropdownValue[]): JSX.Element[] {
    return source.map((item, index) => {
      return (
        <div
          key={`user-geo-filter-suggested-${index}`}
          role="search"
          ref={(valueElement: HTMLDivElement) => (this.values[index] = valueElement)}
          {...mergeStyles(
            style['c_filters-suggested_items'],
            this.state.selectedIndex === index && style['c_filters-suggested_items--focus'],
            item.label === this.state.selectedValue && style['c_filters-suggested_items--selected'],
          )}
          onClick={() => this.onItemSelected(item, index)}
          onMouseOver={() => this.handleValueMouseOver(index)}
        >
          {item.label}
        </div>
      );
    });
  }

  private filterList = (source: IDropdownValue[], searchText: string): IDropdownValue[] => {
    return source.filter(item => item.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);
  };

  private renderFilteredList(source: IDropdownValue[], searchText: string): JSX.Element[] {
    return this.filterList(source, searchText).map((item, index) => {
      return (
        <div
          key={`user-geo-filter-${index}`}
          role="search"
          ref={(valueElement: HTMLDivElement) => (this.values[index] = valueElement)}
          {...mergeStyles(
            style['c_filters-suggested_items'],
            this.state.selectedIndex === index && style['c_filters-suggested_items--focus'],
            item.label === this.state.selectedValue && style['c_filters-suggested_items--selected'],
          )}
          onClick={() => this.onItemSelected(item, index)}
          onMouseOver={() => this.handleValueMouseOver(index)}
        >
          {item.label}
        </div>
      );
    });
  }

  private onKeyDown(event: React.KeyboardEvent): void {
    const selectedIndex = this.state.selectedIndex as number;
    const source = this.getSource();

    switch (event.keyCode) {
      case 38: //up key
        this.setState(
          {
            selectedIndex: selectedIndex - 1 > 0 ? selectedIndex - 1 : 0,
          },
          () => this.state.selectedIndex && this.scrollTo(this.state.selectedIndex),
        );
        break;

      case 40: //down key
        this.setState(
          {
            selectedIndex: selectedIndex + 1 >= source.length ? source.length - 1 : selectedIndex + 1,
          },
          () => this.state.selectedIndex && this.scrollTo(this.state.selectedIndex),
        );
        break;

      case 13: {
        //enter key
        const items = this.filterList(source, this.state.value || '');

        const index = !items.length
          ? source.findIndex(item => item.label === source[selectedIndex].label)
          : source.findIndex(item => item.label === items[selectedIndex].label);

        this.onItemSelected(items.length ? items[selectedIndex] : source[selectedIndex], index);
        break;
      }

      default:
        return;
    }
  }

  private getIndex(): number {
    return this.getSource().findIndex(item => item.label === this.state.selectedValue);
  }

  private onInputContainerClick(): void {
    if (this.state.isPopupOpen) {
      return;
    }

    Analytics.clickGeoSuggest(this.props.section);

    this.setState(
      {
        isPopupOpen: true,
        byClick: true,
        value: '',
      },
      () => this.state.selectedIndex && this.scrollTo(this.state.selectedIndex, true),
    );
  }

  private onOutsideClick(): void {
    const index = this.getIndex();

    this.setState({
      selectedIndex: index,
      value: this.state.selectedValue,
      isPopupOpen: false,
    });
  }

  private onTextChanged(value: string): void {
    if (this.state.regionsAndCities && !this.state.regionsAndCities.length) {
      this.getRegionsAndCities();
    }

    const index = value ? 0 : this.getIndex();

    this.setState(
      {
        selectedIndex: index,
        value,
        isPopupOpen: true,
        byClick: false,
      },
      () => {
        if (!value && this.state.selectedIndex) {
          this.scrollTo(this.state.selectedIndex, true);
        }
      },
    );
  }

  private onItemSelected(region: IDropdownValue, index: number): void {
    this.setState({
      selectedIndex: index,
      selectedValue: region.label as string,
      value: region.label as string,
      isPopupOpen: false,
    });

    this.props.onChange(region.value as number);
  }

  private getRegionsAndCities(): void {
    if (this.state.requestSent) {
      return;
    }

    if (this.props.section === 'new-buildings') {
      this.setState({
        regionsAndCities: values,
      });

      return;
    }

    this.setState({ requestSent: true });

    getRegions()
      .then((regions: IRegion[]) => {
        const fullList = regions.map((item: IRegion) => {
          return {
            label: item.displayName,
            value: item.id,
          } as IDropdownValue;
        });

        this.setState({
          regionsAndCities: fullList,
          requestSent: false,
        });
      })
      .catch(error => {
        this.setState({
          requestSent: false,
        });
        console.error(error);
      });
  }

  private getSource(): IDropdownValue[] {
    if (this.state.regionsAndCities && this.state.regionsAndCities.length) {
      return this.state.regionsAndCities;
    }

    return dropdownList as IDropdownValue[];
  }
}
