import { TThunkAction } from '../../types/redux';

import { actionGenerator } from '../../utils/redux/actionGenerator';
import { EVacanciesActionType } from './types';
import { EVacanciesStatus, getFilterVacancies } from '../../services/getFilterVacancies';
import { TVacancies } from '../../types/vacancies';

export const getVacanciesFetching = actionGenerator<EVacanciesActionType.fetching>(EVacanciesActionType.fetching);
export const getVacanciesSuccess = actionGenerator<EVacanciesActionType.success, TVacancies>(
  EVacanciesActionType.success,
);
export const getVacanciesFailure = actionGenerator<EVacanciesActionType.failure>(EVacanciesActionType.failure);

export const getVacancies = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { logger, httpApi } = context;

    dispatch(getVacanciesFetching());

    const state = getState();
    const regionId = state.mainpage.regionsData.subdomainCityId;

    try {
      const {
        status,
        data: { vacancies },
      } = await getFilterVacancies({ logger, httpApi }, { regionId, pageSize: 3 });

      if (status !== EVacanciesStatus.Success) {
        dispatch(getVacanciesFailure());

        return;
      }

      dispatch(getVacanciesSuccess(vacancies || []));
    } catch (e) {
      dispatch(getVacanciesFailure());
    }
  };
};
