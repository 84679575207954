import * as React from 'react';
import { formatPrice } from '../../../shared/utils/format_helper';
import { Link } from '../link/link';
import { IPromo, IPromoLink } from '../../../shared/types/promo';

const s = require('./promo_links.css');

interface IProps {
  promo: IPromo;
  onLinkClick(url: string): void;
}

export const PromoLinks = (props: IProps) => (
  <div className={s['c-promo-block-text-content']}>
    <h5 className={s['c-promo-title']}>{props.promo.title}</h5>
    <ul className={s['c-promo-list']}>
      {props.promo.links.map((promoLink: IPromoLink) => (
        <li key={promoLink.url} className={s['c-promo-list-item']}>
          <Link
            href={promoLink.url}
            className={`${s['c-promo-link']}`}
            onClick={() => props.onLinkClick(promoLink.url)}
            title={promoLink.h1}
          >
            {promoLink.title}
          </Link>
          <span className={s['c-promo-count']}>{formatPrice(promoLink.objectCount, 4)}</span>
        </li>
      ))}
    </ul>
  </div>
);
