import { IGetRegionResponse200 } from '../../../repositories/monolith-python/v1/get-region';
import { IRegion } from '../../../types/geoSwitcher';
import { TThunkAction } from '../../../types/redux';
import { getRegionFailed } from './getRegionFailed';
import { getRegionLoading } from './getRegionLoading';
import { getRegionSucceed } from './getRegionSucceed';
import { fetchRegion } from '../../../services/region/fetchRegion';

function prepareRegion({ response: { data: region } }: IGetRegionResponse200): IRegion {
  return {
    id: region.id,
    displayName: region.displayName,
    mainTownId: region.mainTownId || null,
    baseHost: region.baseHost || null,
  };
}

export const getRegion = (regionId: number): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const {
      httpApi,
      logger,
      custom: { subdomain },
    } = context;

    dispatch(getRegionLoading());

    const response = await fetchRegion(
      { httpApi, logger },
      {
        regionId,
        subdomain,
      },
    );

    if (response !== null) {
      dispatch(getRegionSucceed(prepareRegion(response)));
    } else {
      dispatch(getRegionFailed());
    }
  };
};
