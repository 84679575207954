import * as React from 'react';
import { INewbuildingsRecommendations, IRecommendations } from '../../types/newbuildingRecommendations';
import { RecommendationCard, ItemsCarousel } from '../../components/NewbuildingRecommendations';
import classNames from 'classnames';
import { RecommendationsSection } from '../../components/NewbuildingRecommendations/RecommendationsSection/RecommendationsSection';
import { RecommendationReview } from '../../components/NewbuildingRecommendations/RecommendationReview';
import { Underground } from '../../components/NewbuildingRecommendations/Underground';
import { LinkButton } from '@cian/ui-kit';

import { bannerTypeToDefaultTheme } from '../../components/NewbuildingBanners/utils/bannerTypeToTheme';
import { Banner } from './Banner';
import { TBanners } from '../../types/newbuildingBanners/banners';
import { mergeBannersAndRecommendations } from './helpers/mergeBannersAndRecommendations';
import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import {
  onCardClickAnalytic,
  onRecommendationsSectionShowAnalytic,
  onShowAllClickAnalytic,
} from './analytics/analytic';

export interface INewbuildingRecommendationsProps {
  newbuildings: INewbuildingsRecommendations;
  banners: TBanners[];
}

export const NewbuildingRecommendations = ({ newbuildings, banners }: INewbuildingRecommendationsProps) => {
  const bannersAndRecommendations = mergeBannersAndRecommendations({ newbuildings, banners });

  const onRecommendationsShow = React.useCallback(() => {
    const products = newbuildings.recommendations.map((item, idx) => ({ id: item.id, position: idx }));
    onRecommendationsSectionShowAnalytic(products);
  }, [newbuildings]);

  const onShowAllClick = React.useCallback(() => {
    onShowAllClickAnalytic();
  }, []);

  const onCardClick = React.useCallback(
    ({ newbuildingId, position }: { newbuildingId: number; position: number }) =>
      () => {
        onCardClickAnalytic({ newbuildingId, position });
      },
    [],
  );

  const renderOutside = React.useCallback(() => {
    const items = bannersAndRecommendations.map((data: IRecommendations | TBanners, idx) => {
      if (data && data.type) {
        return (
          <li key={`banner-${idx}`}>
            <Banner data={data as TBanners} themeMapper={bannerTypeToDefaultTheme} />
          </li>
        );
      } else {
        const item = data as IRecommendations;

        return (
          <li key={`recommendation-${idx}`}>
            <RecommendationCard
              url={item.url}
              name={item.name}
              status={item.status}
              minPrice={item.minPrice || ''}
              mediaUrl={item.media[0] ? item.media[0].url : ''}
              underground={item.underground ? <Underground underground={item.underground} /> : null}
              reviews={item.review.reviewCount ? <RecommendationReview review={item.review} /> : null}
              key={item.id}
              onClick={onCardClick({ newbuildingId: item.id, position: idx })}
            />
          </li>
        );
      }
    });

    return items;
  }, [bannersAndRecommendations]);

  return (
    <div className={classNames('cg-container-fluid-xs')}>
      <ActionAfterViewed callback={onRecommendationsShow}>
        <RecommendationsSection
          title="Подобрали ЖК для вас"
          watchAll={
            <LinkButton
              href={newbuildings.allRecommendations.link}
              onClick={onShowAllClick}
              theme="fill_secondary"
              fullWidth={true}
              target="_blank"
            >
              Посмотреть еще
            </LinkButton>
          }
        >
          <ItemsCarousel scrollVisible renderOutside={renderOutside} />
        </RecommendationsSection>
      </ActionAfterViewed>
    </div>
  );
};
