import { Button } from '@cian/ui-kit';
import * as React from 'react';
import { IRegion } from '../../../../types/geoSwitcher/types';

import * as styles from './GeoSwitcherBody.css';

interface IGeoSwitcherBodyProps {
  popularCities: IRegion[];
  onChangeRegion(city: IRegion): void;
}

export const GeoSwitcherBody: React.FC<IGeoSwitcherBodyProps> = ({ popularCities, onChangeRegion }) => {
  return (
    <div className={styles['container']}>
      <div className={styles['cities-tabs']}>
        {popularCities.slice(0, 10).map(city => (
          <Button key={city.id} theme="fill_secondary" size="XS" onClick={() => onChangeRegion(city)}>
            {city.displayName}
          </Button>
        ))}
      </div>
      <ul className={styles['cities']}>
        {popularCities.map(city => (
          <li key={city.id} className={styles['city']}>
            <button className={styles['city-button']} onClick={() => onChangeRegion(city)}>
              {city.displayName}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
