import cx from 'classnames';
import * as React from 'react';

import * as styles from './style.css';
import { LinksBlock } from './LinksBlock';
import { INewbuildingSeoBlockData } from '../../types/newbuildingSeoBlock';
import { FullLinksBlock } from './FullLinksBlock';
import { CategoryButton } from './CategoryButton';

interface ISeoLinksProps {
  region: INewbuildingSeoBlockData | null;
  city: INewbuildingSeoBlockData | null;
}

export function NewbuildingSeoBlock({ region, city }: ISeoLinksProps) {
  const [activeBlock, setActiveBlock] = React.useState<string | null>(null);

  return (
    <div className={styles['container']}>
      {activeBlock === null && (
        <div className={cx(styles['grid'], styles['grid_fit'])}>
          {region && <LinksBlock seoBlock={region} onClick={() => setActiveBlock('region')} />}
          {city && <LinksBlock seoBlock={city} onClick={() => setActiveBlock('city')} />}
        </div>
      )}

      {region && <FullLinksBlock seoBlock={region} isActive={activeBlock === 'region'} />}
      {city && <FullLinksBlock seoBlock={city} isActive={activeBlock === 'city'} />}

      {activeBlock !== null && (
        <div className={cx(styles['actions-row'], styles['grid'])}>
          {region && (
            <CategoryButton
              onClick={() => setActiveBlock('region')}
              isActive={activeBlock === 'region'}
              text={region.title}
            />
          )}
          {city && (
            <CategoryButton
              onClick={() => setActiveBlock('city')}
              isActive={activeBlock === 'city'}
              text={city.title}
            />
          )}
        </div>
      )}
    </div>
  );
}
