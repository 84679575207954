import * as React from 'react';

abstract class GenericFilter<P, S = void> extends React.Component<P, S> {
  protected abstract setDirty(isDirty: boolean): void;

  public componentDidMount() {
    this.setDirty(true);
  }

  public componentWillUnmount() {
    this.setDirty(false);
  }
}

export { GenericFilter };
