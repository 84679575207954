import { IBuildingSuggest, ILocationSuggest, IUndergroundSuggest } from '@cian/geosuggest-widget';
// eslint-disable-next-line import/no-restricted-paths
import { createMakeRequest } from '../../browser/client';

type ICoords = [number, number];
export type TBoundedBy = [ICoords, ICoords];

export type TSuggestType =
  | 'addresses'
  | 'cities'
  | 'districts'
  | 'undergrounds'
  | 'newbuildings'
  | 'businessCenters'
  | 'shoppingCenters'
  | 'railway'
  | 'village'
  | 'road';

type IMatch = ICoords;
export type TMatches = IMatch[];

export interface ILine {
  lineColor: string;
}

export interface ISuggest<T> {
  items: T[];
}

export interface IRailwaySuggest {
  id: number;
  name: string;
  fullName: string;
  matches: TBoundedBy;
  type: TSuggestType;
  regionId?: number;
}

export interface IStructuredSuggestRequest {
  offerType: string;
  regionId: number;
  query: string;
  searchType: string;
}

export interface IStructuredSuggestions {
  cities: ISuggest<ILocationSuggest>;
  districts: ISuggest<ILocationSuggest>;
  undergrounds: ISuggest<IUndergroundSuggest>;
  newbuildings: ISuggest<IBuildingSuggest>;
  businessCenters: ISuggest<IBuildingSuggest>;
  shoppingCenters: ISuggest<IBuildingSuggest>;
  railway: ISuggest<IRailwaySuggest>;
  villages: ISuggest<IBuildingSuggest>;
  roads: ISuggest<ILocationSuggest>;
}

export interface IStructuredSuggestData {
  suggestions: IStructuredSuggestions;
  sortOrder: string[];
}

export interface IStructuredSuggestResponse {
  status: 'ok';
  data: IStructuredSuggestData;
}

const mapSearchType: { [key: string]: string } = {
  buy: 'sale',
  rent_long: 'rent',
  rent_short: 'rent',
};
export function suggestParamsToQueryString(params: IStructuredSuggestRequest): string {
  const dealType = mapSearchType[params.searchType];

  return [
    `regionId=${params.regionId}`,
    `query=${encodeURIComponent(params.query)}`,
    `offerType=${params.offerType}`,
    dealType && `dealType=${dealType}`,
    'source=mainpage',
  ]
    .filter(Boolean)
    .join('&');
}

export function getStructuredSuggestions(
  suggestionRequest: IStructuredSuggestRequest,
): Promise<IStructuredSuggestData> {
  const request = createMakeRequest(window.__mainpage_geo_suggest_api_base_url__);

  return request({
    method: 'GET',
    uri: {
      path: '/v1/suggest/',
      query: suggestParamsToQueryString(suggestionRequest),
    },
  }).then((response: IStructuredSuggestResponse) => {
    return response.data;
  });
}
