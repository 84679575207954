import * as React from 'react';
import { formatPrice } from '../../utils/format_helper';

interface IProps {
  url: string;
  objectCount: number;
  h1: string;
  title: string;
}

const s = require('./commercial_promo_link.css');

export class CommercialPromoLink extends React.Component<IProps> {
  public render() {
    return (
      <div className={s['c-com-promo-block-links-col']}>
        <a className={s['c-com-promo-block-links-item']} href={this.props.url}>
          <span className={s['c-com-promo-link-text']}>{this.props.title}</span>
          <span className={s['c-com-promo-link-count']}>{formatPrice(this.props.objectCount, 4)}</span>
        </a>
      </div>
    );
  }
}
