import { TReduxActions } from '../../../types/redux';
import { RESET_LAST_ROOMS, SET_LAST_ROOMS } from '../../../constants/action_types';
import { ERoomType } from '../../../../packages/api-models/common/json_query';

export function lastRoomsReducer(state: ERoomType[] = [], action: TReduxActions): ERoomType[] {
  switch (action.type) {
    case SET_LAST_ROOMS: {
      return action.payload;
    }

    case RESET_LAST_ROOMS: {
      return [];
    }

    default:
      return state;
  }
}
