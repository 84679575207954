import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import { Dispatch } from 'redux';

import { trackAutoOfferChangeToSuburban } from '../../../../shared/utils/analytics_helper';
import { DEFAULT_FILTERS } from './default_filters';
import { TReduxActions, TThunkAction } from '../../../../shared/types/redux';
import { IBuildingTypes } from '../../../../shared/types/filters/building_types';

export const suburbanTypes = ['house', 'housepart', 'townhouse', 'sector'];

const BUILDING_TYPE_SET_TYPE = 'buildingType/setType';
const BUILDING_TYPE_REMOVE_TYPE = 'buildingType/removeType';
const BUILDING_TYPE_SET_DERTY = 'buildingType/setDirty';
const BUILDING_TYPE_SET_SECTION = 'buildingType/setSection';
const BUILDING_TYPE_UPDATE_TYPE = 'buildingType/updateType';
const BUILDING_TYPE_TOGGLE_POPUP_AUTO_CHANGE_TO_SUBURBAN = 'buildingType/togglePopupAutoChangeToSuburban';

type TTypedReduxAction<Type = string, Payload = undefined> = { type: Type; payload: Payload };

type TSetBuildingType = TTypedReduxAction<typeof BUILDING_TYPE_SET_TYPE, { buildingType: string }>;
type TRemoveBuildingType = TTypedReduxAction<typeof BUILDING_TYPE_REMOVE_TYPE, { buildingType: string }>;
type TSetDirty = TTypedReduxAction<typeof BUILDING_TYPE_SET_DERTY, { isDirty: boolean }>;
type TSetBuildingTypeSectione = TTypedReduxAction<
  typeof BUILDING_TYPE_SET_SECTION,
  { section: string; buildingType: string[] }
>;
type TUpdateBuildingType = TTypedReduxAction<typeof BUILDING_TYPE_UPDATE_TYPE, { buildingTypes: string[] }>;
type TTogglePopupAutoChangeToSuburban = TTypedReduxAction<
  typeof BUILDING_TYPE_TOGGLE_POPUP_AUTO_CHANGE_TO_SUBURBAN,
  { show: boolean }
>;

export type TAllActions =
  | TSetBuildingType
  | TRemoveBuildingType
  | TSetDirty
  | TSetBuildingTypeSectione
  | TUpdateBuildingType
  | TTogglePopupAutoChangeToSuburban;

export const setBuildingType = (buildingType: string): TSetBuildingType => ({
  type: BUILDING_TYPE_SET_TYPE,
  payload: {
    buildingType,
  },
});

export const removeBuildingType = (buildingType: string): TRemoveBuildingType => ({
  type: BUILDING_TYPE_REMOVE_TYPE,
  payload: {
    buildingType,
  },
});

export const setDirty = (isDirty: boolean): TSetDirty => ({
  type: BUILDING_TYPE_SET_DERTY,
  payload: {
    isDirty,
  },
});

export const setBuildingTypeSection = (section: string): TSetBuildingTypeSectione => ({
  type: BUILDING_TYPE_SET_SECTION,
  payload: {
    section,
    buildingType: DEFAULT_FILTERS[section].building_type_value,
  },
});

export const updateBuildingType = (buildingTypes: string[]): TUpdateBuildingType => ({
  type: BUILDING_TYPE_UPDATE_TYPE,
  payload: {
    buildingTypes,
  },
});

export const togglePopupAutoChangeToSuburban = (show: boolean): TTogglePopupAutoChangeToSuburban => ({
  type: BUILDING_TYPE_TOGGLE_POPUP_AUTO_CHANGE_TO_SUBURBAN,
  payload: {
    show,
  },
});

export function selectSuburbanBuildingType(id: number): TThunkAction {
  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const { buildingType } = state.buildingType;
    if (intersection(buildingType, suburbanTypes).length === 0) {
      trackAutoOfferChangeToSuburban(id, buildingType);
      dispatch(togglePopupAutoChangeToSuburban(true));
      dispatch(updateBuildingType(suburbanTypes));
    }
  };
}

const initialState: IBuildingTypes = {
  buildingType: ['flat'],
  isDirty: false,
  canShowPopupAutoChangeToSuburban: false,
};

export const buildingTypeReducer = (state = initialState, action: TReduxActions): IBuildingTypes => {
  let nextState = state;
  switch (action.type) {
    case BUILDING_TYPE_SET_TYPE:
      if (intersection([action.payload.buildingType], suburbanTypes).length) {
        nextState = {
          ...state,
          buildingType: uniq([
            ...state.buildingType.filter(item => suburbanTypes.includes(item)),
            action.payload.buildingType,
          ]),
        };
      } else {
        nextState = {
          ...state,
          buildingType: [action.payload.buildingType],
        };
      }
      break;
    case BUILDING_TYPE_REMOVE_TYPE: {
      const nextBuildingType = state.buildingType.filter(type => type !== action.payload.buildingType);
      nextState = {
        ...state,
        buildingType: nextBuildingType.length ? nextBuildingType : state.buildingType,
      };
      break;
    }

    case BUILDING_TYPE_SET_DERTY:
    case BUILDING_TYPE_SET_SECTION:
      nextState = { ...state, ...action.payload };
      break;
    case BUILDING_TYPE_UPDATE_TYPE:
      nextState = {
        ...state,
        buildingType: action.payload.buildingTypes,
      };
      break;
    case BUILDING_TYPE_TOGGLE_POPUP_AUTO_CHANGE_TO_SUBURBAN:
      nextState = {
        ...state,
        canShowPopupAutoChangeToSuburban: action.payload.show,
      };
      break;
    default:
      nextState = state;
  }

  return nextState;
};
