export const getOfferType = (buildingType: string): string => {
  switch (buildingType) {
    case 'flat':
    case 'bed':
    case 'secondary_flat':
    case 'room':
    case 'part':
      return 'flat';
    case 'house':
    case 'housepart':
    case 'townhouse':
    case 'sector':
      return 'suburban';
    case 'new_flat':
      return 'newobject';
    default:
      return 'commercial';
  }
};
