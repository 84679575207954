import { ITypedReduxAction } from '../../types/redux/actionType';
import { TUser } from '../../types/user';

export enum EGetUserStatusActionType {
  Loading = 'user/getUser/loading',
  Succeed = 'user/getUser/succeed',
  Failed = 'user/getUser/failed',
}

export type TGetUserLoading = ITypedReduxAction<EGetUserStatusActionType.Loading>;
export type TGetUserSucceed = ITypedReduxAction<EGetUserStatusActionType.Succeed, TUser>;
export type TGetUserFailed = ITypedReduxAction<EGetUserStatusActionType.Failed>;

export type TUserActions = TGetUserLoading | TGetUserSucceed | TGetUserFailed;
