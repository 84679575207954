import { Action } from 'redux';
import { batch } from 'react-redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SELECT_OFFER_TYPE_TYPE } from '../../constants/action_types';
import { FOfferType, isSuburban } from '../../utils/category';
import { getMeta } from './getMeta';
import { resetRegionExpansion, setRegionExpansion } from '../regionExpansion';
import { getCookie } from '../../utils/helpers/cookie_helper';
import { EXPANSION_REGION_DISABLE_COOKIE } from '../../constants/region';
import { getTermsValue } from '../../utils/jsonQuery';

export type TSelectOfferTypeType = 'filters/select_offer_type';

export interface ISelectOfferType extends Action<TSelectOfferTypeType> {
  type: TSelectOfferTypeType;
  offerType: FOfferType;
}

export type TSelectOfferType = ISelectOfferType;

export function selectOfferType(offerType: FOfferType): TThunkAction {
  return (dispatch, getState) => {
    batch(async () => {
      dispatch<TSelectOfferType>({
        type: FILTERS_SELECT_OFFER_TYPE_TYPE,
        offerType,
      });

      const {
        regionExpansion,
        filters: { jsonQuery, geoSearch },
      } = getState();

      const regions = getTermsValue('region')(jsonQuery);
      const isSuburbanOfferType = isSuburban(offerType);

      if (
        isSuburbanOfferType &&
        !regionExpansion &&
        !geoSearch &&
        !getCookie(document.cookie, EXPANSION_REGION_DISABLE_COOKIE)
      ) {
        await dispatch(setRegionExpansion());
      } else if (!isSuburbanOfferType && regionExpansion && regionExpansion === regions[0]) {
        dispatch(resetRegionExpansion());
      }

      dispatch(getMeta());
    });
  };
}
