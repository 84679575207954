import { IRegion } from '../../../types/geoSwitcher';
import { ITypedReduxAction } from '../../../types/redux/actionType';

export enum EGetRegionActionTypes {
  Loading = 'getRegion/loading',
  Succeed = 'getRegion/succeed',
  Failed = 'getRegion/failed',
}

export type TGetRegionLoading = ITypedReduxAction<EGetRegionActionTypes.Loading>;
export type TGetRegionSucceed = ITypedReduxAction<EGetRegionActionTypes.Succeed, IRegion>;
export type TGetRegionFailed = ITypedReduxAction<EGetRegionActionTypes.Failed>;

export type TGetRegion = TGetRegionLoading | TGetRegionSucceed | TGetRegionFailed;
