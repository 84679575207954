import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { GenericFilter } from './generic_filter';
import { YearFilter as YearFilterInput } from '../../components/search_filters/filters/year_filter';
import { setYear, setDirty } from '../../reducers/modules/search/year';
import { IYear } from '../../types/filters/year';
import { IApplicationState } from '../../types/redux';

interface IProps {
  year: IYear;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
}

class YearFilter extends GenericFilter<IProps> {
  protected setDirty(isDirty: boolean) {
    this.props.dispatch(setDirty(isDirty));
  }

  public render() {
    return (
      <YearFilterInput
        values={this.props.year.years}
        onChange={(values: string[]) => this.props.dispatch(setYear(values))}
      />
    );
  }
}

const YearFilterWrapper = connect((state: IApplicationState) => ({
  year: state.year,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}))(YearFilter as any);

export { YearFilterWrapper as YearFilter, YearFilter as YearFilterClass };
