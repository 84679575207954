import { ITypedReduxAction } from '../../types/redux/actionType';
import { IPromoBanners } from '../../types/newbuildingBanners/newbuildingBanners';

export enum EGetNewbuildingBannersStatusActionType {
  Loading = 'newbuildingBanners/loading',
  Succeed = 'newbuildingBanners/succeed',
  Failed = 'newbuildingBanners/failed',
}

export type TGetNewbuildingBannersLoading = ITypedReduxAction<EGetNewbuildingBannersStatusActionType.Loading>;
export type TGetNewbuildingBannersSucceed = ITypedReduxAction<
  EGetNewbuildingBannersStatusActionType.Succeed,
  IPromoBanners
>;
export type TGetNewbuildingBannersFailed = ITypedReduxAction<EGetNewbuildingBannersStatusActionType.Failed>;

export type TNewbuildingBannersActions =
  | TGetNewbuildingBannersLoading
  | TGetNewbuildingBannersSucceed
  | TGetNewbuildingBannersFailed;
