export const DEAL_RENT_TRAP_MAINPAGE = 'deal_rent_trap_mainpage';

export const AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT = 'aud_desktop_aud2776_cian_assistant_entry_point';

export const NEWBUILDING_ENTRY_POINTS_MAIN_DESKTOP_ENABLED = 'newbuilding_entry_points_main_desktop';

export const EXPERIMENTS = [
  DEAL_RENT_TRAP_MAINPAGE,
  AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT,
  NEWBUILDING_ENTRY_POINTS_MAIN_DESKTOP_ENABLED,
];
