import { SCROLL_VISIBLE_DEAD_ZONE } from '../constants';

export const findRightElement = (
  childCollection: HTMLCollection,
  rightBorder: number,
  offset: number,
): HTMLElement | null => {
  const childList = Array.from(childCollection);

  const candidate = childList.find((child: HTMLElement) => {
    const rightX = child.offsetLeft - offset + child.offsetWidth;

    return rightX > rightBorder + SCROLL_VISIBLE_DEAD_ZONE;
  });

  return (candidate as HTMLElement) || null;
};
