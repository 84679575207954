import { actionGenerator } from '../../utils/redux/actionGenerator';
import { EGetNewbuildingRecommendationsStatusActionType, TGetNewbuildingRecommendationsSucceed } from './types';
import { TThunkAction } from '../../types/redux';
import { INewbuildingsRecommendations } from '../../types/newbuildingRecommendations';
import {
  fetchGetUserRecommendations,
  TGetUserRecommendationsResponse,
} from '../../repositories/newbuilding-search/v1/get-user-recommendations';

export const setNewbuildingRecommendationsLoading =
  actionGenerator<EGetNewbuildingRecommendationsStatusActionType.Loading>(
    EGetNewbuildingRecommendationsStatusActionType.Loading,
  );
export const setNewbuildingRecommendationsFailed =
  actionGenerator<EGetNewbuildingRecommendationsStatusActionType.Failed>(
    EGetNewbuildingRecommendationsStatusActionType.Failed,
  );

export const setNewbuildingRecommendationsSucceed: (
  recommendations: INewbuildingsRecommendations,
) => TGetNewbuildingRecommendationsSucceed = actionGenerator<
  EGetNewbuildingRecommendationsStatusActionType.Succeed,
  INewbuildingsRecommendations
>(EGetNewbuildingRecommendationsStatusActionType.Succeed);

export function getNewbuildingRecommendations(): TThunkAction {
  return async (dispatch, getState, context) => {
    const { httpApi } = context;
    dispatch(setNewbuildingRecommendationsLoading());
    try {
      const { statusCode, response }: TGetUserRecommendationsResponse = await fetchGetUserRecommendations({
        httpApi,
        parameters: {},
      });

      if (statusCode !== 200) {
        dispatch(setNewbuildingRecommendationsFailed());
      }

      dispatch(setNewbuildingRecommendationsSucceed(response as INewbuildingsRecommendations));
    } catch (error) {
      dispatch(setNewbuildingRecommendationsFailed());
    }
  };
}
