import { RadioButtonGroup, NumberInput, InputGroup, InputAdornment } from '@cian/ui-kit';

import React, { ChangeEvent, memo, useCallback } from 'react';
import { EJsonQueryPriceType } from '../../../../../../packages/api-models/common/json_query';

import { usePriceTypeSelector } from '../../../../../hooks/selectors/usePriceTypeSelector';

import { coworkingSwitchButtonsMapper } from '../../mappers/coworkingSwitchButtonsMapper';

import * as styles from './CoworkingPriceDropdown.css';
import { ICoworkingPriceDropdownProps } from './types';

export const CoworkingPriceDropdown = memo<ICoworkingPriceDropdownProps>(function CoworkingPriceDropdown(props) {
  const { isErroneous, max, min, onMaxBlur, onMaxValueChange, onMinBlur, onMinValueChange, onPriceTypeChange } = props;

  const priceType = usePriceTypeSelector() || 'all';

  const handlePriceTypeChange = useCallback<(event: ChangeEvent<HTMLInputElement>, value: EJsonQueryPriceType) => void>(
    (_event, coworkingPriceType) => {
      if (Object.values(EJsonQueryPriceType).includes(coworkingPriceType)) {
        onPriceTypeChange(coworkingPriceType);
      } else {
        onPriceTypeChange(undefined);
      }
    },
    [onPriceTypeChange],
  );

  return (
    <div className={styles['container']}>
      <div className={styles['prise-type-switch']}>
        <RadioButtonGroup
          name="prise-type-switch"
          onChange={handlePriceTypeChange}
          options={coworkingSwitchButtonsMapper}
          value={priceType}
        />
      </div>
      <InputGroup>
        <NumberInput
          placeholder="от"
          invalid={isErroneous}
          rightAdornment={<InputAdornment>{'\u20bd'}</InputAdornment>}
          min={0}
          value={min}
          onChange={onMinValueChange}
          onBlur={onMinBlur}
        />
        <NumberInput
          placeholder="до"
          invalid={isErroneous}
          rightAdornment={<InputAdornment>{'\u20bd'}</InputAdornment>}
          min={0}
          value={max}
          onChange={onMaxValueChange}
          onBlur={onMaxBlur}
        />
      </InputGroup>
    </div>
  );
});
