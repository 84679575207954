import * as React from 'react';

import * as styles from './UsefulLink.css';
import { UIText1 } from '@cian/ui-kit';

interface IUsefulLinkProps {
  url: string;
  onClick(categoryName: string): void;
  imageUrl: string;
  title: string;
  categoryName: string;
}

export const UsefulLink: React.FC<IUsefulLinkProps> = ({ url, onClick, imageUrl, title, categoryName }) => {
  return (
    <a className={styles['container']} href={url} onClick={() => onClick(categoryName)}>
      <img src={imageUrl} width="322" height="100" loading="lazy" decoding="async" />
      <UIText1>{title}</UIText1>
    </a>
  );
};
