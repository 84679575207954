import { ca } from '@cian/analytics';

import { EPageKind } from '../../types/page';
import { getEventCategoryFromPageKind } from '../../utils/tracking';

export function trackGeoSuggestSucceed(pageKind: EPageKind) {
  ca('eventSite', {
    category: getEventCategoryFromPageKind(pageKind),
    action: 'GeoSuggest',
    label: 'Success',
  });
}

export function trackGeoSuggestError(pageKind: EPageKind, error: string) {
  ca('eventSite', {
    category: getEventCategoryFromPageKind(pageKind),
    action: 'GeoSuggest',
    label: `Error/${error}`,
  });
}
