import { IPromo } from './promo';

export enum IMAGE_POSITION {
  left,
  right,
  none,
}

export enum IMAGE_APPEARANCE {
  show,
  hide,
  stay,
}

export interface IPromoBanner {
  size: number;
  xsSize: number;
  promo?: IPromo;
}

export interface IPromoBlock extends IPromoBanner {
  imagePosition: IMAGE_POSITION;
  imageAppearance: IMAGE_APPEARANCE;
  id: string;
  promo: IPromo;
}
