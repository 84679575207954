import { ca } from '@cian/analytics';
import { ESalesType } from '../../types/salesLink';

const SALES_TYPE_ANALYTICS = new Map<ESalesType, string>([
  [ESalesType.SalesLeader, 'SalesLeaders'],
  [ESalesType.SalesStart, 'SalesStart'],
  [ESalesType.UpcomingSale, 'SalesSoon'],
]);

export function trackSalesLinkClick(salesType: ESalesType) {
  if (salesType === ESalesType.BlackFriday) {
    ca('eventSite', {
      action: 'click',
      category: 'BlackFriday',
      label: 'nb_main',
      name: 'oldevent',
    });

    return;
  }

  const gaLabel = SALES_TYPE_ANALYTICS.get(salesType);

  ca('eventSite', {
    action: 'Click',
    category: 'main_page',
    label: gaLabel,
    name: 'oldevent',
  });
}

export function trackBlackFridaySalesLinkShow() {
  ca('eventSite', {
    action: 'show_sopr',
    category: 'BlackFriday',
    label: 'nb_main',
    name: 'oldevent',
  });
}
