import { IApplicationState } from '../../types/redux';
import { isNewbuildingRecommendationsEnabledSelector } from './isNewbuildingRecommendationsEnabled';
import { isExperimentActive } from '../../utils/isExperimentActive';
import { NEWBUILDING_ENTRY_POINTS_MAIN_DESKTOP_ENABLED } from '../../constants/experiments';

export const selectNewbuildingRecommendationCExperimentEnabled = (state: IApplicationState): boolean => {
  const isEnabled = isNewbuildingRecommendationsEnabledSelector(state);

  return isExperimentActive(state.abUseExperiments, NEWBUILDING_ENTRY_POINTS_MAIN_DESKTOP_ENABLED, 'C') && isEnabled;
};

export const selectNewbuildingRecommendationBCExperimentEnabled = (state: IApplicationState): boolean => {
  const isEnabled = isNewbuildingRecommendationsEnabledSelector(state);

  return (
    (isExperimentActive(state.abUseExperiments, NEWBUILDING_ENTRY_POINTS_MAIN_DESKTOP_ENABLED, 'C') ||
      isExperimentActive(state.abUseExperiments, NEWBUILDING_ENTRY_POINTS_MAIN_DESKTOP_ENABLED, 'B')) &&
    isEnabled
  );
};
