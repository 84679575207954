import { connect } from 'react-redux';

import { workplaceCountValueSelector } from './selectors';

import { IApplicationState } from '../../types/redux';

import { setMaxWorkplaceCount, setMinWorkplaceCount } from '../../actions/filters';
import { IFilterWorkplaceCountProps, FilterWorkplaceCount } from '../../components/FilterWorkplaceCount';

type IFilterWorkplaceCountStateProps = Pick<IFilterWorkplaceCountProps, 'value'>;
type TFilterWorkplaceCountDispatchProps = Pick<IFilterWorkplaceCountProps, 'setMin' | 'setMax'>;
type IFilterWorkplaceCountOwnProps = Omit<IFilterWorkplaceCountProps, 'value' | 'setMin' | 'setMax'>;

export const mapStateToProps = (state: IApplicationState): IFilterWorkplaceCountStateProps => ({
  value: workplaceCountValueSelector(state),
});

export const mapDispatchToProps: TFilterWorkplaceCountDispatchProps = {
  setMin: setMinWorkplaceCount,
  setMax: setMaxWorkplaceCount,
};

export const FilterWorkplaceCountContainer = connect<
  IFilterWorkplaceCountStateProps,
  TFilterWorkplaceCountDispatchProps,
  IFilterWorkplaceCountOwnProps,
  IApplicationState
>(
  mapStateToProps,
  mapDispatchToProps,
)(FilterWorkplaceCount);
