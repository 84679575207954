import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Cell.css';
import { ICellProps } from './types';

export const Cell = (props: ICellProps) => {
  const { type, content = null, onClick } = props;

  return (
    <div className={classNames(styles['cell'], styles[type])} onClick={onClick}>
      <div className={styles['inner']}>{content}</div>
    </div>
  );
};
