import {
  AdFoxBanner,
  calculatedParamsMiddleware,
  fetchTargets,
  IAdFoxBannerContext,
  mergeMiddlewares,
} from '@cian/adfox';
import { IAdFoxBannerOnLoadData, IAdFoxBannerOnErrorError } from '@cian/adfox-component';
import * as React from 'react';
import { SECTION_TYPES } from '../../constants/sectionTypes';
import { IAdfoxConstants } from './constants';

export const adfoxMiddleware = (constants: IAdfoxConstants, section: string = 'index', onError?: () => void) => {
  return mergeMiddlewares(
    calculatedParamsMiddleware(),
    fetchTargets('apps', {
      baseUrl: `//${window.__mainpage_api_host__ || window.location.host}/`,
      section_type: SECTION_TYPES[section] || 1,
    }).catch(() => {
      if (onError) {
        onError();
      }
    }),
    Promise.resolve(constants),
  );
};

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  banner(context: IAdFoxBannerContext): React.ReactElement<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  failureBanner?(error: Error): React.ReactElement<any>;
  constants: IAdfoxConstants;
  section?: string;
  key?: string;
}

export interface IAdfoxNewProps {
  constants: IAdfoxConstants;
  section?: string;
  key?: string;
  onLoad?(data: IAdFoxBannerOnLoadData): void;
  onStub?(): void;
  onError?(error: IAdFoxBannerOnErrorError): void;
  replaceRenderEmpty?: React.ReactElement;
  withPostView?: boolean;
  label?: string;
}

export const Adfox = (props: IProps) => {
  // компонент AdFoxBanner может вернуть null,
  // что пораждает ошибку типизации. Попытка поднять версии реакта и тайпскрипта
  // до версии в либе @cian/adfox не увенчалась успехом, и ошибка продолжала
  // воспроизводиться. Таким образом, вот такой хак.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const AnyComponent = AdFoxBanner as any;

  return (
    <AnyComponent
      middleware={() => adfoxMiddleware(props.constants, props.section)}
      renderFetched={props.banner}
      renderFailure={props.failureBanner}
    ></AnyComponent>
  );
};
