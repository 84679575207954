import * as React from 'react';
import { IReview } from '../../../types/newbuildingRecommendations';
import { IconToggleStarOn16 } from '@cian/ui-kit-design-tokens/icons';
import { UIHeading5 } from '@cian/ui-kit';

import * as styles from './recommendationReview.css';

interface IReviewProps {
  review: IReview;
}

export const RecommendationReview = ({ review }: IReviewProps) => {
  return (
    <div className={styles['reviews']}>
      <IconToggleStarOn16 color={'decorative-accent-orange'} />
      <UIHeading5 color={'decorative-accent-orange'}> {review.totalRate}</UIHeading5>
    </div>
  );
};
