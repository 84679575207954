import { Tooltip } from '@cian/ui-kit';
import cn from 'classnames';
import React from 'react';
import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';
import styles from './FilterButton.css';
import { IFilterButtonProps } from './types';

export const FilterButton = (props: IFilterButtonProps) => {
  const { label, isActive, isPlaceholderLabel, tooltipDisabled, onClick } = props;

  const filterButton = (
    <button
      className={cn(
        stylesCommon['button'],
        isActive && stylesCommon['button--active'],
        isPlaceholderLabel && styles['button--placeholder'],
      )}
      onClick={onClick}
    >
      {label}
    </button>
  );

  if (isPlaceholderLabel || tooltipDisabled) {
    return filterButton;
  }

  return (
    <Tooltip placement="top" title={label}>
      {filterButton}
    </Tooltip>
  );
};

FilterButton.displayName = 'FilterButton';
