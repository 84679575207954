import { ca } from '@cian/analytics';
import { IProduct } from '../../types/tracking';
import { getPageExtra } from '../../utils/tracking/getPageExtra';
import { FDealType } from '../../utils/category';
import { TSectionType } from '../../types/sectionType';

export const trackRecommendationsView = (dealType: FDealType, section: TSectionType, products?: IProduct[]) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'show_similar_offers',
    label: 'user-based',
    page: {
      extra: getPageExtra({ dealType, section }),
      pageType: 'Home',
      siteType: 'desktop',
    },
    products,
  });
};

export const trackRecommendationsTrapView = (dealType: FDealType, type: string, section: TSectionType) => {
  ca('eventSite', {
    name: 'oldevent',
    category: type,
    action: 'show_sopr',
    label: 'recs_user_based',
    page: {
      extra: getPageExtra({ dealType, withEntrSource: true, section }),
      pageType: 'Home',
      siteType: 'desktop',
    },
  });
};

export const trackRecommendationItemClick = (dealType: FDealType, section: TSectionType, products?: IProduct[]) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'similar_objects',
    action: 'click_similar_offers',
    label: 'user-based',
    page: {
      extra: getPageExtra({ dealType, section }),
      pageType: 'Home',
      siteType: 'desktop',
    },
    products,
  });
};

export const trackTrapRecommendationClick = (dealType: FDealType, type: string, section: TSectionType) => {
  ca('eventSite', {
    name: 'oldevent',
    category: type,
    action: 'click',
    label: 'recs_user_based',
    page: {
      extra: getPageExtra({ dealType, withEntrSource: true, section }),
      pageType: 'Home',
      siteType: 'desktop',
    },
  });
};
