import { connect } from 'react-redux';

import { TThunkDispatch, IApplicationState } from '../../types/redux';
import { FilterRegion, IFilterRegionProps } from '../../components/FilterRegion';
import debounce from 'lodash.debounce';
import { loadRegionSuggests, setMortgageRegion, setRegionUserInput } from '../../actions/mortgage';
import { IRegion } from '../../../app/api/get_regions';
import { ESuggestType, IMultiSuggestResult, ILocationSuggest } from '@cian/geosuggest-widget';

type TFilterRegionStateProps = Pick<
  IFilterRegionProps,
  'suggestions' | 'suggestionError' | 'isSuggestionsAvailable' | 'userInput' | 'selectedRegionTitle'
>;
type TFilterRegionDispatchProps = Pick<IFilterRegionProps, 'setRegion' | 'onUserInput'>;

const MIN_QUERY_LENGTH = 3;

export function mapStateToProps(state: IApplicationState): TFilterRegionStateProps {
  const { filterRegionUserInput, regionSuggests, regionSuggestsError, filterRegionTitle } = state.mortgage;
  const isSuggestionsAvailable =
    filterRegionUserInput.length >= MIN_QUERY_LENGTH &&
    (regionSuggests.length > 0 || regionSuggestsError !== undefined);
  const cities = regionSuggests.map<ILocationSuggest>((item: IRegion) => {
    return {
      id: item.id,
      name: item.displayName,
      fullName: item.displayName,
      matches: [],
      type: ESuggestType.city,
    };
  });

  const sortOrder = ['cities'];
  const suggestions: IMultiSuggestResult = {
    sortOrder,
    suggestions: {
      cities,
    },
  };

  return {
    suggestions,
    suggestionError: regionSuggestsError,
    isSuggestionsAvailable,
    userInput: filterRegionUserInput,
    selectedRegionTitle: filterRegionTitle,
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TFilterRegionDispatchProps {
  const getSuggestions = debounce((query: string) => {
    if (query.length >= MIN_QUERY_LENGTH) {
      dispatch(loadRegionSuggests(query));
    }
  }, 300);

  const setFilterUserInput = (query: string) => dispatch(setRegionUserInput(query));

  return {
    onUserInput: (query: string) => {
      setFilterUserInput(query);
      getSuggestions(query);
    },
    setRegion: (regionId: number, regionTitle: string) => dispatch(setMortgageRegion(regionId, regionTitle)),
  };
}

export const FilterRegionContainer = connect(mapStateToProps, mapDispatchToProps)(FilterRegion);
