import { connect } from 'react-redux';

import { TThunkDispatch, IApplicationState } from '../../types/redux';
import { setMinPrice, setMaxPrice } from '../../actions/filters';
import { FilterPrice, IFilterPriceProps } from '../../components/FilterPrice';
import { selectJsonQuery } from '../../selectors/filters/selectJsonQuery';

type TFilterPriceStateProps = Pick<IFilterPriceProps, 'gte' | 'lte'>;
type TFilterPriceDispatchProps = Pick<IFilterPriceProps, 'setMin' | 'setMax'>;

const defaultValue = {} as TFilterPriceStateProps;

export function mapStateToProps(state: IApplicationState): TFilterPriceStateProps {
  const value = selectJsonQuery(state).price?.value || defaultValue;

  return {
    gte: value.gte,
    lte: value.lte,
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TFilterPriceDispatchProps {
  return {
    setMin: (value: number | undefined) => dispatch(setMinPrice(value)),
    setMax: (value: number | undefined) => dispatch(setMaxPrice(value)),
  };
}

export const FilterPriceContainer = connect(mapStateToProps, mapDispatchToProps)(FilterPrice);
