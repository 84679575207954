import { Dispatch } from 'redux';
import { IService, IServiceAction } from '../../../types/service';
import { TThunkAction } from '../../../types/redux';

/** Action Types */
export const SEND_SMS = 'service/SEND_SMS';
export const RESEND_SMS = 'service/RESEND_SMS';
export const SMS_COOLDOWN = 'service/SMS_COOLDOWN';
export const SMS_FAILURE = 'service/SMS_FAILURE';

export interface ISmsResponse {
  pretty: string;
}

/** Initial State */
const initialState: IService = {
  isFetching: false,
  isSended: false,
  phone: '',
};

const defaultAction: IServiceAction = {
  type: '',
};

/** Reducer */
export const serviceReducer = (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case SEND_SMS:
      return Object.assign({}, state, {
        isFetching: true,
        isSended: false,
        error: null,
      });

    case RESEND_SMS:
      return Object.assign({}, state, {
        isSended: false,
        error: null,
      });

    case SMS_COOLDOWN:
      return Object.assign({}, state, {
        phone: action.phone,
        isSended: true,
        isFetching: false,
        error: null,
      });

    case SMS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isSended: false,
        isFetching: false,
      });

    default:
      return state;
  }
};

/** Action Creator */
export const sendingsSMS = (): IServiceAction => {
  return {
    type: SEND_SMS,
  };
};

export const startCooldown = (phone: string): IServiceAction => {
  return {
    type: SMS_COOLDOWN,
    phone,
  };
};

export const resendSMS = (): IServiceAction => {
  return {
    type: RESEND_SMS,
  };
};

export const smsFailure = (): IServiceAction => {
  return {
    type: SMS_FAILURE,
    error: 'Не удалось получить ссылку',
  };
};

/** Async Action Creator */
export const sendSMS = (phone: string): TThunkAction => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (dispatch: Dispatch<any>, getState, context) => {
    dispatch(sendingsSMS());

    return context
      .makeRequest({
        method: 'POST',
        uri: {
          path: '/ajax/mobile_app_dwl/',
        },
        headers: [
          ['Accept', 'application/json'],
          ['Content-Type', 'application/json'],
        ],
        body: JSON.stringify({ phone }),
      })
      .then((res: ISmsResponse) => {
        dispatch(startCooldown(res.pretty));
      })
      .catch(() => dispatch(smsFailure()));
  };
};
