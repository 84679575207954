import { Outside } from '@cian/ui-kit/services/Outside';
import { Input } from '@cian/ui-kit';
import { Header3 } from '@cian/ui-kit/typography';
import * as React from 'react';
import { IRegion } from '../../../../types/geoSwitcher';
import { GeoSuggest } from '../GeoSuggest';

import * as styles from './GeoSwitcherHeader.css';

interface IGeoSwitcherHeaderProps {
  inputValue: string;
  suggests: IRegion[];
  loading: boolean;
  changeInputValue(value: string): void;
  selectRegion(region: IRegion): void;
}

export const GeoSwitcherHeader: React.FC<IGeoSwitcherHeaderProps> = ({
  inputValue,
  suggests,
  loading,
  changeInputValue,
  selectRegion,
}) => {
  const [visibleSuggest, setVisibleSuggest] = React.useState<boolean>(false);
  const openSuggests = React.useCallback(() => {
    setVisibleSuggest(true);
  }, []);

  const closeSuggests = React.useCallback(() => {
    setVisibleSuggest(false);
  }, []);

  const handleChangeValue = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      changeInputValue(e.target.value);
    },
    [changeInputValue],
  );

  const handleSelect = React.useCallback(
    (region: IRegion) => {
      closeSuggests();
      selectRegion(region);
    },
    [closeSuggests, selectRegion],
  );

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <Header3>Выберите регион или город</Header3>
      </div>
      <Outside onOutside={closeSuggests}>
        <div className={styles['search']}>
          <div className={styles['input']}>
            <Input
              placeholder="Выберите регион или город"
              value={inputValue}
              loading={loading}
              onChange={handleChangeValue}
              onFocus={openSuggests}
            />
            {suggests.length > 0 && visibleSuggest && <GeoSuggest suggests={suggests} onSelect={handleSelect} />}
          </div>
        </div>
      </Outside>
    </div>
  );
};
