import { batch } from 'react-redux';
import { Action } from 'redux';
import { EJsonQueryPriceType } from '../../../packages/api-models/common/json_query';

import { FILTERS_SET_PRICE_TYPE } from '../../constants/action_types';
import { TThunkDispatch } from '../../types/redux';

import { getMeta } from './getMeta';

export interface ISetPriceTypeActionType extends Action<typeof FILTERS_SET_PRICE_TYPE> {
  payload: EJsonQueryPriceType | undefined;
}

export const setPriceTypeAction =
  (payload: ISetPriceTypeActionType['payload']) =>
  (dispatch: TThunkDispatch): void => {
    batch(() => {
      dispatch<ISetPriceTypeActionType>({
        type: FILTERS_SET_PRICE_TYPE,
        payload,
      });

      dispatch(getMeta());
    });
  };
