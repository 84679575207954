import * as React from 'react';
import { Link } from '../link/link';
import { ISeoUrl } from '../../../shared/types/seo_data';

const s = require('./new_seo_urls.css');

interface IProps {
  links: ISeoUrl[];
}

export const NewSeoUrlsContainer = (props: IProps) => (
  <div className="cg-col-5 cg-skip-1">
    <h4 className={s['c-new-on-cian-title']}>Новое на ЦИАН</h4>
    {props.links.map((link: ISeoUrl, i: number) => (
      <div key={`new_seo_link_${i}`} className={s['c-new-on-cian-link-item']}>
        <Link href={link.url}>{link.title}</Link>
      </div>
    ))}
  </div>
);
