import { defaultState } from './defaultState';

import { TReduxActions } from '../../../types/redux';

import {
  FILTERS_SET_MAX_WORKPLACE_COUNT_TYPE,
  FILTERS_SET_MIN_WORKPLACE_COUNT_TYPE,
} from '../../../constants/action_types';

import { JsonQuery } from '../../../utils/jsonQuery';
import { IJsonQuery } from '../../../../packages/api-models/common/json_query';

export function setWorkplaceCountReducer(jsonQuery: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  switch (action.type) {
    case FILTERS_SET_MIN_WORKPLACE_COUNT_TYPE:
      return new JsonQuery(jsonQuery).setMinWorkplaceCount(action.minWorkplaceCount).getSerializable();

    case FILTERS_SET_MAX_WORKPLACE_COUNT_TYPE:
      return new JsonQuery(jsonQuery).setMaxWorkplaceCount(action.maxWorkplaceCount).getSerializable();

    default:
      return jsonQuery;
  }
}
