import * as React from 'react';

import { INewbuildingPromo } from '../../../../types/newbuildingPromo';
import { DefaultAttributes } from '../DefaultAttributes';
import { StateMortgageAttributes } from '../StateMortgageAttributes';

export interface IPromoAttributesProps {
  promo: INewbuildingPromo;
}

export const PromoAttributes: React.VFC<IPromoAttributesProps> = ({ promo }) => {
  const { interestRateText, maxCreditText, newbuildingCountText, promoTypeText } = promo;

  if (interestRateText || maxCreditText) {
    return <StateMortgageAttributes limit={maxCreditText} rate={interestRateText} />;
  }

  return <DefaultAttributes newbuildingsCount={newbuildingCountText} type={promoTypeText} />;
};
