import { pathOr, compose } from 'ramda';
import { connect } from 'react-redux';

import { withApplicationContext } from '../../utils/applicationContext';
import { IApplicationContext } from '../../types/application';
import { TThunkDispatch, IApplicationState } from '../../types/redux';
import {
  TSuggestResult,
  IYandexSuggestOptions,
  suggest,
  selectGeo,
  clearGeo,
  geocode,
  setRegion,
  setVillage,
  setBSCenters,
  setBuilder,
  setCoworkingIds,
  changeGeoSearch,
  validateGeo,
} from '../../actions/filters';
import { FilterGeo, IFilterGeoProps } from '../../components/FilterGeo';
import { getGeoBbox } from '../../actions/filters/geo/getGeoBoundedBy';
import { selectGeoSearch } from '../../selectors/filters/geoSearch';
import { selectIsGeoLoading } from '../../selectors/filters';
import { selectFiltersClientErrors } from '../../selectors/filters/clientErrors';
import { getGeoPlaceholder } from '../../utils/geo';
import { dealTypeFromJsonQuery, offerTypeFromJsonQuery } from '../../utils/category';
import { TGeoValue } from '../../../packages/api-models/common/json_query';

type TFilterGeoContextProps = Pick<IFilterGeoProps, 'telemetry'>;

type TFilterGeoStateProps = Pick<
  IFilterGeoProps,
  'pageKind' | 'regionOrMaintownId' | 'suggestions' | 'jsonQuery' | 'placeholder' | 'isLoading' | 'search' | 'geoError'
>;

type TFilterGeoDispatchProps = Pick<
  IFilterGeoProps,
  | 'getGeoBbox'
  | 'getSuggestions'
  | 'setRegion'
  | 'setVillage'
  | 'setBSCenters'
  | 'selectGeo'
  | 'geocode'
  | 'clearGeo'
  | 'setBuilder'
  | 'setCoworkingIds'
  | 'changeSearch'
  | 'clearGeoError'
>;

export function mapContextToProps(context: IApplicationContext): TFilterGeoContextProps {
  return {
    telemetry: context.telemetry,
  };
}

export function mapStateToProps(state: IApplicationState): TFilterGeoStateProps {
  const clientErrors = selectFiltersClientErrors(state);
  const jsonQuery = state.filters.jsonQuery;
  const dealType = dealTypeFromJsonQuery(jsonQuery);

  const offerType = offerTypeFromJsonQuery(jsonQuery);

  return {
    pageKind: state.page.kind,
    jsonQuery,
    suggestions: state.filters.suggestions,
    regionOrMaintownId: pathOr(state.userGeo.userGeo, ['region', 'value'], jsonQuery),
    search: selectGeoSearch(state),
    placeholder: getGeoPlaceholder({ dealType, offerType }),
    isLoading: selectIsGeoLoading(state),
    geoError: clientErrors.geo,
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TFilterGeoDispatchProps {
  return {
    getGeoBbox: (regionOrMaintownId: number) => dispatch(getGeoBbox(regionOrMaintownId)),
    getSuggestions: (query: string, options?: IYandexSuggestOptions): Promise<TSuggestResult> => {
      return dispatch(suggest({ query, options }));
    },
    setRegion: (region: number[]) => dispatch(setRegion(region)),
    setVillage: (villageId: number | undefined) => dispatch(setVillage(villageId)),
    setBSCenters: (bsCenterIds: number[]) => dispatch(setBSCenters(bsCenterIds)),
    selectGeo: (geoValue: TGeoValue) => dispatch(selectGeo(geoValue)),
    geocode: (query: string): Promise<TGeoValue> => dispatch(geocode({ query })),
    clearGeo: () => dispatch(clearGeo()),
    setBuilder: (builderId: number) => dispatch(setBuilder(builderId)),
    setCoworkingIds: (coworkingIds: number[]) => dispatch(setCoworkingIds(coworkingIds)),
    changeSearch: (geoSearch: string) => dispatch(changeGeoSearch(geoSearch)),
    clearGeoError: () => dispatch(validateGeo(null)),
  };
}

export const FilterGeoContainer = compose(
  withApplicationContext(mapContextToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(FilterGeo);
