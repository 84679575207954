import * as React from 'react';
import { useInView } from '@cian/valuation-utils-component';
import { UIHeading1 } from '@cian/ui-kit';

import * as styles from './UsefulLinks.css';
import classNames from 'classnames';
import { ItemsCarousel } from '../ItemsCarousel';

interface IUsefulLinksProps {
  children: React.ReactNode;
  onShow(): void;
}

export const UsefulLinks: React.FC<IUsefulLinksProps> = ({ children, onShow }) => {
  const [isShown, setIsShown] = React.useState(false);

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  React.useEffect(() => {
    if (inView && onShow && !isShown) {
      onShow();
      setIsShown(true);
    }
  }, [inView, onShow, isShown]);

  return (
    <div ref={ref} className={classNames('cg-container-fluid-xs', styles['container'])}>
      <UIHeading1>Часто ищут</UIHeading1>
      <ItemsCarousel styles={styles['carousel']} arrowVerticalOffset={-20}>
        {children}
      </ItemsCarousel>
    </div>
  );
};
