import { TReduxActions } from '../../../../shared/types/redux';
import { IYear, IYearAction } from '../../../../shared/types/filters/year';

export const YEAR_UPDATE = 'search/YEAR_UPDATE';

const initialState: IYear = {
  years: [],
  isDirty: false,
};

export const yearReducer = (state = initialState, action: TReduxActions) => {
  switch (action.type) {
    case YEAR_UPDATE:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
};

export const setYear = (years: string[]): IYearAction => {
  return {
    type: YEAR_UPDATE,
    payload: {
      years,
    },
  };
};

export const setDirty = (isDirty: boolean): IYearAction => {
  return {
    type: YEAR_UPDATE,
    payload: {
      isDirty,
    },
  };
};
