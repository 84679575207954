/* eslint-disable max-lines */
import { Outside, Tooltip } from '@cian/ui-kit';
import { ITelemetryLogger } from '@cian/telemetry/shared';
import {
  IResultSuggestions,
  IItemClickParams,
  TSuggestType,
  ESuggestType,
  GeoSuggest,
  EInputType,
} from '@cian/geosuggest-widget';
import { mergeStyles } from '@cian/utils';
import debounce from 'lodash.debounce';
import * as React from 'react';
import { EPageKind } from '../../types/page';
import { ISuggestionsState, ESuggestionsStatus } from '../../types/filters';
import { TSuggestResult, IYandexSuggestOptions } from '../../actions/filters';
import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';
import { trackGeoSuggestSucceed, trackGeoSuggestError } from './tracking';
import * as styles from './FilterGeo.css';
import { IGeoBoundedBy } from '../../types/filters/user_geo';
import { IJsonQuery, TGeoValue } from '../../../packages/api-models/common/json_query';

export interface IFilterGeoProps {
  pageKind: EPageKind;
  suggestions: ISuggestionsState;
  jsonQuery: IJsonQuery;
  regionOrMaintownId: number;
  placeholder: string;
  isLoading: boolean;
  search: string;
  geoError: string | null;
  telemetry: ITelemetryLogger;
  inSuggestTwoStringExperiment: boolean;

  getGeoBbox(regionOrMaintownId: number): Promise<IGeoBoundedBy>;
  getSuggestions(query: string, options?: IYandexSuggestOptions): Promise<TSuggestResult>;
  setRegion(region: number[]): void;
  setVillage(villageId: number | undefined): void;
  setBSCenters(bsCenterIds: number[]): void;
  selectGeo(geoValue: TGeoValue): void;
  geocode(query: string): Promise<TGeoValue>;
  clearGeo(): void;
  setBuilder(builderId: number): void;
  setCoworkingIds: (coworkingIds: number[]) => void;
  changeSearch: (search: string) => void;
  clearGeoError: () => void;
}

export interface IFilterGeoState {
  active: boolean;
  value: string;
  selectedIndex: number;
  geoBoundedBy: [[number, number], [number, number]];
}

const MIN_QUERY_LENGTH = 3;
// Bbox для Москвы по умолчанию
export const BOUNDED_BY_DEFAULT: [IYmapsCoord, IYmapsCoord] = [
  [37.967428, 56.021224],
  [36.803101, 55.142175],
];

export class FilterGeo extends React.Component<IFilterGeoProps, IFilterGeoState> {
  public constructor(props: IFilterGeoProps) {
    super(props);

    this.state = {
      active: false,
      value: '',
      selectedIndex: 0,
      geoBoundedBy: BOUNDED_BY_DEFAULT,
    };

    this.getSuggestions = debounce(this.getSuggestions, 300) as (query: string) => Promise<void>;
  }

  public componentDidMount() {
    const { regionOrMaintownId } = this.props;

    this.props
      .getGeoBbox(regionOrMaintownId)
      .then(response => {
        this.setState({
          geoBoundedBy: [
            [response.data.boundedBy.upperCorner.lng, response.data.boundedBy.upperCorner.lat],
            [response.data.boundedBy.lowerCorner.lng, response.data.boundedBy.lowerCorner.lat],
          ],
        });
      })
      .catch(() => {});
  }

  public render() {
    const { suggestions: geoSuggestions, jsonQuery, placeholder, isLoading, search, geoError } = this.props;

    const { active, value } = this.state;

    const isCleanable = (active && !!value) || (!active && !!search) || !!jsonQuery.geo;

    return (
      <Outside onOutside={this.handleOutsideClick}>
        <Tooltip arrow theme="black" placement="bottom-start" open={!!geoError} title={geoError}>
          <div {...mergeStyles(stylesCommon['container'], styles['container'])} data-mark="FilterGeo">
            <GeoSuggest
              value={value}
              suggestData={{
                suggestions: geoSuggestions.suggestions as IResultSuggestions,
                sortOrder: geoSuggestions.sortOrder,
              }}
              selectedValue={search}
              placeholder={placeholder}
              error={this.getError()}
              inputType={EInputType.search}
              onItemClick={this.handleItemClick}
              onValueChange={this.handleValueChange}
              onFocus={this.handleInputFocus}
              popupStyle={styles['dropdown']}
              isLoading={isLoading}
              onLinkClick={this.handleLinkClick}
              inputStyle={
                mergeStyles(stylesCommon['input'], styles['input'], isCleanable && styles['input--cleanable']).className
              }
            />
            {!isLoading && isCleanable && (
              <button className={styles['button']} onClick={this.handleClearButtonClick}>
                {/* eslint-disable-next-line react/forbid-elements */}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" width="12" height="12">
                  <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M11.657.343a.999.999 0 0 1 0 1.414L7.414 6l4.243 4.243a.999.999 0 1 1-1.414 1.414L6 7.414l-4.243 4.243a.999.999 0 1 1-1.414-1.414L4.586 6 .343 1.757A.999.999 0 1 1 1.757.343L6 4.586 10.243.343a1 1 0 0 1 1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            )}
          </div>
        </Tooltip>
      </Outside>
    );
  }

  private handleInputFocus = () => {
    const { clearGeoError, geoError } = this.props;

    this.setState({
      active: true,
    });

    if (geoError) {
      clearGeoError();
    }

    this.sendTelemetryInputFocus();
  };

  private handleValueChange = (value: string) => {
    this.setState({
      value,
    });

    if (value.length >= MIN_QUERY_LENGTH) {
      this.getSuggestions(value);
    }
  };

  private handleClearButtonClick = () => {
    this.props.clearGeo();

    this.setState({
      value: '',
      active: false,
    });
  };

  private handleItemClick = (item: IItemClickParams) => {
    switch (item.group) {
      case ESuggestType.geo:
        this.geocode(item.title);
        break;

      case ESuggestType.city:
      case ESuggestType.district:
      case ESuggestType.road:
      case ESuggestType.underground:
      case ESuggestType.railway:
      case ESuggestType.newbuilding:
      case ESuggestType.regionalDistrict:
        if (item.regionId) {
          this.props.setRegion([item.regionId]);
        }
        this.props.selectGeo(normalizeGeoObject(item as ISuggestGeoItem));
        break;

      case ESuggestType.village:
        if (item.regionId) {
          this.props.setRegion([item.regionId]);
        }
        this.props.setVillage(item.id);
        break;

      case ESuggestType.businessCenter:
      case ESuggestType.shoppingCenter:
        if (item.regionId) {
          this.props.setRegion([item.regionId]);
        }

        if (item.id) {
          this.props.setBSCenters([item.id]);
        }
        break;
      case ESuggestType.builder:
        if (item.id) {
          this.props.setBuilder(item.id);
        }
        break;
      case ESuggestType.coworking:
        if (item.id) {
          this.props.setCoworkingIds([item.id]);
        }
        break;
    }

    this.props.changeSearch(item.title);

    this.setState({
      value: item.title,
      active: false,
    });

    this.sendTelemetrySuggestClick();
  };

  private sendTelemetrySuggestClick = () => {
    const { telemetry } = this.props;

    telemetry.increment('filterGeo.geoSuggest.click');
  };

  private sendTelemetryInputFocus = () => {
    const { telemetry } = this.props;

    telemetry.increment('filterGeo.input.focus');
  };

  private handleLinkClick = (title: string, group: TSuggestType, id: number) => {
    // eslint-disable-next-line no-console
    console.log(title, group, id);
  };

  private handleOutsideClick = () => {
    this.setState({
      active: false,
    });
  };

  private getError = () => {
    const { status } = this.props.suggestions;

    switch (status) {
      case ESuggestionsStatus.Succeed:
        if (Object.keys(this.props.suggestions.suggestions).length === 0) {
          return {
            title: 'Ничего не найдено',
            text: 'Укажите другой адрес',
          };
        }
        break;

      case ESuggestionsStatus.Failed:
        return {
          title: 'Не удалось получить возможные варианты',
          text: 'Попробуйте еще раз',
        };
    }

    return undefined;
  };

  public getSuggestions = async (query: string): Promise<void> => {
    const { pageKind } = this.props;
    const { geoBoundedBy } = this.state;

    try {
      const [geoSuggestions, yandexSuggestions] = await this.props.getSuggestions(query, {
        boundedBy: geoBoundedBy,
        results: 5,
      });

      if (!geoSuggestions) {
        trackGeoSuggestError(pageKind, 'GeoSuggestionsFailed');
      } else if (!yandexSuggestions) {
        trackGeoSuggestError(pageKind, 'YandexSuggestionsFailed');
      } else {
        trackGeoSuggestSucceed(pageKind);
      }
    } catch (e) {
      trackGeoSuggestError(pageKind, 'SuggestionsFailed');
    }
  };

  public geocode = async (query: string) => {
    try {
      await this.props.geocode(query);
    } catch (e) {
      trackGeoSuggestError(this.props.pageKind, 'GeocodeFailed');
    }
  };
}

interface ISuggestGeoItem {
  group:
    | ESuggestType.city
    | ESuggestType.district
    | ESuggestType.newbuilding
    | ESuggestType.railway
    | ESuggestType.road
    | ESuggestType.underground
    | ESuggestType.village
    | ESuggestType.regionalDistrict;
  id: number;
}

export const GEO_OBJECT_TYPES_MAP = {
  [ESuggestType.city]: 'location',
  [ESuggestType.district]: 'district',
  [ESuggestType.newbuilding]: 'newobject',
  [ESuggestType.railway]: 'railway',
  [ESuggestType.road]: 'highway',
  [ESuggestType.underground]: 'underground',
  [ESuggestType.village]: 'village',
  [ESuggestType.regionalDistrict]: 'regional_district_id',
};

function normalizeGeoObject(geoObject: ISuggestGeoItem): TGeoValue {
  return {
    type: GEO_OBJECT_TYPES_MAP[geoObject.group],
    id: geoObject.id,
  } as TGeoValue;
}
