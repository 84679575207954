import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_VILLAGE_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';
import { isSuburban, offerTypeFromJsonQuery } from '../../utils/category';
import { togglePopupAutoChangeToSuburban } from '../../reducers/modules/search/building_types';

export type TSetVillageType = 'filters/set_village';

export interface ISetVillage extends Action<TSetVillageType> {
  type: TSetVillageType;
  villageId: number | undefined;
}

export type TSetVillage = ISetVillage;

export function setVillage(villageId: number | undefined): TThunkAction {
  return (dispatch, getState) => {
    const {
      filters: { jsonQuery },
    } = getState();

    const offerType = offerTypeFromJsonQuery(jsonQuery);

    if (villageId !== undefined && !isSuburban(offerType)) {
      dispatch(togglePopupAutoChangeToSuburban(true));
    }

    dispatch<TSetVillage>({
      type: FILTERS_SET_VILLAGE_TYPE,
      villageId,
    });

    dispatch(getMeta());
  };
}
