import { IBuildingTypesConfig } from '../../../shared/types/filters/building_types';

export const SuburbanOfferFilterTypes: IBuildingTypesConfig = {
  living: {
    name: 'Категория',
    options: {
      all: { values: ['Любая', 'Любой'], order: 0 },
      from_builder: { values: ['От застройщиков', 'От застройщиков'], order: 1 },
      from_individual: { values: ['От собственников и агентов', 'От собственников и агентов'], order: 2 },
    },
  },
};

export const SuburbanOfferFilterOptions: IBuildingTypesConfig = {
  0: SuburbanOfferFilterTypes.living,
};
