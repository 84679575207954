import { pathOr } from 'ramda';
import { getMeta, setRegion } from '../../../shared/actions/filters';
import { IReduxStore } from '../../../shared/types/redux';
import { resolveCookieRegion } from '../geoCookie';

/**
 * состояние главной страницы может быть получено из кеша
 * поэтому важно пропатчить регион из кук на клиенте
 * @param store
 */
export const patchCookieRegion = (store: IReduxStore) => {
  const state = store.getState();
  const cookieRegion = resolveCookieRegion();
  const jsonQueryRegion = pathOr(
    [state.mainpage.regionsData.subdomainRegionId],
    ['region', 'value'],
    state.filters.jsonQuery,
  );

  if (cookieRegion && cookieRegion !== jsonQueryRegion[0]) {
    store.dispatch(setRegion([cookieRegion]));
    store.dispatch(getMeta());
  }
};
