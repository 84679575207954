import { clone } from 'ramda';
import { IJsonQuery } from '../../../packages/api-models/common/json_query';
import { setSpecialtyTypesTerms } from './setSpecialtyTypesTerms';

const toggleSpecialtyTypes =
  (jsonQuery: IJsonQuery) =>
  (specialtyType: number): IJsonQuery => {
    if (jsonQuery.specialty_types?.value) {
      const { value } = jsonQuery.specialty_types;

      const newSpecialtyTypes = value.includes(specialtyType)
        ? value.filter(type => type !== specialtyType)
        : value.concat(specialtyType);

      return setSpecialtyTypesTerms(jsonQuery)(newSpecialtyTypes);
    }

    return setSpecialtyTypesTerms(jsonQuery)(Array.of(specialtyType));
  };

export const setFilterSpecialtyTypes =
  (jsonQuery: IJsonQuery) =>
  (nextSpecialtyType: number | Array<number>): IJsonQuery => {
    const clonedJsonQuery = clone(jsonQuery);

    if (Array.isArray(nextSpecialtyType)) {
      return setSpecialtyTypesTerms(clonedJsonQuery)(nextSpecialtyType);
    }

    return toggleSpecialtyTypes(clonedJsonQuery)(nextSpecialtyType);
  };
