import { ca } from '@cian/analytics';

export const blocksClicksHandlers: { [x: string]: () => void } = {
  newbuildingsbooking: () => {
    ca('event', {
      action: 'Landing_open',
      category: 'Booking',
      label: 'Home',
      name: 'oldevent',
    });
  },
};
