import * as React from 'react';

interface IProps {
  popularPresented: boolean;
  wideRow: boolean;
  children: React.ReactNode;
}

export class TwoColumnContainer extends React.Component<IProps> {
  public render() {
    if (!this.props.children || !React.Children.count(this.props.children)) {
      return <div></div>;
    }
    const children = React.Children.toArray(this.props.children);

    return (
      <div className="cg-container-fluid-xs">
        {children.length === 1 ? (
          <div className="cg-row">{children[0]}</div>
        ) : (
          <div className="cg-row">
            {/* tslint:disable-next-line:max-line-length */}
            <div
              className={`${
                this.props.popularPresented || this.props.wideRow ? 'cg-col-18' : 'cg-col-24'
              } cg-col-xs-24`}
            >
              {children[0]}
            </div>
            <div
              className={`${
                this.props.popularPresented ? 'cg-col-5 cg-col-xs-24 cg-skip-1 cg-skip-xs-0' : 'cg-col-24'
              }`}
            >
              {children[1]}
            </div>
          </div>
        )}
      </div>
    );
  }
}
