import * as React from 'react';
import { connect } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { IPromo, IPromoLink } from '../../types/promo';
import { ISeoBlockItem } from '../../types/seo_urls';
import { IRegionsData } from '../../types/regions_data';
import { IMainPage } from '../../types/mainpage';
import { VerticalPromoBlocks } from '../../components/vertical_promo_blocks/vertical_promo_blocks';

interface IProps {
  promo: IPromo[];
  seoUrlsBlocks: ISeoBlockItem[];
  regionsData: IRegionsData;
  section: string;
  isFetching: boolean;
  mainpage: IMainPage;
  domrfPromo: IPromo | null;
  salesLeaderInfo: IPromoLink | null;
}

class VerticalPromo extends React.Component<IProps> {
  public render() {
    const { salesLeaderInfo, promo: prevPromo } = this.props;
    const substitutedPromoIndex = prevPromo.findIndex(promo => promo.id === 'newBuildingsPromos');

    const newProps = Object.assign({}, this.props);

    if (salesLeaderInfo && substitutedPromoIndex !== -1) {
      newProps.promo[substitutedPromoIndex] = {
        extraLinks: [],
        id: 'newBuildingSalesLeaders',
        links: [salesLeaderInfo],
        title: 'Лидеры продаж',
        type: 'iconic',
      };
    }

    return (
      <VerticalPromoBlocks
        promoBlocks={newProps.promo}
        promoMenu={newProps.seoUrlsBlocks}
        regionsData={newProps.regionsData}
        section={newProps.section}
        domrfPromo={newProps.domrfPromo}
      />
    );
  }
}

function parsePromoBlocks(promos: IPromo[]) {
  const pikPromo = promos.find(block => block.id === 'newBuildingsBooking');

  if (pikPromo) {
    return promos.filter(promo => {
      return promo.id !== 'newBuildingsBooking';
    });
  }

  return promos;
}

export function mapStateToProps(store: IApplicationState) {
  const {
    mainpage,
    config: { isDomrfEnabled, salesLeaderInfo },
  } = store;

  let promo = mainpage.section === 'new-buildings' ? parsePromoBlocks(mainpage.promoBlocks) : mainpage.promoBlocks;

  let domrfPromo = null;
  const domrfPromoIndex = promo.findIndex(promo => promo.id === 'rentDomrf');
  // т.к. стили захардкожены и зависят от количества промо-блоков, решил сделать такую реализацию
  // условие с флагом вынесено отдельно на случай, если его выключат на фронте и забудут выключить на бэке.
  if (domrfPromoIndex !== -1) {
    const newPromo = [...promo];
    const domrfPromoBlock = newPromo.splice(domrfPromoIndex, 1)[0];
    promo = newPromo;
    if (isDomrfEnabled) {
      domrfPromo = domrfPromoBlock;
    }
  }

  return {
    mainpage,
    promo,
    seoUrlsBlocks: mainpage.seoUrlsBlocks,
    isFetching: mainpage.isFetching,
    regionsData: mainpage.regionsData,
    section: mainpage.section,
    domrfPromo,
    salesLeaderInfo,
  };
}

const VerticalPromoWrapper = connect(mapStateToProps)(VerticalPromo);

export { VerticalPromoWrapper as VerticalPromo, VerticalPromo as VerticalPromoClass };
