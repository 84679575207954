import { CheckboxGroup } from '@cian/nested-checkbox-groups';
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterBusinessAppointmentsIds } from '../../actions/businessAppointments';
import { filterBusinessAppointmentsIdsSelector } from '../../selectors/businessAppointments/filterBusinessAppointmentsIdsSelector';
import { FilterNestedCheckboxGroupsWithSearchDropdown } from '../FilterNestedCheckboxGroupsWithSearchDropdown';
import styles from './FilterBusinessAppointments.css';
import { getLabel } from './internal/helpers/getLabel';
import { useBusinessAppointmentsDataSelector } from './internal/hooks/useBusinessAppointmentsDataSelector';
import { useFilterBusinessAppointmentsMountEffect } from './internal/hooks/useFilterBusinessAppointmentsMountEffect';
import { HandleNestedCheckboxClick, HandleTitleCheckboxClick, IFilterBusinessAppointmentsProps } from './types';

const defaultLabel = 'Категория';

export const FilterBusinessAppointments = memo<IFilterBusinessAppointmentsProps>(function FilterBusinessAppointments() {
  const dispatch = useDispatch();
  const businessAppointments = useBusinessAppointmentsDataSelector();
  const filterBusinessAppointmentsIds = useSelector(filterBusinessAppointmentsIdsSelector);

  const groups = useMemo<Array<CheckboxGroup>>(
    () =>
      businessAppointments.map(group => ({
        items: group.items.map(item => ({ label: item.name, value: item.id })),
        label: group.name,
        value: group.groupType,
      })),
    [businessAppointments],
  );

  useFilterBusinessAppointmentsMountEffect();

  const handleNestedCheckboxClick = useCallback<HandleNestedCheckboxClick>(
    checkboxGroupsItemChangeModel => {
      dispatch(
        setFilterBusinessAppointmentsIds(
          checkboxGroupsItemChangeModel.checkboxGroupItemChangeModel.checkboxGroupChangeModel.allValues,
        ),
      );
    },
    [dispatch],
  );

  const handleTitleCheckboxClick = useCallback<HandleTitleCheckboxClick>(
    checkboxGroupsChangeModel => {
      dispatch(setFilterBusinessAppointmentsIds(checkboxGroupsChangeModel.checkboxGroupChangeModel.allValues));
    },
    [dispatch],
  );

  const label = getLabel(filterBusinessAppointmentsIds, groups, defaultLabel);
  const isPlaceholderLabel = label === defaultLabel;

  return (
    <div className={styles['container']}>
      <FilterNestedCheckboxGroupsWithSearchDropdown
        label={label}
        checkboxGroups={groups}
        checkboxValues={filterBusinessAppointmentsIds}
        onNestedCheckboxClick={handleNestedCheckboxClick}
        onTitleCheckboxClick={handleTitleCheckboxClick}
        isPlaceholderLabel={isPlaceholderLabel}
      />
    </div>
  );
});
