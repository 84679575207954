import { Outside, NumberInput, InputGroup, InputAdornment } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';
import * as React from 'react';

import { getAreaLabel } from '../../utils/area';
import { isValidInput } from '../../utils/isValidInput';
import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';
import * as styles from './FilterArea.css';
import { IJsonQueryRangeValue } from '../../../packages/api-models/common/json_query';

export interface IFilterAreaProps {
  value: IJsonQueryRangeValue;
  adornmentPlaceholder?: string;
  setMin(value: number | undefined): void;
  setMax(value: number | undefined): void;
}

interface IFilterAreaState {
  min: number | undefined;
  max: number | undefined;
  isError: boolean;
}

export function FilterArea({ value, setMin, setMax, adornmentPlaceholder = `м\u00b2` }: IFilterAreaProps) {
  const [active, setActive] = React.useState(false);
  const [inputState, setInputState] = React.useState<IFilterAreaState>({
    max: value.lte,
    min: value.gte,
    isError: false,
  });

  const { min, max, isError } = inputState;
  const label = getAreaLabel(min, max, adornmentPlaceholder);

  const handleButtonClick = React.useCallback(() => {
    setActive(active => !active);
  }, []);

  const handleOutsideClick = React.useCallback(() => {
    setActive(false);
  }, []);

  const handleMinValueChange = (value: number | undefined) => {
    setInputState(state => ({
      ...state,
      min: value,
      isError: !isValidInput(value, state.max),
    }));
  };

  const handleMaxValueChange = (value: number | undefined) => {
    setInputState(state => ({
      ...state,
      max: value,
      isError: !isValidInput(state.min, value),
    }));
  };

  const handleMinBlur = React.useCallback(() => {
    if (value.gte !== min) {
      setMin(min);
    }
  }, [value.gte, min, setMin]);

  const handleMaxBlur = React.useCallback(() => {
    if (value.lte !== max) {
      setMax(max);
    }
  }, [value.lte, max, setMax]);

  return (
    <Outside onOutside={handleOutsideClick}>
      <div {...mergeStyles(stylesCommon['container'], styles['container'])} data-mark="FilterArea">
        <button
          {...mergeStyles(
            stylesCommon['button'],
            active && stylesCommon['button--active'],
            !label && stylesCommon['button--empty'],
          )}
          title={label || undefined}
          onClick={handleButtonClick}
        >
          {label || 'Площадь'}
        </button>
        {active && (
          <div className={styles['dropdown']}>
            <InputGroup>
              <NumberInput
                placeholder="от"
                invalid={isError}
                rightAdornment={<InputAdornment>{adornmentPlaceholder}</InputAdornment>}
                min={0}
                max={99999}
                value={min}
                onChange={handleMinValueChange}
                onBlur={handleMinBlur}
              />
              <NumberInput
                placeholder="до"
                invalid={isError}
                rightAdornment={<InputAdornment>{adornmentPlaceholder}</InputAdornment>}
                min={0}
                max={99999}
                value={max}
                onChange={handleMaxValueChange}
                onBlur={handleMaxBlur}
              />
            </InputGroup>
          </div>
        )}
      </div>
    </Outside>
  );
}
