import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_RESET_ROOM_TYPE_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';

export type TResetRoomTypeType = 'filters/reset_room_type';

export interface IResetRoomType extends Action<TResetRoomTypeType> {
  type: TResetRoomTypeType;
}

export type TResetRoomType = IResetRoomType;

export function resetRoomType(callGetMeta = false): TThunkAction {
  return dispatch => {
    dispatch<TResetRoomType>({
      type: FILTERS_RESET_ROOM_TYPE_TYPE,
    });

    if (callGetMeta) {
      dispatch(getMeta());
    }
  };
}
