import * as React from 'react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import join from 'lodash/join';

import { onInsideClick } from './helpers';
import { OptionalDropdown } from './optional_dropdown';
import { filterChange, filterClick } from './tracking';

const s = require('../search_filters.css');

interface IProps {
  values: string[];
  isRent: boolean;
  isNewb?: boolean;
  onChange(values: string[]): void;
}

interface INames {
  [Identifier: string]: string;
}

const EM_DASH = '\u2014';

const RoomNames: INames = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
};
const ExtraNames: INames = {
  free: 'своб. план.',
  studio: 'студия',
};

class RoomsFilter extends React.Component<IProps> {
  public render() {
    return (
      <div
        className={`${s['c-filters-field']} ${s['c-filters-field-room']}`}
        onClick={onInsideClick(() => filterClick('RoomsCount'))}
      >
        <OptionalDropdown
          value={this.props.values}
          multiple={true}
          popupContentStyle={s['c-filters-operations-content-check']}
          onChange={(values: string[]) => {
            filterChange('RoomsCount', values.sort().join(',') || 'deleted');
            this.props.onChange(values);
          }}
          label={this.renderLabel()}
          values={
            this.props.isRent
              ? [
                  { value: 'studio', label: 'Студия' },
                  { value: '1', label: '1-комнатная' },
                  { value: '2', label: '2-комнатная' },
                  { value: '3', label: '3-комнатная' },
                  { value: '4', label: '4-комнатная' },
                  { value: '5', label: '5-комнатная' },
                  { value: '6', label: '6-комнатная +' },
                ]
              : [
                  { value: 'studio', label: 'Студия' },
                  { value: '1', label: '1-комнатная' },
                  { value: '2', label: '2-комнатная' },
                  { value: '3', label: '3-комнатная' },
                  { value: '4', label: '4-комнатная' },
                  { value: '5', label: '5-комнатная' },
                  { value: '6', label: '6-комнатная +' },
                  { value: 'free', label: 'Свободная планировка' },
                ]
          }
        />
      </div>
    );
  }

  private filterValues(values: string[], names: INames) {
    const filteredValues = values;
    if (this.props.isRent) {
      const index = filteredValues.indexOf('free', 0);
      if (index > -1) {
        filteredValues.splice(index, 1);
      }
    }

    return filter<string>(
      map(filteredValues.sort(), (val: string) => names[val]),
      Boolean,
    );
  }

  private filterRoomValues(values: string[], names: INames): string[] {
    const sortedValues = values.filter(Number).map(Number).sort();

    if (sortedValues.length < 3) {
      return this.filterValues(values, names);
    }

    let seq: [number, number] = [sortedValues[0], sortedValues[0]];
    const sequences: [number, number][] = [];

    sortedValues.forEach((value, index) => {
      if (index === 0) {
        return;
      } else if (value - 1 === seq[1]) {
        seq[1] = value;
      } else {
        sequences.push(seq);
        seq = [value, value];
      }
    });

    sequences.push(seq);

    return sequences.map(([start, end]) => {
      return start === end ? names[start] : `${names[start]}${end - start > 1 ? EM_DASH : ', '}${names[end]}`;
    });
  }

  private renderLabel() {
    let postfix = '';
    const roomInfo = this.filterRoomValues(this.props.values, RoomNames);
    const extraInfo = this.filterValues(this.props.values, ExtraNames);

    if (roomInfo.length) {
      postfix = !extraInfo.length && roomInfo.length === 1 && !roomInfo[0].includes(EM_DASH) ? '-комнатная' : ' комн.';
    }

    const roomInfoFormatted = join(roomInfo, ', ');
    const extraInfoFormatted = join(extraInfo, ', ');

    const defaultValue = this.props.isNewb ? 'Кол-во комнат' : 'Комнат';

    return join(filter([roomInfoFormatted + postfix, extraInfoFormatted], Boolean), ', ') || defaultValue;
  }
}

export { RoomsFilter };
