import * as React from 'react';

import { declineNumeric } from '../../../shared/utils/format_helper';
import { ETransportType } from '../../types/newbuilding_recommendations';

import * as styles from './popular_item.css';

const minuteNames = ['минута', 'минуты', 'минут'];

export interface IAddressProps {
  metro?: {
    timeType: string;
    name: string;
    lineColor: string;
    time: number;
  };
  address?: string;
}

export function Address({ metro, address }: IAddressProps) {
  if (metro) {
    return (
      <div className={styles['metro']}>
        <span className={styles['metro-name']}>
          <span
            className={styles['line-marker']}
            style={{
              backgroundColor: `#${metro.lineColor}`,
            }}
          />
          {metro.name}
        </span>
        {metro.time !== null && (
          <span className={styles['metro-time']}>
            {`${metro.time} ${declineNumeric(metro.time, minuteNames)}`}
            &nbsp;
            {metro.timeType === ETransportType.Walk ? 'пешком' : 'на транспорте'}
          </span>
        )}
      </div>
    );
  } else {
    return <div className={styles['address']}>{address}</div>;
  }
}
