import { ca } from '@cian/analytics';

export const trackToPromoClick = () => {
  ca('eventSite', {
    action: 'click',
    category: 'main_page',
    label: 'to_promo',
    name: 'oldevent',
  });
};
