import * as React from 'react';
import { IconicPromoLink } from './iconic_promo_link';
import { IPromoLink } from '../../../types/promo';

interface IProps {
  id: string;
  title: string;
  titleLink?: IPromoLink;
  links: IPromoLink[];
  type?: string;
  backgroundClassName: string;
  blockClassName: string;
  clickHandler?(): void;
}

const s = require('../vertical_promo_blocks.css');

export class IconicPromoBlock extends React.Component<IProps, object> {
  public render() {
    return (
      <div
        onClick={this.props.clickHandler}
        className={`${s['c-new-promo-block']}
                    ${s[this.props.backgroundClassName]}
                    ${this.props.blockClassName}`}
      >
        <div
          className={`${s['c-new-promo-block-content']}
                      ${s['c-new-promo-block-content-link-icon']}`}
        >
          {this.props.links.map((link: IPromoLink, i: number) => (
            <IconicPromoLink rootTitle={this.props.title} id={this.props.id} key={`promo-link-${i}`} {...link} />
          ))}
        </div>
      </div>
    );
  }
}
