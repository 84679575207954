import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import { openPromoDetails } from '../../actions/newbuildingPromo';
import { NewbuildingFlightBanner, NewbuildingPromoBlock } from '../../components/NewbuildingPromoBlock';
import { selectNewbuildingFlightActive, selectNewbuildingFlightBannerUrl } from '../../selectors/newbuildingFlight';
import { selectPromos } from '../../selectors/newbuildingPromo';
import { INewbuildingPromo } from '../../types/newbuildingPromo';
import { useApplicationContext } from '../../utils/applicationContext';
import { getNewbuildingPromosUrl } from './helpers/getNewbuildingPromosUrl';
import {
  trackNewbuildingFlightBannerClick,
  trackNewbuildingFlightBannerShow,
  trackDetailsClick,
  trackPromoShow,
  trackToPromoClick,
} from './tracking';
import { SubsidisedMortgageBannerContainer } from '../SubsidisedMortgageBannerContainer';

export const NewbuildingPromoBlockContainer: React.VFC = () => {
  const { config } = useApplicationContext();
  const promosUrl = getNewbuildingPromosUrl(config);
  const promos = useSelector(selectPromos);

  const isNewbuildingFlightActive = useSelector(selectNewbuildingFlightActive);
  const newbuildingFlightBannerUrl = useSelector(selectNewbuildingFlightBannerUrl);

  const dispatch = useDispatch();

  const handlePromoShow = React.useCallback(() => {
    trackPromoShow(promos);

    if (isNewbuildingFlightActive) {
      trackNewbuildingFlightBannerShow();
    }
  }, [promos, isNewbuildingFlightActive]);

  const handlePromoClick = React.useCallback(
    (promo: INewbuildingPromo, index: number) => {
      dispatch(openPromoDetails(promo));
      trackDetailsClick({ promo, index });
    },
    [dispatch],
  );

  // TODO: Удалить второе условие в CD-221204.
  if (!promos.length && !isNewbuildingFlightActive) {
    return null;
  }

  return (
    <ActionAfterViewObserver callback={handlePromoShow} triggerOnce>
      <NewbuildingPromoBlock
        subsidisedMortgageBanner={<SubsidisedMortgageBannerContainer key="subsidised-mortgage-banner" />}
        // TODO: Удалить в CD-221204.
        newbuildingFlightBanner={
          isNewbuildingFlightActive ? (
            <NewbuildingFlightBanner
              url={newbuildingFlightBannerUrl as string}
              onClick={trackNewbuildingFlightBannerClick}
            />
          ) : null
        }
        promos={promos}
        onPromoClick={handlePromoClick}
        onPromoLinkClick={trackToPromoClick}
        promosUrl={promosUrl}
      />
    </ActionAfterViewObserver>
  );
};
