import * as React from 'react';
import { Link } from '../../link/link';

const s = require('../vertical_promo_blocks.css');

interface IProps {
  coordinates?: {
    lat: number;
    lng: number;
  };
  section?: string;
  blockClassName?: string;
  subdomainId: number;
}

export class MapPromoBlock extends React.Component<IProps> {
  public render() {
    return (
      <div
        className={`${s['c-new-promo-block']}
                    ${s['c-new-promo-block_map']}
                    ${this.props.blockClassName || 'cg-col-16 cg-col-promo-s-12 cg-col-xs-12'}`}
      >
        <div
          className={`${s['c-new-promo-block-content']}
                      ${s['c-promo-info-block-map-content']}`}
        >
          <span className={s['c-promo-info-map-title']}>Поиск на карте</span>
          <span className={s['c-promo-info-map-text']}>
            Ищите новостройки рядом с работой, <br />
            парком или родственниками
          </span>
          <Link href="/map-novostroyki/" className={s['c-promo-info-map-button']}>
            Найти на карте
          </Link>
        </div>
      </div>
    );
  }
}
