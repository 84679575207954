import { IOfferType } from '../types/offerTypeList';
import { FOfferType } from './category';

export const getOfferTypesSaleResidentialList = (isSearchFlatShareEnabled: boolean): IOfferType[][] => [
  [
    { label: 'Квартира в новостройке', value: FOfferType.FlatNew },
    { label: 'Квартира во вторичке', value: FOfferType.FlatOld },
  ],
  isSearchFlatShareEnabled
    ? [{ label: 'Комната', value: FOfferType.Room }]
    : [
        { label: 'Комната', value: FOfferType.Room },
        { label: 'Доля', value: FOfferType.FlatShared },
      ],
  [
    { label: 'Дом, дача', value: FOfferType.House },
    { label: 'Часть дома', value: FOfferType.HousePart },
    { label: 'Таунхаус', value: FOfferType.Townhouse },
    { label: 'Участок', value: FOfferType.Land },
  ],
  [{ label: 'Гараж', value: FOfferType.Garage }],
];
