import { IJsonQuery } from '../../packages/api-models/common/json_query';
import { ESource, ISuggestRequest } from '../repositories/geo-suggest/v2/suggest';
import { GeoSuggestDependencies, getGeoSuggestions, IGeoSuggestResult } from '../services/geoSuggest';
import { dealTypeFromJsonQuery, offerTypeFromJsonQuery, FOfferType, FDealType } from '../utils/category';

export interface IGetGeoSuggestionsParameters {
  jsonQuery: IJsonQuery;
  regionId: number;
  query: string;
}

export function getGeoSuggestionItems(
  depenpencies: GeoSuggestDependencies,
  parameters: IGetGeoSuggestionsParameters,
): Promise<IGeoSuggestResult> {
  const formattedParameters = getFormattedGeoSuggestionsParameters(parameters);

  return getGeoSuggestions(depenpencies, formattedParameters);
}

export function getFormattedGeoSuggestionsParameters(parameters: IGetGeoSuggestionsParameters): ISuggestRequest {
  const { jsonQuery, regionId, query } = parameters;
  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  const queryDealType = mapDealType(dealType) as ISuggestRequest['dealType'];
  const queryOfferType = mapOfferType(offerType) as ISuggestRequest['offerType'];

  const isCoworkingSearch = !!jsonQuery?.coworking_offer_type?.value?.length;

  return {
    query,
    regionId,
    isCoworkingSearch,
    dealType: queryDealType,
    offerType: queryOfferType,
    source: ESource.Mainpage,
    isDailyRent: dealType === FDealType.RentDaily,
  };
}

export function mapDealType(dealType: FDealType): string | null {
  switch (true) {
    case (dealType & FDealType.Sale) !== 0:
      return 'sale';

    case (dealType & FDealType.Rent) !== 0:
      return 'rent';
  }

  return null;
}

export function mapOfferType(offerType: FOfferType): string | null {
  switch (true) {
    case offerType === FOfferType.FlatNew:
      return 'newobject';

    case (offerType & FOfferType.Flat) !== 0:
      return 'flat';

    case (offerType & FOfferType.Suburban) !== 0:
      return 'suburban';

    case (offerType & FOfferType.Commercial) !== 0:
      return 'commercial';
  }

  return null;
}
