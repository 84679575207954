import classNames from 'classnames';
import React, { DetailedHTMLProps, useCallback, HTMLAttributes, MouseEvent, HTMLAttributeAnchorTarget } from 'react';

import styles from './LinkArea.css';

interface ILinkAreaProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  href: string;
  preventDefault?: boolean;
  target?: HTMLAttributeAnchorTarget;
}

export function LinkArea({ href, children, preventDefault, target, ...divProps }: ILinkAreaProps) {
  const onLinkClick = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      if (preventDefault) {
        e.preventDefault();
      }
    },
    [preventDefault],
  );

  return (
    <div {...divProps} className={classNames(styles['container'], divProps.className)}>
      <a href={href} className={styles['link']} onClick={onLinkClick} target={target}>
        <span className={styles['link-area']} />
      </a>
      {children}
    </div>
  );
}
