import { ENewbuildingPromosActionTypes } from '../../actions/newbuildingPromo';
import { INewbuildingPromoState } from '../../types/newbuildingPromo';
import { TReduxActions } from '../../types/redux';

export const INITIAL_STATE: INewbuildingPromoState = {
  details: null,
  isDetailsOpen: false,
  promos: [],
};

export const newbuildingPromoReducer = (
  state: INewbuildingPromoState = INITIAL_STATE,
  action: TReduxActions,
): INewbuildingPromoState => {
  switch (action.type) {
    case ENewbuildingPromosActionTypes.CloseDetails:
      return {
        ...state,
        details: null,
        isDetailsOpen: false,
      };

    case ENewbuildingPromosActionTypes.OpenDetails:
      return {
        ...state,
        details: action.payload,
        isDetailsOpen: true,
      };

    default:
      return state;
  }
};
