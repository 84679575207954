import { TControlOptions } from '@cian/ui-kit';

import { CoworkingSwitchButtonModel } from './CoworkingSwitchButtonModel';

export class CoworkingSwitchButtonsCollectionModel {
  public readonly collection: TControlOptions;

  public constructor(...collection: Array<CoworkingSwitchButtonModel>) {
    this.collection = collection;
  }
}
