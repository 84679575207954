export const COMPOSITION_CONFIG: IComposition = {
  9: [8, 8, 8, 8, 8, 8, 8, 8, 8],
  8: [12, 12, 8, 8, 8, 8, 8, 8],
  7: [12, 12, 8, 8, 8, 12, 12],
  6: [8, 8, 8, 8, 8, 8],
  5: [12, 12, 8, 8, 8],
  4: [12, 12, 12, 12],
  3: [8, 8, 8],
  2: [12, 12],
};

export const BACKGROUND_CONFIG: IBackground = {
  commercialOffice: 'c-com-promo-block_office',
  commercialWarehouse: 'c-com-promo-block_storage',
  commercialShoppingArea: 'c-com-promo-block_shop',
  commercialIndustry: 'c-com-promo-block_industry',
  commercialBuilding: 'c-com-promo-block_building',
  commercialFreeAppointment: 'c-com-promo-block_free',
  commercialBusiness: 'c-com-promo-block_business',
  commercialLand: 'c-com-promo-block_commerce',
  commercialGarage: 'c-com-promo-block_garage',
};

interface IComposition {
  [Identifier: number]: number[];
}

interface IBackground {
  [Identifier: string]: string;
}
