import * as React from 'react';
import { ServiceAds } from '../service_ads/service_ads';

import * as styles from './sale_item_block.css';

interface IProps {
  onAdsClick(eventLabel: string): void;
}

export const SaleAdsBlock = (props: IProps) => (
  <div className={`${styles['c-bottom-selfads-col']} cg-col-12`}>
    <ServiceAds
      class={styles['c-selfads-item-add']}
      onClick={() => props.onAdsClick('add_adv')}
      title="Размещайте объявления"
      description="Новая форма подачи позволит быстро и удобно разместить объявление"
      buttonText="Добавить объявление"
      buttonUrl="/razmestit-obyavlenie/"
    />
  </div>
);
