import * as React from 'react';

import { PopularItem } from '../popular_item/popular_item';
import { PopularContainer } from './popular_container';
import { PopularContent } from './popular_content';
import { PopularHeader } from './popular_header';
import { ITopGroup, ITopOffer } from '../../../shared/types/top_three';

interface IProps {
  id: string;
  title: string;
  groups: ITopGroup[];
  selectItem(id: string): void;
  isItemSelected(id: string): boolean;
  onTabClick(eventLabel: string): void;
  onItemClick(order: number): void;
  getOffers(): ITopOffer[];
}

export const PopularList = (props: IProps) => (
  <PopularContainer>
    <PopularHeader
      key={props.id}
      title={props.title}
      groups={props.groups}
      onTabClick={(eventLabel: string) => props.onTabClick(eventLabel)}
      selectGroupItem={props.selectItem}
      groupItemSelected={props.isItemSelected}
    />
    <PopularContent
      onItemClick={(order: number) => props.onItemClick(order)}
      popularItem={PopularItem}
      offers={props.getOffers()}
    />
  </PopularContainer>
);
