import { ESpecialTabs } from '../../constants/specialTabs';

export const SET_ACTIVE_SPECIAL_TAB = 'specialTabs/set_active' as const;

export const setActiveSpecialTab = (payload: ESpecialTabs | null) => ({
  type: SET_ACTIVE_SPECIAL_TAB,
  payload,
});

export type TSetActionSpecialTabActive = ReturnType<typeof setActiveSpecialTab>;
