import { Action } from 'redux';
import { batch } from 'react-redux';

import { getMeta } from './getMeta';

import { TThunkAction } from '../../types/redux';
import {
  FILTERS_SET_MAX_WORKPLACE_COUNT_TYPE,
  FILTERS_SET_MIN_WORKPLACE_COUNT_TYPE,
} from '../../constants/action_types';

export interface ISetMaxWorkplaceCount extends Action<typeof FILTERS_SET_MAX_WORKPLACE_COUNT_TYPE> {
  maxWorkplaceCount?: number;
}

export const setMaxWorkplaceCount: (maxWorkplaceCount?: number) => TThunkAction = maxWorkplaceCount => dispatch => {
  batch(() => {
    dispatch<ISetMaxWorkplaceCount>({
      type: FILTERS_SET_MAX_WORKPLACE_COUNT_TYPE,
      maxWorkplaceCount,
    });

    dispatch(getMeta());
  });
};

export interface ISetMinWorkplaceCount extends Action<typeof FILTERS_SET_MIN_WORKPLACE_COUNT_TYPE> {
  minWorkplaceCount?: number;
}

export const setMinWorkplaceCount: (minWorkplaceCount?: number) => TThunkAction = minWorkplaceCount => dispatch => {
  batch(() => {
    dispatch<ISetMinWorkplaceCount>({
      type: FILTERS_SET_MIN_WORKPLACE_COUNT_TYPE,
      minWorkplaceCount,
    });

    dispatch(getMeta());
  });
};
