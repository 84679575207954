import { TReduxActions } from '../../../types/redux';
import { IGeocodeState } from '../../../types/filters';
import { FILTERS_GEOCODE_TYPE } from '../../../constants/action_types';
import { defaultState } from './defaultState';

export function geocodeReducer(state: IGeocodeState = defaultState, action: TReduxActions): IGeocodeState {
  switch (action.type) {
    case FILTERS_GEOCODE_TYPE:
      return {
        ...state,
        status: action.status,
      };
  }

  return state;
}
