import * as React from 'react';

import * as styles from './Calendar.css';

export interface ICalendarProps {
  months: React.ReactNode;
  prevButton: React.ReactNode;
  nextButton: React.ReactNode;
}

export const Calendar = (props: ICalendarProps) => {
  const { months, prevButton, nextButton } = props;

  return (
    <div className={styles['container']}>
      <div className={styles['arrows']}>
        {prevButton}
        <div className={styles['next_button']}>{nextButton}</div>
      </div>

      <div className={styles['months']}>{months}</div>
    </div>
  );
};
