import * as React from 'react';

import { useSelector } from 'react-redux';
import { selectIsDetailsOpen } from '../../selectors/newbuildingPromo';
import { NewbuildingPromoModalLoadable } from './NewbuildingPromoModalLoadable';

export const NewbuildingPromoModalLazy: React.VFC = () => {
  const isOpen = useSelector(selectIsDetailsOpen);

  if (!isOpen) {
    return null;
  }

  return <NewbuildingPromoModalLoadable />;
};
