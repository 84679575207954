import { ISearchRealtorsState } from '../../types/searchRealtors';
import { ESpecialistTypes } from '../../constants/searchRealtors';
import {
  SET_DEAL_TYPE,
  SET_OFFER_TYPE,
  SET_SEARCH_TEXT,
  SET_SPECIALIST_TYPE,
  TSearchRealtorsActionTypes,
} from '../../actions/searchRealtors';

const defaultState: ISearchRealtorsState = {
  specialistType: ESpecialistTypes.Realtors,
  dealType: null,
  offerType: null,
  searchText: null,
};

export function searchRealtorsReducer(state = defaultState, action: TSearchRealtorsActionTypes): ISearchRealtorsState {
  switch (action.type) {
    case SET_SPECIALIST_TYPE:
      return {
        ...state,
        specialistType: action.payload,
      };

    case SET_DEAL_TYPE:
      return {
        ...state,
        dealType: action.payload,
      };

    case SET_OFFER_TYPE:
      return {
        ...state,
        offerType: action.payload,
      };

    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };

    default:
      return state;
  }
}
