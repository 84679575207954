/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetUserRecommendationsModel,
  IGetUserRecommendationsRequest,
  IMappers,
  IGetUserRecommendationsResponse,
  IGetUserRecommendationsResponseError,
  TGetUserRecommendationsResponse,
} from './types';

const defaultConfig: TGetUserRecommendationsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'newbuilding-search',
  pathApi: '/v1/get-user-recommendations/',
  hostType: 'api',
} as TGetUserRecommendationsModel;

function createGetUserRecommendationsModel(parameters: IGetUserRecommendationsRequest): TGetUserRecommendationsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetUserRecommendationsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetUserRecommendationsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetUserRecommendations<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetUserRecommendationsOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetUserRecommendationsResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetUserRecommendationsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetUserRecommendationsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetUserRecommendationsResponseError);
    }
  }

  return { response, statusCode } as TGetUserRecommendationsResponse;
}

export { defaultConfig, createGetUserRecommendationsModel, fetchGetUserRecommendations };
