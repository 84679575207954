/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetBusinessPossibleAppointmentsModel,
  IGetBusinessPossibleAppointmentsRequest,
  IMappers,
  IGetBusinessPossibleAppointmentsResponse,
  IGetBusinessPossibleAppointmentsResponseError,
  TGetBusinessPossibleAppointmentsResponse,
} from './types';

const defaultConfig: TGetBusinessPossibleAppointmentsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'announcements',
  pathApi: '/v1/get-business-possible-appointments/',
  hostType: 'api',
} as TGetBusinessPossibleAppointmentsModel;

function createGetBusinessPossibleAppointmentsModel(
  parameters: IGetBusinessPossibleAppointmentsRequest,
): TGetBusinessPossibleAppointmentsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetBusinessPossibleAppointmentsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetBusinessPossibleAppointmentsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetBusinessPossibleAppointments<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetBusinessPossibleAppointmentsOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetBusinessPossibleAppointmentsResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetBusinessPossibleAppointmentsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetBusinessPossibleAppointmentsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetBusinessPossibleAppointmentsResponseError);
    }
  }

  return { response, statusCode } as TGetBusinessPossibleAppointmentsResponse;
}

export { defaultConfig, createGetBusinessPossibleAppointmentsModel, fetchGetBusinessPossibleAppointments };
