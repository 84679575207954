import * as React from 'react';
import { mergeStyles } from '@cian/utils';
import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';
import * as styles from './FilterAmountInput.css';
import MaskedInput from 'react-text-mask';
// tslint:disable-next-line: match-default-export-name
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export interface IFilterAmountInputProps extends React.HTMLAttributes<HTMLDivElement> {
  integerLimit: number;
  placeholder: string;
  suffix: string;
  value?: number;

  onValueChange?(value: number | undefined): void;
}

const NUMBER_MASK_OPTIONS = {
  prefix: '',
  thousandsSeparatorSymbol: ' ',
};

export const FilterAmountInput = (props: IFilterAmountInputProps) => {
  const { integerLimit, placeholder, onValueChange, suffix, className, value, ...sharedProps } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value.replace(/\s+/gi, '');
    if (onValueChange) {
      onValueChange(value ? Number(value) : undefined);
    }
  };

  return (
    <div
      {...mergeStyles(stylesCommon['container'], styles['container'], className)}
      data-mark="FilterAmountInput"
      {...sharedProps}
    >
      <MaskedInput
        type="text"
        {...mergeStyles(stylesCommon['input'], styles['input'])}
        placeholder={placeholder}
        onChange={handleChange}
        mask={createNumberMask({
          ...NUMBER_MASK_OPTIONS,
          integerLimit,
        })}
        value={value}
      />
      {suffix != null && <span {...mergeStyles(styles['suffix'])}>{suffix}</span>}
    </div>
  );
};
