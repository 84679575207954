import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import map from 'lodash/map';
import find from 'lodash/find';

import { IGeoObject } from '../../components/search_filters/filters/geo_suggest/geo_helpers';
import { UserGeoFilter as UserGeoFilterInput } from '../../components/search_filters/filters/user_geo_filter';
import { UserGeoMultiFilter } from '../../components/search_filters/filters/user_geo_multi_filter';
import * as Analytics from '../../utils/analytics_helper';
import { getMainPlaceholder } from '../../utils/placeholder';
import { IDomain } from '../../reducers/modules/domain/domain';
import { selectSuburbanBuildingType } from '../../reducers/modules/search/building_types';
import {
  ISetUserGeoWithGeoType,
  selectGeo,
  setUserGeoSuggest,
  ISetDirty,
  ISetUserGeo,
  setDirty,
  setUserGeo,
  setUserGeoWithGeoType,
} from '../../reducers/modules/search/user_geo';
import { getCookie } from '../../utils/helpers/cookie_helper';
import { IUserGeo, IUserGeoDefinitionWithFilter, IUserGeoSearch } from '../../types/filters/user_geo';
import { IRegionsData } from '../../types/regions_data';
import { IApplicationState } from '../../types/redux';

interface IStateProps {
  buildingType: string;
  searchType: string;
  userGeo: IUserGeo;
  regionsData: IRegionsData;
  domain: IDomain;
  section: string;
}

interface IDispatchProps {
  setDirty: ISetDirty;
  setUserGeo: ISetUserGeo;
  setUserGeoWithGeoType: ISetUserGeoWithGeoType;
  setGeoSuggest(geoSuggest: string): void;
  onGeoSelected(value: IGeoObject): void;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IOwnProps {}

interface IUserGeoFilterProps extends IStateProps, IDispatchProps, IOwnProps {}

interface IRegionAndCity {
  id: number;
  title: string;
  subdomainName?: string;
}

interface IFormatedRegionAndCity {
  value: number;
  label: string;
}

enum EFavoritesNewBuildinsRegions {
  MoscowAndMO = 1,
  SPBAndLO = 2,
}

class UserGeoFilter extends React.Component<IUserGeoFilterProps> {
  protected setDirty(isDirty: boolean) {
    this.props.setDirty(isDirty);
  }

  private getGeoCookie = (query: string): string => {
    if (typeof window !== 'undefined') {
      return getCookie(document.cookie, query);
    }

    return '';
  };

  public componentDidMount() {
    this.setDirty(true);

    const geoIDSessionMainTown = Number(this.getGeoCookie('session_main_town_region_id'));
    const geoIDSessionRegion = Number(this.getGeoCookie('session_region_id'));
    const geoIDForeverMainTown = Number(this.getGeoCookie('forever_main_town_region_id'));
    const geoIDForeverRegion = Number(this.getGeoCookie('forever_region_id'));

    let geoID: number | undefined;

    if (this.props.section !== 'new-buildings') {
      geoID = geoIDSessionMainTown || geoIDSessionRegion || geoIDForeverMainTown || geoIDForeverRegion;
    }

    if (!geoID) {
      geoID = find(this.props.regionsData.regions, ['id', this.props.userGeo.userGeo]) ? this.props.userGeo.userGeo : 1;
    }

    if (this.props.section === 'new-buildings') {
      switch (geoID) {
        case EFavoritesNewBuildinsRegions.MoscowAndMO:
          geoID = -1 * EFavoritesNewBuildinsRegions.MoscowAndMO;
          break;

        case EFavoritesNewBuildinsRegions.SPBAndLO:
          geoID = -1 * EFavoritesNewBuildinsRegions.SPBAndLO;
          break;

        default:
          break;
      }
    }

    this.props.setUserGeo(geoID);
  }

  public render() {
    const {
      section,
      userGeo,
      regionsData: { regions, cities },
      setUserGeo,
      buildingType,
      searchType,
      onGeoSelected,
    } = this.props;

    const placeholder = getMainPlaceholder(buildingType);

    if (section !== 'new-buildings') {
      return (
        <UserGeoMultiFilter
          bsCenterId={userGeo.bsCenterId}
          buildingType={buildingType}
          searchType={searchType}
          section={section}
          regionOrMaintownId={userGeo.userGeo}
          geoValue={userGeo.geoValue}
          regions={this.formatData(regions)}
          cities={this.formatData(cities)}
          onChange={this.onGeoStrictFilterChanged}
          onGeoSuggestChange={this.onGeoSuggestChange}
          onGeoSelected={onGeoSelected}
          placeholder={placeholder}
        />
      );
    }

    return (
      <UserGeoFilterInput
        section={section}
        value={userGeo.userGeo}
        values={this.formatData(regions)}
        cities={this.formatData(cities)}
        onChange={(geo: number) => {
          Analytics.changeGeoSuggest(section, geo);
          setUserGeo(geo);
        }}
      />
    );
  }

  private onGeoStrictFilterChanged = (
    userGeoDefinitionWithFilter: IUserGeoDefinitionWithFilter,
    userGeoSearch: IUserGeoSearch,
  ): void => {
    this.props.setUserGeoWithGeoType(userGeoDefinitionWithFilter, userGeoSearch);
  };

  private onGeoSuggestChange = (geoSuggest: string) => {
    this.props.setGeoSuggest(geoSuggest);
  };

  private formatData = (data: IRegionAndCity[]): IFormatedRegionAndCity[] => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return map(data, (item: any) => {
      return {
        label: item.title,
        value: item.id,
      };
    });
  };
}

export function mapStateToProps(state: IApplicationState): IStateProps {
  const { mainpage, domain, userGeo, buildingType, type } = state;
  const { section, regionsData } = mainpage;

  return {
    buildingType: buildingType.buildingType[0],
    domain,
    regionsData,
    searchType: type.searchType,
    section,
    userGeo,
  };
}

export function mapDispatchToProps(dispatch: Dispatch): IDispatchProps {
  return {
    onGeoSelected: (value: IGeoObject) => {
      if (value.type === 'village') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dispatch(selectSuburbanBuildingType(value.id) as any);
      }
      dispatch(selectGeo(value));
    },
    setDirty: (isDirty: boolean) => dispatch(setDirty(isDirty)),
    setUserGeo: (userGeo: number) => dispatch(setUserGeo(userGeo)),
    setGeoSuggest: (geoSuggest: string) => dispatch(setUserGeoSuggest(geoSuggest)),
    setUserGeoWithGeoType: (
      userGeoDefinitionWithGeoFilter: IUserGeoDefinitionWithFilter,
      userGeoSearch: IUserGeoSearch,
    ) => dispatch(setUserGeoWithGeoType(userGeoDefinitionWithGeoFilter, userGeoSearch)),
  };
}

const UserGeoFilterWrapper = connect<IStateProps, IDispatchProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(UserGeoFilter);

export { UserGeoFilterWrapper as UserGeoFilter, UserGeoFilter as UserGeoFilterClass };
