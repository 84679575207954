import * as React from 'react';
import { ArticleParagraph1, UIHeading3 } from '@cian/ui-kit';

import { ENTER_KEY } from '../../../../constants/keyboard';
import * as styles from './NewbuildingFlightBanner.css';

interface INewbuildingFlightBannerProps {
  url: string | undefined;
  onClick: () => void;
}

export const NewbuildingFlightBanner: React.FC<INewbuildingFlightBannerProps> = ({ url, onClick }) => {
  const handleKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === ENTER_KEY) {
      event.preventDefault();
      event.currentTarget.click();
    }
  }, []);

  return (
    <li>
      <a
        className={styles['container']}
        onKeyDown={handleKeyDown}
        onClick={onClick}
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        tabIndex={0}
        data-testid="NewbuildingFlightBanner"
      >
        <div className={styles['title']}>
          <UIHeading3 color="white_100">12 способов купить квартиру выгодно</UIHeading3>
        </div>
        <div>
          {/* TODO: После установки свежей версии кита заменить на кнопку с темой, либо удалить в CD-221204. */}
          <button className={styles['button']}>
            <ArticleParagraph1 fontWeight="bold">Выбрать</ArticleParagraph1>
          </button>
        </div>
      </a>
    </li>
  );
};
