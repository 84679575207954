import * as React from 'react';

import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import { IBuildingTypesConfig, IBuildingTypesRow } from '../../../../shared/types/filters/building_types';
import { ISearchConfig } from '../../../../shared/types/search';

export const filterOptions = (
  searchType: string,
  buildingTypes: IBuildingTypesConfig,
  searchConfig: ISearchConfig,
): IBuildingTypesConfig => {
  const keys = Object.keys(searchConfig[searchType]);

  return pickBy(
    map(buildingTypes, (option: IBuildingTypesRow) => {
      return {
        name: option.name,
        options: pick(option.options, keys),
      };
    }),
    (value: IBuildingTypesRow) => !isEmpty(value.options),
  ) as IBuildingTypesConfig;
};

export const getSuitableBuildingType = (
  type: string,
  buildingType: string[],
  buildingTypes: IBuildingTypesConfig,
  searchConfig: ISearchConfig,
): string[] => {
  const filteredBuildingTypes = filterOptions(type, buildingTypes, searchConfig);

  const selectedKeys = flatten(map(filteredBuildingTypes, (value: IBuildingTypesRow) => Object.keys(value.options)));

  return intersection(selectedKeys, buildingType);
};

export function onInsideClick(fn: Function) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (event: React.MouseEvent<any>) => {
    const currentTargetRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    const isInByY =
      event.clientY >= currentTargetRect.top && event.clientY <= currentTargetRect.top + currentTargetRect.height;
    const isInByX =
      event.clientX >= currentTargetRect.left && event.clientX <= currentTargetRect.left + currentTargetRect.width;

    if (!isInByX || !isInByY) {
      return;
    }

    fn();
  };
}
