import { ca } from '@cian/analytics';

export function trackHotelsPostfixClick() {
  ca('eventSite', {
    action: 'Click',
    category: 'main_page',
    label: 'dailyrent_hotels_link_filter',
    name: 'oldevent',
    page: {
      pageType: 'Home',
      siteType: 'desktop',
    },
  });
}
