import * as React from 'react';
import * as styles from './Checkbox.css';
import { mergeStyles } from '@cian/utils';

export interface ICheckboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label: React.ReactNode;
}

// eslint-disable-next-line react/display-name
export const Checkbox = React.forwardRef<HTMLInputElement, ICheckboxProps>((props, ref) => {
  const { checked, disabled, tabIndex, label, ...restProps } = props;

  return (
    <label>
      <input
        {...restProps}
        ref={ref}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        className={styles['input']}
        tabIndex={tabIndex}
      />
      <span
        {...mergeStyles(
          props.className,
          styles['checkbox'],
          styles['common'],
          props.checked && styles['checked'],
          props.disabled && styles['disabled'],
        )}
      >
        <span className={styles['box']}>
          <span className={styles['icon']}>
            {/* eslint-disable-next-line react/forbid-elements */}
            <svg width="12" height="10" viewBox="0 0 12 10">
              <path
                fillRule="evenodd"
                d="M3.983 9.193l.97.807.824-.963L12 1.773 10.048 0 4.562 6.403 1.636 3.794 0 5.878z"
              />
            </svg>
          </span>
        </span>
        <span className={styles['label']}>{label}</span>
      </span>
    </label>
  );
});
