import {
  IGetPromoBannersResponse200,
  TGetPromoBannersResponse,
} from '../../../repositories/newbuilding-promos/v1/get-promo-banners';
import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

export function assertStatusCode200(
  response: TGetPromoBannersResponse,
): asserts response is IGetPromoBannersResponse200 {
  if (response.statusCode !== 200) {
    throw new HttpBadStatusCodeError(
      'Не удалось загрузить баннеры',
      response.statusCode,
      'shared.services.getNewbuildingBanners',
    );
  }
}
