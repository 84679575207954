// TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
import { useSelector } from 'react-redux';

import { selectExperiments } from '../../../selectors/experiments';
import { selectIsUserAgent, selectUserTariffs } from '../../../selectors/user';
import {
  CONTROL_GROUP_TEXT_NODE,
  CONTROL_GROUP_URL_NODE,
  EXPERIMENTAL_GROUP_NAME_NODE,
  EXPERIMENTAL_GROUP_TEXT_NODE,
  EXPERIMENTAL_GROUP_URL_NODE,
  EXPERIMENT_NAME_NODE,
} from '../../../utils/placementTextExperimentConstants';
import { useApplicationContext } from '../../../utils/applicationContext';
import { getExperimentGroup } from '../../../utils/getExperimentGroup';

export const usePlacementTabProps = () => {
  const { config } = useApplicationContext();
  const isAgent = useSelector(selectIsUserAgent);
  const tariffs = useSelector(selectUserTariffs);
  const experiments = useSelector(selectExperiments);
  const experimentName = config.get<string>(EXPERIMENT_NAME_NODE);
  const experimentalGroupName = config.get<string>(EXPERIMENTAL_GROUP_NAME_NODE);

  const experimentalProps = {
    text: config.get<string>(EXPERIMENTAL_GROUP_TEXT_NODE),
    url: config.get<string>(EXPERIMENTAL_GROUP_URL_NODE),
  };

  const controlProps = {
    text: config.get<string>(CONTROL_GROUP_TEXT_NODE),
    url: config.get<string>(CONTROL_GROUP_URL_NODE),
  };

  if (isAgent || isCommertialTariffs(tariffs) || !experimentName || !experimentalGroupName) {
    return {
      text: null,
      url: null,
    };
  }

  const experimentGroup = getExperimentGroup(experiments, experimentName);

  const isExperimentalProps = experimentGroup === experimentalGroupName;

  return isExperimentalProps ? experimentalProps : controlProps;
};

function isCommertialTariffs(tariffs: string[] | null) {
  const NON_COMMERCIAL_TARIFFS = ['none', 'noCommerce'];

  if (!tariffs) {
    return false;
  }

  if (tariffs.some(tariff => !NON_COMMERCIAL_TARIFFS.includes(tariff))) {
    return true;
  }

  return false;
}
