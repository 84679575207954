// eslint-disable-next-line import/no-restricted-paths
import { config } from '../../browser/config';

export const FAVORITE_REGIONS = [-1, 1, 4593, 2, -2, 4588];

interface ICoord {
  lat: number;
  lng: number;
}

export const formatMapURL = (coord?: ICoord, section?: string, subdomainId?: number) => {
  let options = '';
  if (section === 'new-buildings' && subdomainId && FAVORITE_REGIONS.includes(subdomainId)) {
    return `/map-novostroyki/`;
  }

  switch (section) {
    case 'index':
      break;
    case 'daily-rent':
      options = 'deal_type=1&flats=yes&type=2';
      break;
    case 'commercial':
      options = 'deal_type=rent&offices=yes';
      break;
    case 'sale':
      options = 'deal_type=2&flats=yes';
      break;
    case 'rent':
      options = 'deal_type=1&flats=yes';
      break;
    case 'new-buildings':
      options = `deal_type=2&engine_version=2&newbuildings_search=true&region=${subdomainId}`;
      break;
    default:
  }
  if (coord) {
    // tslint:disable-next-line:max-line-length
    return `${config.mapUrl}?${options}&engine_version=2&center=${coord.lat.toFixed(6)},${coord.lng.toFixed(
      6,
    )}&zoom=10`;
  } else {
    return `${config.mapUrl}?${options}&engine_version=2`;
  }
};
