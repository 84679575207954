/* eslint-disable */


//tslint:disable


import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import {IGetRegionResponseSchema} from '../../entities/schemas/GetRegionResponseSchema';


export type TGetRegionModel = IModel<IGetRegionRequest, TGetRegionResponse>;


export interface IGetRegionRequest {
/** ID региона **/
regionId?: number | null;
}


export type TGetRegionResponse = IGetRegionResponse200 | IGetRegionResponse400;

export interface IGetRegionResponse200 extends IModelResponse<IGetRegionResponse> {
statusCode: 200;
}

export interface IGetRegionResponse400 extends IModelResponse<IGetRegionResponseError> {
statusCode: 400;
}

export interface IGetRegionResponse {
data: IGetRegionResponseSchema;
status: EStatus;
}

export interface IGetRegionResponseError {
errors: any[];
message: string;
}

export type TResponses = IGetRegionResponse | IGetRegionResponseError;


export enum EStatus {
/** undefined **/
Ok = 'ok',
}


export interface IMappers<TResponse200,TResponse400> {
200(response: IGetRegionResponse): TResponse200;
400(response: IGetRegionResponseError): TResponse400;
}



