import { TCoworkingOfferTypeList } from '../../../../types/coworkingOfferType';

import { FDealType, FOfferType } from '../../../../utils/category';

import { coworkingOfferTypes } from '../mappers/coworkingOfferTypes';

export function getCoworkingOfferTypeList(dealType: FDealType, offerType: FOfferType): TCoworkingOfferTypeList {
  const typeList = coworkingOfferTypes.find(
    coworkingOfferType =>
      coworkingOfferType.dealType === dealType && coworkingOfferType.coworkingOfferType === offerType,
  )?.list;

  return typeList ?? <TCoworkingOfferTypeList>[];
}
