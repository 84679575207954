interface IRegionData {
  id: number;
  title: string;
  subdomainName: string;
}

export const DEFAULT_REGIONS_DATA: IRegionData[] = [
  {
    subdomainName: 'www',
    id: -1,
    title: 'Москва и Московская область',
  },
  {
    subdomainName: 'spb',
    id: -2,
    title: 'Санкт-Петербург и Ленинградская область',
  },
  { subdomainName: 'barnaul', id: 4555, title: 'Алтайский край' },
  {
    subdomainName: 'astrahan',
    id: 4558,
    title: 'Астраханская область',
  },
  { subdomainName: 'ufa', id: 4560, title: 'Башкортостан' },
  {
    subdomainName: 'belgorod',
    id: 4561,
    title: 'Белгородская область',
  },
  { subdomainName: 'bryansk', id: 4562, title: 'Брянская область' },
  { subdomainName: 'ulan-ude', id: 4563, title: 'Бурятия' },
  {
    subdomainName: 'vladimir',
    id: 4564,
    title: 'Владимирская область',
  },
  {
    subdomainName: 'volgograd',
    id: 4565,
    title: 'Волгоградская область',
  },
  {
    subdomainName: 'vologda',
    id: 4566,
    title: 'Вологодская область',
  },
  {
    subdomainName: 'voronezh',
    id: 4567,
    title: 'Воронежская область',
  },
  { subdomainName: 'mahachkala', id: 4568, title: 'Дагестан' },
  { subdomainName: 'ivanovo', id: 4570, title: 'Ивановская область' },
  { subdomainName: 'irkutsk', id: 4572, title: 'Иркутская область' },
  {
    subdomainName: 'kaliningrad',
    id: 4574,
    title: 'Калининградская область',
  },
  { subdomainName: 'elista', id: 4575, title: 'Калмыкия' },
  { subdomainName: 'kaluga', id: 4576, title: 'Калужская область' },
  {
    subdomainName: 'kemerovo',
    id: 4580,
    title: 'Кемеровская область',
  },
  { subdomainName: 'kirov', id: 4581, title: 'Кировская область' },
  {
    subdomainName: 'krasnodar',
    id: 4584,
    title: 'Краснодарский край',
  },
  {
    subdomainName: 'krasnoyarsk',
    id: 4585,
    title: 'Красноярский край',
  },
  { subdomainName: 'krym', id: 181462, title: 'Крым' },
  { subdomainName: 'kurgan', id: 4586, title: 'Курганская область' },
  { subdomainName: 'kursk', id: 4587, title: 'Курская область' },
  { subdomainName: 'lipetsk', id: 4589, title: 'Липецкая область' },
  { subdomainName: 'nn', id: 4596, title: 'Нижегородская область' },
  {
    subdomainName: 'novgorod',
    id: 4597,
    title: 'Новгородская область',
  },
  {
    subdomainName: 'novosibirsk',
    id: 4598,
    title: 'Новосибирская область',
  },
  { subdomainName: 'omsk', id: 4599, title: 'Омская область' },
  {
    subdomainName: 'orenburg',
    id: 4600,
    title: 'Оренбургская область',
  },
  { subdomainName: 'orel', id: 4601, title: 'Орловская область' },
  { subdomainName: 'penza', id: 4602, title: 'Пензенская область' },
  { subdomainName: 'perm', id: 4603, title: 'Пермский край' },
  {
    subdomainName: 'vladivostok',
    id: 4604,
    title: 'Приморский край',
  },
  { subdomainName: 'pskov', id: 4605, title: 'Псковская область' },
  { subdomainName: 'rostov', id: 4606, title: 'Ростовская область' },
  { subdomainName: 'ryazan', id: 4607, title: 'Рязанская область' },
  { subdomainName: 'samara', id: 4608, title: 'Самарская область' },
  {
    subdomainName: 'saratov',
    id: 4609,
    title: 'Саратовская область',
  },
  { subdomainName: 'ekb', id: 4612, title: 'Свердловская область' },
  { subdomainName: 'sevastopol', id: 184723, title: 'Севастополь' },
  {
    subdomainName: 'smolensk',
    id: 4614,
    title: 'Смоленская область',
  },
  {
    subdomainName: 'stavropol',
    id: 4615,
    title: 'Ставропольский край',
  },
  { subdomainName: 'tambov', id: 4617, title: 'Тамбовская область' },
  { subdomainName: 'kazan', id: 4618, title: 'Татарстан' },
  { subdomainName: 'tver', id: 4619, title: 'Тверская область' },
  { subdomainName: 'tomsk', id: 4620, title: 'Томская область' },
  { subdomainName: 'tula', id: 4621, title: 'Тульская область' },
  { subdomainName: 'tyumen', id: 4623, title: 'Тюменская область' },
  { subdomainName: 'izhevsk', id: 4624, title: 'Удмуртия' },
  {
    subdomainName: 'ulyanovsk',
    id: 4625,
    title: 'Ульяновская область',
  },
  { subdomainName: 'habarovsk', id: 4627, title: 'Хабаровский край' },
  { subdomainName: 'hmao', id: 4629, title: 'Ханты-Мансийский АО' },
  {
    subdomainName: 'chelyabinsk',
    id: 4630,
    title: 'Челябинская область',
  },
  { subdomainName: 'cheboksary', id: 4633, title: 'Чувашия' },
  {
    subdomainName: 'yaroslavl',
    id: 4636,
    title: 'Ярославская область',
  },
];
