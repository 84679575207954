import { IJsonQueryRangeValue } from '../../../../packages/api-models/common/json_query';
import { IApplicationState } from '../../../types/redux';
import { getRangeValue } from '../../../utils/jsonQuery';
import { selectJsonQuery } from '../selectJsonQuery';

export function selectBedsCount(state: IApplicationState): IJsonQueryRangeValue | null {
  const jsonQuery = selectJsonQuery(state);

  return getRangeValue('beds_count')(jsonQuery);
}
