/* eslint-disable react/jsx-no-target-blank, react/no-deprecated */
import { ca } from '@cian/analytics';
import { Input, Tooltip } from '@cian/ui-kit';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { trackRegistration, trackSubscribe } from './tracking';
import { IApplicationState } from '../../types/redux';
import { digestReset, ISubmitDigestSuccess, submitDigest } from '../../reducers/modules/digest/digest';

const s = require('./magazine.css');

interface IProps {
  isAuthenticated: boolean;
  email: string;
  onDigestSubmit(email: string): Promise<ISubmitDigestSuccess>;
  onDigestReset(): void;
  isSubscribed: boolean;
  error?: string;
}

interface IState {
  value: string;
  emailValid?: boolean;
}

const isFakeEmail = /@social\.cian\.ru$/;

export class DigestForm extends React.Component<IProps, IState> {
  private static renderLicence() {
    return (
      <div className={s['c-licence']}>
        <span>При подписке вы принимаете условия </span>
        <a target="_blank" className={s['c-licence-link']} href="//www.cian.ru/help/about/rules/">
          Пользовательского соглашения
        </a>{' '}
        и{' '}
        <a target="_blank" className={s['c-licence-link']} href="//www.cian.ru/help/about/36526/">
          Политики конфиденциальности
        </a>
      </div>
    );
  }

  public constructor(props: IProps) {
    super(props);
    this.state = {
      value: isFakeEmail.test(props.email) ? '' : props.email,
      emailValid: true,
    };
  }

  public getDerivedStateFromProps(props: IProps, state: IState) {
    if (props.isSubscribed) {
      ca('event', {
        name: 'oldevent',
        category: 'Magazine_subscription',
        action: 'Send',
        label: 'news_from_main_page',
      });
    }

    if (props.email && props.email !== state.value) {
      return {
        ...state,
        value: isFakeEmail.test(props.email) ? '' : props.email,
      };
    }

    return null;
  }

  public render() {
    return (
      <>
        {this.props.error ? (
          <div className={s['c-digest-form']}>
            <span className={s['c-digest-form-title']}>ПОДПИШИТЕСЬ НА НОВОСТНУЮ РАССЫЛКУ</span>
            <span className={`${s['c-digest-form-status']} ${s['c-digest-form-status-fail']}`}>
              Что-то пошло не так, попробуйте подписаться еще раз
            </span>
            <button
              onClick={() => this.props.onDigestReset()}
              className={`${s['c-digest-form-button']} ${s['c-digest-form-retry']}`}
            >
              Повторить
            </button>
          </div>
        ) : this.props.isSubscribed ? (
          <div className={s['c-digest-form']}>
            <span className={s['c-digest-form-title']}>ПОДПИШИТЕСЬ НА НОВОСТНУЮ РАССЫЛКУ</span>
            <span className={s['c-digest-form-status']}>Подписка оформлена!</span>
            <span className={s['c-digest-form-info']}>Каждую неделю будем сообщать о важном в мире недвижимости</span>
          </div>
        ) : (
          <div className={s['c-digest-form']}>
            <span className={s['c-digest-form-title']}>ПОДПИШИТЕСЬ НА НОВОСТНУЮ РАССЫЛКУ</span>
            <div className={s['c-digest-form-email-container']}>
              <Tooltip
                placement="bottom-start"
                arrow={true}
                open={!this.state.emailValid}
                title={this.state.emailValid ? '' : 'Укажите email'}
              >
                <Input
                  onChange={(_: React.ChangeEvent<HTMLInputElement>, value: string) => this.onChange(value)}
                  value={this.state.value}
                  type="text"
                  placeholder="Ваш email"
                />
              </Tooltip>
            </div>
            <button onClick={() => this.submitEmail()} className={s['c-digest-form-button']}>
              Подписаться
            </button>
            {!this.props.isAuthenticated && DigestForm.renderLicence()}
          </div>
        )}
      </>
    );
  }

  private submitEmail() {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(this.state.value)) {
      this.props.onDigestSubmit(this.state.value).then(data => {
        if (data.subscribe) {
          trackSubscribe();
        }

        if (data.auth) {
          trackRegistration();
        }
      });
    } else {
      this.showError();
    }
  }

  private onChange(email: string) {
    this.setState({ value: email, emailValid: true });
  }

  private showError = () => {
    this.setState(
      {
        value: this.state.value,
        emailValid: false,
      },
      () => {
        document.addEventListener('click', this.hideError, { capture: true });
      },
    );
  };

  private hideError = () => {
    this.setState(
      {
        value: this.state.value,
        emailValid: true,
      },
      () => {
        document.removeEventListener('click', this.hideError);
      },
    );
  };
}

export const DigestFormContainer = connect<
  Pick<IProps, 'isAuthenticated' | 'email'>,
  Pick<IProps, 'onDigestSubmit' | 'onDigestReset'>,
  Pick<IProps, 'isSubscribed' | 'error'>
>(
  (state: IApplicationState) => {
    const { user } = state;

    return {
      email: user.isAuthenticated && user.email ? user.email : '',
      isAuthenticated: user.isAuthenticated,
    };
  },
  (dispatch: Dispatch) => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDigestSubmit: (email: string) => dispatch(submitDigest(email) as any),
    onDigestReset: () => dispatch(digestReset()),
  }),
)(DigestForm);
