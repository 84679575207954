import { dailyRentChecksBeforeSearch } from './dailyrent/dailyRentChecksBeforeSearch';
import { ERentTime } from '../../../../packages/api-models/common/json_query';
import { TThunkAction } from '../../../types/redux';
import { getTermValue } from '../../../utils/jsonQuery';

// Если функция вернет false - переход к поиску не будет выполнен
export function doChecksBeforeSearch(): TThunkAction<boolean> {
  return (dispatch, getState) => {
    const {
      filters: { jsonQuery },
    } = getState();
    const isDailyRent = getTermValue('for_day')(jsonQuery) === ERentTime.Daily;

    return [
      isDailyRent ? dispatch(dailyRentChecksBeforeSearch()) : true,
      // ...проверки для других вертикалей
    ].every(Boolean);
  };
}
