import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { setRegionCookies } from './utils/cookie';
import { EGeoSwitcherStatus, IGeoSwitcherState, IRegion } from '../../types/geoSwitcher';
import { IApplicationState } from '../../types/redux';
import { getSuggestions, setCurrentRegion } from '../../actions/geoSwitcher';
import { GeoSwitcherHeader } from '../../components/GeoSwitcher/components/GeoSwitcherHeader';
import { GeoSwitcherBody } from '../../components/GeoSwitcher/components/GeoSwitcherBody';
import { useApplicationContext } from '../../utils/applicationContext';
import { fetchRegion } from '../../services/region/fetchRegion';
import { buildRedirectUrl } from './utils/url';

interface IGeoSwitcherContainerProps {
  closeGeoSuggest(): void;
}

const GeoSwitcherContainer: React.FC<IGeoSwitcherContainerProps> = ({ closeGeoSuggest }) => {
  const dispatch = useDispatch();
  const context = useApplicationContext();
  const [inputValue, setInputValue] = React.useState<string>('');
  const [isAutosubmitEnabled, setAutosubmitEnabled] = React.useState<boolean>(false);
  const { status, region, popularCities, suggests } = useSelector<IApplicationState, IGeoSwitcherState>(
    state => state.geoSwitcher,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceGetSuggestions = React.useCallback(
    debounce(value => {
      dispatch(getSuggestions(value));
    }, 300),
    [],
  );

  const onSubmitRegion = React.useCallback(async () => {
    if (region) {
      setRegionCookies(region);
      context.telemetry.increment('geoSwitcher.selectRegion');

      const res = await fetchRegion(
        {
          httpApi: context.httpApi,
          logger: context.logger,
        },
        { regionId: region.id, subdomain: context.custom.subdomain },
      );

      if (res !== null) {
        const { baseHost } = res.response.data;
        if (baseHost === window.location.origin) {
          window.location.reload();
        } else if (baseHost) {
          window.location.href = buildRedirectUrl(baseHost);
        }
      }

      closeGeoSuggest();
    }
  }, [region, context.telemetry, context.httpApi, context.logger, context.custom.subdomain, closeGeoSuggest]);

  React.useEffect(() => {
    if (region) {
      setInputValue(region.displayName);
      debounceGetSuggestions(region.displayName);
    }
    if (isAutosubmitEnabled) {
      onSubmitRegion();
      setAutosubmitEnabled(false);
    }
  }, [region, debounceGetSuggestions, isAutosubmitEnabled]);

  const onChangeInputValue = React.useCallback(
    (value: string) => {
      setInputValue(value);
      debounceGetSuggestions(value);
    },
    [debounceGetSuggestions],
  );

  const onChangeRegion = React.useCallback(
    (region: IRegion) => {
      setAutosubmitEnabled(true);
      dispatch(setCurrentRegion(region));
      debounceGetSuggestions(region.displayName);

      context.telemetry.increment('geoSwitcher.changeRegion');
    },
    [dispatch, debounceGetSuggestions, context.telemetry],
  );

  const onSelectRegion = React.useCallback(
    (selectedRegion: IRegion) => {
      setInputValue(selectedRegion.displayName);
      setAutosubmitEnabled(true);
      dispatch(setCurrentRegion(selectedRegion));
      debounceGetSuggestions(selectedRegion.displayName);
    },
    [dispatch, debounceGetSuggestions],
  );

  return popularCities.length > 0 && region ? (
    <>
      <GeoSwitcherHeader
        inputValue={inputValue}
        suggests={suggests}
        loading={status === EGeoSwitcherStatus.Loading}
        changeInputValue={onChangeInputValue}
        selectRegion={onSelectRegion}
      />
      <GeoSwitcherBody popularCities={popularCities} onChangeRegion={onChangeRegion} />
    </>
  ) : null;
};

// eslint-disable-next-line import/no-default-export
export default GeoSwitcherContainer;
