import {
  fetchGetRegion,
  IGetRegionResponse200,
  TGetRegionResponse,
} from '../../repositories/monolith-python/v1/get-region';
import { HttpBadStatusCodeError } from '@cian/peperrors/shared';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger/shared';

interface IContext {
  httpApi: IHttpApi;
  logger: ILogger;
}

interface IParams {
  subdomain: string;
  regionId: number;
}

export async function fetchRegion(
  { httpApi, logger }: IContext,
  { regionId, subdomain }: IParams,
): Promise<IGetRegionResponse200 | null> {
  try {
    const response: TGetRegionResponse = await fetchGetRegion({
      httpApi,
      parameters: {
        regionId,
      },
      config: { subdomain },
    });

    assertStatusCode200(response);

    return response;
  } catch (e) {
    logger.error(e, {
      domain: 'shared.services.fetchRegion]',
      message: `Failed to fetch region`,
    });

    return null;
  }
}

function assertStatusCode200(response: TGetRegionResponse): asserts response is IGetRegionResponse200 {
  if (response.statusCode !== 200) {
    throw new HttpBadStatusCodeError('Не удалось загрузить регион', response.statusCode, 'shared.services.fetchRegion');
  }
}
