import { mergeStyles } from '@cian/utils';

import React, { memo } from 'react';

import * as stylesCommon from '../../../../FiltersCommon/FiltersCommon.css';

import { ICoworkingPricePreviewProps } from './types';

export const CoworkingPricePreview = memo<ICoworkingPricePreviewProps>(function CoworkingPricePreview(props) {
  const { isActive, onClick, label, title } = props;

  const buttonClassName = mergeStyles(
    stylesCommon['button'],
    isActive && stylesCommon['button--active'],
    !label && stylesCommon['button--empty'],
  ).className;

  return (
    <button className={buttonClassName} onClick={onClick} title={title ?? undefined}>
      {label || 'Цена в мес.'}
    </button>
  );
});
