import * as React from 'react';

import { OptionalDropdown } from './optional_dropdown';
import { onInsideClick } from './helpers';
import { filterChange, filterClick } from './tracking';
import { SearchType } from '../../../../shared/types/filters/type';

const s = require('../search_filters.css');

interface IProps {
  value: string;
  onChange(value: SearchType): void;
}

export const TypeFilter = (props: IProps) => (
  <div
    className={`${s['c-filters-field']} ${s['c-filters-field-operations']}`}
    onClick={onInsideClick(() => filterClick('DealType'))}
  >
    <OptionalDropdown
      value={props.value}
      onChange={value => {
        filterChange('DealType', value as SearchType);
        props.onChange(value as SearchType);
      }}
      values={[
        { value: 'buy', label: 'Купить' },
        { value: 'rent_long', label: 'Снять' },
        { value: 'rent_short', label: 'Посуточно' },
      ]}
    />
  </div>
);
