const NBSP_CHAR_CODE = 160;

export const formatPrice = (num: number, minLength: number = 5, delimiter: string = ' ') => {
  const str = num.toString().split('.');
  if (str[0].length >= minLength) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, `$1${delimiter}`);
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, `$1${delimiter}`);
  }

  return str.join('.');
};

export const declineNumeric = (num: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];

  return titles[num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]];
};

export const formatDate = (dateString: string, full = true) => {
  const monthNames = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];
  const date = new Date(dateString);
  const now = new Date();

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  if (full) {
    return `${day} ${monthNames[monthIndex]} ${year}`;
  } else {
    /**
     * Если дата публикации новости сегодняшний день,
     * выводим время публикации, иначе выводим дату публикации.
     */
    if (day === now.getDate() && monthIndex === now.getMonth() && year === now.getFullYear()) {
      return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    } else {
      return `${day} ${monthNames[monthIndex].substring(0, 3)}`;
    }
  }
};

export const changeDotToComma = (num: number | string) => num.toString().replace('.', ',');

export const cropText = (text: string, limit: number) => {
  let textOutput = text;

  if (textOutput.length >= limit) {
    textOutput = textOutput.trim().replace(/[^a-zа-я0-9]+$/i, ''); // Удаляем лишние символы
    if (textOutput.length >= limit) {
      textOutput = textOutput.substr(0, limit - 1); // Удаляем последний символ
      textOutput = textOutput.replace(/[^a-zа-я0-9]+$/i, ''); // Удаляем все точки в конце
    }
    textOutput += '\u2026'; // Добавляем троеточие
  }

  return textOutput;
};

export const escapeHtml = (unsafe: string): string =>
  unsafe.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');

export function replaceNBSP(srt: string): string {
  const nbspRegExp = new RegExp(String.fromCharCode(NBSP_CHAR_CODE), 'g');

  return srt.replace(nbspRegExp, ' ');
}
