import * as R from 'ramda';
import { ESuburbanOfferFilter, IJsonQuery } from '../../../packages/api-models/common/json_query';

import { setTerm } from './helpers';

export function setPosessor(jsonQuery: IJsonQuery): (nextPosessor: ESuburbanOfferFilter) => IJsonQuery {
  let nextJsonQuery = R.clone(jsonQuery);

  return nextPosessor => {
    if (nextPosessor === ESuburbanOfferFilter.Any) {
      nextJsonQuery = setTerm('suburban_offer_filter')(nextJsonQuery)(undefined);
    } else {
      nextJsonQuery = setTerm('suburban_offer_filter')(nextJsonQuery)(nextPosessor);
    }

    return nextJsonQuery;
  };
}
