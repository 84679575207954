import { IconBus16, IconWalk16 } from '@cian/ui-kit';
import { UIText2 } from '@cian/ui-kit/typography';
import * as React from 'react';
import { TTransportType } from '../../../types/newbuildingRecommendations';

import * as styles from './GeoTravelTime.css';

interface IGeoTravelTimeProps {
  transportType: TTransportType;
  distance: string;
}

export const GeoTravelTime: React.FC<IGeoTravelTimeProps> = ({ transportType, distance }) => {
  const Icon = transportType === 'walk' ? IconWalk16 : IconBus16;

  return (
    <div className={styles['container']}>
      <div data-name={transportType} className={styles['icon']}>
        <Icon display="block" color="icon-secondary-default" />
      </div>

      <UIText2 display="inline" color="gray60_100">
        {distance}
      </UIText2>
    </div>
  );
};
