import * as React from 'react';
import { IProvider } from '../../../types/newbuildingBanners/compilationBanner';

import * as styles from './Providers.css';

interface IProvidersProps {
  providers: IProvider[];
}

export const Providers = (props: IProvidersProps) => {
  return (
    <div className={styles['providers']}>
      {props.providers.map(item => {
        return <img alt="" className={styles['img']} key={item.id} src={item.logoUrl} />;
      })}
    </div>
  );
};
