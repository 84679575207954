import { connect } from 'react-redux';

import * as React from 'react';
import { resetLastRooms, resetRoomType, setDealType, setLastRooms, setLastRoomTypes } from '../../actions/filters';
import { setActiveSpecialTab } from '../../actions/specialTabs';
import { FiltersTabs, IFiltersTabProps, IFiltersTabsProps } from '../../components/FiltersTabs';
import { DEAL_TYPE_NAMES } from '../../constants/dealType';
import { ESpecialTabs } from '../../constants/specialTabs';
import { selectSpecialTabActive } from '../../hooks/selectors/specialTabs';
import { EPageKind } from '../../types/page';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { dealTypeFromJsonQuery, FDealType } from '../../utils/category';
import { isAddformTabEnabled } from '../../utils/isAddformTabEnabled';
import { isDealRentTrapEnabled } from '../../utils/isDealRentTrapEnabled';
import { trackDealRentTrapClick, trackDealTypeTabClick, trackSpecialTabClick } from './tracking';
import { selectIsBuildersProjectsTabEnabled } from '../../selectors/buildersProjects';
import { selectIsUserAgent } from '../../selectors/user';

// TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
interface IOwnProps {
  placementTabProps?: {
    text: string | null;
    url: string | null;
  };
}

type TFiltersTabsStateProps = {
  currentTabActive: FDealType | ESpecialTabs;
  pageKind: EPageKind;
  isDealRentTrapEnabled: boolean;
  isAddformTabEnabled: boolean;
  isBuildersProjectsTabEnabled: boolean;
  isAgent?: boolean;
};
type TFilterOfferTypeDispatchProps = {
  onDealTypeSelect(event: React.MouseEvent<HTMLAnchorElement>, dealType: FDealType, pageKind: EPageKind): void;
  onLinkTabClick(pageKind: EPageKind, tab: ESpecialTabs): void;
  onSpecialTabSelect(event: React.MouseEvent<HTMLAnchorElement>, tab: ESpecialTabs, pageKind: EPageKind): void;
};

export function mapStateToProps(state: IApplicationState): TFiltersTabsStateProps {
  const currentTabActive = selectSpecialTabActive(state) || dealTypeFromJsonQuery(state.filters.jsonQuery);
  const isBuildersProjectsTabEnabled = selectIsBuildersProjectsTabEnabled(state);
  const isAgent = selectIsUserAgent(state);

  return {
    currentTabActive,
    pageKind: state.page.kind,
    isDealRentTrapEnabled: isDealRentTrapEnabled(state),
    isAddformTabEnabled: isAddformTabEnabled(currentTabActive),
    isBuildersProjectsTabEnabled,
    isAgent,
  };
}

export function mapDispatchToProps(dispatch: TThunkDispatch): TFilterOfferTypeDispatchProps {
  return {
    onDealTypeSelect: (event: React.MouseEvent<HTMLAnchorElement>, dealType: FDealType, pageKind: EPageKind) => {
      event.preventDefault();
      if ([FDealType.RentLongterm, FDealType.Sale].includes(dealType)) {
        // подставим фильтры, сохраненные перед переходом на посутку
        dispatch(setLastRoomTypes());
        // очистим фильтры, сохраненные перед переходом на посутку
        dispatch(resetLastRooms());
      } else {
        // при переходе на посутку сохраним фильтры
        dispatch(setLastRooms());
        // очистим текущие фильтры комнатности
        dispatch(resetRoomType());
      }

      dispatch(setDealType(dealType));
      dispatch(setActiveSpecialTab(null));
      trackDealTypeTabClick(pageKind, dealType);
    },
    onSpecialTabSelect: (event: React.MouseEvent<HTMLAnchorElement>, tab: ESpecialTabs, pageKind: EPageKind) => {
      event.preventDefault();

      dispatch(setActiveSpecialTab(tab));

      trackSpecialTabClick(pageKind, tab);
    },
    onLinkTabClick: (pageKind: EPageKind, tab: ESpecialTabs) => {
      trackSpecialTabClick(pageKind, tab);
    },
  };
}

export function mergeProps(
  stateProps: TFiltersTabsStateProps,
  dispatchProps: TFilterOfferTypeDispatchProps,
  // TODO: Безжалостно выпилить как только завершится эксперимент SPEC-348
  { placementTabProps }: IOwnProps = {},
): IFiltersTabsProps {
  const {
    currentTabActive,
    pageKind,
    isBuildersProjectsTabEnabled,
    isDealRentTrapEnabled,
    isAddformTabEnabled,
    isAgent,
  } = stateProps;
  const { onDealTypeSelect, onLinkTabClick, onSpecialTabSelect } = dispatchProps;
  const items = [
    {
      label: DEAL_TYPE_NAMES[FDealType.Sale],
      url: '/kupit/',
      isSelected: currentTabActive === FDealType.Sale,
      clickHandler: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        onDealTypeSelect(event, FDealType.Sale, pageKind);
      },
    },
    {
      label: DEAL_TYPE_NAMES[FDealType.RentLongterm],
      url: '/snyat/',
      isSelected: currentTabActive === FDealType.RentLongterm,
      clickHandler: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        onDealTypeSelect(event, FDealType.RentLongterm, pageKind);
      },
    },
    {
      label: DEAL_TYPE_NAMES[FDealType.RentDaily],
      url: '/posutochno/',
      isSelected: currentTabActive === FDealType.RentDaily,
      clickHandler: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        onDealTypeSelect(event, FDealType.RentDaily, pageKind);
      },
    },
    isBuildersProjectsTabEnabled && {
      label: 'Построить',
      url: '/country-builders-projects/?utm_medium=main_page&utm_content=bookmark',
      isSelected: false,
      clickHandler: () => {},
    },
    {
      label: 'Оценить',
      url: isAgent ? '/kalkulator-nedvizhimosti/' : '/my-home/?from=mainPage',
      isSelected: false,
      clickHandler: () => {
        onLinkTabClick(pageKind, ESpecialTabs.Valuation);
      },
    },
    {
      label: 'Ипотека',
      url: '/mortgage/',
      isSelected: currentTabActive === ESpecialTabs.Mortgage,
      clickHandler: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        onSpecialTabSelect(event, ESpecialTabs.Mortgage, pageKind);
      },
    },
    {
      label: 'Подбор риелтора',
      url: '/podbor-rieltora/',
      isSelected: false,
      clickHandler: () => {
        onLinkTabClick(pageKind, ESpecialTabs.SearchRealtors);
      },
    },
    isDealRentTrapEnabled && {
      label: 'Сдать квартиру',
      url: 'https://promo.cian.ru/sday-snimi-2/arenda',
      isSelected: false,
      clickHandler: trackDealRentTrapClick,
      target: '_blank',
    },
    isAddformTabEnabled && {
      label: placementTabProps?.text || 'Разместить объявление',
      url:
        placementTabProps?.url ||
        ` https://cian.ru/razmestit-obyavlenie/?channel=${
          currentTabActive === FDealType.Sale ? 'from_buy_submission_form' : 'from_rent_submission_form'
        }`,
      isSelected: false,
      clickHandler: () => {},
      datatestId: 'add-offer-tab',
    },
  ].filter(Boolean) as IFiltersTabProps[];

  return { items };
}

export const FiltersTabsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(FiltersTabs);
