import { fetchSuggest, ISuggestRequest } from '../../repositories/geo-suggest/v2/suggest';

import { mappers } from './mappers';
import { EGeoSuggestStatus, GeoSuggestDependencies, IGeoSuggestResult } from './types';

export const getGeoSuggestions = async (
  dependencies: GeoSuggestDependencies,
  parameters: ISuggestRequest,
): Promise<IGeoSuggestResult> => {
  const { httpApi, logger } = dependencies;

  try {
    return (await fetchSuggest<IGeoSuggestResult, IGeoSuggestResult>({
      httpApi,
      parameters,
      mappers,
    })) as IGeoSuggestResult;
  } catch (error) {
    logger.error(error, {
      degradation: 'Запрашивались данные из гео саджест',
      description: 'geo-suggest /v2/suggest/ rejection error',
      requestParameters: JSON.stringify(parameters),
    });

    return {
      data: {
        suggestions: {},
        sortOrder: [],
      },
      message: 'geo-suggest /v2/suggest/ rejection error',
      status: EGeoSuggestStatus.CriticalError,
    };
  }
};
