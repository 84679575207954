import { dataLayer } from './data_layer';

interface IProductData {
  name: string;
  id: string;
  price: number;
  brand: string;
  category: string;
  variant: string;
  list: string;
  position: number;
}

interface IPopularClickData {
  actionField: {
    list: string;
  };
  products: IProductData[];
}

interface IECommerce {
  impressions?: IProductData[];
  click?: IPopularClickData;
}

export const submitEvent = (action: string, label: string, ecommerce?: IECommerce) => {
  if (ecommerce) {
    dataLayer.push({
      event: 'oldevent',
      eventCategory: 'main_page',
      eventAction: action,
      eventLabel: label,
      ecommerce,
    });
  } else {
    dataLayer.push({
      event: 'oldevent',
      eventCategory: 'main_page',
      eventAction: action,
      eventLabel: label,
    });
  }
};

export const trackSearch = (trackValue: string) => {
  submitEvent('button_search', trackValue);
};

export const trackSpecialist = (trackValue: string) => {
  submitEvent('block_specialists', trackValue);
};

export const trackService = (trackValue: string) => {
  submitEvent('service_block', trackValue);
};

export const trackPremium = (trackValue: string) => {
  submitEvent('premium', trackValue);
};

export const trackPopular = (trackValue: string) => {
  submitEvent('top3', trackValue);
};

export const trackPopularAds = (trackValue: string, ecommerce?: IECommerce) => {
  submitEvent('pop_ads', trackValue, ecommerce);
};

export const trackLink = (url?: string) => {
  if (url) {
    submitEvent('direct_links', url);
  }
};
