import { findLeftElement } from './findLeftElement';

export function getLeftScrollOffset(container: HTMLElement, scrollLeft: number) {
  const leftChild = findLeftElement(container.children, scrollLeft, container.offsetLeft);
  const minLeftOffset = 0;

  if (!leftChild) {
    return minLeftOffset;
  }

  return leftChild.offsetLeft - container.offsetLeft;
}
