import {
  SET_BUSINESS_APPOINTMENTS_TYPE,
  SET_FILTER_BUSINESS_APPOINTMENTS_IDS,
} from '../../actions/businessAppointments';
import { TBusinessAppointmentsState } from '../../types/businessAppointments';
import { TReduxActions } from '../../types/redux';

const initialState: TBusinessAppointmentsState = {
  isLoaded: false,
  data: null,
  filterBusinessAppointmentsIds: Array.of<number>(),
};

export function businessAppointmentsReducer(state = initialState, action: TReduxActions): TBusinessAppointmentsState {
  switch (action.type) {
    case SET_BUSINESS_APPOINTMENTS_TYPE:
      return {
        ...state,
        isLoaded: true,
        data: action.payload,
      };

    case SET_FILTER_BUSINESS_APPOINTMENTS_IDS:
      return {
        ...state,
        filterBusinessAppointmentsIds: action.payload,
      };
  }

  return state;
}
