export const COMPOSITION_CONFIG: IComposition = {
  2: [
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-16 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-12 cg-col-promo-s-12 cg-col-xs-12',
  ],
  3: ['cg-col-24', 'cg-col-8 cg-col-promo-s-12 cg-col-xs-12', 'cg-col-16 cg-col-promo-s-12 cg-col-xs-12'],
  4: [
    'cg-col-16 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-16 cg-col-promo-s-12 cg-col-xs-12',
  ],
  5: [
    'cg-col-16 cg-col-promo-s-24 cg-col-xs-24',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
  ],
  6: [
    'cg-col-16 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-12',
    'cg-col-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-16 cg-col-promo-s-12 cg-col-xs-12',
  ],
  7: [
    'cg-col-16 cg-col-promo-s-24 cg-col-xs-24',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-12',
    'cg-col-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
  ],
  8: [
    'cg-col-16 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
    'cg-col-8 cg-col-promo-s-12 cg-col-xs-12',
  ],
};

export const BACKGROUND_CONFIG: IBackground = {
  newBuildingSalesLeaders: 'c-new-promo-block_white--bordered',
  newBuildingsBooking: 'c-new-promo-block_blue',
  newBuildingsBuilders: 'c-new-promo-block_builders',
  newBuildingsFinished: 'c-new-promo-block_buy-new-flat',
  newBuildingsFlat: 'c-new-promo-block_buy-new-flat2',
  newBuildingsFlatsFromDevelopers: 'c-new-promo-block_developers',
  newBuildingsInProcess: 'c-new-promo-block_under-construction',
  newBuildingsMap: '',
  newBuildingsPromos: 'c-new-promo-block_white',
  dailyRentPeriods: 'c-new-promo-block_rent-flat-daily',
  dailyRentFlat: 'c-new-promo-block_daily_rent',
  dailyRentRoom: 'c-new-promo-block_rent-room-daily',
  dailyRent_1roomFlat: 'c-new-promo-block_rent-1room-flat-daily',
  dailyRentHostel: 'c-new-promo-block_hostels',
  dailyRentHouse: 'c-new-promo-block_rent-house-daily',
  rentFlat: 'c-new-promo-block_rent-flat',
  rentHouse: 'c-new-promo-block_house',
  rentRoom: 'c-new-promo-block_rent-room',
  rentStudio: 'c-new-promo-block_rent-studio',
  rentGarage: 'c-new-promo-block_garage',
  rentCommercial: 'c-new-promo-block_blue',
  rentOffice: 'c-new-promo-block_office',
  rentBsCenter: 'c-new-promo-block_bs-center',
  saleFlat: 'c-new-promo-block_buy-flat',
  saleHouse: 'c-new-promo-block_house',
  saleRoom: 'c-new-promo-block_buy-room',
  saleTownhouse: 'c-new-promo-block_buy-townhouse',
  saleNewobjectFlat: 'c-new-promo-block_buy-new-flat',
  saleGarage: 'c-new-promo-block_garage',
  saleCommercial: 'c-new-promo-block_blue',
  saleLand: 'c-new-promo-block_buy-site',
  saleBsCenter: 'c-new-promo-block_bs-center',
};

interface IComposition {
  [Identifier: number]: string[];
}

interface IBackground {
  [Identifier: string]: string;
}
