import { IRegion } from '../../../types/geoSwitcher';
import { ITypedReduxAction } from '../../../types/redux/actionType';

export enum EGetPopularCitiesActionTypes {
  Loading = 'getPopularCities/loading',
  Succeed = 'getPopularCities/succeed',
  Failed = 'getPopularCities/failed',
}

export type TGetPopularCitiesLoading = ITypedReduxAction<EGetPopularCitiesActionTypes.Loading>;
export type TGetPopularCitiesSucceed = ITypedReduxAction<EGetPopularCitiesActionTypes.Succeed, IRegion[]>;
export type TGetPopularCitiesFailed = ITypedReduxAction<EGetPopularCitiesActionTypes.Failed>;

export type TGetPopularCities = TGetPopularCitiesLoading | TGetPopularCitiesSucceed | TGetPopularCitiesFailed;
