import * as React from 'react';
import { Button, Theme } from '@cian/ui-kit';
import { Banner } from '../Banner/Banner';
import { TBannerTheme } from '../utils/bannerTypeToTheme';
import { IPromoBanner } from '../../../types/newbuildingBanners/promoBanner';

interface IPromosBannerProps extends IPromoBanner {
  theme: TBannerTheme;
}

const DEFAULT_ACTION_TEXT = 'Открыть';

export const PromosBanner = ({ action, imageUrl, subtitle, title, theme }: IPromosBannerProps) => {
  return (
    <Banner
      theme={theme}
      title={title}
      imageUrl={imageUrl}
      labels={null}
      subtitle={subtitle}
      footer={
        <Theme theme="colored">
          <Button fullWidth={true}>{action.title || DEFAULT_ACTION_TEXT}</Button>
        </Theme>
      }
    />
  );
};
