import { connect } from 'react-redux';

import { IApplicationState } from '../../types/redux';
import { Hero, IHeroProps } from '../../components/Hero';

type THeroStateProps = Pick<IHeroProps, 'title' | 'isDobroshriftEnabled' | 'isChildrenDayEnabled'>;

export function mapStateToProps(state: IApplicationState): THeroStateProps {
  const {
    features: { isChildrenDayEnabled, isDobroshriftEnabled },
    mainpage: { seoData },
  } = state;

  return {
    title: seoData.h1,
    isDobroshriftEnabled,
    isChildrenDayEnabled,
  };
}

export const HeroContainer = connect(mapStateToProps)(Hero);
