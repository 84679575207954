import { IAbExperiment } from '../../types/abUseExperiments';
import { getExperiment } from '../getExperiment';

export function isExperimentActive(experiments: IAbExperiment[], experimentName: string, groupName: string): boolean {
  const experiment = getExperiment(experiments, experimentName);

  if (!experiment) {
    return false;
  }

  return experiment.groupName === groupName;
}
