import * as React from 'react';
import { NewbuildingSeoBlock } from '../../components/NewbuildingSeoBlock';
import { useSelector } from 'react-redux';
import { selectNewbuildingSeoBlock } from '../../selectors/newbuildingSeoBlock';

export const NewbuildingSeoBlockContainer = () => {
  const seoBlock = useSelector(selectNewbuildingSeoBlock);

  return <NewbuildingSeoBlock region={seoBlock.region} city={seoBlock.city} />;
};
