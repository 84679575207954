import { TReduxActions } from '../../../types/redux';
import { FILTERS_SET_MIN_AREA_TYPE, FILTERS_SET_MAX_AREA_TYPE } from '../../../constants/action_types';
import { JsonQuery } from '../../../utils/jsonQuery';
import { defaultState } from './defaultState';
import { IJsonQuery } from '../../../../packages/api-models/common/json_query';

export function setAreaReducer(jsonQuery: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  switch (action.type) {
    case FILTERS_SET_MIN_AREA_TYPE:
      return new JsonQuery(jsonQuery).setMinArea(action.minArea).getSerializable();

    case FILTERS_SET_MAX_AREA_TYPE:
      return new JsonQuery(jsonQuery).setMaxArea(action.maxArea).getSerializable();
  }

  return jsonQuery;
}
