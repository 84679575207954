import {
  IJsonQuery,
  IJsonQueryDateRange,
  IJsonQueryRange,
  IJsonQueryTerm,
  IJsonQueryTerms,
  IJsonQueryType,
  TGeoValue,
} from '../../../packages/api-models/common/json_query';
import * as R from 'ramda';

import { range, term, terms, geo, dateRange } from './constructors';

export function setRootType(value: IJsonQueryType): (jsonQuery: IJsonQuery) => IJsonQuery {
  return jsonQuery => ({
    ...jsonQuery,
    _type: value,
  });
}

export function setTerm<T, K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryTerm<T>>(
  key: K,
): (jsonQuery: IJsonQuery) => (value: V['value'] | undefined) => IJsonQuery {
  return jsonQuery => value => R.assoc(key, term(value), jsonQuery);
}

export function setTerms<T, K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryTerms<T>>(
  key: K,
): (jsonQuery: IJsonQuery) => (value: V['value']) => IJsonQuery {
  return jsonQuery => value => R.assoc(key, terms(value), jsonQuery);
}

export function setRange<K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryRange>(
  key: K,
): (jsonQuery: IJsonQuery) => (gte: V['value']['gte'], lte: V['value']['lte']) => IJsonQuery {
  return jsonQuery => (gte, lte) => R.assoc(key, range(gte, lte), jsonQuery);
}

export function setRangeMin<K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryRange>(
  key: K,
): (jsonQuery: IJsonQuery) => (gte: V['value']['gte']) => IJsonQuery {
  return jsonQuery => gte => R.assoc(key, range(gte, R.pathOr(undefined, [key, 'value', 'lte'], jsonQuery)), jsonQuery);
}

export function setRangeMax<K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryRange>(
  key: K,
): (jsonQuery: IJsonQuery) => (lte: V['value']['lte']) => IJsonQuery {
  return jsonQuery => lte => R.assoc(key, range(R.pathOr(undefined, [key, 'value', 'gte'], jsonQuery), lte), jsonQuery);
}

export function setDateRange<K extends keyof IJsonQuery>(
  key: K,
): (jsonQuery: IJsonQuery) => (gte: string, lt: string) => IJsonQuery {
  return jsonQuery => (gte, lt) => R.assoc(key, dateRange(gte, lt), jsonQuery);
}

export function setGeo(): (jsonQuery: IJsonQuery) => (value: TGeoValue[]) => IJsonQuery {
  return jsonQuery => value => R.assoc('geo', geo(value), jsonQuery);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTerm<T>(value: any): value is IJsonQueryTerm<T> {
  return typeof value === 'object' && value.type === 'term';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTerms<T>(value: any): value is IJsonQueryTerms<T> {
  return typeof value === 'object' && value.type === 'terms';
}

export function isRange(value: unknown): value is IJsonQueryRange {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'range';
}

export function isDateRange(value: unknown): value is IJsonQueryDateRange {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'date_range';
}

export function getTermValue<T, K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryTerm<T>>(
  key: K,
): (jsonQuery: IJsonQuery) => V['value'] | undefined {
  return jsonQuery => {
    const termField = jsonQuery[key];

    return isTerm<T>(termField) ? termField.value : undefined;
  };
}

export function getTermsValue<T, K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryTerms<T>>(
  key: K,
): (jsonQuery: IJsonQuery) => V['value'] {
  return jsonQuery => {
    const termsField = jsonQuery[key];

    return isTerms<T>(termsField) ? termsField.value : [];
  };
}

export function getRangeValue<K extends keyof IJsonQuery>(
  key: K,
): (jsonQuery: IJsonQuery) => IJsonQueryRange['value'] | null {
  return jsonQuery => {
    const rangeField = jsonQuery[key];

    return isRange(rangeField) ? rangeField.value : null;
  };
}

export function getDateRangeValue<K extends keyof IJsonQuery>(
  key: K,
): (jsonQuery: IJsonQuery) => IJsonQueryDateRange['value'] | null {
  return jsonQuery => {
    const rangeField = jsonQuery[key];

    return isDateRange(rangeField) ? rangeField.value : null;
  };
}
