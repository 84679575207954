import { ca } from '@cian/analytics';
import { parseCookies } from '@cian/utils/lib/shared/cookie';
import { sendTeaserEvent } from '../../api/sendTeaserEvent';
import { TDevice } from '../../types/teaser';
import { getViewportDimensions } from './utils/getViewportDimensions';

interface IParams {
  abGroup: number;
  userId?: number | null;
  soprApiUrl: string;
  isTeaserShow: boolean;
}

export function trackMortgageShow(params: IParams) {
  const event = {
    user_id: params.userId,
    pageType: 'main',
    device_type: 'desktop' as TDevice,
    ab_group: params.abGroup,
    viewport: getViewportDimensions(),
    cid: parseCookies(document.cookie)['_CIAN_GK'],
    items: [
      {
        isTeaserShow: params.isTeaserShow,
        block_type: 'mortgage_widget',
        name: 'dvornik_shown',
        dl: window.location.href,
      },
    ],
  };

  sendTeaserEvent(params.soprApiUrl, event);
}

export const trackCalculateClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'click',
    category: 'mortgage_widget',
    label: 'stavka_online_click',
  });
};
