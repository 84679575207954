import { connect } from 'react-redux';

import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { dealTypeFromJsonQuery, FDealType, FOfferType } from '../../utils/category';
import { selectOfferType } from '../../actions/filters';
import { FilterOfferType, IFilterOfferTypeProps } from '../../components/FilterOfferType';
import { togglePopupAutoChangeToSuburban } from '../../reducers/modules/search/building_types';
import { addBusinessTypesToOfferTypes } from '../../utils/addBusinessTypesToOfferTypes';
import { IApplicationContext } from '../../types/application';
import { compose } from 'ramda';
import { withApplicationContext } from '../../utils/applicationContext';
import { trackHotelsPostfixClick } from './tracking';
import { getTermsValue } from '../../utils/jsonQuery';

type TFilterOfferTypeDispatchProps = Pick<IFilterOfferTypeProps, 'onSelect' | 'hidePopupAutoChangeToSuburban'>;

export function mapStateToProps(
  state: IApplicationState,
  ownProps: IFilterOfferTypeProps,
): Pick<
  IFilterOfferTypeProps,
  'canShowPopupAutoChangeToSuburban' | 'offerType' | 'showPostfix' | 'postfixTitle' | 'postfixLink' | 'onPostfixClick'
> {
  const jsonQuery = state.filters.jsonQuery;
  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const isDailyRent = dealType === FDealType.RentDaily;
  const regionFromFilters = getTermsValue('region')(jsonQuery);
  const regionParam = regionFromFilters?.length ? `&region=${regionFromFilters}` : '';

  const offerType: IFilterOfferTypeProps['offerType'] = addBusinessTypesToOfferTypes(
    jsonQuery.ready_business_types?.value,
    ownProps.offerType,
  );

  return {
    canShowPopupAutoChangeToSuburban: Boolean(state.buildingType.canShowPopupAutoChangeToSuburban),
    offerType,
    showPostfix: ownProps.showPostfix && isDailyRent,
    postfixTitle: 'Отели и гостиницы',
    postfixLink: `/cat.php?deal_type=rent&engine_version=2&offer_type=flat&type=2&show_hotels=true${regionParam}`,
    onPostfixClick: trackHotelsPostfixClick,
  };
}

export const mapContextToProps = ({ config }: IApplicationContext) => {
  return {
    isSearchFlatShareEnabled: config.getStrict('frontend_search_flat_share_enabled.Enabled'),
    showPostfix: config.get<boolean>('Dailyrent.HotelsSearchFrontend.Enabled'),
  };
};

export function mapDispatchToProps(dispatch: TThunkDispatch): TFilterOfferTypeDispatchProps {
  return {
    onSelect: (offerType: FOfferType) => dispatch(selectOfferType(offerType)),
    hidePopupAutoChangeToSuburban: () => {
      dispatch(togglePopupAutoChangeToSuburban(false));
    },
  };
}

export const FilterOfferTypeContainer = compose(
  withApplicationContext(mapContextToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(FilterOfferType);
