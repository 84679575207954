import { findLeftElement } from './findLeftElement';

export function getFullLeftScrollOffset(container: HTMLElement, containerOffsetLeft: number) {
  const leftChild = findLeftElement(container.children, containerOffsetLeft, container.offsetLeft);
  const minLeftOffset = 0;

  if (!leftChild) {
    return minLeftOffset;
  }

  const leftOffset = leftChild.offsetLeft + leftChild.offsetWidth - container.offsetLeft - container.offsetWidth;

  return leftOffset >= minLeftOffset ? leftOffset : minLeftOffset;
}
