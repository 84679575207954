import { EVacanciesStatus, VacanciesMappers } from './types';

export const mappers: VacanciesMappers = {
  200: state => ({
    data: state,
    message: null,
    status: EVacanciesStatus.Success,
  }),
  400: ({ message }) => ({
    data: {
      pageCount: 0,
      vacancies: [],
      vacancyCount: 0,
    },
    message,
    status: EVacanciesStatus.Error,
  }),
};
