import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_MAX_PRICE_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';

export type TSetMaxPriceType = 'filters/set_max_price';

export interface ISetMaxPrice extends Action<TSetMaxPriceType> {
  type: TSetMaxPriceType;
  maxPrice: number | undefined;
}

export type TSetMaxPrice = ISetMaxPrice;

export function setMaxPrice(maxPrice: number | undefined): TThunkAction {
  return dispatch => {
    dispatch<TSetMaxPrice>({
      type: FILTERS_SET_MAX_PRICE_TYPE,
      maxPrice,
    });

    dispatch(getMeta());
  };
}
