import * as React from 'react';
import { connect } from 'react-redux';

import { PriceFilter as PriceFilterInput } from '../../components/search_filters/filters/price_filter';
import {
  initialState,
  setDirty,
  setFromPrice,
  setPriceCurrency,
  setPriceType,
  setToPrice,
} from '../../reducers/modules/search/price';
import { GenericFilter } from './generic_filter';
import { IApplicationState } from '../../types/redux';
import { ISearchPrice } from '../../types/filters/price';

interface IProps {
  price: ISearchPrice;
  setPriceCurrency(currency: string): void;
  setPriceType(priceType: string): void;
  setFromPrice(fromPrice: number): void;
  setToPrice(toPrice: number): void;
  setDirty(isDirty: boolean): void;
}

class PriceFilter extends GenericFilter<IProps> {
  protected setDirty(isDirty: boolean) {
    this.props.setDirty(isDirty);
  }

  public componentDidMount() {
    this.props.setPriceCurrency(initialState.currency);
    this.props.setPriceType(initialState.priceType);
  }

  public render() {
    return (
      <PriceFilterInput
        fromPrice={this.props.price.fromPrice}
        toPrice={this.props.price.toPrice}
        onChangeFromPrice={(value: number) => this.props.setFromPrice(value)}
        onChangeToPrice={(value: number) => this.props.setToPrice(value)}
      />
    );
  }
}

class CommercialPriceFilter extends React.Component<IProps> {
  public render() {
    return (
      <PriceFilterInput
        fromPrice={this.props.price.fromPrice}
        toPrice={this.props.price.toPrice}
        currency={this.props.price.currency}
        priceType={this.props.price.priceType}
        priceTypes={[
          { value: 'all', label: 'за все' },
          { value: 'meters', label: 'за м²' },
        ]}
        onChangeFromPrice={(value: number) => this.props.setFromPrice(value)}
        onChangeToPrice={(value: number) => this.props.setToPrice(value)}
        onChangeCurrency={(value: string) => this.props.setPriceCurrency(value)}
        onChangePriceType={(value: string) => this.props.setPriceType(value)}
      />
    );
  }
}

class CommercialRentPriceFilter extends React.Component<IProps> {
  public render() {
    return (
      <PriceFilterInput
        fromPrice={this.props.price.fromPrice}
        toPrice={this.props.price.toPrice}
        currency={this.props.price.currency}
        priceType={this.props.price.priceType}
        priceTypes={[
          { value: 'all', label: 'за месяц' },
          { value: 'meters', label: 'за м² в год' },
        ]}
        onChangeFromPrice={(value: number) => this.props.setFromPrice(value)}
        onChangeToPrice={(value: number) => this.props.setToPrice(value)}
        onChangeCurrency={(value: string) => this.props.setPriceCurrency(value)}
        onChangePriceType={(value: string) => this.props.setPriceType(value)}
      />
    );
  }
}

const PriceFilterWrapper = connect(
  (state: IApplicationState) => ({
    price: state.price,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }),
  dispatch => ({
    setPriceCurrency: (currency: string) => {
      dispatch(setPriceCurrency(currency));
    },
    setPriceType: (priceType: string) => {
      dispatch(setPriceType(priceType));
    },
    setFromPrice: (fromPrice: number) => {
      dispatch(setFromPrice(fromPrice));
    },
    setToPrice: (toPrice: number) => {
      dispatch(setToPrice(toPrice));
    },
    setDirty: (isDirty: boolean) => {
      dispatch(setDirty(isDirty));
    },
  }),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)(PriceFilter as any);

const CommercialPriceFilterWrapper = connect(
  (state: IApplicationState) => ({
    price: state.price,
  }),
  dispatch => ({
    setPriceCurrency: (currency: string) => {
      dispatch(setPriceCurrency(currency));
    },
    setPriceType: (priceType: string) => {
      dispatch(setPriceType(priceType));
    },
    setFromPrice: (fromPrice: number) => {
      dispatch(setFromPrice(fromPrice));
    },
    setToPrice: (toPrice: number) => {
      dispatch(setToPrice(toPrice));
    },
  }),
)(CommercialPriceFilter);

const CommercialRentPriceFilterWrapper = connect(
  (state: IApplicationState) => ({
    price: state.price,
  }),
  dispatch => ({
    setPriceCurrency: (currency: string) => {
      dispatch(setPriceCurrency(currency));
    },
    setPriceType: (priceType: string) => {
      dispatch(setPriceType(priceType));
    },
    setFromPrice: (fromPrice: number) => {
      dispatch(setFromPrice(fromPrice));
    },
    setToPrice: (toPrice: number) => {
      dispatch(setToPrice(toPrice));
    },
  }),
)(CommercialRentPriceFilter);

export {
  PriceFilterWrapper as PriceFilter,
  PriceFilter as PriceFilterClass,
  CommercialPriceFilterWrapper as CommercialPriceFilter,
  CommercialPriceFilter as CommercialPriceFilterClass,
  CommercialRentPriceFilterWrapper as CommercialRentPriceFilter,
  CommercialRentPriceFilter as CommercialRentPriceFilterClass,
};
