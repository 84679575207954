import * as React from 'react';

const s = require('./popular_list.css');

export interface IPopularContainerProps {
  children?: React.ReactNode;
}

export const PopularContainer = (props: IPopularContainerProps) => (
  <div className={`c-tabs ${s['c-popular-tabs']}`}>{props.children}</div>
);
