import { TReduxActions } from '../../../types/redux';
import { FILTERS_SELECT_COWORKING_OFFER_TYPE_TYPE } from '../../../constants/action_types';
import { JsonQuery } from '../../../utils/jsonQuery';
import { defaultState } from './defaultState';
import { IJsonQuery } from '../../../../packages/api-models/common/json_query';

export function selectCoworkingOfferTypeReducer(
  jsonQuery: IJsonQuery = defaultState,
  action: TReduxActions,
): IJsonQuery {
  switch (action.type) {
    case FILTERS_SELECT_COWORKING_OFFER_TYPE_TYPE:
      return new JsonQuery(jsonQuery).setCoworkingOfferType(action.coworkingOfferType).getSerializable();
  }

  return jsonQuery;
}
