import * as React from 'react';

import { PromoItemProp } from '../PromoItemProp';
import * as styles from './StateMortgageAttributes.css';

export interface IStateMortgageAttributesProps {
  limit?: string;
  rate?: string;
}

export const StateMortgageAttributes: React.VFC<IStateMortgageAttributesProps> = ({ limit, rate }) => {
  return (
    <div className={styles['container']} data-testid="StateMortgageAttributes">
      {rate && (
        <div className={styles['prop']}>
          <PromoItemProp label="Ставка" value={rate} />
        </div>
      )}
      {limit && (
        <div className={styles['prop']}>
          <PromoItemProp label="Сумма кредита" value={limit} />
        </div>
      )}
    </div>
  );
};
