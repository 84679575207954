export interface IClientConfig {
  baseUrl: string;
  mapUrl: string;
  storesUrls: {
    google: string;
    apple: string;
    huawei: string;
  };
}

/* tslint:disable:max-line-length */
export const config: IClientConfig = {
  baseUrl: '//master.dev3.cian.ru',
  mapUrl: '//map.cian.ru',
  storesUrls: {
    apple: 'http://m.onelink.me/1229ee8d ',
    google: 'http://m.onelink.me/318c04d5',
    huawei: 'https://appgalleryhuawei.onelink.me/9VFh/3e171a2b',
  },
};
/* tslint:enable:max-line-length */
