import { Action } from 'redux';
import { IGetBusinessPossibleAppointmentsResponse } from '../../repositories/announcements/v1/get-business-possible-appointments';
import { fetchGetBusinessPossibleAppointmentsService } from '../../services/getBusinessPossibleAppointments';
import { TThunkAction } from '../../types/redux';

export const SET_BUSINESS_APPOINTMENTS_TYPE = 'businessAppointments/set_business_appointments_type' as const;

export interface ISetBusinessAppointments extends Action<typeof SET_BUSINESS_APPOINTMENTS_TYPE> {
  payload: IGetBusinessPossibleAppointmentsResponse | null;
}

export function setBusinessAppointmentsAction(): TThunkAction {
  return async (dispatch, _getState, context) => {
    const businessAppointments = await fetchGetBusinessPossibleAppointmentsService(context);

    dispatch<ISetBusinessAppointments>({
      type: SET_BUSINESS_APPOINTMENTS_TYPE,
      payload: businessAppointments,
    });
  };
}
