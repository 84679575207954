import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { GenericFilter } from './generic_filter';
import { RoomsFilter as RoomsFilterInput } from '../../components/search_filters/filters/rooms_filter';

import { setSelectedRooms, setDirty } from '../../reducers/modules/search/rooms';
import { IRooms } from '../../types/filters/rooms';
import { IApplicationState } from '../../types/redux';

interface IProps {
  rooms: IRooms;
  section: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
}

class RoomsFilter extends GenericFilter<IProps> {
  protected setDirty(isDirty: boolean) {
    this.props.dispatch(setDirty(isDirty));
  }

  public render() {
    return (
      <RoomsFilterInput
        values={this.props.rooms.rooms}
        isRent={false}
        isNewb={this.props.section === 'new-buildings'}
        onChange={(values: string[]) => this.props.dispatch(setSelectedRooms(values))}
      />
    );
  }
}

class RoomsRentFilter extends GenericFilter<IProps> {
  protected setDirty(isDirty: boolean) {
    this.props.dispatch(setDirty(isDirty));
  }

  public render() {
    return (
      <RoomsFilterInput
        values={this.props.rooms.rooms}
        isRent={true}
        onChange={(values: string[]) => this.props.dispatch(setSelectedRooms(values))}
      />
    );
  }
}

const RoomsFilterWrapper = connect((state: { rooms: IRooms; type: { section: string } }) => ({
  rooms: state.rooms,
  section: state.type.section,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}))(RoomsFilter as any);
const RoomsRentFilterWrapper = connect((state: IApplicationState) => ({
  rooms: state.rooms,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}))(RoomsRentFilter as any);

export {
  RoomsFilterWrapper as RoomsFilter,
  RoomsFilter as RoomsFilterClass,
  RoomsRentFilterWrapper as RoomsRentFilter,
  RoomsRentFilter as RoomsRentFilterClass,
};
