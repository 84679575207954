/* eslint-disable react/no-danger */
import * as React from 'react';
import { IHousingInfo } from '../../types/housing_info';
import { declineNumeric, formatPrice } from '../../utils/format_helper';

import { Link } from '../link/link';
import { mergeStyles } from '@cian/utils';

const styles = require('./media_banner.css');

export interface IMediaBannerProps {
  containerId?: string;
  bannerId?: string;
  hasNewPlacement?: boolean;
  url?: string;
  logoImageUrl?: string;
  logoWithoutBackground?: string;
  substrateNotNeeded?: string;
  name?: string;
  actionText?: string;
  phone?: string;
  buttonText?: string;
  info?: string;
  infoText?: string;
  isApartment?: string;
  housingInfo?: IHousingInfo;
  onDidMount?(): void;
  onLinkClick?(): void;
}

export class MediaBanner extends React.Component<IMediaBannerProps> {
  public static defaultProps: IMediaBannerProps = {
    url: '',
    logoImageUrl: '',
    logoWithoutBackground: '0',
    name: '',
    actionText: '',
    phone: '',
    buttonText: 'Узнать цены',
    info: '',
  };

  public componentDidMount() {
    if (this.props.onDidMount) {
      this.props.onDidMount();
    }
  }

  private getInfoText = () => {
    let mediaInfoText: string | undefined;
    const { housingInfo } = this.props;

    if (housingInfo && housingInfo.offers_count) {
      mediaInfoText = `В продаже ${formatPrice(housingInfo.offers_count)}
     ${declineNumeric(
       housingInfo.offers_count,
       this.props.isApartment === '1'
         ? ['апартамент', 'апартамента', 'апартаментов']
         : ['квартира', 'квартиры', 'квартир'],
     )} по цене от ${formatPrice(housingInfo.min_price2)} ₽`;
    }

    return mediaInfoText;
  };

  public render() {
    const props = this.props;

    if (!this.props.logoImageUrl) {
      return <div />;
    } else {
      return (
        <div
          {...mergeStyles(styles['c-filters-brand'], props.hasNewPlacement && styles['c-filters-brand--new-placement'])}
        >
          <div className={styles['c-filters-brand-info']}>
            <a
              className={styles['c-filters-brand-flag']}
              href={props.url}
              target="_blank"
              role="img"
              rel="noopener noreferrer"
              dangerouslySetInnerHTML={{ __html: props.actionText as string }}
              onClick={props.onLinkClick}
            />
            <a
              className={
                this.props.logoWithoutBackground === '1' || this.props.substrateNotNeeded === '1'
                  ? styles['c-filters-brand-logo']
                  : styles['c-filters-brand-logo-bg']
              }
              href={props.url}
              rel="noopener noreferrer"
              role="img"
              target="_blank"
              onClick={props.onLinkClick}
            >
              <img src={props.logoImageUrl} alt="logo" />
            </a>
            <div className={styles['c-filters-brand-left']}>
              <a
                className={styles['c-filters-brand-link']}
                href={props.url}
                rel="noopener noreferrer"
                role="button"
                target="_blank"
                onClick={props.onLinkClick}
              >
                <h3
                  className={styles['c-filters-brand-title']}
                  dangerouslySetInnerHTML={{ __html: props.name as string }}
                />
                <p
                  className={styles['c-filters-brand-text']}
                  dangerouslySetInnerHTML={{ __html: (props.infoText || this.getInfoText() || props.info) as string }}
                />
              </a>
              <div className={styles['c-filters-brand-prices']}>
                <Link
                  href={props.url}
                  className={styles['c-filters-brand-button']}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={props.onLinkClick}
                >
                  {props.buttonText}
                </Link>
                <span
                  className={styles['c-filters-brand-tel']}
                  dangerouslySetInnerHTML={{ __html: props.phone as string }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
