import * as React from 'react';

import { NewbuildingPromoBlockContainer } from '../NewbuildingPromoBlock';
import { NewbuildingPromoModalLazy } from '../NewbuildingPromoModal';
import { useSelector } from 'react-redux';
import { selectNewbuildingRecommendationBCExperimentEnabled } from '../../selectors/newbuildingRecommendations/isNewbuildingRecommendationsExperimentEnabled';

export const TopNewbuildingBlockContainer: React.VFC = () => {
  const isNewbuildingRecommendationsEnabled = useSelector(selectNewbuildingRecommendationBCExperimentEnabled);

  if (isNewbuildingRecommendationsEnabled) {
    return null;
  }

  return (
    <>
      <NewbuildingPromoBlockContainer />
      <NewbuildingPromoModalLazy />
    </>
  );
};
