import { getConfig } from '@cian/config/browser';

let ymapsPromise: Promise<YMaps.IYMaps> | null = null;

export function loadYMapsApi(options: YMaps.IReadyOptions): Promise<YMaps.IYMaps> {
  if (!ymapsPromise) {
    ymapsPromise = new Promise<YMaps.IYMaps>((resolve, reject) => {
      if (window.ymaps) {
        resolve(window.ymaps);

        return;
      }

      const config = getConfig();
      const apiKey = config.get<string>('audience.YandexApiKey');
      const suggestApiKey = config.get<string>('audience.YandexSuggestApiKey');

      const element = document.createElement('script');
      element.src = `https://enterprise.api-maps.yandex.ru/2.1/?apikey=${apiKey}&suggest_apikey=${suggestApiKey}&lang=ru-RU&load=package.system&coordorder=longlat`;
      element.async = true;
      element.defer = true;
      element.onload = () => {
        resolve(window.ymaps);
      };
      element.onerror = () => {
        document.body.removeChild(element);
        reject(new Error('Unable to load Yandex Maps API'));
      };
      document.body.appendChild(element);
    });
  }

  return ymapsPromise
    .catch(e => {
      ymapsPromise = null;

      throw e;
    })
    .then(ymaps => ymaps.ready(options).then(() => ymaps));
}
