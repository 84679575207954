import * as React from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import get from 'lodash/get';
import matchesProperty from 'lodash/matchesProperty';
import { IApplicationState } from '../../types/redux';
import { trackPopularAds } from '../../utils/helpers/metrics_helper';
import { ITopGroup, ITopThree } from '../../types/top_three';
import { setRentGroupSelected } from '../../reducers/modules/top_three';
import { PopularList } from '../../components/popular_list/popular_list';

interface IProps {
  id: string;
  title: string;
  groups: ITopGroup[];
  topThree: ITopThree;
  setRentGroupSelected(index: string): void;
}

class RentsBlock extends React.Component<IProps> {
  public componentDidMount() {
    this.props.setRentGroupSelected(this.props.groups[0].id);
  }

  public getOffers() {
    return get(find(this.props.groups, matchesProperty('id', this.props.topThree.groupRentSelected)), 'offers', []);
  }

  public getProductData(group: string = this.props.topThree.groupRentSelected) {
    return get(find(this.props.groups, matchesProperty('id', group)), 'trackingProductData', []);
  }

  private onTabClick(eventLabel: string) {
    trackPopularAds(eventLabel, { impressions: this.getProductData(eventLabel) });
  }

  private onSelectItem(index: string) {
    this.props.setRentGroupSelected(index);
  }

  private onItemClick(order: number) {
    const products = this.getProductData();
    trackPopularAds(this.props.topThree.groupSaleSelected, {
      click: {
        actionField: { list: 'main_page' },
        products: products.length > order ? [products[order]] : [],
      },
    });
  }

  public render() {
    return (
      <PopularList
        id={this.props.id}
        title={this.props.title}
        groups={this.props.groups}
        selectItem={(index: string) => this.onSelectItem(index)}
        isItemSelected={(index: string) => this.props.topThree.groupRentSelected === index}
        onTabClick={(eventLabel: string) => this.onTabClick(eventLabel)}
        onItemClick={(order: number) => this.onItemClick(order)}
        getOffers={() => this.getOffers()}
      />
    );
  }
}

type StateProps = Pick<IProps, 'topThree'>;
type DispatchProps = Pick<IProps, 'setRentGroupSelected'>;
type OwnProps = Omit<IProps, keyof (StateProps & DispatchProps)>;

const RentsBlockWrapper = connect<StateProps, DispatchProps, OwnProps, IApplicationState>(
  state => ({
    topThree: state.topThree,
  }),
  dispatch => ({
    setRentGroupSelected: (index: string) => {
      dispatch(setRentGroupSelected(index));
    },
  }),
)(RentsBlock);

export { RentsBlockWrapper as RentsBlock, RentsBlock as RentsBlockClass };
