import { TReduxActions } from '../../../types/redux';
import { FILTER_SET_COWORKING_ID_TYPE } from '../../../constants/action_types';
import { JsonQuery } from '../../../utils/jsonQuery';

import { defaultState } from './defaultState';
import { IJsonQuery } from '../../../../packages/api-models/common/json_query';

export function setCoworkingIdReducer(jsonQuery: IJsonQuery = defaultState, action: TReduxActions) {
  switch (action.type) {
    case FILTER_SET_COWORKING_ID_TYPE:
      return new JsonQuery(jsonQuery)
        .clearGeo()
        .setVillage(undefined)
        .setCoworkingIds(action.coworkingIds)
        .getSerializable();
  }

  return jsonQuery;
}
