import { ca } from '@cian/analytics';

import { ESpecialTabs } from '../../constants/specialTabs';
import { EPageKind } from '../../types/page';
import { FDealType } from '../../utils/category';
import { getEventCategoryFromPageKind } from '../../utils/tracking';

export function trackDealTypeTabClick(pageKind: EPageKind, type: FDealType) {
  const TAB_NAME_MAP = {
    [FDealType.Sale]: 'sale',
    [FDealType.RentLongterm]: 'rent',
    [FDealType.RentDaily]: 'daily',
  };

  ca('eventSite', {
    category: getEventCategoryFromPageKind(pageKind),
    action: 'Click',
    label: `Tab/${TAB_NAME_MAP[type] || 'unexpected'}`,
  });
}

export function trackSpecialTabClick(pageKind: EPageKind, type: ESpecialTabs) {
  const TAB_NAME_MAP = {
    [ESpecialTabs.SearchRealtors]: 'realtor',
    [ESpecialTabs.Mortgage]: 'mortgage',
    [ESpecialTabs.Valuation]: 'calculate',
    [ESpecialTabs.AddForm]: 'add_form',
  };

  ca('eventSite', {
    category: getEventCategoryFromPageKind(pageKind),
    action: 'Click',
    label: `Tab/${TAB_NAME_MAP[type] || 'unexpected'}`,
  });
}

export function trackDealRentTrapClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'main_page',
    action: 'click',
    label: 'dealRentTrap',
  });
}
