import * as React from 'react';
import { adfoxDemandBannerConstants } from '../adfox/constants';
import { PromoImage } from './promo_image';
import { PromoLinks } from './promo_links';
import { IMAGE_APPEARANCE, IMAGE_POSITION, IPromoBlock } from '../../../shared/types/promo_block';
import { AdfoxNew } from '../adfox/adfoxNew';
import { useState } from 'react';
const s = require('./promo_block.css');

interface IProps {
  isFirstBlock: boolean;
  promoBlock: IPromoBlock;
  class?: string;
  onLinkClick(url: string): void;
}

const IMAGE_POSITIONS = {
  [IMAGE_POSITION.left]: 'left',
  [IMAGE_POSITION.right]: 'right',
  [IMAGE_POSITION.none]: 'none',
};

const IMAGE_APPEARANCES = {
  [IMAGE_APPEARANCE.show]: 'show',
  [IMAGE_APPEARANCE.hide]: 'hide',
  [IMAGE_APPEARANCE.stay]: 'stay',
};

export const PromoBlock = (props: IProps) => {
  const { isFirstBlock, promoBlock } = props;
  const imgPos = IMAGE_POSITIONS[promoBlock.imagePosition];
  const imgAppear = IMAGE_APPEARANCES[promoBlock.imageAppearance];
  const [hasDemandBanner, setHasDemandBanner] = useState(false);
  // компонент AdFoxBanner может вернуть null,
  // что пораждает ошибку типизации. Попытка поднять версии реакта и тайпскрипта
  // до версии в либе @cian/adfox не увенчалась успехом, и ошибка продолжала
  // воспроизводиться. Таким образом, вот такой хак.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  // Данный код не используется, но возможно снова понадобится
  // const AnyComponent = AdFoxBanner as any;

  return (
    <div
      className={
        `${s['c-promo-block']} ${s['c-promo-block-image-' + imgPos]} ` +
        `${s['c-promo-block-image-' + imgAppear]} ${props.class || ''}`
      }
    >
      {/* Данный код не используется, но возможно снова понадобится */}
      {/*{isFirstBlock && (*/}
      {/*  <div className={s['c-promo-block-image']}>*/}
      {/*    <AnyComponent*/}
      {/*      middleware={() => adFoxMortgageMiddleware(adfoxMortgageConstants as IAdfoxConstants)}*/}
      {/*      renderLoading={() => <PromoImage id={promoBlock.id} extraLinks={promoBlock.promo.extraLinks} />}*/}
      {/*      renderFetched={(context: IAdFoxBannerContext) =>*/}
      {/*        context.type === 'promo' ? (*/}
      {/*          <PromoMortgage*/}
      {/*            url={context.url}*/}
      {/*            imageUrl={context.imageUrl}*/}
      {/*            title={context.title}*/}
      {/*            description={context.description}*/}
      {/*            backgroundColor={context.backgroundColor}*/}
      {/*            textColor={context.textColor}*/}
      {/*          />*/}
      {/*        ) : (*/}
      {/*          <FinanceMainPageConnected*/}
      {/*            imageUrl={context.imageUrl}*/}
      {/*            reference={context.url}*/}
      {/*            bannerId={context.bannerId}*/}
      {/*          />*/}
      {/*        )*/}
      {/*      }*/}
      {/*      renderFailure={() => <PromoImage id={promoBlock.id} extraLinks={promoBlock.promo.extraLinks} />}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
      {isFirstBlock && (
        <div className={s['c-promo-block-image']}>
          <AdfoxNew
            constants={adfoxDemandBannerConstants}
            onStub={() => setHasDemandBanner(false)}
            onLoad={() => setHasDemandBanner(true)}
            onError={() => setHasDemandBanner(false)}
          />
          {!hasDemandBanner && (
            <div className={s['c-promo-block-image-replace-adfox']}>
              <PromoImage id={promoBlock.id} extraLinks={promoBlock.promo.extraLinks} />
            </div>
          )}
        </div>
      )}
      {promoBlock.imagePosition !== IMAGE_POSITION.none && !isFirstBlock && (
        <div className={s['c-promo-block-image']}>
          <PromoImage id={promoBlock.id} extraLinks={promoBlock.promo.extraLinks} />
        </div>
      )}
      <div className={s['c-promo-block-text']}>
        <PromoLinks promo={promoBlock.promo} onLinkClick={url => props.onLinkClick(url)} />
      </div>
    </div>
  );
};
