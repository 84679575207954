import * as React from 'react';

import { TBannerTheme } from '../../components/NewbuildingBanners/utils/bannerTypeToTheme';
import { PromosBanner } from '../../components/NewbuildingBanners/PromosBanner/PromosBanner';
import { TopPromoBanner } from '../NewbuildingBanners/TopPromoBanner/TopPromoBanner';
import { ConsultantBanner } from '../../components/NewbuildingBanners/ConsultantBanner/ConsultantBanner';
import { CompilationBanner } from '../../components/NewbuildingBanners/CompilationBanner/CompilationBanner';
import { TBanners, TBannerTypes } from '../../types/newbuildingBanners/banners';

interface IThemeMapperProps {
  type: TBannerTypes;
}

interface IUseBannerProp {
  themeMapper(props: IThemeMapperProps): TBannerTheme;
  data: TBanners;
}

export const Banner = ({ themeMapper, data }: IUseBannerProp) => {
  switch (data.type) {
    case 'allPromos':
      return <PromosBanner theme={themeMapper({ type: data.type })} {...data} />;
    case 'promosFlight':
      return <PromosBanner theme={themeMapper({ type: data.type })} {...data} />;
    case 'topPromo':
      return <TopPromoBanner theme={themeMapper({ type: data.type })} {...data} />;
    case 'kpn':
      return <ConsultantBanner theme={themeMapper({ type: data.type })} {...data} />;
    case 'lastChanceDiscount':
      return <CompilationBanner theme={themeMapper({ type: data.type })} {...data} />;
    case 'topPromos':
      return <CompilationBanner theme={themeMapper({ type: data.type })} {...data} />;
  }
};
