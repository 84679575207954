// tslint:disable:object-literal-sort-keys
import { IBuildingTypesConfig } from '../../../shared/types/filters/building_types';

export const BuildingTypes: IBuildingTypesConfig = {
  living: {
    name: 'Жилая',
    options: {
      flat: { values: ['Квартира', 'Квартиру'], order: 0 },
      secondary_flat: { values: ['Квартира во вторичке', 'Квартиру во вторичке'], order: 1 },
      new_flat: { values: ['Квартира в новостройке', 'Квартиру в новостройке'], order: 2 },
      room: { values: ['Комната', 'Комнату'], order: 3 },
      part: { values: ['Доля', 'Долю'], order: 4 },
      house: { values: ['Дом, дача', 'Дом, дачу'], order: 5 },
      bed: { values: ['Койко-место', 'Койко-место'], order: 6 },
      housepart: { values: ['Часть дома', 'Часть дома'], order: 7 },
      townhouse: { values: ['Таунхаус', 'Таунхаус'], order: 8 },
      sector: { values: ['Участок', 'Участок'], order: 9 },
    },
  },
  countryside: {
    name: 'Загородная',
    options: {
      houserent: { values: ['Дом, дача', 'Дом, дачу'], order: 0 },
    },
  },
  commerce: {
    name: 'Коммерческая',
    options: {
      office: { values: ['Офис', 'Офис'], order: 0 },
      trade_area: { values: ['Торговая площадь', 'Торг. площадь'], order: 1 },
      warehouse: { values: ['Склад', 'Склад'], order: 2 },
      psn: { values: ['Помещение свободного назначения', 'ПСН'], order: 3 },
      fastfood: { values: ['Общепит', 'Общепит'], order: 4 },
      garage: { values: ['Гараж', 'Гараж'], order: 5 },
      enterprise: { values: ['Производство', 'Производство'], order: 6 },
      auto: { values: ['Автосервис', 'Автосервис'], order: 7 },
      ready: { values: ['Готовый бизнес', 'Готовый бизнес'], order: 8 },
      building: { values: ['Здание', 'Здание'], order: 9 },
      domestic: { values: ['Бытовые услуги', 'Бытовые услуги'], order: 11 },
      comsector: { values: ['Коммерческая земля', 'Коммерческую землю'], order: 12 },
    },
  },
};

export const FlatTypes: IBuildingTypesConfig = {
  living: {
    name: 'Категория',
    options: {
      flat: { values: ['Любая', 'Любую'], order: 0 },
      secondary_flat: { values: ['Вторичка', 'Во вторичке'], order: 1 },
      new_flat: { values: ['Новостройка', 'В новостройке'], order: 2 },
    },
  },
};

export const BuildingTypesWithAbstractFlat: IBuildingTypesConfig = {
  ...BuildingTypes,
  living: {
    name: 'Жилая',
    options: {
      flat: { values: ['Квартира', 'Квартиру'], order: 0 },
      room: { values: ['Комната', 'Комнату'], order: 3 },
      part: { values: ['Доля', 'Долю'], order: 4 },
      house: { values: ['Дом, дача', 'Дом, дачу'], order: 5 },
      bed: { values: ['Койко-место', 'Койко-место'], order: 6 },
      housepart: { values: ['Часть дома', 'Часть дома'], order: 7 },
      townhouse: { values: ['Таунхаус', 'Таунхаус'], order: 8 },
      sector: { values: ['Участок', 'Участок'], order: 9 },
    },
  },
};
