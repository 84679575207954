/* eslint-disable @typescript-eslint/no-explicit-any */
import get from 'lodash/get';
import { IAddressSuggestion } from '../../../../utils/geo_suggest';
import { IGeoValue } from '../../../../reducers/modules/search/search';
import { IDropdownValue } from '../../../../types/dropdown';

interface INewbuildingSuggestion {
  Info: string;
  Url: string;
  LocationId: number;
  Name: string;
  Address: string;
  Lat: string;
  Lng: string;
  Type: 1 | 2;
  Id: number;
  Value: string;
}

export type TGeoSuggestItemType = 1 | 2;
export const GeoSuggestItemType = {
  Address: 1 as const,
  Newbuilding: 2 as const,
};

export interface IAbstractSuggestion {
  suggestionType: TGeoSuggestItemType;
}

export interface ITypedAddressSuggestion extends IAddressSuggestion, IAbstractSuggestion {}
export interface ITypedNewbuildingSuggestion extends INewbuildingSuggestion, IAbstractSuggestion {}

export type Suggestion = ITypedAddressSuggestion | ITypedNewbuildingSuggestion;
export type Suggestions = IGeoSuggestGroup<Suggestion>[] | IGeoSuggestItem<Suggestion>[];

const MAX_SUGGESTIONS = 4;

export interface IGeoSuggestItem<T> {
  title: string;
  value: T;
  subTitle?: string;
  externalLink?: string;
}

export interface IGeoSuggestGroup<T> {
  title: string;
  items: IGeoSuggestItem<T>[];
}

type AbstractGeoSuggestItem = IGeoSuggestItem<any>; // tslint:disable-line:no-any
type AbstractGeoSuggestGroup = IGeoSuggestGroup<any>; // tslint:disable-line:no-any

type AbstractSuggestions = AbstractGeoSuggestItem[] | AbstractGeoSuggestGroup[];

const newbuildingsSuggestionsBuildingsTypes = ['flat', 'secondary_flat', 'new_flat', 'room', 'part', 'bed'];

export const isNewbuildingsSuggestEnabledForBuildingType = (buildingType: string) => {
  return newbuildingsSuggestionsBuildingsTypes.includes(buildingType);
};

export const areSuggestsGrouped = (suggestions: AbstractSuggestions): suggestions is AbstractGeoSuggestGroup[] =>
  'items' in suggestions[0];

export const removeCountryName = (value: string) => value.replace(/(^Россия, )|(, Россия$)/i, '');

export const getAddressSuggestion = (item: IAddressSuggestion): IGeoSuggestItem<ITypedAddressSuggestion> => ({
  title: removeCountryName(item.displayName),
  value: {
    ...item,
    suggestionType: GeoSuggestItemType.Address,
  },
});

export const getNewbuildingSuggestion = (
  item: INewbuildingSuggestion,
): IGeoSuggestItem<ITypedNewbuildingSuggestion> => ({
  title: item.Name,
  subTitle: removeCountryName(item.Address),
  externalLink: item.Url,
  value: {
    ...item,
    suggestionType: GeoSuggestItemType.Newbuilding,
  },
});

export const getCombinedSuggestions = (
  addressSuggestions: IAddressSuggestion[],
  newbuildingsSuggestions: INewbuildingSuggestion[],
): Suggestions => {
  if (addressSuggestions.length && newbuildingsSuggestions.length) {
    return [
      {
        title: 'Адреса',
        items: addressSuggestions.slice(0, MAX_SUGGESTIONS).map(getAddressSuggestion),
      },
      {
        title: 'Жилые комплексы',
        items: newbuildingsSuggestions.slice(0, MAX_SUGGESTIONS).map(getNewbuildingSuggestion),
      },
    ];
  } else {
    let suggestions: IGeoSuggestItem<Suggestion>[] = [];

    if (addressSuggestions.length) {
      suggestions = suggestions.concat(addressSuggestions.slice(0, MAX_SUGGESTIONS).map(getAddressSuggestion));
    } else if (newbuildingsSuggestions.length) {
      suggestions = suggestions.concat(newbuildingsSuggestions.slice(0, MAX_SUGGESTIONS).map(getNewbuildingSuggestion));
    }

    return suggestions;
  }
};

export function getGeoRegionName(
  cities: IDropdownValue[],
  regions: IDropdownValue[],
  regionOrMaintownId: number,
  geoValue?: IGeoValue,
): string | void {
  const geoValueText = get(geoValue, 'text');

  if (geoValueText) {
    return geoValueText;
  }

  const mainTown = cities.find(city => city.value === Number(regionOrMaintownId));

  if (mainTown) {
    return mainTown.label;
  }

  const region = regions.find(region => Number(region.value) === Number(regionOrMaintownId));

  if (region) {
    return region.label;
  }
}

/*
  Выделяем три стратегии поиска внутри геосаджеста

  default - первый поиск при установке фокуса в предзаполненный дефолтной гео-информацией инпут
  straight - поиск продолжением ввода в инпут, не редактируя предзаполненную часть
  custom - поиск со скорректированной любым образом предзаполненной частью
*/

export type TSuggestSearchMode = 'default' | 'straight' | 'custom';

export const detectSuggestSearchMode = (defaultGeoText: string, newText: string): TSuggestSearchMode => {
  let suggestSearchMode: TSuggestSearchMode = 'default';

  if (newText === defaultGeoText || newText === `${defaultGeoText} `) {
    suggestSearchMode = 'default';
  } else {
    // оставлена ли предзаполненная часть поисковой строки неизменной
    if (newText.indexOf(defaultGeoText) === 0) {
      suggestSearchMode = 'straight';
    } else {
      suggestSearchMode = 'custom';
    }
  }

  return suggestSearchMode;
};

export const applySuggestSearchModeForSuggestionsParams = (
  searchText: string,
  defaultGeoText: string,
  regionOrMaintownId: number,
  suggestSearchMode?: TSuggestSearchMode,
): {
  newbuildingsSearchText: string;
  newbuildingsRegionId: number | undefined;
  isNewbuildingsGeosuggestEnabledForSearchMode: boolean;
} => {
  let isNewbuildingsGeosuggestEnabledForSearchMode = false;

  // tslint:disable-next-line:prefer-switch
  if (suggestSearchMode === 'straight' || suggestSearchMode === 'custom') {
    isNewbuildingsGeosuggestEnabledForSearchMode = true;
  } else if (suggestSearchMode === 'default') {
    isNewbuildingsGeosuggestEnabledForSearchMode = false;
  }

  let newbuildingsSearchText = searchText;
  let newbuildingsRegionId: number | undefined = regionOrMaintownId;
  const newbuildingKeyFrase = 'жк';

  if (suggestSearchMode === 'straight') {
    const newbuildingKeyFraseIndex = newbuildingsSearchText.toLowerCase().indexOf(newbuildingKeyFrase);

    if (newbuildingKeyFraseIndex !== -1) {
      newbuildingsSearchText = newbuildingsSearchText.slice(newbuildingKeyFraseIndex + newbuildingKeyFrase.length);
    } else {
      newbuildingsSearchText = newbuildingsSearchText.replace(defaultGeoText, '');
    }
  } else if (suggestSearchMode === 'custom') {
    newbuildingsRegionId = undefined;

    const newbuildingKeyFrase = 'жк';
    const newbuildingKeyFraseIndex = newbuildingsSearchText.toLowerCase().indexOf(newbuildingKeyFrase);

    if (newbuildingKeyFraseIndex !== -1) {
      newbuildingsSearchText = newbuildingsSearchText.slice(newbuildingKeyFraseIndex + newbuildingKeyFrase.length);
    }
  }

  return {
    newbuildingsSearchText,
    newbuildingsRegionId,
    isNewbuildingsGeosuggestEnabledForSearchMode,
  };
};

export const checkSuggestionType = (suggestion: IGeoSuggestItem<Suggestion>, type: TGeoSuggestItemType) =>
  suggestion.value.suggestionType === type;

export const isNewbuildingsSuggestion = (item: IGeoSuggestItem<Suggestion>): boolean =>
  checkSuggestionType(item, GeoSuggestItemType.Newbuilding);

export const isAddressSuggestion = (item: IGeoSuggestItem<Suggestion>): boolean =>
  checkSuggestionType(item, GeoSuggestItemType.Address);
