import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IAdfoxConstants } from '../../components/adfox/constants';
import { selectAdfoxRequestedParams } from '../../selectors/selectAdfoxRequestedParams';
import { getAdfoxCalculatedParams } from './getAdfoxCalculatedParams';

type TAdFoxTypeParams = { [key: string]: string };

export function useAdfoxParams(constants: IAdfoxConstants) {
  const adfoxRequestedParams = useSelector(selectAdfoxRequestedParams);

  return useMemo(() => {
    const params: TAdFoxTypeParams = {
      ...adfoxRequestedParams,
      ...constants,
      ...getAdfoxCalculatedParams(),
    };

    return params;
  }, [adfoxRequestedParams]);
}
