import { TReduxActions } from '../../../../shared/types/redux';
import { ISearchPrice, ISearchPriceAction } from '../../../../shared/types/filters/price';

export const SEARCH_PRICE_UPDATE = 'search/SEARCH_PRICE_UPDATE';

export const initialState: ISearchPrice = {
  fromPrice: undefined,
  toPrice: undefined,
  currency: 'rub',
  priceType: 'all',
  isDirty: false,
};

export const priceReducer = (state = initialState, action: TReduxActions) => {
  switch (action.type) {
    case SEARCH_PRICE_UPDATE:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
};

export const setFromPrice = (fromPrice: number): ISearchPriceAction => {
  return {
    type: SEARCH_PRICE_UPDATE,
    payload: {
      fromPrice,
    },
  };
};

export const setToPrice = (toPrice: number): ISearchPriceAction => {
  return {
    type: SEARCH_PRICE_UPDATE,
    payload: {
      toPrice,
    },
  };
};

export const setPriceCurrency = (currency: string): ISearchPriceAction => {
  return {
    type: SEARCH_PRICE_UPDATE,
    payload: {
      currency,
    },
  };
};

export const setPriceType = (priceType: string): ISearchPriceAction => {
  return {
    type: SEARCH_PRICE_UPDATE,
    payload: {
      priceType,
    },
  };
};

export const setDirty = (isDirty: boolean): ISearchPriceAction => {
  return {
    type: SEARCH_PRICE_UPDATE,
    payload: {
      isDirty,
    },
  };
};
