import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { ArticleParagraph2, UIHeading5 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './SubsidisedMortgageBanner.css';

interface ISubsidisedMortgageBannerProps {
  title: string;
  subtitle: string;
  backgroundColor: string;
  backgroundImage: string;
  url: string;
  onClick(): void;
  onShow(): void;
}

export const SubsidisedMortgageBanner: React.FC<ISubsidisedMortgageBannerProps> = ({
  title,
  subtitle,
  backgroundColor,
  backgroundImage,
  url,
  onClick,
  onShow,
}) => {
  return (
    <li>
      <ActionAfterViewObserver callback={onShow} percentVisible={0.8} triggerOnce>
        <a
          data-testid="SubsidisedMortgageBanner"
          className={styles['container']}
          href={url}
          onClick={onClick}
          style={{ background: backgroundColor }}
        >
          <div className={styles['content']}>
            <ArticleParagraph2 whiteSpace="pre-line">{title}</ArticleParagraph2>
            <UIHeading5 whiteSpace="pre-line">{subtitle}</UIHeading5>
          </div>
          <img className={styles['image']} src={backgroundImage} alt="" />
        </a>
      </ActionAfterViewObserver>
    </li>
  );
};
