import * as React from 'react';

import { SaleAdsBlock } from './sale_ads_block';
import { MapSearchBlock } from './map_search_block';

interface IProps {
  coordinates?: {
    lat: number;
    lng: number;
  };
  section: string;
  onAdsClick(eventLabel: string): void;
}

export const SaleServiceBlock = (props: IProps) => (
  <div className={'cg-row'}>
    <SaleAdsBlock onAdsClick={(label: string) => props.onAdsClick(label)} />
    <MapSearchBlock
      coordinates={props.coordinates}
      section={props.section}
      onAdsClick={(label: string) => props.onAdsClick(label)}
    />
  </div>
);
