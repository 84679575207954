import { DEAL_RENT_TRAP_MAINPAGE } from '../../constants/experiments';
import { IApplicationState } from '../../types/redux';
import { getExperimentGroup } from '../getExperimentGroup';

export function isDealRentTrapEnabled(state: IApplicationState) {
  const regionId = state.userGeo.userGeo;
  const { user } = state;
  if (user.isAuthenticated && user.isAgent) {
    return false;
  }

  if (regionId === null || !state.dealRegions.includes(regionId)) {
    return false;
  }

  const inExperiment = getExperimentGroup(state.abUseExperiments, DEAL_RENT_TRAP_MAINPAGE) === 'enabled';

  if (!inExperiment) {
    return false;
  }

  return true;
}
