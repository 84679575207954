import { AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT } from '../../constants/experiments';
import { IApplicationState } from '../../types/redux';
import { isExperimentActive } from '../../utils/isExperimentActive';
import { selectExperiments } from './selectExperiments';

export function selectIsCianAssistantExperimentEnabled(state: IApplicationState) {
  const experiments = selectExperiments(state);
  const bGroup = isExperimentActive(experiments, AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT, 'B');
  const cGroup = isExperimentActive(experiments, AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT, 'C');

  return bGroup || cGroup;
}
