const MONTHS_SHORT = ['янв.', 'фев.', 'мар.', 'апр.', 'мая', 'июня', 'июля', 'авг.', 'сен.', 'окт.', 'нояб.', 'дек.'];

export const formatDate = (date: Date, shouldPrintYear: boolean): string => {
  const fullYear = date.getFullYear();
  const year = shouldPrintYear ? `${fullYear} г.` : null;

  const dayOfMonth = date.getDate();

  const monthNumber = date.getMonth();
  const month = MONTHS_SHORT[monthNumber];

  return [dayOfMonth, month, year].filter(Boolean).join(' ');
};
