import { IOfferType } from '../../types/offerTypeList';
import {
  OFFER_TYPES_RENT_RESIDENTIAL,
  OFFER_TYPES_RENT_COMMERCIAL,
  OFFER_TYPES_SALE_COMMERCIAL,
  OFFER_TYPES_DAILY_RESIDENTIAL,
} from '../../constants/filters/offerTypes';
import { FDealType, FOfferType } from '../../utils/category';
import { getOfferTypesSaleResidentialList } from '../../utils/saleResidential';

export function normalizeOfferType(offerType: FOfferType): FOfferType {
  return (offerType & FOfferType.Residential) !== 0 ? FOfferType.Residential : FOfferType.Commercial;
}

interface IOfferTCollectionType {
  dealType: FDealType;
  offerType: FOfferType;
  list: IOfferType[][];
}

type TOfferTypes = Array<IOfferTCollectionType>;

const getOfferTypes = (isSearchFlatShareEnabled: boolean = false): TOfferTypes => [
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Residential,
    list: getOfferTypesSaleResidentialList(isSearchFlatShareEnabled),
  },
  {
    dealType: FDealType.Sale,
    offerType: FOfferType.Commercial,
    list: OFFER_TYPES_SALE_COMMERCIAL,
  },
  {
    dealType: FDealType.RentLongterm,
    offerType: FOfferType.Residential,
    list: OFFER_TYPES_RENT_RESIDENTIAL,
  },
  {
    dealType: FDealType.RentLongterm,
    offerType: FOfferType.Commercial,
    list: OFFER_TYPES_RENT_COMMERCIAL,
  },
  {
    dealType: FDealType.RentDaily,
    offerType: FOfferType.Residential,
    list: OFFER_TYPES_DAILY_RESIDENTIAL,
  },
];

export function getOfferTypeList({
  dealType,
  offerType,
  isSearchFlatShareEnabled,
}: {
  dealType: FDealType;
  offerType: FOfferType;
  isSearchFlatShareEnabled?: boolean;
}): IOfferType[][] {
  const normalizedOfferType = normalizeOfferType(offerType);

  const typeList = getOfferTypes(isSearchFlatShareEnabled).find(
    t => t.dealType === dealType && t.offerType === normalizedOfferType,
  )?.list;

  return typeList ?? <IOfferType[][]>[];
}
