import { Outside, TControlOptions, TOptionValue } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';
import { Checkbox } from '../Checkbox';
import * as React from 'react';

import * as stylesCommon from '../FiltersCommon/FiltersCommon.css';
import * as styles from './FilterDropdown.css';

export interface IFilterDropdownProps {
  selected: TOptionValue | TOptionValue[];
  multiple: boolean;
  options: TControlOptions;
  label: string;
  onValueChange(values: TOptionValue[]): void;
}

export const FilterDropdown = (props: IFilterDropdownProps) => {
  const withCloseOutsideRef = React.useRef<HTMLButtonElement>(null);
  const [isActive, setIsActive] = React.useState(false);

  const { label, selected, options, onValueChange, multiple } = props;

  const handleButtonClick = (): void => {
    setIsActive(!isActive);
  };

  const isOptionSelected = (selected: TOptionValue | TOptionValue[], option: TOptionValue): boolean => {
    const values: TOptionValue[] = Array.isArray(selected) ? selected : [selected];

    return values.includes(option);
  };

  const handleOutsideClick = (): void => {
    setIsActive(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.currentTarget.value;

    if (multiple) {
      const values: TOptionValue[] = Array.isArray(selected) ? selected : [selected];
      const set = new Set(values);
      if (set.has(value)) {
        set.delete(value);
      } else {
        set.add(value);
      }

      onValueChange([...set]);
    } else {
      onValueChange([value]);
    }
  };

  return (
    <Outside onOutside={handleOutsideClick}>
      <div {...mergeStyles(stylesCommon['container'], styles['container'])} data-mark="FilterDropdown">
        <button
          ref={withCloseOutsideRef}
          {...mergeStyles(
            stylesCommon['button'],
            styles['button'],
            isActive ? stylesCommon['button--active'] : undefined,
          )}
          title={label || undefined}
          onClick={handleButtonClick}
        >
          {label}
        </button>
        {isActive && (
          <div className={styles['dropdown']}>
            <ul className={styles['list']}>
              {options.map(option => (
                <li key={`item_${option.value}`}>
                  <Checkbox
                    label={option.label}
                    value={option.value}
                    checked={isOptionSelected(selected, option.value)}
                    onChange={handleChange}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Outside>
  );
};
