import { ca } from '@cian/analytics';
import { INewbuildingPromo } from '../../../types/newbuildingPromo';
import { mapPromoToProduct } from '../../../utils/newbuildingPromo';

interface ITrackDetailsClickParams {
  promo: INewbuildingPromo;
  index: number;
}

export const trackDetailsClick = ({ promo, index }: ITrackDetailsClickParams) => {
  ca('eventSite', {
    action: 'Click',
    category: 'main_page',
    label: 'Details',
    name: 'oldevent',
    products: [mapPromoToProduct(promo, index)],
  });
};
