import * as React from 'react';
import { useSelector } from 'react-redux';

import { ChatbotButton } from '../../components/ChatbotButton';
import { ChatPopup } from '../../components/ChatPopup';
import { selectIsUserAgent } from '../../selectors/user';
import { trackOpenChatbot } from './tracking';
import { selectIsCianAssistantExperimentEnabled } from '../../selectors/experiments/selectIsCianAssistantExperimentEnabled';
import { selectPageSection } from '../../selectors/pageSection';

export const ChatPopupContainer: React.FC = () => {
  const [isChatOpened, setIsChatOpened] = React.useState(false);

  const isAgent = useSelector(selectIsUserAgent);
  const isChatbotEnabled = useSelector(selectIsCianAssistantExperimentEnabled);
  const pageSection = useSelector(selectPageSection);

  const onClose = React.useCallback(() => setIsChatOpened(false), []);

  const onClick = React.useCallback(() => {
    trackOpenChatbot();
    setIsChatOpened(true);
  }, []);

  if (!isChatbotEnabled || isAgent || pageSection === 'new-buildings' || pageSection === 'commercial') {
    return null;
  }

  if (!isChatOpened) {
    return <ChatbotButton onClick={onClick} />;
  }

  return <ChatPopup onClose={onClose} />;
};
