import { ITypedReduxAction } from '../../types/redux/actionType';
import { INewbuildingsRecommendations } from '../../types/newbuildingRecommendations/newbuildingRecommendations';

export enum EGetNewbuildingRecommendationsStatusActionType {
  Loading = 'newbuildingRecommendations/loading',
  Succeed = 'newbuildingRecommendations/succeed',
  Failed = 'newbuildingRecommendations/failed',
}

export type TGetNewbuildingRecommendationsLoading =
  ITypedReduxAction<EGetNewbuildingRecommendationsStatusActionType.Loading>;
export type TGetNewbuildingRecommendationsSucceed = ITypedReduxAction<
  EGetNewbuildingRecommendationsStatusActionType.Succeed,
  INewbuildingsRecommendations
>;
export type TGetNewbuildingRecommendationsFailed =
  ITypedReduxAction<EGetNewbuildingRecommendationsStatusActionType.Failed>;

export type TNewbuildingRecommendationsActions =
  | TGetNewbuildingRecommendationsLoading
  | TGetNewbuildingRecommendationsSucceed
  | TGetNewbuildingRecommendationsFailed;
