import { InlineText2 } from '@cian/ui-kit/typography';
import * as React from 'react';
import { IRegion } from '../../../../types/geoSwitcher';

import * as styles from './GeoSuggest.css';

export interface IGeoSuggestProps {
  suggests: IRegion[];
  onSelect(region: IRegion): void;
}

export function GeoSuggest({ suggests, onSelect }: IGeoSuggestProps) {
  return (
    <div className={styles['suggests']}>
      {suggests.map(region => {
        return (
          <div key={region.id} className={styles['suggest']} onClick={() => onSelect(region)}>
            <InlineText2>{region.displayName}</InlineText2>
          </div>
        );
      })}
    </div>
  );
}
