import * as R from 'ramda';
import { IJsonQuery } from '../../../packages/api-models/common/json_query';

import { offerTypeFromJsonQuery, isSuburban, FOfferType } from '../category';
import { setTerm } from './helpers';
import { selectOfferType } from './selectOfferType';

export function setVillage(jsonQuery: IJsonQuery): (villageId: number | undefined) => IJsonQuery {
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  let nextJsonQuery = R.clone(jsonQuery);

  return villageId => {
    if (villageId && !isSuburban(offerType)) {
      nextJsonQuery = selectOfferType(nextJsonQuery)(FOfferType.House);
      nextJsonQuery = selectOfferType(nextJsonQuery)(FOfferType.HousePart);
      nextJsonQuery = selectOfferType(nextJsonQuery)(FOfferType.Townhouse);
      nextJsonQuery = selectOfferType(nextJsonQuery)(FOfferType.Land);
    }

    nextJsonQuery = setTerm('kp_id')(nextJsonQuery)(villageId);

    return nextJsonQuery;
  };
}
