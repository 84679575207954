import * as React from 'react';
import classNames from 'classnames';

import * as styles from './styles.css';

export interface IFiltersBackgroundProps {
  section: string;
  imageUrl?: string;
  defaultImageUrl?: string;
}

export function FiltersBackground({ section, imageUrl, defaultImageUrl }: IFiltersBackgroundProps) {
  const backgroundImageUrl = imageUrl ? imageUrl : defaultImageUrl;
  const imgClassName = imageUrl ? styles['old-image'] : styles['image'];

  return (
    <div className={styles['container']}>
      <div
        className={classNames(imgClassName, section && styles[section])}
        style={getBackgroundImage(backgroundImageUrl)}
      />
    </div>
  );
}

function getBackgroundImage(imageUrl?: string) {
  return imageUrl ? { backgroundImage: `url(${imageUrl})` } : {};
}
