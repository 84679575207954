import { clone } from 'ramda';
import { EJsonQueryReadyBusinessType, EOfficeType, IJsonQuery } from '../../../packages/api-models/common/json_query';
import { setTerms } from './helpers';

export function toggleReadyBusinessTypes(jsonQuery: IJsonQuery) {
  return (readyBusinessType: EJsonQueryReadyBusinessType) => {
    let newJsonQuery = clone(jsonQuery);
    const officeTypes = newJsonQuery.office_type?.value ?? [];
    const readyBusinessTypes = newJsonQuery.ready_business_types?.value ?? [];

    if (!officeTypes.includes(EOfficeType.Business)) {
      newJsonQuery = setTerms('office_type')(newJsonQuery)(officeTypes.concat(EOfficeType.Business));
    }

    const newReadyBusinessTypes = readyBusinessTypes.includes(readyBusinessType)
      ? readyBusinessTypes.filter(type => type !== readyBusinessType)
      : readyBusinessTypes.concat(readyBusinessType);

    if (!newReadyBusinessTypes.length) {
      const newOfficeType = newJsonQuery.office_type?.value.filter(type => type !== EOfficeType.Business) || [];

      if (newOfficeType.length) {
        newJsonQuery = setTerms('office_type')(newJsonQuery)(newOfficeType);

        return setTerms('ready_business_types')(newJsonQuery)([]);
      }

      return setTerms('ready_business_types')(newJsonQuery)(readyBusinessTypes);
    }

    return setTerms('ready_business_types')(newJsonQuery)(newReadyBusinessTypes);
  };
}
