import { VALIDATE_GEO } from '../../../actions/filters';
import { TReduxActions } from '../../../types/redux';
import { defaultState } from './defaultState';
import { IFiltersClientErrors } from '../../../types/filters';

export function clientErrorsReducer(
  state: IFiltersClientErrors = defaultState,
  action: TReduxActions,
): IFiltersClientErrors {
  switch (action.type) {
    case VALIDATE_GEO: {
      return {
        ...state,
        geo: action.payload,
      };
    }

    default:
      return state;
  }
}
