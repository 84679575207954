import * as React from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import { NewBuildingCaption as Caption } from '../../components/new_building_caption/new_building_caption';
import { IRegionsData } from '../../types/regions_data';
import { IApplicationState } from '../../types/redux';
import { IDomain } from '../../reducers/modules/domain/domain';

interface IProps {
  regionsData: IRegionsData;
  domain: IDomain;
  section: string;
}

interface ICapProps {
  section: string;
}

interface IState {
  shown: boolean;
}

class NewBuildingCaption extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);

    this.state = { shown: true };
  }

  public render() {
    if (this.captionShown()) {
      return (
        <Caption
          onClose={() => this.setState({ shown: false })}
          regionDomain={this.props.domain.domain}
          regionName={this.props.regionsData.subdomainRegionInflectedTitle}
          availableRegions={this.props.regionsData.regions}
        />
      );
    } else {
      return <div />;
    }
  }

  private captionShown() {
    if (this.props.section === 'new-buildings' && this.state.shown) {
      const geoID = this.props.regionsData.subdomainRegionId;

      if (!geoID) {
        return false;
      }

      return !find(this.props.regionsData.regions, ['id', geoID]);
    } else {
      return false;
    }
  }
}

const CaptionWrapper = connect((state: IApplicationState, props: ICapProps) => ({
  regionsData: state.mainpage.regionsData,
  domain: state.domain,
  section: props.section,
}))(NewBuildingCaption);

export { CaptionWrapper as NewBuildingCaption };
