export interface IAdFoxCalculatedParams {
  pd: string;
  pw: string;
  pv: string;
  prr: string;
  dl: string;
}

export function getAdfoxCalculatedParams(): IAdFoxCalculatedParams {
  const now = new Date();

  return {
    pd: now.getDate().toString(),
    pw: now.getDay().toString(),
    pv: now.getHours().toString(),
    prr: document.referrer ? encodeURIComponent(document.referrer) : '',
    dl: location.href,
  };
}
