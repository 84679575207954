import { ITypedReduxAction } from '../../types/redux';
import { TVacancies } from '../../types/vacancies';

export enum EVacanciesActionType {
  success = 'vacancies/success',
  failure = 'vacancies/failure',
  fetching = 'vacancies/fetching',
}

export type TVacanciesSuccess = ITypedReduxAction<EVacanciesActionType.success, TVacancies>;
export type TVacanciesFailure = ITypedReduxAction<EVacanciesActionType.failure>;
export type TVacanciesFetching = ITypedReduxAction<EVacanciesActionType.fetching>;

export type TVacanciesActions = TVacanciesSuccess | TVacanciesFailure | TVacanciesFetching;
