import React from 'react';
import { useSelector } from 'react-redux';
import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { FiltersSearchButton } from '../../components/FiltersSearchButton';
import { selectMortgageFilterDownPayment } from '../../selectors/mortgage/selectMortgageFilterDownPayment';
import { selectMortgageFilterRealtyPrice } from '../../selectors/mortgage/selectMortgageFilterRealtyPrice';
import { createMortgageMainUrl } from '../../utils/mortgage/createMortgageMainUrl';
import { trackCalculateClick } from './tracking';
import { useTrackIntoView } from './utils/useTrackIntoView';

const text = 'Получить предложения банков';

export const FiltersMortgageButtonsContainer = () => {
  const realtyPrice = useSelector(selectMortgageFilterRealtyPrice);
  const downPayment = useSelector(selectMortgageFilterDownPayment);

  return (
    <ActionAfterViewObserver callback={useTrackIntoView}>
      <FiltersSearchButton
        onClick={trackCalculateClick}
        dataMark="FiltersMortgageRightButton"
        text={text}
        title={text}
        href={createMortgageMainUrl(realtyPrice, downPayment)}
      />
    </ActionAfterViewObserver>
  );
};
