import { TReduxActions } from '../../../types/redux';
import { FILTERS_SET_DEAL_TYPE_TYPE } from '../../../constants/action_types';
import { JsonQuery } from '../../../utils/jsonQuery';
import { defaultState } from './defaultState';
import { IJsonQuery } from '../../../../packages/api-models/common/json_query';

export function setDealTypeReducer(jsonQuery: IJsonQuery = defaultState, action: TReduxActions): IJsonQuery {
  switch (action.type) {
    case FILTERS_SET_DEAL_TYPE_TYPE:
      return new JsonQuery(jsonQuery).setDealType(action.dealType).getSerializable();
  }

  return jsonQuery;
}
