import { NoSsr } from '@cian/react-utils';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'ramda';
import { ITelemetryLogger } from '@cian/telemetry/shared';

import { IApplicationState } from '../../types/redux';
import { IMagazineItem } from '../../types/magazine';
import { IDigestState } from '../../types/digest';
import { IConfig } from '../../types/config';
import { Magazine } from '../../components/magazine/magazine';
import { withApplicationContext } from '../../utils/applicationContext';
import { IApplicationContext } from '../../types/application';
import {
  EMPTY_KEY,
  SUCCESS_KEY,
  EMPTY_ARTICLES_KEY,
  EMPTY_BLOGS_KEY,
  EMPTY_NEWS_KEY,
  EMPTY_QUESTIONS_KEY,
} from './constants';

interface IProps {
  journal: IMagazineItem[];
  digest: IDigestState;
  config: IConfig;
  telemetry: ITelemetryLogger;
}

export class MagazineBlock extends React.Component<IProps, {}> {
  public componentDidMount() {
    this.sendTelemetry();
  }

  public render() {
    const news = this.getNews();
    const articles = this.getArticles();
    const blogs = this.getBlogs();
    const questions = this.getQuestions();

    const isNotAllData = !news.length && !articles.length && !blogs.length && !questions.length;

    if (isNotAllData) {
      return null;
    }

    return (
      <NoSsr>
        <Magazine
          contentUrl={this.props.config.frontContentUrl}
          news={news}
          articles={articles}
          blogs={blogs}
          questions={questions}
          digestState={this.props.digest}
        />
      </NoSsr>
    );
  }

  private getNews() {
    return this.props.journal.filter(item => item.attributes.type === 'news').slice(0, 6);
  }

  private getArticles() {
    return this.props.journal.filter(item => item.attributes.type === 'articles').slice(0, 4);
  }

  private getBlogs() {
    return this.props.journal.filter(item => item.attributes.type === 'blogs').slice(0, 3);
  }

  private getQuestions() {
    return this.props.journal.filter(item => item.attributes.type === 'questions').slice(0, 4);
  }

  private sendTelemetry() {
    const { telemetry } = this.props;

    const news = this.getNews();
    const articles = this.getArticles();
    const blogs = this.getBlogs();
    const questions = this.getQuestions();
    const isEmpty = !news.length && !articles.length && !blogs.length && !questions.length;

    if (isEmpty) {
      telemetry.increment(EMPTY_KEY);
    } else {
      telemetry.increment(SUCCESS_KEY);
    }
    if (!news.length) {
      telemetry.increment(EMPTY_NEWS_KEY);
    }
    if (!articles.length) {
      telemetry.increment(EMPTY_ARTICLES_KEY);
    }
    if (!blogs.length) {
      telemetry.increment(EMPTY_BLOGS_KEY);
    }
    if (!questions.length) {
      telemetry.increment(EMPTY_QUESTIONS_KEY);
    }
  }
}

const mapContextToProps = (context: IApplicationContext) => ({
  telemetry: context.telemetry,
});

const mapStateToProps = (state: IApplicationState) => ({
  journal: state.mainpage.contentPartData,
  digest: state.digest,
  config: state.config,
});

export const MagazineContainer = compose(
  withApplicationContext(mapContextToProps),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  connect<{}, {}, any>(mapStateToProps),
)(MagazineBlock);
