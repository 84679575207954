import * as React from 'react';
import { IRegion } from '../../types/regions_data';
import { Select } from '@cian/ui-kit';

const s = require('./new_building_caption.css');

interface IProps {
  regionDomain: string;
  regionName: string;
  availableRegions: IRegion[];
  onClose(): void;
}

export class NewBuildingCaption extends React.Component<IProps> {
  public render() {
    return (
      <div className={s['c-non-building-welcome']}>
        <div
          role="button"
          data-testid="close_button"
          className={s['c-non-building-welcome__close']}
          onClick={() => this.props.onClose()}
        />
        <div className={s['cg-container-fluid-s']}>
          <div className={s['cg-row-22']}>
            <div className={s['c-non-building-welcome__content']}>
              <div className={s['c-non-building-welcome__image']} />
              <h2 className={s['c-non-building-welcome__title']}>
                Новостройки {this.props.regionName} скоро появятся на ЦИАН
              </h2>
              <p className={s['c-non-building-welcome__description']}>
                Мы работаем над этим. Ознакомитесь с предложениями жилых комплексов в другом регионе
              </p>
              <div className={s['c-non-building-welcome__region']}>
                <Select
                  options={this.getValues()}
                  onChange={(value: string) => this.redirectToNew(value)}
                  value="www"
                  inline
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private redirectToNew(domain: string) {
    window.location.href = window.location.href.replace(this.props.regionDomain, domain);
  }

  private getValues() {
    return this.props.availableRegions.map(region => {
      return {
        value: region.subdomainName,
        label: region.title,
      };
    });
  }
}
