import { TSectionType } from './sectionType';

export enum ELandingType {
  /** Снять — квартиру на долгий срок **/
  'FlatRent' = 'flatRent',
  /** Посуточно — снять квартиру посуточно **/
  'FlatRentForDay' = 'flatRentForDay',
  /** Купить — квартиру во вторичке **/
  'SecondaryFlatSale' = 'secondaryFlatSale',
  /** Загородка — купить дом, дачу, таунхаус, участок **/
  'SuburbanSale' = 'suburbanSale',
  /** Главная cian.ru **/
  'MainPage' = 'mainPage',
}

export const mapSectionToLandingType = (section: TSectionType) => {
  switch (section) {
    case 'daily-rent':
      return ELandingType.FlatRentForDay;
    case 'sale':
      return ELandingType.SecondaryFlatSale;
    case 'rent':
      return ELandingType.FlatRent;
    case 'index':
    default:
      return ELandingType.MainPage;
  }
};
