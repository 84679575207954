export const SESSION_REGION_NAME_COOKIE_KEY = 'session_region_name';
export const FOREVER_REGION_NAME_COOKIE_KEY = 'forever_region_name';

export const SESSION_REGION_ID_COOKIE_KEY = 'session_region_id';
export const FOREVER_REGION_ID_COOKIE_KEY = 'forever_region_id';

export const SESSION_MAIN_TOWN_REGION_ID_COOKIE_KEY = 'session_main_town_region_id';
export const FOREVER_MAIN_TOWN_REGION_ID_COOKIE_KEY = 'forever_main_town_region_id';

export const MOSCOW_REGION_ID = 1;
export const MOSCOW_WITH_AREA_REGION_ID = -1;
export const MOSCOW_AREA_REGION_ID = 4593;
export const ST_PETERSBURG_REGION_ID = 2;
export const ST_PETERSBURG_WITH_AREA_REGION_ID = -2;
export const ST_PETERSBURG_AREA_REGION_ID = 4588;

export const MOSCOW_REGION_NAME = 'Москва';
export const MOSCOW_AREA_REGION_NAME = 'Московская область';
export const ST_PETERSBURG_REGION_NAME = 'Санкт-Петербург';
export const ST_PETERSBURG_AREA_REGION_NAME = 'Ленинградская область';
