import * as React from 'react';
import { connect } from 'react-redux';
import find from 'lodash/find';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import { trackPopular } from '../../utils/helpers/metrics_helper';
import { ITopThree, ITopThreeItem, ITopGroup } from '../../types/top_three';
import { IApplicationState } from '../../types/redux';
import { TopThree } from '../../components/top_three/top_three';

interface IProps {
  top3: ITopThreeItem[];
  topThree: ITopThree;
  showLinkToAll: boolean;
  services?: React.ReactNode;
}

interface IPopularProps {
  showLinkToAll: boolean;
  services?: React.ReactNode;
}

class PopularList extends React.Component<IProps> {
  public render() {
    const top = this.props.top3;

    if (top && top.length === 0) {
      if (this.props.services) {
        return this.props.services;
      } else {
        return <div />;
      }
    }

    const salesInfo = this.filterPopular(find(top || [], { id: 'sale' }));
    const rentsInfo = this.filterPopular(find(top || [], { id: 'rent' }));

    return (
      <TopThree
        // tslint:disable-next-line:no-unnecessary-callback-wrapper
        onPromoClick={(eventLabel: string) => trackPopular(eventLabel)}
        showLinkToAll={this.props.showLinkToAll}
        salesId={salesInfo ? salesInfo.id : undefined}
        salesTitle={salesInfo ? salesInfo.title : undefined}
        salesGroups={salesInfo ? salesInfo.groups : undefined}
        salesAllLink={this.getAllLink(salesInfo, this.props.topThree.groupSaleSelected)}
        rentsId={rentsInfo ? rentsInfo.id : undefined}
        rentsTitle={rentsInfo ? rentsInfo.title : undefined}
        rentsGroups={rentsInfo ? rentsInfo.groups : undefined}
        rentsAllLink={this.getAllLink(rentsInfo, this.props.topThree.groupRentSelected)}
        services={this.props.services}
      />
    );
  }

  private getAllLink = (info: ITopThreeItem | null, selected: string) => {
    if (info && info.groups) {
      const group = find(info.groups, { id: selected });
      if (group) {
        return group.url;
      }
    }

    return undefined;
  };

  private filterPopular = (item: ITopThreeItem | undefined) => {
    if (!item) {
      return null;
    }
    const filteredItem = item;

    filteredItem.groups = filter(filteredItem.groups as ITopGroup[], (group: ITopGroup) => group.offers.length > 1);

    return isEmpty(filteredItem.groups) ? null : filteredItem;
  };
}

const PopularListWrapper = connect((state: IApplicationState, props: IPopularProps) => ({
  top3: state.mainpage.top3,
  topThree: state.topThree,
  isFetching: state.mainpage.isFetching,
  services: props.services,
  showLinkToAll: props.showLinkToAll,
}))(PopularList);

export { PopularListWrapper as PopularList, PopularList as PopularListClass };
