import { TReduxActions } from '../../../types/redux';
import { ISuggestionsState, ESuggestionsStatus } from '../../../types/filters';
import { FILTERS_GET_GEO_SUGGESTIONS_TYPE } from '../../../constants/action_types';
import { defaultState } from './defaultState';

export function suggestionsReducer(state: ISuggestionsState = defaultState, action: TReduxActions): ISuggestionsState {
  switch (action.type) {
    case FILTERS_GET_GEO_SUGGESTIONS_TYPE:
      if (action.status !== ESuggestionsStatus.Succeed) {
        return {
          ...state,
          status: action.status,
        };
      }

      return {
        ...state,
        status: action.status,
        suggestions: action.suggestions,
        sortOrder: action.sortOrder,
      };
  }

  return state;
}
