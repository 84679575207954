import { SET_SPECIALITY_TYPES_TYPE } from '../../actions/specialtyTypes/setSpecialtyTypes';
import { IGetPossibleAppointmentItem } from '../../repositories/announcements/entities/AnnouncementReferences/GetPossibleAppointmentItem';
import { TReduxActions } from '../../types/redux';
import { TSpecialtyTypes } from '../../types/specialtyTypes';

const initialState: TSpecialtyTypes = {
  isLoaded: false,
  items: Array.of<IGetPossibleAppointmentItem>(),
};

export function setSpecialtyTypesReducer(state = initialState, action: TReduxActions): TSpecialtyTypes {
  switch (action.type) {
    case SET_SPECIALITY_TYPES_TYPE:
      return {
        ...state,
        isLoaded: true,
        items: action.payload,
      };
  }

  return state;
}
