import * as React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationContext } from '../../../utils/applicationContext';
import { IApplicationState } from '../../../types/redux';
import { trackMortgageShow } from '../tracking';
import { selectUserId } from '../../../selectors/user';

export const useTrackIntoView = () => {
  const userId = useSelector(selectUserId);
  const abGroup = useSelector((state: IApplicationState) => state.config.abGroup);
  const { config } = React.useContext(ApplicationContext);

  return React.useCallback(
    (isTeaserShow: boolean) => {
      const soprApiUrl = config.get<string>('soprApi.browserUrl');

      if (!isTeaserShow || !soprApiUrl) {
        return;
      }

      trackMortgageShow({
        abGroup,
        userId,
        isTeaserShow,
        soprApiUrl,
      });
    },
    [abGroup, userId, config],
  );
};
