import { TReduxActions } from '../../types/redux';
import { SET_ACTIVE_SPECIAL_TAB } from '../../actions/specialTabs';
import { ISpecialTabsState } from '../../types/specialTabs';

const defaultState: ISpecialTabsState = {
  specialTabActive: null,
};

export function specialTabsReducer(state = defaultState, action: TReduxActions): ISpecialTabsState {
  switch (action.type) {
    case SET_ACTIVE_SPECIAL_TAB:
      return {
        ...state,
        specialTabActive: action.payload,
      };
    default:
      return state;
  }
}
