import { DEFAULT_FILTERS } from './default_filters';
import { TReduxActions } from '../../../../shared/types/redux';
import { ISearchType, ISearchTypeAction, SearchType } from '../../../../shared/types/filters/type';

export const SEARCH_TYPE_UPDATE = 'search/SEARCH_TYPE_UPDATE';

const initialState: ISearchType = {
  searchType: 'buy',
  isDirty: false,
};

export const typeReducer = (state = initialState, action: TReduxActions) => {
  switch (action.type) {
    case SEARCH_TYPE_UPDATE:
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
};

export const setSearchType = (searchType: SearchType): ISearchTypeAction => {
  return {
    type: SEARCH_TYPE_UPDATE,
    payload: {
      searchType,
    },
  };
};

export const setDirty = (isDirty: boolean): ISearchTypeAction => {
  return {
    type: SEARCH_TYPE_UPDATE,
    payload: {
      isDirty,
    },
  };
};

export const setTypeSection = (section: string): ISearchTypeAction => {
  return {
    type: SEARCH_TYPE_UPDATE,
    payload: {
      section,
      searchType: DEFAULT_FILTERS[section].type_value,
    },
  };
};
