import { HttpBadStatusCodeError, UnknownError } from '@cian/peperrors/shared';
import {
  fetchGetBusinessPossibleAppointments,
  IGetBusinessPossibleAppointmentsResponse,
  TGetBusinessPossibleAppointmentsResponse,
} from '../../repositories/announcements/v1/get-business-possible-appointments';
import { IApplicationContext } from '../../types/application';

const domain = 'src/shared/services/getBusinessPossibleAppointments/service.ts';

export async function fetchGetBusinessPossibleAppointmentsService(
  dependencies: IApplicationContext,
): Promise<IGetBusinessPossibleAppointmentsResponse | null> {
  const { httpApi, logger } = dependencies;

  try {
    const result = (await fetchGetBusinessPossibleAppointments({
      httpApi,
      parameters: {},
    })) as TGetBusinessPossibleAppointmentsResponse;

    const { statusCode } = result;

    switch (result.statusCode) {
      case 200:
        return result.response;
      case 400:
        throw new HttpBadStatusCodeError({
          details: { errors: result.response.errors },
          domain,
          message: result.response.message,
          statusCode: result.statusCode,
        });

      default:
        throw new HttpBadStatusCodeError({
          domain,
          message: 'Неизвестный статус ответа от сервера',
          statusCode,
        });
    }
  } catch (error) {
    if (error instanceof HttpBadStatusCodeError) {
      logger.warning(error.message, {
        ...error.details,
        domain: error.domain,
        statusCode: error.statusCode,
      });
    } else {
      const e = error as Error;

      const unknownError = new UnknownError({
        details: {
          errorName: e.name,
          errorStack: e.stack,
          degradation: 'Запрашивались назначения готового бизнеса',
          description: 'announcements /public/v1/get-business-possible-appointments/ rejection error',
        },
        domain,
        message: e.message,
      });

      logger.error(unknownError.message, {
        ...unknownError.details,
        domain: unknownError.domain,
      });
    }

    return null;
  }
}
