import { IMakeRequest, isSuccessResponse } from '../../../utils/network_helper';
import { TThunkAction } from '../../../types/redux';
import { IGeoBoundedBy, IGeocodeError } from '../../../types/filters/user_geo';

export function getBbox(makeRequest: IMakeRequest, regionOrMaintownId: number): Promise<IGeoBoundedBy> {
  return makeRequest({
    method: 'GET',
    uri: {
      path: `/cian-api/site/v1/get-location/`,
      query: `regionId=${regionOrMaintownId}`,
    },
  }).then((response: IGeoBoundedBy | IGeocodeError) => {
    if (!isSuccessResponse<IGeoBoundedBy, IGeocodeError>(response, 'data')) {
      throw new Error();
    }

    return response;
  });
}

export function getGeoBbox(regionOrMaintownId: number): TThunkAction<Promise<IGeoBoundedBy>> {
  return (dispatch, getState, context) => {
    return getBbox(context.makeRequest, regionOrMaintownId);
  };
}
