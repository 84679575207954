import * as React from 'react';
import { connect } from 'react-redux';

import { trackService } from '../../utils/helpers/metrics_helper';
import { IRegionsData } from '../../types/regions_data';
import { IApplicationState } from '../../types/redux';
import { SaleServiceBlock } from '../../components/service_block/sale_service_block';

interface IProps {
  regionsData: IRegionsData;
  section: string;
}

export class SaleServicesComponent extends React.Component<IProps> {
  public render() {
    return (
      <SaleServiceBlock
        coordinates={this.props.regionsData.subdomainRegionCoordinates}
        section={this.props.section}
        // tslint:disable-next-line:no-unnecessary-callback-wrapper
        onAdsClick={(eventLabel: string) => trackService(eventLabel)}
      />
    );
  }
}

export const SaleServices = connect<IProps, {}, {}>((state: IApplicationState) => ({
  regionsData: state.mainpage.regionsData,
  section: state.mainpage.section,
}))(SaleServicesComponent);
