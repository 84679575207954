import { Action } from 'redux';

import { TThunkAction } from '../../types/redux';
import { FILTERS_SET_MAX_LAND_AREA_TYPE } from '../../constants/action_types';
import { getMeta } from './getMeta';

export type TSetMaxLandAreaType = 'filters/set_max_land_area';

export interface ISetMaxLandArea extends Action<TSetMaxLandAreaType> {
  type: TSetMaxLandAreaType;
  maxLandArea: number | undefined;
}

export type TSetMaxLandArea = ISetMaxLandArea;

export function setMaxLandArea(maxLandArea: number | undefined): TThunkAction {
  return dispatch => {
    dispatch<TSetMaxLandArea>({
      type: FILTERS_SET_MAX_LAND_AREA_TYPE,
      maxLandArea,
    });

    dispatch(getMeta());
  };
}
