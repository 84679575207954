import { TCalendarDay } from '../../types/calendar';
import { getFirstWeekDayOfMonth } from './getFirstWeekDayOfMonth';
import { getNumberOfWeeksInMonth } from './getNumberOfWeeksInMonth';

interface IParams {
  month: number;
  year: number;
  start: Date;
  end: Date;
}

export const getDaysData = (params: IParams): Array<TCalendarDay> => {
  const { month, year, start, end } = params;

  const numberOfDaysInMonth = new Date(year, month + 1, 0).getDate();
  const firstWeekDayOfMonth = getFirstWeekDayOfMonth(month, year);
  const numberOfWeeksInMonth = getNumberOfWeeksInMonth(firstWeekDayOfMonth, numberOfDaysInMonth);

  const daysArray = [...new Array(numberOfWeeksInMonth * 7)];

  [...new Array(numberOfDaysInMonth).keys()].forEach(index => {
    const key = index + firstWeekDayOfMonth;
    const day = index + 1;
    const date = new Date(year, month, day);
    const isDateAvailable = start <= date && date <= end;

    daysArray[key] = {
      number: day,
      date,
      type: isDateAvailable ? 'available' : 'disabled',
    };
  });

  return daysArray;
};
