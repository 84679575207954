import { ensureMicrofrontend } from './ensureMicrofrontend';
import { createApplicationState } from '../applicationState';
import { renderApplication } from '../renderApplication';
import { initializeGeoLinks } from '../../geo_links';
import { createContext } from './createContext';

export async function runApp() {
  const context = createContext();

  const store = createApplicationState(context);

  const microfrontend = await ensureMicrofrontend();
  renderApplication({ store, context, microfrontend });

  initializeGeoLinks();
}
