import * as React from 'react';
import { IDropdownValue } from '../../../types/dropdown';

import { onInsideClick } from './helpers';
import { OptionalDropdown } from './optional_dropdown';
import { filterChange, filterClick } from './tracking';

const s = require('../search_filters.css');

interface IProps {
  fromPrice?: number;
  toPrice?: number;
  currency?: string;
  priceType?: string;
  priceTypes?: IDropdownValue[];

  onChangeFromPrice(value: number | void): void;
  onChangeToPrice(value: number | void): void;
  onChangeCurrency?(value: string): void;
  onChangePriceType?(value: string): void;
}

class PriceFilter extends React.Component<IProps, {}> {
  private minPrice: number | void;
  private maxPrice: number | void;

  public constructor(props: IProps) {
    super(props);

    this.minPrice = props.fromPrice;
    this.maxPrice = props.toPrice;
  }

  public render() {
    return (
      <div className={`${s['c-filters-field']} ${s['c-filters-field-price']}`}>
        <div className={s['c-several-filters-field']}>
          <div className={s['c-several-filters-field-part']} onClick={onInsideClick(() => filterClick('MinPrice'))}>
            <div className={`${s['c-several-filters-dropdown-container']}`}>
              <input
                className={s['c-filters-field-price-text-input']}
                placeholder="от"
                onChange={this.handleValueChangeFromPrice}
                value={this.props.fromPrice}
                onBlur={() => {
                  if (this.minPrice !== this.props.fromPrice) {
                    filterChange(
                      'MinPrice',
                      String(typeof this.props.fromPrice !== 'undefined' ? this.props.fromPrice : 'deleted'),
                    );

                    this.minPrice = this.props.fromPrice;
                  }
                }}
              />
            </div>
          </div>
          <div
            className={`${s['c-several-filters-field-double']}
              ${this.props.currency ? '' : s['c-several-filters-field_disabled']}`}
          >
            <div className={s['c-several-filters-field-part']} onClick={onInsideClick(() => filterClick('MaxPrice'))}>
              <div className={`${s['c-several-filters-dropdown-container']}`}>
                <input
                  className={s['c-filters-field-price-text-input']}
                  placeholder="до"
                  onChange={this.handleValueChangeToPrice}
                  value={this.props.toPrice}
                  onBlur={() => {
                    if (this.maxPrice !== this.props.toPrice) {
                      filterChange(
                        'MaxPrice',
                        String(typeof this.props.toPrice !== 'undefined' ? this.props.toPrice : 'deleted'),
                      );

                      this.maxPrice = this.props.toPrice;
                    }
                  }}
                />
              </div>
            </div>
            <div className={s['c-several-filters-field-part']} onClick={onInsideClick(() => filterClick('Currency'))}>
              <OptionalDropdown
                values={
                  this.props.currency
                    ? [
                        { value: 'rub', label: '₽' },
                        { value: 'usd', label: '$' },
                        { value: 'eur', label: '€' },
                      ]
                    : [{ value: 'rub', label: '₽' }]
                }
                value={this.props.currency || 'rub'}
                onChange={(value: string) => {
                  filterChange('Currency', value);

                  if (this.props.onChangeCurrency) {
                    this.props.onChangeCurrency(value);
                  }
                }}
              />
            </div>
            {this.props.priceType && (
              <div
                className={s['c-several-filters-field-part']}
                onClick={onInsideClick(() => filterClick('PaymentType'))}
              >
                <OptionalDropdown
                  values={this.props.priceTypes || []}
                  value={this.props.priceType}
                  onChange={(value: string) => {
                    filterChange('PaymentType', value);

                    if (this.props.onChangePriceType) {
                      this.props.onChangePriceType(value);
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  private handleValueChangeToPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeToPrice(Number(event.currentTarget.value));
  };

  private handleValueChangeFromPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChangeFromPrice(Number(event.currentTarget.value));
  };
}

export { PriceFilter };
